import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
} from "react-bootstrap";
import { allApiUrl } from "../../../api/apiRoute";
import { apiPostMethod } from "../../../api/rest";
import { Link, useHistory } from "react-router-dom";
//Icons
// import leftArrow from '../../shared/assets/icons/left-arrow.svg';

//SideBanner
import SideBanner from "../sideBanner";
import toastMessages from "../../toastMessages";
import CommonButton from "../../common-ui/Button";
function ForgotPassword() {
  const history = useHistory();
  const [resetPassword, setResetPassword] = useState({ email: "" });
  const [resetPasswordResponse, setResetPasswordResponse] = useState("");
  const [loader, setLoader] = useState(false);
  async function onSubmit(e) {
    setLoader(true);
    e.preventDefault();
    try {
      const response = await apiPostMethod(
        allApiUrl.forgot_password,
        resetPassword,
        ""
      );
      if (response.status === 200) {
        setLoader(false);
        let token = response.data.token.split('token=')[1]
        history.push(`/changepassword?token=${token}`);
      }
    } catch (err) {
      setLoader(false);
      console.log("err", err);
      toastMessages("error", err.data.message);
      setResetPasswordResponse(err.data);
    }
  }
  return (
    <React.Fragment>
      <section className="formWrapper">
        <Container fluid>
          <Row className="flex-row-reverse">
            <SideBanner />
            <Col xl={5} lg={6} className="formGrid">
              <div className="formOuter">
                <div className="formOuter__inner">
                  <h1>Reset Password</h1>
                  <Form onSubmit={onSubmit}>
                    <Form.Group className="form-group">
                      <Form.Label>
                        Email or Username<sup>*</sup>
                      </Form.Label>
                      <Form.Control
                        className="formField notCapital"
                        type="text"
                        required
                        value={resetPassword.email}
                        onChange={(e) =>
                          setResetPassword({
                            ...resetPassword,
                            email: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                    {resetPasswordResponse &&
                      resetPasswordResponse.message ==  //eslint-disable-line
                      "No such user found." && (
                        <p style={{ color: "red" }}>
                          {resetPasswordResponse.message}
                        </p>
                      )}
                    <CommonButton
                      buttonName="Reset Password"
                      type="fullWidth"
                      loader={loader}
                    />
                    <span className="backToLogin">
                      <Link className="linkText" to="/login">Back To Login</Link>
                    </span>
                    {/* <Button
                      className="commonButton commonButton--fullWidth"
                      type="submit"
                    >
                      Reset Password
                    </Button> */}
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
}
export default ForgotPassword;
