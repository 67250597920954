import React, { useEffect, useState, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Table,
  Button,
  Container,
  Row,
  Col,
  Form,
} from "react-bootstrap";
import {
  StandaloneSearchBox,
  useJsApiLoader,
} from "@react-google-maps/api";
import queryString from "query-string";
import LoadMap from "../mapComponent/Map"
import Header from "../common/header";
import Sidebar from "../common/sidebar";
import toastMessages from "../toastMessages";
import { apiGetMethod, apiPostMethod } from "../../api/rest";
import { allApiUrl } from "../../api/apiRoute";
import { c, titleCase } from "../../features/helper";
import dummyProduct from "../../components/shared/assets/images/dummyProduct.jpg";
import Loader from "../shared/spinner";
import { v4 as uuidv4 } from "uuid";
import SidebarTwo from "../common/sidebarTwo";
import { formatDate_mm_dd_yyyy } from "../../features/helper";
import refund1 from "../shared/assets/images/refund1.svg";
import { CustomModal } from "../common-ui/Modal";
// import CommonButton from '../common-ui/Button';
import CustomTooltip from "../common/tooltip";
import CommonButton from "../common-ui/Button";
import Select from "react-select";
import { useToast } from "react-toastify";
import DummyLogo from "../shared/assets/images/dummyLogo.png";
import Logo from "../shared/assets/images/foodBuddyLogo.svg";
import Modal from "react-bootstrap/Modal";
// import { LocationSearchInput } from "../mapComponent/autoComplete";
//Partner image
import orderAppsImg1 from "../shared/assets//template/image-1.png";
import orderAppsImg2 from "../shared/assets/template/image-2.png";
import orderAppsImg3 from "../shared/assets/template/image-3.png";
import orderAppsImg4 from "../shared/assets/template/image-4.png";

import galleryImage1 from "../shared/assets/template/gallery/1.jpeg"
import galleryImage2 from "../shared/assets/template/gallery/2.jpeg"
import galleryImage3 from "../shared/assets/template/gallery/3.jpeg"
import galleryImage4 from "../shared/assets/template/gallery/4.jpeg"
import galleryImage5 from "../shared/assets/template/gallery/5.jpeg"

import PreviewTemplate from "../template/preview";
//social Media Image
// import SocialMedia1 from "../shared/assets/images/socialMedia/social1.png"
// import SocialMedia2 from "../shared/assets/images/socialMedia/social2.png"
// import SocialMedia3 from "../shared/assets/images/socialMedia/social3.png"
import PlaceComponent from "../mapComponent/autoComplete";
import Template from "../template";
// import Nopermission from "../nopermission";
const images = [orderAppsImg1, orderAppsImg2, orderAppsImg3, orderAppsImg4];
const galleryPhotos = [galleryImage1, galleryImage2, galleryImage3, galleryImage4, galleryImage5]
// const socialMediaImages = [SocialMedia1, SocialMedia2, SocialMedia3]

const PreviewModal = ({
  showPreview,
  setShowPreview,
  previewAllTemplate,
  children,
  currentModal,
  setPreviewAllTemplate
}) => {
  let modalname = previewAllTemplate ? "Website Template" : currentModal.substring(5);
  return (
    <Modal
      show={showPreview}
      fullscreen={true}
      className="modal-fullscreen modal-dark"
      onHide={() => {
        setPreviewAllTemplate(false)
        setShowPreview(false)
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {modalname}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
};
const HeaderWebsite = (props) => {
  const [websiteBuilder, setWebsiteBuilder] = useState({
    DescriptionOfRestaurant: "",
  });
  useEffect(() => {
    // c('97')
    if (props.apiwebsiteData[0].headerDetails) {
      setWebsiteBuilder(props.apiwebsiteData[0].headerDetails);
    }
  }, []);
  const saveHeaderData = async (event) => {
    event.preventDefault()
    props.setLoader({ ...props.loader, header: true })
    let headerDetails = {
      branchDetails: props.branchArray,
      DescriptionOfRestaurant: websiteBuilder.DescriptionOfRestaurant,
    };
    if (props.isHeaderDeleted) {
      headerDetails["logo"] = {};
    }
    if (props.isBannerimageDeleted) {
      headerDetails["bannerImage"] = {};
    }
    try {
      const formData = new FormData();
      formData.append("logo", props.logo);
      formData.append("bannerImage", props.backgroundImage);
      formData.append(
        "websiteTemplate",
        JSON.stringify({ headerDetails: headerDetails })
      );
      const response = await apiPostMethod(allApiUrl.updateWebsiteBuilder, formData, props.header);
      if (response.status === 200) {
        toastMessages("success", "Header Updated Successfully");
        props.setModalShow(false);
        props.setLoader({ ...props.loader, header: false })
        props.setIsDataUpdated(!props.isDataUpdated);
      }
    } catch (err) {
      toastMessages("error", err.data.message);
    }
  };
  return (
    <div className="whiteBg whiteBg--minHeight whiteBg--topRadiusNone small">
      <Form>
        <Form.Group className="form-group text-start">
          <Form.Label>Header Logo</Form.Label>
          <div className="imagePreview">
            <Form.Control
              type="file"
              placeholder=""
              accept="image/*"
              required
              style={{ display: "none" }}
              id="file-upload"
              onChange={(e) => {
                if (e) {
                  const file = e.target.files;
                  if (
                    file &&
                    file[0] &&
                    file[0].type &&
                    file[0].type.split("/")[0] == "image"
                  ) {
                    props.setPreviewImage({
                      ...props.previewImage,
                      headerLogo: URL.createObjectURL(file[0]),
                    });
                    props.setLogo(file[0]);
                    props.setIsHeaderDeleted(false);
                  } else {
                    toastMessages(
                      "error",
                      "The image format is not correct"
                    );
                  }
                }
              }}
            />
            <label htmlFor="file-upload" className="custom-file-upload">
              Upload Image
            </label>
            <div className="imageView">
              {props.previewImage && props.previewImage.headerLogo && (
                <img src={props.previewImage.headerLogo} alt="Header Logo"
                  onError={(e) => {
                    e.target.src = dummyProduct // Replace with a fallback image
                  }}
                />
              )}
              {props.previewImage.headerLogo &&
                props.previewImage.headerLogo.length > 0 && (
                  <i
                    className="fa-solid fa-trash-can pointer"
                    onClick={() => {
                      props.setIsHeaderDeleted(true);
                      props.setLogo("");
                      props.setPreviewImage({ ...props.previewImage, headerLogo: "" });
                    }}
                  ></i>
                )}
            </div>
          </div>
        </Form.Group>
        {/*//////////////////////Banner Image///////////////////*/}
        <Form.Group className="form-group text-start">
          <Form.Label>Banner Image</Form.Label>
          <div className="imagePreview">
            <Form.Control
              type="file"
              placeholder=""
              accept="image/*"
              required
              style={{ display: "none" }}
              id="file-upload_banner"
              onChange={(e) => {
                if (e) {
                  const file = e.target.files;
                  if (
                    file &&
                    file[0] &&
                    file[0].type &&
                    file[0].type.split("/")[0] == "image"
                  ) {
                    props.setPreviewImage({
                      ...props.previewImage,
                      bannerImage: URL.createObjectURL(file[0]),
                    });
                    props.setBackgroundImage(file[0]);
                    props.setIsBannerImageDeleted(false);
                  } else {
                    toastMessages(
                      "error",
                      "The image format is not correct"
                    );
                  }
                }
              }}
            />
            <label
              htmlFor="file-upload_banner"
              className="custom-file-upload"
            >
              Upload Image
            </label>
            <div className="imageView ms-3">
              {props.previewImage && props.previewImage.bannerImage && (
                <img src={props.previewImage.bannerImage} alt="Banner Image" onError={(e) => {
                  e.target.src = dummyProduct // Replace with a fallback image
                }} />
              )}
              {props.previewImage.bannerImage &&
                props.previewImage.bannerImage.length > 0 && (
                  <i
                    className="fa-solid fa-trash-can pointer"
                    onClick={() => {
                      props.setIsBannerImageDeleted(true);
                      props.setBackgroundImage("");
                      props.setPreviewImage({ ...props.previewImage, bannerImage: "" });
                    }}
                  ></i>
                )}
            </div>
          </div>
        </Form.Group>
        {/* /////////////////////////////////// */}

        <Form.Group className="form-group">
          <Form.Label>Description of Restaurant</Form.Label>
          <Form.Control
            as="textarea"
            className="me-3 notCapital"
            placeholder="description"
            value={websiteBuilder?.DescriptionOfRestaurant}
            onChange={(e) => {
              setWebsiteBuilder({
                ...websiteBuilder,
                DescriptionOfRestaurant: e.target.value,
              });
            }}
          />
        </Form.Group>
        <Form.Group className="form-group">
          <CommonButton
            buttonName="Save"
            onClick={(event) => saveHeaderData(event)}
            type="half"
            loader={props.loader.header}
          />
        </Form.Group>
      </Form>
    </div>
  );
};
const ContactDetails = (props) => {
  const [contactDetail, setContactDetail] = useState({
    aboutTheRestaurant: "",
    review: "",
    openingDays: [],
    openingHours: {
      startTiming: "",
      startPostMeridiem: { label: "", value: "" },
      endTiming: "",
      endPostMeridiem: { label: "", value: "" },
    },
  });
  useEffect(() => {
    if (props.apiwebsiteData[0].contactDetail) {
      setContactDetail(props.apiwebsiteData[0].contactDetail);
    }
  }, []);
  const saveContactDetailHandler = async (e) => {
    e.preventDefault()
    props.setLoader({ ...props.loader, contact: true });
    const formData = new FormData();
    formData.append(
      "websiteTemplate",
      JSON.stringify({ contactDetail: contactDetail })
    );
    if (contactDetail.openingDays.length) {

      try {
        const response = await apiPostMethod(
          allApiUrl.updateWebsiteBuilder,
          formData,
          props.header
        );
        if (response.status === 200) {
          toastMessages("success", "contact Updated Successfully");
          props.setLoader({ ...props.loader, contact: false });
          props.setModalShow(false);
          props.setIsDataUpdated(!props.isDataUpdated);
        }
      } catch (err) {
      }
    }
    else {
      toastMessages('error', "Opening Days cannot be empty")
      props.setLoader({ ...props.loader, contact: false });
    }
  };
  let quantity_input__screen = {
    width: "40px",
    height: "30px",
  };
  return (
    <div className="orderDetail">
      <div className="whiteBg whiteBg--topRadiusNone small">
        <Form>
          <Form.Group className="form-group group-flex">
            <Form.Label>About the Restaurant</Form.Label>
            <Form.Control
              onChange={(e) => {
                setContactDetail((prevData) => {
                  prevData.aboutTheRestaurant = e.target.value;
                  return { ...prevData };
                });
              }}
              className="notCapital"
              as="textarea"
              value={contactDetail.aboutTheRestaurant}
            />
          </Form.Group>
          <Form.Group className="form-group group-flex">
            <Form.Label>Review</Form.Label>
            <Form.Control
              onChange={(e) => {
                setContactDetail((prev) => {
                  prev.review = e.target.value;
                  return { ...prev };
                });
              }}
              type="text"
              value={contactDetail.review}
            />
          </Form.Group>
          <Form.Group className="form-group group-flex">
            <Form.Label>Opening Days</Form.Label>
            <Select
              className="select2 singleSelect"
              isMulti
              placeholder="Select"
              options={[
                {
                  value: "Monday",
                  label: "Monday",
                },
                {
                  value: "Tuesday",
                  label: "Tuesday",
                },
                {
                  value: "Wednesday",
                  label: "Wednesday",
                },
                {
                  value: "Thursday",
                  label: "Thursday",
                },
                {
                  value: "Friday",
                  label: "Friday",
                },
                {
                  value: "Saturday",
                  label: "Saturday",
                },
                {
                  value: "Sunday",
                  label: "Sunday",
                },
              ]}
              value={contactDetail.openingDays}
              onChange={(e) => {
                setContactDetail((prevData) => {
                  prevData.openingDays = e;
                  return { ...prevData };
                });
              }}
              labelledBy="Select"
            />
          </Form.Group>
          <Form.Group className="form-group group-flex">
            <Form.Label>Opening Hours</Form.Label>
            <div className="form-group group-flex">
              <Row className="g-2">
                <Col xs={3}>
                  <Form.Control
                    className={quantity_input__screen}
                    onChange={(e) => {
                      setContactDetail((prevData) => {
                        prevData.openingHours.startTiming = e.target.value;
                        return { ...prevData };
                      });
                    }}
                    type="text"
                    placeholder="To"
                    value={contactDetail.openingHours.startTiming}
                  />
                </Col>
                <Col xs={3}>
                  <Select
                    isSearchable={false}
                    onChange={(e) => {
                      setContactDetail((prevData) => {
                        prevData.openingHours.startPostMeridiem = e;
                        return { ...prevData };
                      });
                    }}
                    className="select2 singleSelect"
                    placeholder="Select"
                    options={[
                      {
                        value: "AM",
                        label: "AM",
                      },
                      {
                        value: "PM",
                        label: "PM",
                      },
                    ]}
                    value={contactDetail.openingHours.startPostMeridiem}
                    labelledBy="Select"
                  />
                </Col>
                <Col xs={3}>
                  <Form.Control
                    onChange={(e) => {
                      setContactDetail((prevData) => {
                        prevData.openingHours.endTiming = e.target.value;
                        return { ...prevData };
                      });
                    }}
                    type="text"
                    placeholder="From"
                    value={contactDetail.openingHours.endTiming}
                  />
                </Col>
                <Col xs={3}>
                  <Select
                    onChange={(e) => {
                      setContactDetail((prevData) => {
                        prevData.openingHours.endPostMeridiem = e;
                        return { ...prevData };
                      });
                    }}
                    className="select2 singleSelect"
                    placeholder="Select"
                    options={[
                      {
                        value: "AM",
                        label: "AM",
                      },
                      {
                        value: "PM",
                        label: "PM",
                      },
                    ]}
                    value={contactDetail.openingHours.endPostMeridiem}
                    labelledBy="Select"
                  />
                </Col>
              </Row>
            </div>
          </Form.Group>
          <Form.Group className="form-group text-end">
            <CommonButton
              buttonName="Save"
              className="commonButton"
              onClick={(e) => saveContactDetailHandler(e)}
              loader={props.loader.contact}
            />
          </Form.Group>
        </Form>
      </div>
    </div>
  );
};
const Location = (props) => {
  const [deliveryFeesCount, setDeliveryFeesCount] = useState(2)
  const [deliveryFeesList, setDeliveryFeesList] = useState({
    deliveryFees: [
      {
        zone: "",
        min: "",
        fee: "",
      },
      {
        zone: "",
        min: "",
        fee: "",
      },
    ],
    describeTheLocation: "",
    enterLocation: {
      formatted_address: '',
      latitude: '',
      longitude: ''
    },
    show: 1
  })
  const inputRef = useRef();
  const libraries = ["places"];
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries,
  });
  const handlePlaceChanged = () => {
    console.log('-------524------', inputRef.current.getPlaces())
    const [place] = inputRef.current.getPlaces();
    if (place) {
      setDeliveryFeesList((prev) => {
        prev.enterLocation = {
          formatted_address: place.formatted_address,
          latitude: place.geometry.location.lat(),
          longitude: place.geometry.location.lng()
        };
        return { ...prev };
      });
      console.log('', place.formatted_address);
      console.log('', place.geometry.location.lat());
      console.log('', place.geometry.location.lng());
    }
  };
  useEffect(() => {
    if (props.apiwebsiteData && props.apiwebsiteData[0] && props.apiwebsiteData[0].locations) {
      let newData = props.apiwebsiteData[0].locations
      let updatedData = { ...deliveryFeesList, ...newData, show: newData.show !== undefined ? newData.show : 1 }
      setDeliveryFeesList(updatedData)
      setDeliveryFeesCount(props.apiwebsiteData[0].locations.deliveryFees.length)
    }
  }, [])
  console.log('deliveryFeesList', deliveryFeesList)
  const onSaveLocationHandler = async (e) => {
    e.preventDefault()
    let isValuesEmpty = deliveryFeesList.deliveryFees.every((item) => {
      console.log('562', item.zone && item.min && item.fee)
      return item.zone && item.min && item.fee
    })
    console.log('isValuesEmpty', isValuesEmpty)
    if (!isValuesEmpty) {
      toastMessages('error', 'Please add a Zone or delete all the empty Zones')
    }
    else {
      let updated = { ...deliveryFeesList }
      //when zone length and description is empty then hide the location section
      if (!deliveryFeesList.deliveryFees.length && !deliveryFeesList.describeTheLocation) {
        updated = { ...deliveryFeesList, show: 0 }
      }
      try {
        const formData = new FormData();
        formData.append("websiteTemplate", JSON.stringify({ locations: updated }));
        props.setLoader({ ...props.loader, location: true });
        const response = await apiPostMethod(
          allApiUrl.updateWebsiteBuilder,
          formData,
          props.header
        );
        if (response.status === 200) {
          toastMessages("success", "Location Updated Successfully");
          props.setModalShow(false);
          props.setLoader({ ...props.loader, location: false });
          props.setIsDataUpdated(!props.isDataUpdated);
        }
      } catch (err) {
        toastMessages("error", err.data.message);
      }
    }
  };
  async function addNewDeliveryCount(event) {
    event.preventDefault();
    let addNew = { fee: '', zone: '', min: '' }
    let updated = [...deliveryFeesList.deliveryFees, addNew]
    setDeliveryFeesList({
      ...deliveryFeesList,
      deliveryFees: updated
    })
    setDeliveryFeesCount(deliveryFeesCount + 1);
  }
  async function deleteDeliveryAdddress(event, index) {
    event.preventDefault()
    let backupData = [...deliveryFeesList.deliveryFees];
    backupData.splice(index, 1);
    setDeliveryFeesList((prev) => {
      prev.deliveryFees = backupData;
      return { ...prev };
    });
    setDeliveryFeesCount((prev) => prev - 1);
  };
  return (
    <div className="orderDetail">
      <div className="whiteBg whiteBg--topRadiusNone small">
        <Form>
          <Form.Group className="form-group">
            <Row>
              <Col lg={3}>
                <Form.Label>Show Location</Form.Label>
              </Col>
              <Col xxl={3} xl={6} lg={6} className="extraPX">
                <Form.Check
                  onClick={(e) => {
                    setDeliveryFeesList((prevData) => {
                      prevData.show = e.target.checked == true ? 1 : 0; //eslint-disable-line
                      return {
                        ...prevData,
                      };
                    });
                  }}
                  checked={deliveryFeesList.show == 1 ? true : false} //eslint-disable-line
                  onChange={() => { }}
                  type="switch"
                  id="custom-switch"
                  className="enabled"
                  label=""
                />
              </Col>
              <Col
                xxl={6}
                xl={3}
                lg={3}
                className="d-lg-block d-none"
              >
                &nbsp;
              </Col>
            </Row>
          </Form.Group>

          <Form.Group className="form-group group-flex">
            <Form.Label>Delivery fee</Form.Label>
            <div className="addChoicesTable commonTableResponsive text-start">
              <Table className="basicTable">
                <thead>
                  <tr>
                    <th className="sizePrice">Zone</th>
                    <th>Min</th>
                    <th>Fee</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {[...Array(deliveryFeesCount)].map((value, index) => {
                    return (
                      <React.Fragment>
                        <tr>
                          <td data-label="Zone">
                            <Form.Control
                              type="text"
                              placeholder=""
                              value={deliveryFeesList.deliveryFees[index].zone}
                              onChange={(e) => {
                                setDeliveryFeesList((prev) => {
                                  prev.deliveryFees[index].zone = e.target.value
                                  return { ...prev }
                                })
                              }}
                            />
                          </td>
                          <td data-label="Min">
                            <Form.Control
                              type="text"
                              placeholder=""
                              value={deliveryFeesList?.deliveryFees[index]?.min}
                              onChange={(e) => {
                                setDeliveryFeesList((prev) => {
                                  prev.deliveryFees[index].min = e.target.value
                                  return { ...prev }
                                })
                              }}
                            />
                          </td>
                          <td data-label="Fee">
                            <Form.Control
                              type="text"
                              placeholder=""
                              value={deliveryFeesList?.deliveryFees[index]?.fee}
                              onChange={(e) => {
                                setDeliveryFeesList((prev) => {
                                  prev.deliveryFees[index].fee = e.target.value
                                  return { ...prev }
                                })
                              }}
                            />
                          </td>
                          {<td><Button
                            className="deleteButton"
                            type="button"
                            onClick={(event) => deleteDeliveryAdddress(event, index)}
                          ><i className="fa-solid fa-trash-can" //eslint-disable-line

                          ></i></Button></td>}
                        </tr>
                        {/* {index == deliveryFeesCount - 1 && */}
                        {/* <tr>
                          <td >
                            <Button type="button" className="commonButton"
                              onClick={(event) => {
                                addNewDeliveryCount(event)
                              }}>
                              Add</Button>
                          </td>
                        </tr> */}
                        {/* } */}
                      </React.Fragment>
                    );
                  })}
                </tbody>
              </Table>
              <Table className="basicTable">
                <tr>
                  <Button type="button" className="commonButton"
                    onClick={(event) => {
                      addNewDeliveryCount(event)
                    }}>
                    Add</Button>
                </tr>
              </Table>
            </div>
          </Form.Group>

          <Form.Group className="form-group group-flex">
            <Form.Label>Describe the location.</Form.Label>
            <Form.Control
              as="textarea"
              className="notCapital"
              value={deliveryFeesList.describeTheLocation}
              onChange={(e) => {
                setDeliveryFeesList((prevData) => {
                  prevData.describeTheLocation = e.target.value;
                  return { ...prevData };
                });
              }}
            />
          </Form.Group>
          <Form.Group className="form-group group-flex">
            <Form.Label>Enter Location.</Form.Label>
            {isLoaded && (
              <StandaloneSearchBox
                // value={}
                onLoad={(ref) => (inputRef.current = ref)}
                onPlacesChanged={handlePlaceChanged}
              >
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Location"
                  value={deliveryFeesList?.enterLocation?.formatted_address}
                  onChange={(e) => {
                    setDeliveryFeesList((prev) => {
                      prev.enterLocation.formatted_address = e.target.value
                      return { ...prev }
                    })
                  }}
                />
              </StandaloneSearchBox>)
            }
          </Form.Group>
          <Form.Group className="form-group text-end">
            <CommonButton
              buttonName="Save"
              className="commonButton"
              onClick={(e) => onSaveLocationHandler(e)}
              loader={props.loader.location}
            />
          </Form.Group>
          <sup>Note: Enter at least one Zone or describe the location, only then your Location will be visible.</sup>
        </Form>
      </div>
    </div>
  );
};
const Footer = (props) => {
  const [footer, setFooter] = useState({
    social: [],
    footerText: "All Rights Reserved AmeriCloud Solutions",
  });
  useEffect(() => {
    if (props.apiwebsiteData[0].footer) {
      setFooter(props.apiwebsiteData[0].footer);
    }
  }, []);
  const onSaveFooterInfoHandler = async (event) => {
    event.preventDefault()
    props.setLoader({ ...props.loader, footer: true });
    const formData = new FormData();
    formData.append("websiteTemplate", JSON.stringify({ footer: footer }));
    try {
      const response = await apiPostMethod(
        allApiUrl.updateWebsiteBuilder,
        formData,
        props.header
      );
      if (response.status === 200) {
        toastMessages("success", "Footer Updated Successfully");
        props.setModalShow(false);
        props.setLoader({ ...props.loader, footer: false });
        props.setIsDataUpdated(!props.isDataUpdated);
      }
    } catch (err) {
    }
  };
  const onDeleteSocailMediaHandler = (event, index) => {
    event.preventDefault();
    console.log('deliveryPartnerInfo.partners.length', footer.social.length)
    let backupData = [...footer.social];
    backupData.splice(index, 1);
    const updatedForm = { ...footer, social: backupData };
    setFooter(updatedForm);
  }
  const onRemoveAllHandler = async (event) => {
    event.preventDefault()
    const updatedForm = { ...footer, social: [] };
    const formData = new FormData();
    props.setLoader({ ...props.loader, removeAll: true });
    formData.append(
      "websiteTemplate",
      JSON.stringify({ footer: updatedForm })
    );
    try {
      const response = await apiPostMethod(
        allApiUrl.updateWebsiteBuilder,
        formData,
        props.header
      );
      if (response.status === 200) {
        toastMessages("success", "All social media handle has been deleted successfully");
        props.setModalShow(false);
        props.setLoader({ ...props.loader, removeAll: false });
        props.setIsDataUpdated(!props.isDataUpdated);
      }
    } catch (err) {
      console.log('err', err.data.message)
    }
    // ---------------------------------------------------------------------
  }
  console.log('------1497-----')
  return (
    <div className="mt-md-0 mt-4">
      <div className="whiteBg whiteBg--minHeight whiteBg--topRadiusNone small">
        <Form className="">
          {props.isAddNewSocialClicked ?
            <AddNewSocailMediaAccount  {...props} /> :
            <div className="">
              {footer &&
                footer?.social &&
                footer?.social.length > 0 &&
                footer?.social.map((item, index) => {
                  return (
                    <React.Fragment>
                      <div className="mainCard">
                        <div style={{
                          display: "flex",
                          justifyContent: "space-between"
                        }}>

                          <Form.Group className="form-group text-start socialMediaUpdate">
                            {item?.socialImage && item?.socialImage[0] && item?.socialImage[0]?.thumbnail ?
                              <img
                                onError={(e) => {
                                  e.target.src = dummyProduct // Replace with a fallback image
                                }}
                                src={item?.socialImage[0]?.thumbnail}
                                alt={`socialLogo_${index}`} /> : ''}
                          </Form.Group>
                          <div style={{ cursor: 'pointer' }}>
                            {footer.social.length > 1 ?
                              <i className="fa-solid fa-trash-can pointer" onClick={(event) => onDeleteSocailMediaHandler(event, index)}></i>
                              : <CommonButton
                                buttonName="Remove All"
                                onClick={(event) => onRemoveAllHandler(event)}
                                type="half"
                                className="deleteButton"
                                loader={props.loader.removeAll}
                              />
                            }
                          </div>
                        </div>
                        <Form.Group className="form-group">
                          <Form.Label>Name</Form.Label>
                          <Form.Control
                            type="text"
                            className="notCapital"
                            onChange={(e) => {
                              setFooter((prev) => {
                                prev.social[index].value = e.target.value;
                                return { ...prev };
                              });
                            }}
                            value={item.value}
                          />
                        </Form.Group>
                        <Form.Group className="form-group">
                          <Form.Label>Link</Form.Label>
                          <Form.Control
                            placeholder="eg: www.facebook.com"
                            className="notCapital"
                            onChange={(e) => {
                              setFooter((prev) => {
                                prev.social[index].label = e.target.value;
                                return { ...prev };
                              });
                            }}
                            as="textarea"
                            value={item.label}
                          />
                        </Form.Group>
                      </div>

                    </React.Fragment>
                  )
                })
              }
              <Form.Group className="form-group">
                <Form.Label>Footer Text</Form.Label>
                <Form.Control
                  as="textarea"
                  className="notCapital"
                  onChange={(e) => {
                    setFooter((prev) => {
                      prev.footerText = e.target.value;
                      return { ...prev };
                    });
                  }}
                  value={footer.footerText}
                />
              </Form.Group>
            </div>
          }
          {(!props.isAddNewSocialClicked &&
            <div style={{
              display: 'flex',
              justifyContent: "space-between"
            }}>
              <Form.Group className="form-group text-endf">
                {footer.social.length ?
                  <CommonButton
                    buttonName="Save"
                    className="commonButton"
                    onClick={(e) => onSaveFooterInfoHandler(e)}
                    loader={props.loader.footer}
                  /> : ''
                }
              </Form.Group>
              <Form.Group className="form-group">
                <Button
                  type="button"
                  className="commonButton"
                  onClick={() => props.setIsAddNewSocialClicked(true)}
                >
                  Add New Social Media Handle
                </Button>
              </Form.Group>
            </div>
          )}
          {/* <Form.Group className="form-group text-end">
        </Form.Group> */}
        </Form>
      </div>
    </div>
  );
};
const AddNewSocailMediaAccount = (props) => {
  const [footer, setFooter] = useState(
    {
      social: [{
        label: "",
        value: "",
      }],
      footerText: "All Rights Reserved AmeriCloud Solutions",
    }
  )
  async function onAddNewSocailHandler(event) {
    event.preventDefault()
    if (props.socialImage) {
      if (footer.social[0].value && footer.social[0].label) {
        try {
          props.setLoader({ ...props.loader, addSocailMediaPartner: true })
          const addPartnerFormData = new FormData()
          addPartnerFormData.append('socialImage', props.socialImage)
          addPartnerFormData.append('websiteTemplate', JSON.stringify({ footer: footer }))
          const response = await apiPostMethod(allApiUrl.updateWebsiteBuilder, addPartnerFormData, props.header)
          if (response.status === 200) {
            toastMessages('success', 'New social media handle added successfully')
            props.setIsAddNewSocialClicked(false)
            props.setSocailImage('')
            props.setPreviewImage({
              ...props.socialLogo, socialLogo: ""
            })
            props.setModalShow(false);
            props.setIsDataUpdated(!props.isDataUpdated)
            props.setLoader({ ...props.loader, addSocailMediaPartner: false })
          }
        }
        catch (err) {
          props.setLoader({ ...props.loader, addSocailMediaPartner: false })
          toastMessages('error', err.data.message)
        }
      }
      else {
        toastMessages('error', 'Name or Link is missing.')
      }
    }
    else {
      toastMessages("error", "Add Social Media Handle Image")
    }
  }
  return (
    <div className="whiteBg whiteBg--topRadiusNone small">
      <Form className="">
        <Form.Group className="form-group text-start">
          <Form.Label>Social Media Logo</Form.Label>
          <div className="imagePreview">
            <Form.Control
              type="file"
              placeholder=""
              accept="image/*"
              required
              style={{ display: "none" }}
              id="file-upload_4"
              onChange={(e) => {
                if (e) {
                  const file = e.target.files;
                  if (
                    file &&
                    file[0] &&
                    file[0].type &&
                    file[0].type.split("/")[0] == "image"
                  ) {
                    props.setPreviewImage({
                      ...props.previewImage,
                      socialLogo: URL.createObjectURL(file[0]),
                    });
                    props.setSocailImage(file[0]);
                  } else {
                    toastMessages(
                      "error",
                      "The image format is not correct"
                    );
                  }
                }
              }}
            />
            <label htmlFor="file-upload_4" className="custom-file-upload">
              Upload Image
            </label>
            <div className="imageView">
              {props.previewImage && props.previewImage.socialLogo && (
                <img className="ms-2" src={props.previewImage.socialLogo} alt={`socail_media_logo`} onError={(e) => {
                  e.target.src = dummyProduct // Replace with a fallback image
                }} />
              )}
              {props.previewImage.socialLogo &&
                props.previewImage.socialLogo.length > 0 && (
                  <i
                    className="fa-solid fa-trash-can pointer"
                    onClick={() => {
                      props.setIsSocailImageDeleted(true);
                      props.setSocailImage("");
                      props.setPreviewImage({ ...props.previewImage, socialLogo: "" });
                    }}
                  ></i>
                )}
            </div>
          </div>
        </Form.Group>
        <Form.Group className="form-group">
          <Form.Label>Link</Form.Label>
          <Form.Control
            type="text"
            placeholder="www.facebook.com"
            className="notCapital"
            onChange={(e) => {
              setFooter((prev) => {
                prev.social[0].label = e.target.value
                return { ...prev }
              })
            }}
            value={footer.social[0].label}
          />
        </Form.Group>
        <Form.Group className="form-group">
          <Form.Label>Name</Form.Label>
          <Form.Control
            className="notCapital"
            placeholder="Facebook"
            onChange={(e) => {
              setFooter((prev) => {
                prev.social[0].value = e.target.value
                return { ...prev }
              })
            }}
            value={footer.social[0].value}
            as="textarea"
          />
        </Form.Group>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <Form.Group className="form-group">
            <Button
              type="button"
              className="commonButton commonButton--delete"
              onClick={() => props.setIsAddNewSocialClicked(false)}
            >
              Cancel
            </Button>
          </Form.Group>
          <Form.Group className="form-group text-end">
            <CommonButton
              buttonName="Save"
              className="commonButton"
              onClick={(event) => onAddNewSocailHandler(event)}
              loader={props.loader.addSocailMediaPartner}
            />
          </Form.Group>
        </div>
      </Form>
    </div>
  )
}
const Annoucement = (props) => {
  const [websiteBuilder, setWebsiteBuilder] = useState({
    announcementTitle: "",
    announcementDescription: '',
    show: 1
  });
  useEffect(() => {
    if (props.apiwebsiteData[0].announcement) {
      const newAnnouncement = props.apiwebsiteData[0].announcement;
      const updatedWebsiteBuilder = {
        ...websiteBuilder,
        ...newAnnouncement,
        show: newAnnouncement.show !== undefined ? newAnnouncement.show : 1,
      };
      setWebsiteBuilder(updatedWebsiteBuilder);
    }
  }, []);
  console.log('websiteBuilder', websiteBuilder)
  const saveAnnouncementData = async (event) => {
    event.preventDefault()
    props.setLoader({ ...props.loader, announcement: true })
    let announcement = {
      announcementTitle: websiteBuilder.announcementTitle,
      announcementDescription: websiteBuilder.announcementDescription,
      show: websiteBuilder.show
    };
    if (props.isAnnoucementImageDeleted) {
      announcement["announcementImage"] = {};
    }
    try {
      const formData = new FormData();
      formData.append("announcementImage", props.announcementImage);
      formData.append(
        "websiteTemplate",
        JSON.stringify({ announcement: announcement })
      );
      const response = await apiPostMethod(allApiUrl.updateWebsiteBuilder, formData, props.header);
      if (response.status === 200) {
        toastMessages("success", "Announcement Updated Successfully");
        props.setModalShow(false);
        props.setLoader({ ...props.loader, announcement: false })
        props.setIsDataUpdated(!props.isDataUpdated);
      }
    } catch (err) {
      toastMessages("error", err.data.message);
    }
  };
  return (
    <div className="whiteBg whiteBg--minHeight whiteBg--topRadiusNone small">
      <Form>
        <Form.Group className="form-group">
          <Row>
            <Col lg={3}>
              <Form.Label>Show Annoucements</Form.Label>
            </Col>
            <Col xxl={3} xl={6} lg={6} className="extraPX">
              <Form.Check
                onClick={(e) => {
                  setWebsiteBuilder((prevData) => {
                    prevData.show = e.target.checked == true ? 1 : 0; //eslint-disable-line
                    return {
                      ...prevData,
                    };
                  });
                }}
                checked={websiteBuilder.show == 1 ? true : false} //eslint-disable-line
                onChange={() => { }}
                type="switch"
                id="custom-switch"
                className="enabled"
                label=""
              />
            </Col>
            <Col
              xxl={6}
              xl={3}
              lg={3}
              className="d-lg-block d-none"
            >
              &nbsp;
            </Col>
          </Row>
        </Form.Group>

        <Form.Group className="form-group text-start">
          <Form.Label>Announcement Logo</Form.Label>
          <div className="imagePreview">
            <Form.Control
              type="file"
              placeholder=""
              accept="image/*"
              required
              style={{ display: "none" }}
              id="file-upload"
              onChange={(e) => {
                if (e) {
                  const file = e.target.files;
                  if (
                    file &&
                    file[0] &&
                    file[0].type &&
                    file[0].type.split("/")[0] == "image"
                  ) {
                    props.setPreviewImage({
                      ...props.previewImage,
                      announcementImage: URL.createObjectURL(file[0]),
                    });
                    props.setAnnouncementImage(file[0]);
                    props.setIsAnnoucementDeleted(false);
                  } else {
                    toastMessages(
                      "error",
                      "The image format is not correct"
                    );
                  }
                }
              }}
            />
            <label htmlFor="file-upload" className="custom-file-upload">
              Upload Image
            </label>
            <div className="imageView ms-3">
              {props.previewImage && props.previewImage.announcementImage && (
                <img src={props.previewImage.announcementImage} alt="Announcement Image" onError={(e) => {
                  e.target.src = dummyProduct // Replace with a fallback image
                }} />
              )}
              {props.previewImage.announcementImage &&
                props.previewImage.announcementImage.length > 0 && (
                  <i
                    className="fa-solid fa-trash-can pointer"
                    onClick={() => {
                      props.setIsAnnoucementDeleted(true);
                      props.setAnnouncementImage("");
                      props.setPreviewImage({ ...props.previewImage, announcementImage: "" });
                    }}
                  ></i>
                )}
            </div>
          </div>
        </Form.Group>
        <Form.Group className="form-group">
          {/* Title of annoucement */}
          <Form.Label>Title of Annoucement</Form.Label>
          <Form.Control
            as="textarea"
            className="me-3 notCapital"
            placeholder="description"
            value={websiteBuilder?.announcementTitle}
            onChange={(e) => {
              setWebsiteBuilder({
                ...websiteBuilder,
                announcementTitle: e.target.value,
              });
            }}
          />
        </Form.Group>
        {/* Description of annoucement */}
        <Form.Group className="form-group">
          <Form.Label>Description of Annoucement</Form.Label>
          <Form.Control
            as="textarea"
            className="me-3 notCapital"
            placeholder="description"
            value={websiteBuilder?.announcementDescription}
            onChange={(e) => {
              setWebsiteBuilder({
                ...websiteBuilder,
                announcementDescription: e.target.value,
              });
            }}
          />
        </Form.Group>
        <Form.Group className="form-group">
          <CommonButton
            buttonName="Save"
            onClick={(event) => saveAnnouncementData(event)}
            type="half"
            loader={props.loader.announcement}
          />
        </Form.Group>
      </Form>
    </div>
  );
};
const Hygiene = (props) => {
  const [websiteBuilder, setWebsiteBuilder] = useState({
    hygieneStandardTitle: "",
    hygieneStandardDescription: '',
  });
  useEffect(() => {
    if (props.apiwebsiteData[0].hygieneStandard) {
      const newHy = props.apiwebsiteData[0].hygieneStandard
      const updatedWebsiteBuilder = {
        ...websiteBuilder,
        ...newHy,
        show: newHy.show !== undefined ? newHy.show : 1,
      };
      setWebsiteBuilder(updatedWebsiteBuilder);
    }
  }, []);
  const saveHygieneStandardData = async (event) => {
    event.preventDefault()
    props.setLoader({ ...props.loader, hygieneStandard: true })
    let hygieneStandard = {
      hygieneStandardTitle: websiteBuilder.hygieneStandardTitle,
      hygieneStandardDescription: websiteBuilder.hygieneStandardDescription,
      show: (websiteBuilder.hygieneStandardTitle && websiteBuilder.hygieneStandardDescription) ? websiteBuilder.show : 0
    };
    if (props.isHygieneStandardImageDeleted) {
      hygieneStandard["hygieneStandardImages"] = {};
    }
    try {
      const formData = new FormData();
      formData.append("hygieneStandardImages", props.hygieneStandardImage);
      formData.append(
        "websiteTemplate",
        JSON.stringify({ hygieneStandard: hygieneStandard })
      );
      const response = await apiPostMethod(allApiUrl.updateWebsiteBuilder, formData, props.header);
      if (response.status === 200) {
        toastMessages("success", "Hygiene Standard Updated Successfully");
        props.setModalShow(false);
        props.setLoader({ ...props.loader, hygieneStandard: false })
        props.setIsDataUpdated(!props.isDataUpdated);
      }
    } catch (err) {
      toastMessages("error", err.data.message);
    }
  };
  console.log('websiteBuilder', websiteBuilder)
  console.log('1933', props.previewImage)
  return (
    <div className="whiteBg whiteBg--minHeight whiteBg--topRadiusNone small">
      <Form>
        <Form.Group className="form-group">
          <Row>
            <Col lg={3}>
              <Form.Label>Show Hygiene Standard</Form.Label>
            </Col>
            <Col xxl={3} xl={6} lg={6} className="extraPX">
              <Form.Check
                onClick={(e) => {
                  setWebsiteBuilder((prevData) => {
                    prevData.show = e.target.checked == true ? 1 : 0; //eslint-disable-line
                    return {
                      ...prevData,
                    };
                  });
                }}
                checked={websiteBuilder.show == 1 ? true : false} //eslint-disable-line
                onChange={() => { }}
                type="switch"
                id="custom-switch"
                className="enabled"
                label=""
              />
            </Col>
            <Col
              xxl={6}
              xl={3}
              lg={3}
              className="d-lg-block d-none"
            >
              &nbsp;
            </Col>
          </Row>
        </Form.Group>
        <Form.Group className="form-group text-start">
          <Form.Label>Image</Form.Label>
          <div className="imagePreview">
            <Form.Control
              type="file"
              placeholder=""
              accept="image/*"
              required
              style={{ display: "none" }}
              id="file-upload"
              onChange={(e) => {
                if (e) {
                  const file = e.target.files;
                  if (
                    file &&
                    file[0] &&
                    file[0].type &&
                    file[0].type.split("/")[0] == "image"
                  ) {
                    props.setPreviewImage({
                      ...props.previewImage,
                      hygieneStandardImage: URL.createObjectURL(file[0]),
                    });
                    props.setHygieneStandardImage(file[0]);
                    props.setIsHygieneStandardImageDeleted(false);
                  } else {
                    toastMessages(
                      "error",
                      "The image format is not correct"
                    );
                  }
                }
              }}
            />
            <label htmlFor="file-upload" className="custom-file-upload">
              Upload Image
            </label>
            <div className="imageView ms-3">
              {props.previewImage && props.previewImage.hygieneStandardImage && (
                <img src={props.previewImage.hygieneStandardImage} alt="Hygiene Standard Image" onError={(e) => {
                  e.target.src = dummyProduct // Replace with a fallback image
                }} />
              )}
              {props.previewImage.hygieneStandardImage &&
                props.previewImage.hygieneStandardImage.length > 0 && (
                  <i
                    className="fa-solid fa-trash-can pointer"
                    onClick={() => {
                      props.setIsHygieneStandardImageDeleted(true);
                      props.setHygieneStandardImage("");
                      props.setPreviewImage({ ...props.previewImage, hygieneStandardImage: "" });
                    }}
                  ></i>
                )}
            </div>
          </div>
        </Form.Group>
        <Form.Group className="form-group">
          {/* Title of annoucement */}
          <Form.Label>Title of Hygiene</Form.Label>
          <Form.Control
            as="textarea"
            className="me-3 notCapital"
            placeholder="description"
            value={websiteBuilder?.hygieneStandardTitle}
            onChange={(e) => {
              setWebsiteBuilder({
                ...websiteBuilder,
                hygieneStandardTitle: e.target.value,
              });
            }}
          />
        </Form.Group>
        {/* Description of annoucement */}
        <Form.Group className="form-group">
          <Form.Label>Description of Hygiene</Form.Label>
          <Form.Control
            as="textarea"
            className="me-3 notCapital"
            placeholder="description"
            value={websiteBuilder?.hygieneStandardDescription}
            onChange={(e) => {
              setWebsiteBuilder({
                ...websiteBuilder,
                hygieneStandardDescription: e.target.value,
              });
            }}
          />
        </Form.Group>
        <Form.Group className="form-group">
          <CommonButton
            buttonName="Save"
            onClick={(event) => saveHygieneStandardData(event)}
            type="half"
            loader={props.loader.hygieneStandard}
          />
        </Form.Group>
        <sub><b>Note</b> :If the image is not uploaded then content will not be visible*.</sub>
      </Form>
    </div>
  );
};
const AddNewDeliveryPartner = (props) => {
  const [registerNewDeliverPartner, setRegisterNewDeliverPartner] = useState({
    partners: [{
      description: '',
      link: '',
      partnerLogo: ''
    }],
  });
  async function addNewDeliveryHandler(event) {
    event.preventDefault()
    if (props.partnerImage) {
      if (registerNewDeliverPartner.partners[0].description && registerNewDeliverPartner.partners[0].link) {
        try {
          props.setLoader({ ...props.loader, addPartner: true })
          const addPartnerFormData = new FormData()
          addPartnerFormData.append('partnerLogo', props.partnerImage)
          addPartnerFormData.append('websiteTemplate', JSON.stringify({ foodDeliveryPartners: registerNewDeliverPartner }))
          const response = await apiPostMethod(allApiUrl.updateWebsiteBuilder, addPartnerFormData, props.header)
          if (response.status === 200) {
            toastMessages('success', 'New Partner Added Successfully')
            props.setIsAddNewPartnerClicked(false)
            props.setPartnerImage('')
            props.setPreviewImage({

              ...props.partnerLogo,
              partnerLogo: ""
            })
            props.setModalShow(false)
            props.setIsDataUpdated(!props.isDataUpdated)
            props.setLoader({ ...props.loader, addPartner: false })
          }
        }
        catch (err) {
          toastMessages('error', err.data.message)
        }
      }
      else {
        toastMessages('error', 'Description or Link is missing.')
      }
    }
    else {
      toastMessages("error", "Add Partner Image")
    }

  }
  return (
    <React.Fragment>
      <Form>
        <Form.Group className="form-group text-start">
          <Form.Label>Partner Logo</Form.Label>
          <div className="imagePreview">
            <Form.Control
              type="file"
              placeholder=""
              accept="image/*"
              required
              style={{ display: "none" }}
              id="file-upload_2"
              onChange={(e) => {
                if (e) {
                  const file = e.target.files;
                  if (
                    file &&
                    file[0] &&
                    file[0].type &&
                    file[0].type.split("/")[0] == "image"
                  ) {
                    props.setPreviewImage({
                      ...props.previewImage,
                      partnerLogo: URL.createObjectURL(file[0]),
                    });
                    props.setPartnerImage(file[0]);
                  } else {
                    toastMessages(
                      "error",
                      "The image format is not correct"
                    );
                  }
                }
              }}
            />
            <label htmlFor="file-upload_2" className="custom-file-upload">
              Upload Image
            </label>
            <div className="imageView ms-3">
              {props.previewImage && props.previewImage.partnerLogo && (
                <img src={props.previewImage.partnerLogo} alt="Partner Logo" onError={(e) => {
                  e.target.src = dummyProduct // Replace with a fallback image
                }} />
              )}
              {props.previewImage.partnerLogo &&
                props.previewImage.partnerLogo.length > 0 && (
                  <i
                    className="fa-solid fa-trash-can pointer"
                    onClick={() => {
                      props.setIsPartnerImageDeleted(true);
                      props.setPartnerImage("");
                      props.setPreviewImage({ ...props.previewImage, partnerLogo: "" });
                    }}
                  ></i>
                )}
            </div>
          </div>
        </Form.Group>
        <Form.Group className="form-group">
          <Form.Label>Link</Form.Label>
          <Form.Control
            type="text"
            placeholder="www.facebook.com"
            className="notCapital"
            onChange={(e) => {
              setRegisterNewDeliverPartner((prev) => {
                prev.partners[0].link = e.target.value
                return { ...prev }
              })
            }}
            value={registerNewDeliverPartner.partners[0].link}
          />
        </Form.Group>
        <Form.Group className="form-group">
          <Form.Label>Description</Form.Label>
          <Form.Control
            placeholder="this is the description"
            className="notCapital"
            onChange={(e) => {
              setRegisterNewDeliverPartner((prev) => {
                prev.partners[0].description = e.target.value
                return { ...prev }
              })
            }}
            value={registerNewDeliverPartner.partners[0].description}
            as="textarea"
          />
        </Form.Group>
        <div style={{
          display: 'flex',
          justifyContent: "space-between"
        }}>
          <Form.Group className="form-group">
            <Button
              type="button"
              className="commonButton commonButton--delete"
              onClick={() => {
                props.setIsAddNewPartnerClicked(false)
              }}
            >
              Cancel
            </Button>
          </Form.Group>
          <Form.Group className="form-group">
            <CommonButton
              buttonName={"Add New"}
              className="commonButton"
              onClick={(event) => addNewDeliveryHandler(event)}
              loader={props.loader.addPartner}
            // loader={false}
            />
          </Form.Group>

        </div>
      </Form>
    </React.Fragment>)
}
const DeliveryPartners = (props) => {
  const [deliveryPartnerInfo, setDeliveryPartnerInfo] = useState({
    partners: [],
    show: 1
  });
  useEffect(() => {
    if (props.apiwebsiteData[0].foodDeliveryPartners) {
      let data = props.apiwebsiteData[0].foodDeliveryPartners
      const updatedData =
      {
        ...deliveryPartnerInfo
        , ...data
        , show: data.show !== undefined ? data.show : 1
      }
      setDeliveryPartnerInfo(updatedData);
    }
  }, []);
  const saveDeliveryPartnerHandler = async (e) => {
    e.preventDefault()
    // var linkArray = deliveryPartnerInfo.partners.map(function (item) { return item.link });
    // console.log('linkArray', linkArray)
    // var isDuplicate = deliveryPartnerInfo.partners.some(function (item, idx) {
    //   return linkArray.indexOf(item) != idx
    // });
    // if (!isDuplicate) {
    const formData = new FormData();
    props.setLoader({ ...props.loader, partners: true });
    formData.append(
      "websiteTemplate",
      JSON.stringify({ foodDeliveryPartners: deliveryPartnerInfo })
    );
    try {
      const response = await apiPostMethod(
        allApiUrl.updateWebsiteBuilder,
        formData,
        props.header
      );
      if (response.status === 200) {
        toastMessages("success", "Food Delivery Partners Updated Successfully");
        props.setModalShow(false);
        props.setLoader({ ...props.loader, partners: false });
        props.setIsDataUpdated(!props.isDataUpdated);
      }
    } catch (err) {
      console.log('err', err.data.message)
    }
  }
  // else {
  //   toastMessages('error', "Food Delivery Partner link cannot be same")
  // }
  function onDeletePartnerProgramHandler(event, index) {
    event.preventDefault();
    console.log('deliveryPartnerInfo.partners.length', deliveryPartnerInfo.partners.length)
    let backupData = [...deliveryPartnerInfo.partners];
    backupData.splice(index, 1);
    const updatedForm = { ...deliveryPartnerInfo, partners: backupData };
    setDeliveryPartnerInfo(updatedForm);
  }
  console.log('-----1120-----', deliveryPartnerInfo)
  function addDeliveryPartnerHandler() {
    props.setIsAddNewPartnerClicked(true)
  }
  async function onRemoveAllHandler(event) {
    event.preventDefault()
    const updatedForm = { ...deliveryPartnerInfo, partners: [] };
    const formData = new FormData();
    props.setLoader({ ...props.loader, removeAll: true });
    formData.append(
      "websiteTemplate",
      JSON.stringify({ foodDeliveryPartners: updatedForm })
    );
    try {
      const response = await apiPostMethod(
        allApiUrl.updateWebsiteBuilder,
        formData,
        props.header
      );
      if (response.status === 200) {
        toastMessages("success", "All partner program has been deleted successfully");
        props.setModalShow(false);
        props.setLoader({ ...props.loader, removeAll: false });
        props.setIsDataUpdated(!props.isDataUpdated);
      }
    } catch (err) {
      console.log('err', err.data.message)
    }
  }
  return (
    <div className="mt-md-0 mt-4">
      <div className="whiteBg whiteBg--minHeight whiteBg--topRadiusNone small">
        <Form>
          <Form.Group className="form-group">
            <Row>
              <Col lg={3}>
                <Form.Label>Show Delivery partner</Form.Label>
              </Col>
              <Col xxl={3} xl={6} lg={6} className="extraPX">
                <Form.Check
                  onClick={(e) => {
                    setDeliveryPartnerInfo((prevData) => {
                      prevData.show = e.target.checked == true ? 1 : 0; //eslint-disable-line
                      return {
                        ...prevData,
                      };
                    });
                  }}
                  checked={deliveryPartnerInfo.show == 1 ? true : false} //eslint-disable-line
                  onChange={() => { }}
                  type="switch"
                  id="custom-switch"
                  className="enabled"
                  label=""
                />
              </Col>
              <Col
                xxl={6}
                xl={3}
                lg={3}
                className="d-lg-block d-none"
              >
                &nbsp;
              </Col>
            </Row>
          </Form.Group>
          {props.isAddNewPartnerClicked ?
            <AddNewDeliveryPartner {...props} /> :
            deliveryPartnerInfo &&
            deliveryPartnerInfo?.partners.length > 0 &&
            deliveryPartnerInfo?.partners.map((item, index, arrayList) => {
              return (
                <React.Fragment>
                  <div className="mainCard">
                    <div style={{
                      display: "flex",
                      justifyContent: "space-between"
                    }}>
                      <Form.Group className="form-group text-start">
                        {item &&
                          item.partnerLogo &&
                          item.partnerLogo[0] &&
                          item.partnerLogo[0].banner ?
                          <img
                            onError={(e) => {
                              e.target.src = dummyProduct // Replace with a fallback image
                            }}
                            className="deliveryPartnerImg"
                            src={item?.partnerLogo[0]?.banner || images[index]}
                            alt="Partner Logo" /> : ''}
                      </Form.Group>
                      <div style={{ cursor: 'pointer' }}>
                        {/* <i className="fa-solid fa-trash-can" onClick={(event) => onDeletePartnerProgramHandler(event, index)}></i> */}
                        {deliveryPartnerInfo.partners.length > 1 ?
                          <i className="fa-solid fa-trash-can" onClick={(event) => onDeletePartnerProgramHandler(event, index)}></i> :
                          <CommonButton
                            buttonName="Remove All"
                            onClick={(event) => onRemoveAllHandler(event)}
                            type="half"
                            className="deleteButton"
                            loader={props.loader.removeAll}
                          />
                        }
                      </div>
                    </div>
                    <Form.Group className="form-group">
                      <Form.Label>Link</Form.Label>
                      <Form.Control
                        type="text"
                        className="notCapital"
                        onChange={(e) => {
                          setDeliveryPartnerInfo((prev) => {
                            prev.partners[index].link = e.target.value;
                            return { ...prev };
                          });
                        }}
                        value={item.link}
                      />
                    </Form.Group>
                    <Form.Group className="form-group">
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        className="notCapital"
                        onChange={(e) => {
                          setDeliveryPartnerInfo((prev) => {
                            prev.partners[index].description = e.target.value;
                            return { ...prev };
                          });
                        }}
                        as="textarea"
                        value={item.description}
                      />
                    </Form.Group>
                  </div>

                </React.Fragment>
              );
            })}
          {(!props.isAddNewPartnerClicked &&
            <div style={{
              display: 'flex',
              justifyContent: "space-between"
            }}>
              {/* {console.log('-------1182-----', deliveryPartnerInfo.partners.length)} */}
              <Form.Group className="form-group">
                {/* {deliveryPartnerInfo.partners.length ? */}
                <CommonButton
                  buttonName={"Save"}
                  className="commonButton"
                  onClick={(e) => saveDeliveryPartnerHandler(e)}
                  loader={props.loader.partners}
                />
                {/* : ''} */}
              </Form.Group>
              <Form.Group className="form-group">
                <Button
                  type="button"
                  className="commonButton"
                  onClick={addDeliveryPartnerHandler}
                >
                  Add New Partner
                </Button>
              </Form.Group>
            </div>
          )}
        </Form>
      </div>
    </div >
  );
};
const AdditionalInformation = (props) => {
  const [additionalInfo, setAdditionalInfo] = useState({
    discount: "",
    awards_and_batches: [],
    show: 1
  });
  useEffect(() => {
    if (props.apiwebsiteData[0].additionalInformation) {
      let data = props.apiwebsiteData[0].additionalInformation
      console.log('data', data)
      const updatedData = {
        ...additionalInfo,
        ...data,
        show: data.show !== undefined ? data.show : 1
      }
      setAdditionalInfo(updatedData);
    }
  }, []);
  console.log('additionalInfo', additionalInfo)
  const saveAdditionalInfo = async (e) => {
    e.preventDefault()
    try {
      props.setLoader({ ...props.loader, additionalInfo: true });
      const formData = new FormData();
      if (props.awards_and_batches && props.awards_and_batches.length) {
        for (let i = 0; i < props.awards_and_batches.length; i++) {
          formData.append("awards_and_batches", props.awards_and_batches[i]);
        }
      }

      let data = {
        awards_and_batches: props.previewImage && props.previewImage.awards_and_batches.filter((item) => !item.banner.includes("blob")),
        show: additionalInfo.show
      };
      formData.append("websiteTemplate", JSON.stringify({ additionalInformation: data })
      );
      const response = await apiPostMethod(
        allApiUrl.updateWebsiteBuilder,
        formData,
        props.header
      );
      if (response.status == 200) {
        toastMessages(
          "success",
          "Additional Information updated successfully"
        );
        props.setLoader({ ...props.loader, additionalInfo: false });
        props.setModalShow(false);
        props.set_awards_and_batches([]);
        props.setIsDataUpdated(!props.isDataUpdated);
      }
    } catch (err) {
      toastMessages("error", err.data.message);
      props.setLoader({ ...props.loader, additionalInfo: false });
    }
  };
  console.log('788', props.previewImage.awards_and_batches)
  return (
    <div className="orderDetail">
      <div className="whiteBg whiteBg--topRadiusNone small">
        <Form>
          <Form.Group className="form-group">
            <Row>
              <Col lg={3}>
                <Form.Label>Show Additional info</Form.Label>
              </Col>
              <Col xxl={3} xl={6} lg={6} className="extraPX">
                <Form.Check
                  onClick={(e) => {
                    setAdditionalInfo((prevData) => {
                      prevData.show = e.target.checked == true ? 1 : 0; //eslint-disable-line
                      return {
                        ...prevData,
                      };
                    });
                  }}
                  checked={additionalInfo.show == 1 ? true : false} //eslint-disable-line
                  onChange={() => { }}
                  type="switch"
                  id="custom-switch"
                  className="enabled"
                  label=""
                />
              </Col>
              <Col
                xxl={6}
                xl={3}
                lg={3}
                className="d-lg-block d-none"
              >
                &nbsp;
              </Col>
            </Row>
          </Form.Group>
          <Form.Group className="form-group group-flex">
            <Form.Label>Awards and Branches</Form.Label>
            <div className="imagePreview">
              <Form.Control
                type="file"
                placeholder=""
                multiple
                accept="image/*"
                required
                onChange={(e) => {
                  if (e) {
                    const file = e.target.files;
                    if (
                      file &&
                      file[0] &&
                      file[0].type &&
                      file[0].type.split("/")[0] == "image"
                    ) {
                      let images = [];
                      for (let i = 0; i < file.length; i++) {
                        // images.push(URL.createObjectURL(file[i]));
                        images.push({
                          banner: URL.createObjectURL(file[i]),
                          bannerKey: "",
                          thumbnail: "",
                        });
                      }
                      let updatedData = [
                        ...images,
                        ...props.previewImage.awards_and_batches,
                      ];
                      props.setPreviewImage({
                        ...props.previewImage,
                        awards_and_batches: updatedData,
                      });
                      // setAdditionalInfo({
                      //     ...additionalInfo,
                      //     awards_and_batches: images
                      // });
                      props.set_awards_and_batches(file);
                    } else {
                      toastMessages(
                        "error",
                        "The image format is not correct"
                      );
                    }
                  }
                }}
                style={{ display: "none" }}
                id="file-upload"
              />
              <Row className="g-2 align-items-center w-100">
                <Col sm={3}>
                  <label
                    htmlFor="file-upload"
                    className="custom-file-upload"
                  >
                    Upload Image
                  </label>
                </Col>
                <Col sm={9}>
                  <div className="imageView">
                    {props.previewImage &&
                      props.previewImage.awards_and_batches &&
                      props.previewImage.awards_and_batches.map((item, index) => {
                        return (
                          <div className="imageView__grid">
                            <img onError={(e) => {
                              e.target.src = dummyProduct // Replace with a fallback image
                            }} src={item["banner"]} alt="Banner Image Upload" />
                            <i
                              className="fa-solid fa-trash-can pointer"
                              onClick={() => {
                                let remainingPhotos =
                                  props.previewImage.awards_and_batches.filter(
                                    (item, idx) => idx != index
                                  );
                                props.setPreviewImage({
                                  ...props.previewImage,
                                  awards_and_batches: remainingPhotos,
                                });
                                const updatedFiles = [
                                  ...props.awards_and_batches,
                                ];
                                updatedFiles.splice(index, 1);
                                props.set_awards_and_batches(updatedFiles);
                              }}
                            ></i>
                          </div>
                        );
                      })}
                  </div>
                </Col>
              </Row>
            </div>
          </Form.Group>
          <Form.Group className="form-group text-end">
            <CommonButton
              buttonName="Save"
              className="commonButton"
              onClick={(e) => saveAdditionalInfo(e)}
              loader={props.loader.additionalInfo}
            />
          </Form.Group>
        </Form>
      </div>
    </div>
  );
};
const Gallery = (props) => {
  const [galleryInfo, setGalleryInfo] = useState({
    galleryPhotos: [],
    show: 1
  });
  useEffect(() => {
    if (props.apiwebsiteData[0].galleryInfo) {
      let newData = props.apiwebsiteData[0].galleryInfo
      let updatedData = {
        ...galleryInfo,
        ...newData,
        show: newData.show !== undefined ? newData.show : 1
      }
      setGalleryInfo(updatedData);
    }
  }, []);

  const saveGalleryInfo = async (e) => {
    e.preventDefault()
    try {
      props.setLoader({ ...props.loader, gallery: true });
      const formData = new FormData();
      if (props.galleryPhotos && props.galleryPhotos.length) {
        for (let i = 0; i < props.galleryPhotos.length; i++) {
          formData.append("galleryPhotos", props.galleryPhotos[i]);
        }
      }
      let data = {
        galleryPhotos: props.previewImage && props.previewImage.galleryPhotos.filter((item) => !item.banner.includes("blob")),
        show: galleryInfo.show
      };
      formData.append("websiteTemplate", JSON.stringify({ galleryInfo: data })
      );
      const response = await apiPostMethod(
        allApiUrl.updateWebsiteBuilder,
        formData,
        props.header
      );
      if (response.status == 200) {
        toastMessages(
          "success",
          "Gallery Information updated successfully"
        );
        props.setLoader({ ...props.loader, gallery: false });
        props.setModalShow(false);
        props.setGalleryPhotos([]);
        props.setIsDataUpdated(!props.isDataUpdated);
      }
    } catch (err) {
      toastMessages("error", err.data.message);
      props.setLoader({ ...props.loader, gallery: false });
    }
  };
  console.log('1628', props.previewImage.galleryInfo)
  return (
    <div className="orderDetail">
      <div className="whiteBg whiteBg--topRadiusNone small">
        <Form>
          <Form.Group className="form-group">
            <Row>
              <Col lg={3}>
                <Form.Label>Show Gallery</Form.Label>
              </Col>
              <Col xxl={3} xl={6} lg={6} className="extraPX">
                <Form.Check
                  onClick={(e) => {
                    setGalleryInfo((prevData) => {
                      prevData.show = e.target.checked == true ? 1 : 0; //eslint-disable-line
                      return {
                        ...prevData,
                      };
                    });
                  }}
                  checked={galleryInfo.show == 1 ? true : false} //eslint-disable-line
                  onChange={() => { }}
                  type="switch"
                  id="custom-switch"
                  className="enabled"
                  label=""
                />
              </Col>
              <Col
                xxl={6}
                xl={3}
                lg={3}
                className="d-lg-block d-none"
              >
                &nbsp;
              </Col>
            </Row>
          </Form.Group>
          <Form.Group className="form-group group-flex">
            <Form.Label>Gallery Photos</Form.Label>
            <div className="imagePreview">
              <Form.Control
                type="file"
                placeholder=""
                multiple
                accept="image/*"
                required
                onChange={(e) => {
                  if (e) {
                    const file = e.target.files;
                    if (
                      file &&
                      file[0] &&
                      file[0].type &&
                      file[0].type.split("/")[0] == "image"
                    ) {
                      let images = [];
                      for (let i = 0; i < file.length; i++) {
                        // images.push(URL.createObjectURL(file[i]));
                        images.push({
                          banner: URL.createObjectURL(file[i]),
                          bannerKey: "",
                          thumbnail: "",
                        });
                      }
                      console.log('images', images)
                      let updatedData = [
                        ...images,
                        ...props.previewImage.galleryPhotos,
                      ];
                      console.log('updatedDate', updatedData)
                      props.setPreviewImage({
                        ...props.previewImage,
                        galleryPhotos: updatedData,
                      });
                      // setAdditionalInfo({
                      //     ...additionalInfo,
                      //     awards_and_batches: images
                      // });
                      props.setGalleryPhotos(file);
                    } else {
                      toastMessages(
                        "error",
                        "The image format is not correct"
                      );
                    }
                  }
                }}
                style={{ display: "none" }}
                id="file-upload_1"
              />
              <Row className="g-2 align-items-center w-100">
                <Col sm={3}>
                  <label
                    htmlFor="file-upload_1"
                    className="custom-file-upload"
                  >
                    Upload Image
                  </label>
                </Col>
                <Col sm={9}>
                  <div className="imageView">
                    {props.previewImage &&
                      props.previewImage &&
                      props.previewImage.galleryPhotos &&
                      props.previewImage.galleryPhotos.length > 0 &&
                      props.previewImage.galleryPhotos.map((item, index) => {
                        return (
                          <div className="imageView__grid">
                            <img onError={(e) => {
                              e.target.src = dummyProduct // Replace with a fallback image
                            }} src={item["banner"]} alt={`galleryImage_${index}`} />
                            <i
                              className="fa-solid fa-trash-can "
                              onClick={() => {
                                let remainingPhotos =
                                  props.previewImage.galleryPhotos.filter(
                                    (item, idx) => idx != index
                                  );
                                props.setPreviewImage({
                                  ...props.previewImage,
                                  galleryPhotos: remainingPhotos,
                                });
                                const updatedFiles = [
                                  ...props.galleryPhotos,
                                ];
                                updatedFiles.splice(index, 1);
                                props.setGalleryPhotos(updatedFiles);
                              }}
                            ></i>
                          </div>
                        );
                      })}
                  </div>
                </Col>
              </Row>
            </div>
          </Form.Group>
          <Form.Group className="form-group text-end">
            <CommonButton
              buttonName="Save"
              className="commonButton"
              onClick={(e) => saveGalleryInfo(e)}
              loader={props.loader.gallery}
            />
          </Form.Group>
        </Form>
      </div>
    </div>
  );
};
let WebsiteBuilderModal = (props) => {
  const renderSwitch = (value) => {
    switch (value) {
      case "header":
        return <HeaderWebsite {...props} />;
      case "contact":
        return <ContactDetails {...props} />;
      case "additional":
        return <AdditionalInformation {...props} />;
      case "partners":
        return <DeliveryPartners {...props} />;
      case "location":
        return <Location {...props} />;
      case "footer":
        return <Footer {...props} />;
      case "gallery":
        return <Gallery {...props} />;
      case "announcement":
        return <Annoucement  {...props} />;
      case "hygiene":
        return <Hygiene {...props} />
      default:
        return <span></span>;
    }
  };
  return <div className="refundMenuModal">{renderSwitch(props.currentModal)}</div>;
};
function WebsiteBuilder() {
  //constants
  let history = useHistory();
  const branchArray = useSelector((state) => state.counter.branchArray);
  let branches = branchArray.map((item) => item.value);
  let sidebarTypeRedux = useSelector((state) => state.counter.sidebarType);
  let sidebarToggle = useSelector((state) => state.counter.sidebarToggle);
  const header = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    branchid: JSON.stringify(branches),
  };
  //useState
  const [permissionData, setPermissionData] = useState({})
  const [isDataUpdated, setIsDataUpdated] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const [sidebarType, setSidebarType] = useState("doubleSidebar");
  const [isLoading, setIsLoading] = useState(true);
  const [previewImage, setPreviewImage] = useState({
    headerLogo: "",
    bannerImage: "",
    announcementImage: "",
    hygieneStandardImage: '',
    awards_and_batches: [],
    partnerLogo: "",
    socialLogo: "",
    galleryPhotos: [],
  });
  const [apiwebsiteData, setApiWebsiteData] = useState({});
  const [currentHeading, setCurrentHeading] = useState("");
  const [currentModal, setCurrentModal] = useState();
  const [logo, setLogo] = useState("");
  const [backgroundImage, setBackgroundImage] = useState("");
  const [announcementImage, setAnnouncementImage] = useState("")
  const [hygieneStandardImage, setHygieneStandardImage] = useState("")
  const [awards_and_batches, set_awards_and_batches] = useState(null);
  const [galleryPhotos, setGalleryPhotos] = useState(null);
  const [partnerImage, setPartnerImage] = useState("")
  const [isHeaderDeleted, setIsHeaderDeleted] = useState(false);
  const [isBannerimageDeleted, setIsBannerImageDeleted] = useState(false);
  const [isPartnerImageDeleted, setIsPartnerImageDeleted] = useState(false)
  const [isSocailImageDeleted, setIsSocailImageDeleted] = useState(false)
  const [isAnnoucementImageDeleted, setIsAnnoucementDeleted] = useState(false)
  const [isHygieneStandardImageDeleted, setIsHygieneStandardImageDeleted] = useState(false)
  const [socialImage, setSocailImage] = useState('')
  const [loader, setLoader] = useState({
    header: false,
    contact: false,
    location: false,
    additionalInfo: false,
    partners: false,
    footer: false,
    addPartner: false,
    removeAll: false,
    addSocailMediaPartner: false,
    gallery: false,
    announcement: false,
    hygieneStandard: false,
  });
  const [showPreview, setShowPreview] = useState(false);
  const [isAddNewPartnerClicked, setIsAddNewPartnerClicked] = useState(false)
  const [isAddNewSocialClicked, setIsAddNewSocialClicked] = useState(false)
  const [previewAllTemplate, setPreviewAllTemplate] = useState(false)
  const [token, setTokenAuth] = useState('')
  //Function's
  useEffect(() => {
    getWebstiteBuilder();
    getStripeKeys()
    if (
      branchArray.length > 1 ||
      (branchArray && branchArray[0].value == "All")
    ) {
      history.push("/dashboard");
    }
  }, [isDataUpdated, branchArray]);
  async function getWebstiteBuilder() {
    try {
      const response = await apiGetMethod(
        allApiUrl.getWebsiteTemplate,
        "",
        header
      );
      if (response.status === 200) {
        setApiWebsiteData(response.data.data);
        setPreviewImage({
          bannerImage: response.data.data[0].headerDetails.bannerImage?.banner,
          headerLogo: response.data.data[0].headerDetails.logo?.banner,
          awards_and_batches:
            response.data.data[0]?.additionalInformation?.awards_and_batches,
          galleryPhotos: response.data.data[0].galleryInfo.galleryPhotos,
          announcementImage: response.data.data[0].announcement.announcementImage?.banner,
          hygieneStandardImage: response.data.data[0].hygieneStandard?.hygieneStandardImages?.banner
        });
        setIsLoading(false);
      }
    } catch (err) {
      console.log("err", err);
      setIsLoading(false);
    }
  }
  async function getStripeKeys() {
    try {
      const response = await apiGetMethod(`${allApiUrl.linkCreate}`, "", header);
      if (response.status === 200) {
        let token = response.data.data.split('token=')[1]
        setTokenAuth(token)
      }
    } catch (err) {
      console.log("err", err);
    }
  }
  //Props
  let props = {
    apiwebsiteData,
    currentModal,
    isHeaderDeleted,
    isBannerimageDeleted,
    header,
    setModalShow,
    setIsDataUpdated,
    isDataUpdated,
    branchArray,
    logo,
    backgroundImage,
    loader,
    setPreviewImage,
    previewImage,
    setBackgroundImage,
    setIsBannerImageDeleted,
    setLogo,
    setIsHeaderDeleted,
    setLoader,
    set_awards_and_batches,
    awards_and_batches,
    partnerImage,
    setPartnerImage,
    isAddNewPartnerClicked,
    setIsAddNewPartnerClicked,
    isPartnerImageDeleted,
    setIsPartnerImageDeleted,
    isSocailImageDeleted,
    setIsSocailImageDeleted,
    socialImage,
    setSocailImage,
    isAddNewSocialClicked,
    setIsAddNewSocialClicked,
    setPreviewAllTemplate,
    previewAllTemplate,
    galleryPhotos,
    setGalleryPhotos,
    announcementImage,
    setAnnouncementImage,
    isAnnoucementImageDeleted,
    setIsAnnoucementDeleted,
    hygieneStandardImage,
    setHygieneStandardImage,
    isHygieneStandardImageDeleted,
    setIsHygieneStandardImageDeleted

  }
  console.log('previewImage', previewImage)
  console.log('permissionData-2473', permissionData)
  // if (permissionData && !permissionData.websitetemplate) {
  //   return <Nopermission />
  // }
  return (
    <React.Fragment>
      <Header active={sidebarToggle} />
      <CustomModal
        show={modalShow}
        Title={currentHeading}
        onHide={() => setModalShow(false)}
      >
        <WebsiteBuilderModal
          {...props}
        />
      </CustomModal>
      <PreviewModal
        // className="testing"
        showPreview={showPreview}
        setShowPreview={setShowPreview}
        currentModal={currentHeading}
        previewAllTemplate={previewAllTemplate}
        setPreviewAllTemplate={setPreviewAllTemplate}

      >
        {previewAllTemplate ?
          <Template token={token} preview={true} /> :
          <PreviewTemplate type={currentModal} />}
      </PreviewModal>
      <div className="interFaceWrap">
        <Sidebar active={sidebarToggle} activePage="website-builder" permissionData={permissionData} />
        <div
          className={`interFaceWrap__inner ${sidebarTypeRedux == false ? "doubleSidebar" : "singleSidebar"
            }`}
        >
          <div className="commonOuter">
            <div className="pageHeader">
              <Container fluid className="px-0">
                <Row className="align-items-center">
                  <div className="col">
                    <div className="headingText headingText--textLeft mb-0">
                      <div style={{ display: 'flex' }}>
                        <h1>Website Builder</h1>
                        <div className="titleButtons"
                          style={{ marginLeft: "12px" }}>
                          <Button
                            onClick={() => {
                              setCurrentModal("header");
                              setCurrentHeading("website view");
                              setShowPreview(true);
                              setPreviewAllTemplate(true)
                            }}
                          >
                            <i class="fa-solid fa-eye"></i>
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-auto ms-auto onlyButtons">
                    <div className="pageHeader__elements">
                      {/* {(orderDetails.taxPercentage && (orderDetails.orderStatus == "Completed" || orderDetails.orderStatus == "Partial Refunded")) && */}
                      {/* <Button
                                                className="commonButton"
                                                onClick={() => setModalShow(true)}>
                                                <p>Preview</p>
                                            </Button> */}
                      {/* } */}
                    </div>
                  </div>
                </Row>
              </Container>
            </div>
            {isLoading ? (
              <Loader />
            ) : (
              <div className="pageBody">
                <Row>
                  <Col md={4}>
                    <div className="gridOuter">
                      <div className="commonBgHead commonBgHead--bottomRadiusNone">
                        <div className="d-flex">
                          <div>Header Details</div>
                          <div className="titleButtons">
                            <Button
                              onClick={() => {
                                setCurrentModal("header");
                                setCurrentHeading("Edit Header Details");
                                setShowPreview(true);
                              }}
                            >
                              <i class="fa-solid fa-eye"></i>
                            </Button>
                            <Button
                              onClick={() => {
                                setModalShow(true);
                                setCurrentModal("header");
                                setCurrentHeading("Edit Header Details");
                              }}
                            >
                              <i class="fa-solid fa-pen-to-square"></i>
                            </Button>
                          </div>
                        </div>
                      </div>
                      <div className="whiteBg whiteBg--topRadiusNone small">
                        <Form>
                          <Form.Group className="form-group">
                            <Form.Label>Header Logo</Form.Label>
                            <div className="imagePreview">
                              <div className="imageView">
                                {
                                  <img
                                    onError={(e) => {
                                      e.target.src = dummyProduct // Replace with a fallback image
                                    }}
                                    src={
                                      apiwebsiteData[0]?.headerDetails?.logo
                                        ?.banner || dummyProduct
                                    }
                                    alt="Header Details Image"
                                  />
                                }
                              </div>
                            </div>
                          </Form.Group>
                          <Form.Group className="form-group">
                            <Form.Label>Banner Image</Form.Label>
                            <div className="imagePreview">
                              <div className="imageView">
                                {
                                  <img
                                    onError={(e) => {
                                      e.target.src = dummyProduct // Replace with a fallback image
                                    }}
                                    src={
                                      apiwebsiteData[0]?.headerDetails
                                        ?.bannerImage?.banner || dummyProduct
                                    }
                                    alt="header Details image preview"
                                  />
                                }
                              </div>
                            </div>
                          </Form.Group>
                          <Form.Group className="form-group">
                            <Form.Label>Description of Restaurant</Form.Label>
                            <p>
                              {
                                apiwebsiteData[0]?.headerDetails
                                  ?.DescriptionOfRestaurant
                              }
                            </p>
                            {/* <Form.Control
                                                            disabled
                                                            as="textarea"
                                                            value={apiwebsiteData[0]?.headerDetails?.DescriptionOfRestaurant} /> */}
                          </Form.Group>
                        </Form>
                      </div>
                    </div>
                    {/* Annocement  section */}
                    <div className="gridOuter">
                      <div className="commonBgHead commonBgHead--bottomRadiusNone">
                        <div className="d-flex">
                          <div>Announcement Details</div>
                          <div className="titleButtons">
                            <Button
                              disabled={apiwebsiteData[0]?.announcement?.show == 0 ? true : false}
                              onClick={() => {
                                setCurrentModal("announcement");
                                setCurrentHeading("Edit Announcement Details");
                                setShowPreview(true);
                              }}
                            >
                              <i class="fa-solid fa-eye"></i>
                            </Button>
                            <Button
                              onClick={() => {
                                setModalShow(true);
                                setCurrentModal("announcement");
                                setCurrentHeading("Edit Announcement Details");
                              }}
                            >
                              <i class="fa-solid fa-pen-to-square"></i>
                            </Button>
                          </div>
                        </div>
                      </div>
                      <div className="whiteBg whiteBg--topRadiusNone small">
                        <Form>
                          <Form.Group className="form-group">
                            <Form.Label>Announcement Image</Form.Label>
                            <div className="imagePreview">
                              <div className="imageView">
                                {
                                  <img
                                    onError={(e) => {
                                      e.target.src = dummyProduct // Replace with a fallback image
                                    }}
                                    src={
                                      apiwebsiteData[0]?.announcement?.announcementImage
                                        ?.banner || dummyProduct
                                    }
                                    alt="Announcement Imag"
                                  />
                                }
                              </div>
                            </div>
                          </Form.Group>
                          <Form.Group className="form-group">
                            <Form.Label>Title of Restaurant</Form.Label>
                            <p>
                              {apiwebsiteData[0]?.announcement
                                ?.announcementTitle}
                            </p>
                          </Form.Group>
                          <Form.Group className="form-group">
                            <Form.Label>Description of Restaurant</Form.Label>
                            <p> {apiwebsiteData[0]?.announcement?.announcementDescription}</p>
                          </Form.Group>
                        </Form>
                      </div>
                    </div>
                    {/*====================================== hygiene standards ============================================*/}
                    <div className="gridOuter">
                      <div className="commonBgHead commonBgHead--bottomRadiusNone">
                        <div className="d-flex">
                          <div>Hygiene Standards Details</div>
                          <div className="titleButtons">
                            <Button
                              disabled={apiwebsiteData[0]?.hygieneStandard?.show == 0 ? true : false}
                              onClick={() => {
                                setCurrentModal("hygiene");
                                setCurrentHeading("Edit Hygiene Standards Details");
                                setShowPreview(true);
                              }}
                            >
                              <i class="fa-solid fa-eye"></i>
                            </Button>
                            <Button
                              onClick={() => {
                                setModalShow(true);
                                setCurrentModal("hygiene");
                                setCurrentHeading("Edit Hygiene Standards Details");
                              }}
                            >
                              <i class="fa-solid fa-pen-to-square"></i>
                            </Button>
                          </div>
                        </div>
                      </div>
                      <div className="whiteBg whiteBg--topRadiusNone small">
                        <Form>
                          <Form.Group className="form-group">
                            <Form.Label>Image</Form.Label>
                            <div className="imagePreview">
                              <div className="imageView">
                                {
                                  <img
                                    onError={(e) => {
                                      e.target.src = dummyProduct // Replace with a fallback image
                                    }}
                                    src={apiwebsiteData[0]?.hygieneStandard?.hygieneStandardImages?.banner || dummyProduct
                                    }
                                    alt="hygiene Standard Images preview"
                                  />
                                }
                              </div>
                            </div>
                          </Form.Group>
                          <Form.Group className="form-group">
                            <Form.Label>Title for hygiene standard</Form.Label>
                            <p>
                              {apiwebsiteData[0]?.hygieneStandard?.hygieneStandardTitle}
                            </p>
                          </Form.Group>
                          <Form.Group className="form-group">
                            <Form.Label>Description for hygiene standard</Form.Label>
                            <p> {apiwebsiteData[0]?.hygieneStandard?.hygieneStandardDescription}</p>
                          </Form.Group>
                        </Form>
                      </div>
                    </div>
                    {/*  --------------------------------------------------------*/}
                    <div className="gridOuter">
                      {/* <div className="commonBgHead commonBgHead--bottomRadiusNone">Food Delivery Partners</div> */}
                      <div className="commonBgHead commonBgHead--bottomRadiusNone">
                        <div className="d-flex">
                          <div>Food Delivery Partners</div>
                          <div className="titleButtons">
                            <Button
                              disabled={apiwebsiteData[0].foodDeliveryPartners.show == 0 ? true : false}
                              onClick={() => {
                                setShowPreview(true);
                                setCurrentModal("partners");
                                setCurrentHeading(
                                  "Edit Food Delivery Partners Details"
                                );
                              }}
                            >
                              <i class="fa-solid fa-eye"></i>
                            </Button>
                            <Button
                              onClick={() => {
                                setModalShow(true);
                                setCurrentModal("partners");
                                setCurrentHeading(
                                  "Edit Food Delivery Partners Details"
                                );
                              }}
                            >
                              <i class="fa-solid fa-pen-to-square"></i>
                            </Button>
                          </div>
                        </div>
                      </div>
                      <div className="whiteBg whiteBg--minHeight whiteBg--topRadiusNone small">
                        <Form>
                          {apiwebsiteData &&
                            apiwebsiteData[0].foodDeliveryPartners &&
                            apiwebsiteData[0].foodDeliveryPartners?.partners.length > 0 ?
                            apiwebsiteData[0].foodDeliveryPartners?.partners?.map(
                              (item, index) => {
                                return (
                                  <>
                                    <div className="foodDeliveryPartnersGrid">
                                      <Form.Group className="form-group">
                                        {item &&
                                          item.partnerLogo &&
                                          item.partnerLogo[0] &&
                                          item.partnerLogo[0].banner ?
                                          <img
                                            onError={(e) => {
                                              e.target.src = dummyProduct // Replace with a fallback image
                                            }}
                                            className="foodLogoImg"
                                            src={item.partnerLogo[0].banner}
                                            alt={`partner_Logo_${index}`}
                                          /> : ""
                                        }
                                      </Form.Group>
                                      <Form.Group className="form-group">
                                        <Form.Label>Link</Form.Label>
                                        <p>{item.link}</p>
                                      </Form.Group>
                                      <Form.Group className="form-group">
                                        <Form.Label>Description</Form.Label>
                                        <p>{item.description}</p>
                                      </Form.Group>
                                    </div>
                                  </>
                                );
                              }
                            ) :
                            <div className="whiteBg whiteBg--topRadiusNone small">
                              <Form>
                                <Form.Group className="form-group">
                                  <Form.Label>Partner Logo</Form.Label>
                                  <div className="imagePreview">
                                    <div className="imageView">
                                      {
                                        <img
                                          onError={(e) => {
                                            e.target.src = dummyProduct // Replace with a fallback image
                                          }}
                                          src={dummyProduct}
                                          alt="Partner Logo preview"
                                        />
                                      }
                                    </div>
                                  </div>
                                </Form.Group>
                                <Form.Group className="form-group">
                                  <Form.Label>Link</Form.Label>
                                  <p>
                                    {/* {apiwebsiteData[0]?.hygieneStandard?.hygieneStandardTitle} */}
                                  </p>
                                </Form.Group>
                                <Form.Group className="form-group">
                                  <Form.Label>Description</Form.Label>
                                  {/* <p> {apiwebsiteData[0]?.hygieneStandard?.hygieneStandardDescription}</p> */}
                                </Form.Group>
                              </Form>
                            </div>
                          }
                        </Form>
                      </div>
                    </div>
                  </Col>
                  <Col md={8}>
                    <div className="viewOrder">
                      <div className="gridOuter">
                        <div className="commonBgHead commonBgHead--bottomRadiusNone">
                          <div className="d-flex">
                            <div>About Us</div>
                            <div className="titleButtons">
                              <Button
                                onClick={() => {
                                  setCurrentModal("contact");
                                  setCurrentHeading("Edit About Us");
                                  setShowPreview(true);
                                }}
                              >
                                <i class="fa-solid fa-eye"></i>
                              </Button>
                              <Button
                                onClick={() => {
                                  setModalShow(true);
                                  setCurrentModal("contact");
                                  setCurrentHeading("Edit About Us");
                                }}
                              >
                                <i class="fa-solid fa-pen-to-square"></i>
                              </Button>
                            </div>
                          </div>
                        </div>
                        <div className="whiteBg whiteBg--topRadiusNone small">
                          <Form>
                            <Form.Group className="form-group group-flex">
                              <Form.Label>About the Restaurant</Form.Label>
                              <div>
                                {
                                  apiwebsiteData[0]?.contactDetail
                                    ?.aboutTheRestaurant
                                }
                              </div>
                              {/* <Form.Control
                                                                as="textarea"
                                                                disabled
                                                                value={apiwebsiteData[0]?.contactDetail?.aboutTheRestaurant} /> */}
                            </Form.Group>
                            <Form.Group className="form-group group-flex">
                              <Form.Label>Review</Form.Label>
                              <div>
                                {apiwebsiteData[0]?.contactDetail?.review}
                              </div>
                              {/* <Form.Control type="text" disabled
                                                                value={apiwebsiteData[0]?.contactDetail?.review} /> */}
                            </Form.Group>
                            <Form.Group className="form-group group-flex">
                              <Form.Label>Opening Days</Form.Label>
                              <div className="d-flex">
                                {apiwebsiteData[0]?.contactDetail?.openingDays.map(
                                  (item, index, array) => {
                                    return (
                                      <p>
                                        {item.label}
                                        {index !== array.length - 1 ? "," : "."}
                                      </p>
                                    );
                                  }
                                )}
                              </div>
                            </Form.Group>
                            <Form.Group className="form-group group-flex">
                              <Form.Label>Opening Hours</Form.Label>
                              <div className="d-flex">
                                <p style={{ margin: "0 4px" }}>
                                  {
                                    apiwebsiteData[0]?.contactDetail
                                      ?.openingHours?.startTiming
                                  }
                                </p>
                                <p style={{ margin: "0 4px" }}>
                                  {
                                    apiwebsiteData[0]?.contactDetail
                                      ?.openingHours?.startPostMeridiem.label
                                  }
                                </p>
                                <p
                                  style={{
                                    margin: "0 4px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  To
                                </p>
                                <p style={{ margin: "0 4px" }}>
                                  {
                                    apiwebsiteData[0]?.contactDetail
                                      ?.openingHours?.endTiming
                                  }
                                </p>
                                <p style={{ margin: "0 4px" }}>
                                  {
                                    apiwebsiteData[0]?.contactDetail
                                      ?.openingHours?.endPostMeridiem.label
                                  }
                                </p>
                              </div>
                            </Form.Group>
                          </Form>
                        </div>
                      </div>
                      <div className="gridOuter">
                        <div className="commonBgHead commonBgHead--bottomRadiusNone">
                          <div className="d-flex">
                            <div>Additional Information</div>
                            <div className="titleButtons">
                              <Button
                                disabled={apiwebsiteData[0].additionalInformation.show == 0 ? true : false}
                                onClick={() => {
                                  setCurrentModal("additional");
                                  setCurrentHeading(
                                    "Edit Additional Information"
                                  );
                                  setShowPreview(true);
                                }}
                              >
                                <i class="fa-solid fa-eye"></i>
                              </Button>
                              <Button
                                onClick={() => {
                                  setModalShow(true);
                                  setCurrentModal("additional");
                                  setCurrentHeading(
                                    "Edit Additional Information"
                                  );
                                }}
                              >
                                <i class="fa-solid fa-pen-to-square"></i>
                              </Button>
                            </div>
                          </div>
                        </div>
                        <div className="whiteBg whiteBg--topRadiusNone small">
                          <Form className="">
                            <Form.Group className="form-group group-flex">
                              <Form.Label>Award And Branches</Form.Label>
                            </Form.Group>
                            <div className="imageView">
                              {apiwebsiteData &&
                                apiwebsiteData[0] &&
                                apiwebsiteData[0].additionalInformation &&
                                apiwebsiteData[0].additionalInformation.awards_and_batches.map(
                                  (item, index) => {
                                    return (
                                      <img
                                        onError={(e) => {
                                          e.target.src = dummyProduct // Replace with a fallback image
                                        }}
                                        src={item["banner"]}
                                        width={100}
                                        height={100}
                                        alt={`additionalInformation awards_and_batches_${index}`}
                                      />
                                    );
                                  }
                                )}
                            </div>
                          </Form>
                        </div>
                      </div>
                      {/* Gallery Information */}
                      <div className="gridOuter">
                        <div className="commonBgHead commonBgHead--bottomRadiusNone">
                          <div className="d-flex">
                            {console.log('2948', apiwebsiteData[0].galleryInfo.show == 0 ? true : false)}
                            <div>Gallery Information</div>
                            <div className="titleButtons">
                              <Button
                                disabled={apiwebsiteData[0].galleryInfo.show == 0 ? true : false}
                                onClick={() => {
                                  setCurrentModal("gallery");
                                  setCurrentHeading(
                                    "Edit Gallery information"
                                  );
                                  setShowPreview(true);
                                }}
                              >
                                <i class="fa-solid fa-eye"></i>
                              </Button>
                              <Button
                                onClick={() => {
                                  setModalShow(true);
                                  setCurrentModal("gallery");
                                  setCurrentHeading(
                                    "Edit Gallery Information"
                                  );
                                }}
                              >
                                <i class="fa-solid fa-pen-to-square"></i>
                              </Button>
                            </div>
                          </div>
                        </div>
                        <div className="whiteBg whiteBg--topRadiusNone small">
                          <Form className="">
                            <Form.Group className="form-group group-flex">
                              <Form.Label>Gallery</Form.Label>
                            </Form.Group>
                            <div className="imageView">
                              {apiwebsiteData &&
                                apiwebsiteData[0] &&
                                apiwebsiteData[0].galleryInfo &&
                                apiwebsiteData[0].galleryInfo.galleryPhotos.map(
                                  (item, index) => {
                                    return (
                                      <img
                                        onError={(e) => {
                                          e.target.src = dummyProduct // Replace with a fallback image
                                        }}
                                        src={item["banner"]}
                                        width={100}
                                        height={100}
                                        alt={`galleryInfo galleryPhotos_${index}`}
                                      />
                                    );
                                  }
                                )}
                            </div>
                          </Form>
                        </div>
                      </div>
                      <div className="gridOuter">
                        <div className="commonBgHead commonBgHead--bottomRadiusNone">
                          <div className="d-flex">
                            <div>Locations</div>
                            <div className="titleButtons">
                              <Button
                                disabled={apiwebsiteData[0].locations.show == 0 ? true : false}
                                onClick={() => {
                                  setCurrentModal("location");
                                  setCurrentHeading("Edit Locations Details");
                                  setShowPreview(true);
                                }}
                              >
                                <i class="fa-solid fa-eye"></i>
                              </Button>
                              <Button
                                onClick={() => {
                                  setModalShow(true);
                                  setCurrentModal("location");
                                  setCurrentHeading("Edit Locations Details");
                                }}
                              >
                                <i class="fa-solid fa-pen-to-square"></i>
                              </Button>
                            </div>
                          </div>
                        </div>
                        <div className="whiteBg whiteBg--topRadiusNone small">
                          <Form>
                            <Form.Group className="form-group group-flex">
                              <Form.Label>Delivery fee</Form.Label>
                              <div className="addChoicesTable commonTableResponsive text-start">
                                <Table className="basicTable">
                                  <thead>
                                    <tr>
                                      <th>Zone</th>
                                      <th className="sizePrice">Min</th>
                                      <th>Fee</th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {apiwebsiteData &&
                                      apiwebsiteData[0] &&
                                      apiwebsiteData[0].locations &&
                                      apiwebsiteData[0].locations.deliveryFees.map(
                                        (value, index) => {
                                          return (
                                            <>
                                              <tr key={index}>
                                                <td data-label="Zone">
                                                  <div>{value.zone}</div>
                                                  {/* <Form.Control disabled type="text" value={value.zone} placeholder="" /> */}
                                                </td>
                                                <td data-label="Min">
                                                  {/* <Form.Control disabled type="text" value={value.min} placeholder=""/> */}
                                                  <div>{value.min}</div>
                                                </td>
                                                <td data-label="Fee">
                                                  {/* <Form.Control disabled type="text" value={value.fee} placeholder=""  /> */}
                                                  <div>{value.fee} </div>
                                                </td>
                                              </tr>
                                            </>
                                          );
                                        }
                                      )}
                                  </tbody>
                                </Table>
                              </div>
                            </Form.Group>
                            <Form.Group className="form-group group-flex">
                              <Form.Label>Describe the location.</Form.Label>
                              <div>{
                                apiwebsiteData[0]?.locations
                                  ?.describeTheLocation
                              }
                              </div>
                            </Form.Group>
                            <Form.Group className="form-group group-flex">
                              <Form.Label>Location.</Form.Label>
                              <div>
                                {apiwebsiteData &&
                                  apiwebsiteData[0].locations &&
                                  apiwebsiteData[0].locations.enterLocation &&
                                  apiwebsiteData[0].locations.enterLocation ?
                                  <>
                                    <LoadMap data={apiwebsiteData[0].locations.enterLocation} />
                                    <small>{apiwebsiteData[0].locations.enterLocation.formatted_address}</small>
                                  </>
                                  : 'Please select any location'

                                }
                              </div>
                            </Form.Group>
                          </Form>
                        </div>
                      </div>
                      <div className="gridOuter">
                        <div className="commonBgHead commonBgHead--bottomRadiusNone">
                          <div className="d-flex">
                            <div>Footer Details</div>
                            <div className="titleButtons">
                              <Button
                                onClick={() => {
                                  setShowPreview(true);
                                  setCurrentModal("footer");
                                  setCurrentHeading("Edit Footer Details");
                                }}
                              >
                                <i class="fa-solid fa-eye"></i>
                              </Button>
                              <Button
                                onClick={() => {
                                  setModalShow(true);
                                  setCurrentModal("footer");
                                  setCurrentHeading("Edit Footer Details");
                                }}
                              >
                                <i class="fa-solid fa-pen-to-square"></i>
                              </Button>
                            </div>
                          </div>
                        </div>
                        <div className="whiteBg whiteBg--topRadiusNone small">
                          <Form className="half">
                            {apiwebsiteData &&
                              apiwebsiteData[0]?.footer?.social?.map((item) => {
                                return (
                                  <Form.Group className="form-group group-flex">
                                    <Form.Label>{titleCase(item.value)}</Form.Label>
                                    <Form.Control
                                      className="notCapital"
                                      type="text"
                                      disabled
                                      value={item.label}
                                    />
                                  </Form.Group>
                                );
                              })}
                            <Form.Group className="form-group group-flex">
                              <Form.Label>Footer Text</Form.Label>
                              <Form.Control
                                disabled
                                className="not Capital"
                                type="text"
                                value={
                                  apiwebsiteData &&
                                  apiwebsiteData[0]?.footer?.footerText
                                }
                              />
                            </Form.Group>
                          </Form>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            )}
          </div>
        </div>
        {!showPreview &&
          <SidebarTwo
            activePage=""
            setSidebarType={setSidebarType}
            sidebarType={sidebarType}
            setPermissionData={setPermissionData}
          />
        }
      </div>
    </React.Fragment>
  );
}
export default WebsiteBuilder;
