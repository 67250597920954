//---------------------------------------------------------------------------Commmon Regrex-----------------------------------------------------------------------------------

// import { allApiUrl } from "../api/apiRoute";
// import { apiGetMethod } from "../api/rest";
// import toastMessages from "../components/toastMessages";

//---------------------------------------------------------------------------Common Constants---------------------------------------------------------------------------------
export var newDateOptions = {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
};
export let smallStyle = {
  display: "block",
  fontSize: "13px",
  color: "#E02424",
};
let restrictRoutesOrganization = [
  "/organizations",
  // "/add-organization",
  "/editorganization?id=",
  "/profile?developerMode=true",
];
//--------------------------------------------------------------------------Common Function-----------------------------------------------------------------------------------
export let c = console.log;
export const setupLogging = () => {
  //removed logs from production
  if (process.env.REACT_APP_ENVIRONMENT == "production") {
    // console.log("setupLog/ging");
    // console.log("environment", process.env.REACT_APP_ENVIRONMENT);
    console.log = () => {};
    console.info = () => {};
    console.warn = () => {};
    console.error = () => {};
  }
};
//MM/DD/YYYY
export function getAllDatesBetween(startDate, endDate) {
  const dates = [];
  let currentDate = new Date(startDate);
  while (currentDate <= endDate) {
    dates.push(new Date(currentDate).toLocaleString("en-US", newDateOptions));
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return dates;
}

//Mar 21 2023
export function getAllDatesBetweenShortFormat(
  start,
  end,
  list,
  type,
  typeOfData
) {
  // console.log("typeOfData", typeOfData);
  if (typeOfData == "months") {
    function getMonthsBetween(startTimestamp, endTimestamp) {
      const options = { month: "long", year: "numeric" };
      const months = [];
      const startDate = new Date(startTimestamp);
      const endDate = new Date(endTimestamp);
      startDate.setDate(1);
      while (startDate < endDate) {
        months.push(startDate.toLocaleString("en-US", options));
        startDate.setMonth(startDate.getMonth() + 1);
      }
      return months;
    }
    let orderList = list.current.orders;
    if (!type) {
      orderList = list.previous.orders;
    }
    let allDateWiseData = [];
    let allDateListing = getMonthsBetween(start, end);
    // console.log("---52----", allDateListing);
    allDateListing.forEach((dateItem) => {
      let isExist = Object.keys(orderList).find(
        (findItem) => findItem == dateItem
      );
      if (!isExist) {
        allDateWiseData.push(0);
      } else {
        allDateWiseData.push(parseFloat(orderList[isExist].gross.toFixed(2)));
      }
      // console.log("isExist ---->", isExist);
    });
    // console.log("allDateWiseData", allDateWiseData);
    return { allDateListing, allDateWiseData };
  } else {
    let allDateListing = [];
    let currentDate = new Date(start);
    while (currentDate <= end) {
      allDateListing.push(
        currentDate.toLocaleDateString("en-US", {
          month: "short",
          day: "2-digit",
          year: "numeric",
        })
      );
      currentDate.setDate(currentDate.getDate() + 1);
    }
    let allDateWiseData = getAllDateWiseData(allDateListing, list, type);
    return { allDateListing, allDateWiseData };
  }
}

export function getTheDifferenceBetweenTwoMonths(start, end) {
  let currentMonth = daysInMonth(
    new Date(start).getMonth() + 1,
    new Date(start).getFullYear()
  );
  let previousMonth = daysInMonth(
    new Date(end).getMonth() + 1,
    new Date(end).getFullYear()
  );
  return currentMonth - previousMonth;
  function daysInMonth(month, year) {
    return new Date(year, month, 0).getDate();
  }
}

//Calculate Percentage
export function calculatedPercentage(previousData, currentData) {
  if (previousData == 0 && currentData != 0) {
    return "100%";
  } else if (previousData == 0 && currentData == 0) {
    return "0%";
  } else {
    return ((currentData - previousData) / previousData) * 100 + "%";
  }
}

function getAllDateWiseData(allDates, data, type) {
  let orderList = data.current.orders;
  if (!type) {
    orderList = data.previous.orders;
  }
  let list = [];
  allDates.forEach((dateItem) => {
    let isExist = Object.keys(orderList).find(
      (findItem) => findItem == dateItem
    );
    if (!isExist) {
      list.push(0);
    } else {
      list.push(parseFloat(orderList[isExist].gross.toFixed(2)));
    }
  });

  return list;
}

//for Capitaliztion of first letter
export function titleCase(str) {
  var splitStr = str.toLowerCase().split(" ");
  for (var i = 0; i < splitStr.length; i++) {
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(" ");
}

//Check if there is not data in category
export function isCategoryEmpty(data) {
  for (let i = 0; i < data.data.length; i++) {
    const obj = data.data[i];
    const keys = Object.keys(obj);
    if (keys.length !== 1 || keys[0] !== "entity") {
      return false;
    }
  }
  return true;
}

//CsvConvertJson Formatter
export function csvConvertJson(json) {
  let result = [];
  let dates = new Set();
  for (let obj of json) {
    let entity = obj.entity;
    for (let date in obj) {
      if (date !== "entity") {
        let orderCount = obj[date].orderCount;
        let price = obj[date].price;
        dates.add(date);
        let entry = result.find((e) => e.date === date);
        if (!entry) {
          entry = { date: date };
          result.push(entry);
        }
        entry[entity] = price;
      }
    }
  }
  // Sort the result by date
  result.sort((a, b) => new Date(a.date) - new Date(b.date));
  return result;
}

export function countNumberInPhoneNumber(str, letter) {
  let count = 0;
  for (let i = 0; i < str.length; i++) {
    if (str.charAt(i) == letter) {
      count += 1;
    }
  }
  return count;
}

//Time Format Changes
export function dateFormatter(item) {
  var date = new Date(parseInt(item));
  var date_format_str =
    ((date.getMonth() + 1).toString().length == 2
      ? (date.getMonth() + 1).toString()
      : "0" + (date.getMonth() + 1).toString()) +
    "/" +
    (date.getDate().toString().length == 2
      ? date.getDate().toString()
      : "0" + date.getDate().toString()) +
    "/" +
    date.getFullYear().toString() +
    " ";
  return date_format_str;
}
//mm/dd/yyyy
export function formatDate_mm_dd_yyyy(dateString, time = false) {
  const date = new Date(dateString);
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 since months are zero-based
  const day = String(date.getDate()).padStart(2, "0");
  const year = date.getFullYear();
  let timeDisplay;
  if (time) {
    const time = new Date(dateString);
    let hours = time.getHours();
    let minutes = time.getMinutes();
    timeDisplay = ` ${hours}:${minutes < 10 ? "0" + minutes : minutes}`;
  }
  return `${month}/${day}/${year} ${
    timeDisplay !== undefined ? timeDisplay : ""
  }`;
}
//month Difference
export function monthDifference(d1, d2) {
  var months;
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth();
  months += d2.getMonth();
  return months;
}
//Redirection
export function redirection(type, history, toast, isDeveloperMode = false) {
  if (type == "organizationUser") {
    // console.log(
    //   "window.location.pathname------------->",
    //   window.location.pathname,
    //   window.location.search
    // );
    if (
      restrictRoutesOrganization.includes(
        `${window.location.pathname.toLowerCase()}${window.location.search.toLowerCase()}`
      )
    ) {
      history.push("/dashboard");
      // toast(
      //   "error500",
      //   "Only Developer Can Access These Pages If You Are Developer Than Click 'Back To Developer' from the profile icon to go back to the Developer page."
      // );
      // }
    }
  } else {
    if (!isDeveloperMode) {
      // console.log(
      //   "window.location.search",
      //   window.location.search.split("=")[0]
      // );
      if (
        !restrictRoutesOrganization.includes(
          `${window.location.pathname.toLowerCase()}${window.location.search.toLowerCase()}`
        )
      ) {
        if (
          window.location.search.split("=")[0] == "?id" ||
          window.location.search == "?developerMode=true"
        ) {
        } else {
          history.push("/organizations");
          // toast(
          //   "error",
          //   "Developer cannot access this page without selecting any particular organization"
          // );
        }
      }
    }
  }
}
export function getUtcDate(time, date) {
  const originalTimestamp = date;
  const dateFromTimestamp = new Date(originalTimestamp);
  const newTime = time;
  const [hours, minutes] = newTime.split(/:| /); // Split the time string
  dateFromTimestamp.setHours(parseInt(hours, 10));
  dateFromTimestamp.setMinutes(parseInt(minutes, 10));
  dateFromTimestamp.setSeconds(0);
  console.log(dateFromTimestamp);
  const newTimestamp = dateFromTimestamp.getTime();
  console.log(newTimestamp);
  console.log(`Original Timestamp: ${originalTimestamp}`);
  console.log(`New Timestamp: ${newTimestamp}`);
  return newTimestamp;
}
//Validations
// const formData = Object.keys(form).reduce((acc, key) => {
//     if (key === 'fullname' && form.fullname !== currForm.fullname) {
//         acc.fullname = form.fullname;
//     } else if (key === 'password' && form.password) {
//         acc.password = form.password;
//     } else if (key === 'email' && form.email !== currForm.email) {`
//         acc.email = form.email;
//     } else if (key === 'pin' && form.pin !== currForm.pin) {
//         acc.pin = form.pin;
//     } else if (key === 'phone' && form.phone !== currForm.phone) {
//         acc.phone = form.phone;
//     } else if (key === 'roleId' && form.roleId !== currForm.roleId) {
//         acc.roleId = form.roleId.label;
//     } else if (key === 'branch') {
//         if (form.branch.length !== currForm.branch.length) {
//             acc.branch = form.branch;
//         } else {
//             const sortedData = form.branch.sort((a, b) => a.value - b.value);
//             const sortedCompare = currForm.branch.sort((a, b) => a.value - b.value);
//             let equal = true;
//             for (let i = 0; i < sortedData.length; i++) {
//                 if (sortedData[i].value !== sortedCompare[i].value || sortedData[i].label !== sortedCompare[i].label) {
//                     equal = false;
//                     break;
//                 }
//             }
//             if (!equal) {
//                 acc.branch = form.branch;
//             }
//         }
//     }
//     return acc;
// }, {});
