// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.digitalMenuOrderWrap .itemCartInfo {
  max-width: 100%;
  position: relative;
  top: -1px;
  left: 0;
  box-shadow: none;
  border-top: 0;
  padding-bottom: 20px;
}
.digitalMenuOrderWrap .itemCartInfo__body {
  position: relative;
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  overflow: visible;
}
.digitalMenuOrderWrap .itemCartInfo__body .itemSelectInfo .itemTitle {
  margin-top: 20px;
}
.digitalMenuOrderWrap .itemCartInfo .closeButton {
  right: 25px;
  display: none;
}
.digitalMenuOrderWrap .menuItem__description .accordionArrow {
  position: absolute;
  top: 20px;
  right: 10px;
  transition: all 0.5s ease;
}
.digitalMenuOrderWrap .menuItem__description .accordionArrow i {
  color: var(--greenColor);
}
.digitalMenuOrderWrap .menuItem:nth-child(2n) .itemCartInfo {
  left: 0;
  right: inherit;
}
.digitalMenuOrderWrap .menuItem__inner {
  padding-left: 10px;
  padding-right: 10px;
}
.digitalMenuOrderWrap .menuItem.activeTab .menuItem__inner {
  border-left-width: 1px;
  border-right-width: 1px;
}
.digitalMenuOrderWrap .menuItem.activeTab .menuItem__description .accordionArrow {
  transform: rotate(-180deg);
}`, "",{"version":3,"sources":["webpack://./src/components/digital-menu-list/css/digitalMenuStyle.scss"],"names":[],"mappings":"AACE;EACE,eAAA;EACA,kBAAA;EACA,SAAA;EACA,OAAA;EACA,gBAAA;EACA,aAAA;EACA,oBAAA;AAAJ;AACI;EACE,kBAAA;EACA,YAAA;EACA,gBAAA;EACA,gBAAA;EACA,iBAAA;AACN;AACQ;EACE,gBAAA;AACV;AAGI;EACE,WAAA;EACA,aAAA;AADN;AAMM;EACE,kBAAA;EACA,SAAA;EACA,WAAA;EACA,yBAAA;AAJR;AAKQ;EACE,wBAAA;AAHV;AAQM;EACE,OAAA;EACA,cAAA;AANR;AASI;EACE,kBAAA;EACA,mBAAA;AAPN;AAUM;EACE,sBAAA;EACA,uBAAA;AARR;AAWQ;EACE,0BAAA;AATV","sourcesContent":[".digitalMenuOrderWrap {\n  .itemCartInfo {\n    max-width: 100%;\n    position: relative;\n    top: -1px;\n    left: 0;\n    box-shadow: none;\n    border-top: 0;\n    padding-bottom: 20px;\n    &__body {\n      position: relative;\n      height: 100%;\n      min-height: 100%;\n      max-height: 100%;\n      overflow: visible;\n      .itemSelectInfo {\n        .itemTitle {\n          margin-top: 20px;\n        }\n      }\n    }\n    .closeButton {\n      right: 25px;\n      display: none;\n    }\n  }\n  .menuItem {\n    &__description {\n      .accordionArrow {\n        position: absolute;\n        top: 20px;\n        right: 10px;\n        transition: all 0.5s ease;\n        i {\n          color: var(--greenColor);\n        }\n      }\n    }\n    &:nth-child(2n) {\n      .itemCartInfo {\n        left: 0;\n        right: inherit;\n      }\n    }\n    &__inner {\n      padding-left: 10px;\n      padding-right: 10px;\n    }\n    &.activeTab {\n      .menuItem__inner {\n        border-left-width: 1px;\n        border-right-width: 1px;\n      }\n      .menuItem__description {\n        .accordionArrow {\n          transform: rotate(-180deg);\n        }\n      }\n    }\n  }\n  // .commonCheckOptions {\n  //   .form-check {\n  //     .form-check-input {\n  //       display: none;\n  //     }\n  //     .form-check-label {\n  //       padding: 8px 15px;\n  //     }\n  //   }\n  // }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
