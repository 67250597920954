import React from 'react';
import { Spinner, Container, Col, Row } from "react-bootstrap";
function LoadingPage() {
    return (
        <React.Fragment>
            <div className="loadingPageWrap pt-5">
                <Container>
                    <Row className="justify-content-md-center">
                        <Col lg="8" md="10" xs="12">
                            <div className="text-center">
                                <Spinner animation="border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default LoadingPage;