import { useDispatch } from "react-redux";
import { toggleSideBarFuntionalityTwo } from "../../features/todoSlice";

export const HandlerSidebarToggle = () => {
  let dispatch = useDispatch();
  window.addEventListener("click", (e) => {
    const clickedElement = e.target;
    const parentElement = clickedElement.parentNode;

    if (parentElement) {
      // Check if the parent element exists
      const parentClassName = parentElement.className;
      if (parentClassName == "interFaceWrap") {
        dispatch(toggleSideBarFuntionalityTwo(true));
      }
    } else {
      console.log("No parent element found.");
    }
  });
  //   window.addEventListener("mouseover", (e) => {
  //     const clickedElement = e.target;
  //     const parentElement = clickedElement.parentNode;
  //     // Now you have both the clicked element and its parent element
  //     console.log("Clicked Element:", clickedElement);
  //     console.log("Parent Element:", parentElement);
  //     // if (parentElement == "interFaceWrap") {
  //     // }
  //   });
  let mouseTimer;
  function handleMouseInactive() {
    dispatch(toggleSideBarFuntionalityTwo(true));
    // console.log("Mouse has been inactive for 5 seconds");
  }
  function resetMouseTimer() {
    clearTimeout(mouseTimer); // Clear the previous timer, if any
    mouseTimer = setTimeout(handleMouseInactive, 5000); // Set a new timer for 5 seconds
  }
  document.addEventListener("mousemove", resetMouseTimer);
  document.addEventListener("mousedown", resetMouseTimer);
  return null;
};
