import React, { useEffect, useState } from "react";
import { Button, Container, Row } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from "query-string";
//Header
import Header from '../common/header';
import Sidebar from '../common/sidebar';
import { allApiUrl } from "../../api/apiRoute";
import { apiGetMethod, apiPutMethod } from "../../api/rest";
import toastMessages from "../toastMessages";
import { ProductItem } from "../add-product";
import { useSelector } from "react-redux";
import CommonButton from "../common-ui/Button";
import SidebarTwo from "../common/sidebarTwo";
import { c } from "../../features/helper";

function EditProduct() {
    //Sidebar Toggle
    const [preview, setPreview] = useState([]);
    const [permissionData, setPermissionData] = useState({})
    const [active, setActive] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [sidebarType, setSidebarType] = useState('singleSidebar')
    const currBranch = useSelector((state) => state.counter.currBranch)
    const branchArray = useSelector((state) => state.counter.branchArray)
    let branches = branchArray.map((item) => item.value)
    const header = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        branchid: JSON.stringify(branches)
    };
    const [productSizeCount, setProductSizeCount] = useState(1)
    const [currTab, setCurrTab] = useState('')
    const history = useHistory()
    let location = useLocation()
    const [isPageRefresh, setIsPageRefreshed] = useState(false)
    const [activeBranch, setActiveBranch] = useState(null)
    const [currentToggleType, setCurrentToggleType] = useState('')
    let { id } = queryString.parse(location.search);
    const [loader, setLoader] = useState(false)
    const [currForm, setCurrForm] = useState({})
    const [modifierList, setModifierList] = useState([])
    const [initialModifierList, setInitialModifierList] = useState([])
    const [form, setForm] = useState({
        productname: '',
        price: '',
        description: '',
        modifierset: [],
        productsize: {
            type: 'single',
            parameters: [{
                size: '',
                price: "0",
                choices: [],
                default: 1
            }
            ],
            parametersMultiple: [{
                size: '',
                price: "0",
                choices: [],
                default: 1
            }
            ]
        },
        visibility: 1,
        category: [],
        file: ''
    })
    useEffect(() => {
        getProductDetail()
    }, [isPageRefresh, currBranch.value, branchArray])
    function titleCase(str) {
        var splitStr = str.toLowerCase().split(' ');
        for (var i = 0; i < splitStr.length; i++) {
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        return splitStr.join(' ');
    }
    // c('form---->', form.preparationtime == "" ? 0 : 'hello')
    async function getAllModifier() {
        try {
            const response = await apiGetMethod(`${allApiUrl.view_all_modifier}`, "", header);
            if (response.status === 200) {
                // setForm((prev) => {
                //     prev.productsize.parameters.map((item) => {   //eslint-disable-line
                //         item.choices.map((categoryItem) => {   //eslint-disable-line
                //             response.data.categories.Items.forEach((findItem) => {
                //                 if (findItem.SK == categoryItem.value)   //eslint-disable-line
                //                     categoryItem.label = titleCase(findItem.modifiername);
                //             });
                //         });
                //     });
                //     return { ...prev };
                // });
                //
                let modifier = response.data.categories.Items.map((item) => {
                    return ({
                        label: titleCase(item.modifiername),
                        value: item.SK
                    })
                })
                console.log('modifier--name----', modifier)
                setModifierList(modifier)
                setInitialModifierList(modifier)

            }
        } catch (err) {
            toastMessages("error", err.data.message);
            if (err.data.message == "Session Expired.") {   //eslint-disable-line
                localStorage.clear();
                history.push('/login')
            }
            console.log("err", err);
        }
    }
    async function getProductDetail() {
        try {
            const response = await apiGetMethod(allApiUrl.view_all_product, `/${id}`, header);
            if (response.status === 200) {
                setForm(response.data.data.Items[0])
                setCurrForm(response.data.data.Items[0])
                setIsLoading(false)
                // if (response.data.data.Items[0].productsize.type == "single") {   //eslint-disable-line
                //     setForm((prevData) => {
                //         prevData.productsize.parametersMultiple = [{
                //             size: '',
                //             price: "0",
                //             choices: [],
                //             default: 1
                //         }
                //         ]
                //         return {
                //             ...prevData
                //         }
                //     })
                // }
                // else {
                //     setForm((prevData) => {
                //         prevData.productsize.parametersMultiple = prevData.productsize.parameters
                //         prevData.productsize.parameters = [{
                //             size: '',
                //             price: "0",
                //             choices: [],
                //             default: 1
                //         }]
                //         return {
                //             ...prevData
                //         }
                //     })
                // }
                // setCurrentToggleType(response.data.data.Items[0].productsize.type)
                // getAllModifier(response.data.data.Items[0].productsize.type)
                // setProductSizeCount(response.data.data.Items[0].productsize.parameters.length)
                // setCurrTab(response.data.data.Items[0].productsize.type)
                setPreview(response.data.data.Items[0].image.banner)
                getAllModifier()
                if ((response.data.data.Items[0].branchId !== (branchArray && branchArray[0].value))
                    || (branchArray.length > 1)
                    || (branchArray && branchArray[0].value == "All")) {
                    history.push('/products')
                }
            }
        } catch (err) {
            toastMessages("error", err.data.message);
        }
    }
    const [isProductSavedButtonClicked, setIsProductSavedButtonClicked] = useState(false)
    async function onEditHandler(e) {
        e.preventDefault()
        setIsProductSavedButtonClicked(true)  //to highlight the product fields that are empty.
        setLoader(true);
        // let isNotEmpty = false
        // let defaltIsOne = { status: true, errorMsg: '' }
        // //check if field are empty 
        // if (form.productsize.type == 'single') {
        //     isNotEmpty = form.productsize.parameters.every((parameter) => {
        //         return parameter.size !== "" && parameter.price !== ""
        //     })
        //     defaltIsOne = form?.productsize?.parameters.filter(obj => obj.default === 1);
        //     if (defaltIsOne.length == 1) {
        //         defaltIsOne = { status: true, errorMsg: '' }
        //     }
        //     else if (defaltIsOne.length == 0) {
        //         defaltIsOne = { status: false, errorMsg: 'You have to keep at least one size Default Selected.' }
        //     }
        // }
        // else {
        //     isNotEmpty = form.productsize.parametersMultiple.every(parameter => {
        //         return parameter.size !== "" && parameter.price !== ""
        //     })
        //     defaltIsOne = form?.productsize?.parametersMultiple.filter(obj => obj.default === 1);
        //     if (defaltIsOne.length == 1) {
        //         defaltIsOne = { status: true, errorMsg: '' }
        //     }
        //     else if (defaltIsOne.length == 0) {
        //         defaltIsOne = { status: false, errorMsg: 'You have to keep at least one size Default Selected.' }
        //     }
        //     else {
        //         defaltIsOne = { status: false, errorMsg: 'Default Selected can only be one .Please remove Multiple Default Selected.' }
        //     }
        // }
        // if (defaltIsOne.status) {
        if (form.productname && form.price && form.category.length !== 0) {
            // console.log("🚀 ~ file: index.jsx:201 ~ onEditHandler ~ length:", length)
            try {
                const updatedForm = {
                    ...form,
                    // productsize: {
                    //     ...form.productsize,
                    //     parameters: form.productsize.type == "multiple"   //eslint-disable-line
                    //         ? form.productsize.parametersMultiple
                    //         : form.productsize.parameters,
                    //     parametersMultiple: undefined
                    // }
                }
                console.log("🚀 ~ file: index.jsx:204 ~ onEditHandler ~ updatedForm:", updatedForm)
                const formData = new FormData()
                // console.log("🚀 ~ file: index.jsx:215 ~ onEditHandler ~ formData:", formData)
                // if (updatedForm.productname !== currForm.productname) {
                //     formData.append("productname", updatedForm.productname)
                // }
                // if (updatedForm.visibility !== currForm.visibility) {
                //     formData.append("visibility", updatedForm.visibility)
                // }
                // if (updatedForm.description !== currForm.description) {
                //     formData.append("description", updatedForm.description)
                // }
                // if (updatedForm.file) {
                //     formData.append("file", updatedForm.file);
                // }
                // // console.log('deleteImage', updatedForm.deleteImage)
                // if (updatedForm.deleteImage !== undefined) {
                //     formData.append('deleteImage', updatedForm.deleteImage)
                // }
                // //For Checking the changes in category
                // if (updatedForm.category.length !== currForm.category.length) {
                //     formData.append("category", JSON.stringify(updatedForm.category))
                //     console.log("🚀 ~ file: index.jsx:230 ~ onEditHandler ~ updatedForm:", updatedForm)
                // }
                // else {
                //     const sortedData = updatedForm.category.sort((a, b) => a.value - b.value);
                //     console.log("🚀 ~ file: index.jsx:239 ~ onEditHandler ~ sortedData:", sortedData)
                //     const sortedCompare = currForm.category.sort((a, b) => a.value - b.value);
                //     console.log("🚀 ~ file: index.jsx:241 ~ onEditHandler ~ sortedCompare:", sortedCompare)
                //     let equal = true;
                //     console.log("🚀 ~ file: index.jsx:243 ~ onEditHandler ~ equal:", equal)
                //     for (let i = 0; i < sortedData.length; i++) {
                //         if (sortedData[i].value !== sortedCompare[i].value || sortedData[i].label !== sortedCompare[i].label) {
                //             equal = false;
                //             break;
                //         }
                //     }
                //     if (!equal) {
                //         console.log("🚀 ~ file: index.jsx:251 ~ onEditHandler ~ equal:", equal)
                //         formData.append("category", JSON.stringify(updatedForm.category))
                //     }
                // }
                // formData.append('modifierset', JSON.stringify(updatedForm.modifierset))
                // formData.append("price", updatedForm.price)
                // formData.append("preparationtime", updatedForm.preparationtime)
                // console.log("🚀 ~ file: index.jsx:257 ~ onEditHandler ~ formData:", formData)
                formData.append("category", JSON.stringify(updatedForm.category))
                formData.append("productname", updatedForm.productname)
                formData.append("price", updatedForm.price)
                formData.append("productsize", JSON.stringify(updatedForm.productsize))
                formData.append("visibility", updatedForm.visibility)
                formData.append("description", updatedForm.description)
                formData.append('modifierset', JSON.stringify(updatedForm.modifierset))
                // formData.append('', JSON.stringify(updatedForm.modifierset))
                if (updatedForm.file) {
                    formData.append("file", updatedForm.file);
                }
                if (updatedForm.preparationtime) {
                    formData.append("preparationtime", updatedForm.preparationtime);
                }
                // formData.append("productsize", JSON.stringify(updatedForm.productsize))
                const response = await apiPutMethod(
                    allApiUrl.update_product,
                    formData,
                    header, id
                );
                if (response.status === 200) {
                    toastMessages("success", response.data.message);
                    setForm({
                        productname: '',
                        productsize: {
                            type: 'single',
                            parameters: [{
                                size: '',
                                price: "0",
                                choices: [],
                                default: 1
                            }
                            ],
                            parametersMultiple: [{
                                size: '',
                                price: "0",
                                choices: [],
                                default: 1
                            }
                            ]
                        },
                        visibility: 0,
                        category: []
                    })
                    history.push('/products')
                    setLoader(false);
                }

            } catch (err) {
                setLoader(false);
                console.log("err", err);
                if (err.data.message == "Session Expired.") {   //eslint-disable-line
                    localStorage.clear();
                    history.push('/login')
                }
                toastMessages("error", err.data.message);
            }
        }
        else {
            toastMessages("error", "Please fill the required fields")
            setLoader(false)
        }
        // }
        // else {
        //     toastMessages("error", defaltIsOne.errorMsg)
        //     setLoader(false)
        // }
    }
    let sidebarToggle = useSelector((state) => state.counter.sidebarToggle)
    let sidebarTypeRedux = useSelector((state) => state.counter.sidebarType)
    function onSearchHandler(e) {
        console.log('-----203-----')
        if (e.target.value !== "") {
            console.log('-----205-----')
            setModifierList(modifierList.filter((item) => {
                if (item.label.toLowerCase().includes(e.target.value.toLowerCase())) {
                    return item
                }
            }))
        } else {
            console.log('-----213-----', initialModifierList)
            setModifierList(initialModifierList)
        }
    }
    return (
        <React.Fragment>
            <Header setActive={setActive} active={sidebarToggle} />
            <div className="interFaceWrap">
                <Sidebar active={sidebarToggle} activePage="products" permissionData={permissionData} />
                <div className={`interFaceWrap__inner ${sidebarTypeRedux == false ? "doubleSidebar" : "singleSidebar"}`}>
                    <div className="commonOuter">
                        <div className="pageHeader">
                            <Container fluid className="px-0">
                                <Row className="align-items-center" style={{ marginBottom: "12px" }}>
                                    <div className="col">
                                        <div className="headingText headingText--textLeft mb-0">
                                            <h1>Edit Product</h1>
                                        </div>
                                    </div>
                                    <div className="col-auto ms-auto onlyButtons">
                                        <div className="pageHeader__elements">
                                            <Button type="button" className="commonButton commonButton--delete" onClick={() => history.push(("/products"))}>Cancel</Button>
                                            <CommonButton
                                                buttonName="Update"
                                                onClick={onEditHandler}
                                                loader={loader}
                                                type="half"
                                            />

                                        </div>
                                    </div>
                                </Row>
                            </Container>
                        </div>
                        <ProductItem
                            modifierList={modifierList}
                            setModifierList={setModifierList}
                            onSearchHandler={onSearchHandler}
                            currentToggleType={currentToggleType}
                            currTab={currTab}
                            form={form}
                            setForm={setForm}
                            setProductSizeCount={setProductSizeCount}
                            productSizeCount={productSizeCount}
                            formType="Edit"
                            setIsPageRefreshed={setIsPageRefreshed}
                            isPageRefresh={isPageRefresh}
                            setPreview={setPreview}
                            preview={preview}
                            isProductSavedButtonClicked={isProductSavedButtonClicked}
                            isLoading={isLoading}
                            setIsProductSavedButtonClicked={setIsProductSavedButtonClicked}
                        />
                    </div>
                </div>
                <SidebarTwo
                    activePage=""
                    setSidebarType={setSidebarType}
                    sidebarType={sidebarType}
                    setPermissionData={setPermissionData} />
            </div>
        </React.Fragment>
    )
}
export default EditProduct;