import React, { useEffect, useState } from 'react';
import { Button, Badge, Dropdown, Table, Container, Row, Col, Image, ListGroup, Form, FloatingLabel, InputGroup, Pagination, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import useEmblaCarousel from 'embla-carousel-react'

//CSS
import "./style.scss";
import dummyProduct from '../../components/shared/assets/images/dummyProduct.jpg'

//Images
import bannerImg from "../shared/assets/images/bannerImg.png";
import dummyLogo from '../shared/assets/images/dummyLogo.png';
import Logo from "../shared/assets/images/foodBuddyLogo.svg";
import ChefImg from "../shared/assets/images/chefImg.png";
import ratingImg from "../shared/assets/images/starImg.png";
import qualityImg from "../shared/assets/images/qualityImg.png";
import timingImg from "../shared/assets/images/timingIcon.png";
import PlateImg from "../shared/assets/images/plateImg.png";

import orderAppsImg1 from "../shared/assets/template/image-1.png";
import orderAppsImg2 from "../shared/assets/template/image-2.png";
import orderAppsImg3 from "../shared/assets/template/image-3.png";
import orderAppsImg4 from "../shared/assets/template/image-4.png";

//Cards
import card1 from "./images/card-img1.png";
import card2 from "./images/card-img2.png";
import card3 from "./images/card-img3.png";

//Special offers Images
import slideImg1 from "./images/slide-img1.png";
import slideImg2 from "./images/slide-img2.png";
import slideImg3 from "./images/slide-img3.png";
import slideImg4 from "./images/slide-img4.png";

//Explore Us Images
import exploreSlideImg1 from "./images/exploreSlide-img1.png";
import exploreSlideImg2 from "./images/exploreSlide-img2.png";
import exploreSlideImg3 from "./images/exploreSlide-img3.png";
import exploreSlideImg4 from "./images/exploreSlide-img4.png";
import exploreSlideImg5 from "./images/exploreSlide-img5.png";
import exploreSlideImg6 from "./images/exploreSlide-img6.png";
import exploreSlideImg7 from "./images/exploreSlide-img7.png";
import exploreSlideImg8 from "./images/exploreSlide-img8.png";

//Awards And Batches Images
import awardsAndBatches1 from "./images/awardsSlide-img1.png";
import awardsAndBatches2 from "./images/awardsSlide-img2.png";
import awardsAndBatches3 from "./images/awardsSlide-img3.png";
import awardsAndBatches4 from "./images/awardsSlide-img4.png";
import awardsAndBatches5 from "./images/awardsSlide-img5.png";
import awardsAndBatches6 from "./images/awardsSlide-img6.png";

//Authentic Mouthwatering Dishes Images
import authenticMouthwatering1 from "./images/authenticSlide-img1.png";
import authenticMouthwatering2 from "./images/authenticSlide-img2.png";
import authenticMouthwatering3 from "./images/authenticSlide-img3.png";
import authenticMouthwatering4 from "./images/authenticSlide-img4.png";
import authenticMouthwatering5 from "./images/authenticSlide-img5.png";

//gallery images 
import galleryImage1 from "../shared/assets/template/gallery/1.jpeg"
import galleryImage2 from "../shared/assets/template/gallery/2.jpeg"
import galleryImage3 from "../shared/assets/template/gallery/3.jpeg"
import galleryImage4 from "../shared/assets/template/gallery/4.jpeg"
import galleryImage5 from "../shared/assets/template/gallery/5.jpeg"

import onlineOrderImage from "../shared/assets/images/onlineOrderBanner.png"

import Slider from './slider'
import Modal from 'react-bootstrap/Modal';
import queryString from "query-string";
import { allApiUrl } from '../../api/apiRoute';
import { apiGetMethod } from '../../api/rest';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import chooseProductImg from "../shared/assets/images/choose-product-img.svg";
import LoadMap from "../mapComponent/Map"
// console.log('apiWebsiteData', apiWebsiteData)
// console.log('----159---', productImage)

// PRODUCTOPTIONS
// ADDITIONALOPTIONS
// AUTHENTICOPTIONS


const OPTIONS = { loop: true, dragFree: true }
const EmblaCarousel = (props) => {
    const { slides, options } = props
    const [emblaRef] = useEmblaCarousel(options)
    return (
        <div className="embla">
            <div className="embla__viewport" ref={emblaRef}>
                <div className="embla__container">
                    {slides.map((item, index) => (
                        <div className="embla__slide" key={index}>
                            <img className="embla__slide__img" src={item} alt="Your alt text" />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

function LoadingPage() {
    return (
        <>
            <div className="loadingPageWrap pt-5">
                <Container>
                    <Row className="justify-content-md-center">
                        <Col lg="8" md="10" xs="12">
                            <div className="text-center">
                                <Spinner animation="border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}
function SubDomainNotFound(props) {
    console.log('-----118-----', props)
    return (
        <div className="interFaceWrap">
            <div className={`interFaceWrap__inner `}>
                <div className="commonOuter">
                    <div className="headingText headingText--textLeft"></div>
                    <div className="graphChart">
                        <div className="chart whiteBg">
                            <div className="chartHead">
                                <div className="chartHead__left">
                                </div>
                                <div className="chartHead__right">
                                </div>
                            </div>
                            <div className="chooseProductViewStats">
                                <h4>The subdomain {props.subdomain.sub_domain} is not registered.</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const Template = (props) => {
    // console.log()
    let url = ''
    let https = true
    console.log('process.env.REACT_APP_ENVIRONMENT', process.env.REACT_APP_ENVIRONMENT)
    if (process.env.REACT_APP_ENVIRONMENT == "development") {
        // url = 'http://192.168.1.133:3000/'
        url = 'http://localhost:3000/'
        https = false
    }
    else if (process.env.REACT_APP_ENVIRONMENT == "local") {
        // url = 'https://cloudpos-modifier.softuvo.click/'
        url = 'https://cloudpos.softuvo.click/'
    }
    else {
        url = 'https://portal.americloud.dev/'
    }
    console.log('window.location.host', window.location.host)
    const host = window.location.host;
    const domainParts = host.split(".");
    console.log('domainParts', domainParts)
    // useEffect(() => {
    //     // if (sub_domain && sub_domain !== 'www') {
    //     console.log("redirectTo", redirectTo)
    //     window.location.replace(redirectTo);
    //     // window.location.href = redirectTo;
    //     //   }
    // }, [])
    // const redirectTo = `www.${window.location.host}`;
    // console.log("redirectTo", redirectTo);
    // window.location.href = redirectTo;
    // window.location.assign(‘https://www.exampleURL.com/’);
    // const redirectTo = `www.${window.location.host}`;
    // if (!window.location.host.includes('www.')) {
    //     window.location.assign(`${https == true ? "https" : "http"}://${redirectTo}`);
    // }
    // history.push('')
    // window.location.href = redirectTo;
    //   }
    // }, []);
    let history = useHistory()
    // console.log('146', props)
    const [token, setToken] = useState(null)
    let subdomainDataList = useSelector((state) => state.counter.subdomainData.data)
    const [templateLoader, setTemplateLoader] = useState(true)
    // const [subDomainNotFound, setSubDomainNotFound] = useState(true)
    // console.log('149', window.path)
    console.log('152')
    useEffect(() => {
        console.log('154')
        if (props.dataLoadedSuccessfully || props.preview) {
            console.log('156')
            if (props.preview) {
                console.log('158')
                setToken(props.token)
                getWebstiteBuilder(props.token)
            } else {
                console.log('162')
                setToken(subdomainDataList.token)
                getWebstiteBuilder(subdomainDataList.token)
                let shoudincludewww = true
                let splitSubdomain = window.location.host.split('.')[1]
                console.log('splitSubdomain', splitSubdomain)
                if (splitSubdomain == "americloud") {
                    shoudincludewww = false
                }
                const redirectTo = `www.${window.location.host}`;
                if (!window.location.host.includes('www.') && shoudincludewww) {
                    window.location.assign(`${https == true ? "https" : "http"}://${redirectTo}`);
                }
            }
            // history.push('/')
        }
        else {
            console.log('---------160---------')
            setTemplateLoader(false)
            history.push('/')
        }
    }, [props.dataLoadedSuccessfully])
    // useEffect(() => {
    // console.log('-----150------', isSubdomainAvaialble, props.token)
    // if (isSubdomainAvaialble || props.token) {
    //     console.log('----154-----', subdomainDataList, Object.keys(subdomainDataList).length)
    //     if (Object.keys(subdomainDataList).length !== 0) {
    //         token = subdomainDataList.token
    //         // getWebstiteBuilder(subdomainDataList.token)
    //     }
    //     console.log('token--------155--', subdomainDataList.token)
    // }
    // else {
    //     token = props.token
    //     // setToken(props.token)
    //     setTemplateLoader(false)
    // }
    // // }, [isSubdomainAvaialble, props.token])
    const partnerImages = [orderAppsImg1, orderAppsImg2, orderAppsImg3, orderAppsImg4]
    const images = [slideImg1, slideImg2, slideImg3, slideImg4, slideImg2, slideImg3]
    const exploreImages = [exploreSlideImg1, exploreSlideImg2, exploreSlideImg3, exploreSlideImg4, exploreSlideImg5, exploreSlideImg6, exploreSlideImg7, exploreSlideImg8]
    const awardsAndBatchesImages = [awardsAndBatches1, awardsAndBatches2, awardsAndBatches3, awardsAndBatches4, awardsAndBatches5, awardsAndBatches6]
    const authenticMouthwateringImages = [authenticMouthwatering1, authenticMouthwatering2, authenticMouthwatering3, authenticMouthwatering4, authenticMouthwatering5]
    //useStates
    const [additionalInformation, setAdditionalInformation] = useState([])
    const [galleryImagesList, setGalleryImagesList] = useState([])
    const [apiWebsiteData, setApiWebsiteData] = useState({})
    const [productImage, setProductImage] = useState([])
    const [show, setShow] = useState(false);
    const [showGallery, setShowGallery] = useState(false)
    const [showAwards, setShowAwards] = useState(false)
    const [showMore, setShowMore] = useState(false)
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    async function getWebstiteBuilder(tokenAuth) {
        const header = {
            Authorization: `Bearer ${tokenAuth}`,
        };
        try {
            const response = await apiGetMethod(allApiUrl.getWebsiteTemplate, '', header);
            if (response.status === 200) {
                console.log('-----198-------', response.data)
                if (response.data.data[0]) {
                    setApiWebsiteData(response.data.data[0])
                    let additionalImages = []
                    let galleryImagesStack = []
                    if (response.data.data[0].galleryInfo.show === undefined || response.data.data[0].galleryInfo.show == 1) {
                        setShowGallery(true)
                    }
                    if (response.data.data[0].additionalInformation.show === undefined || response.data.data[0].additionalInformation.show == 1) {
                        setShowAwards(true)
                    }

                    response.data.data[0].galleryInfo.galleryPhotos.map((item) => {
                        galleryImagesStack.push(item.banner)
                    })
                    response.data.data[0].additionalInformation.awards_and_batches.map((item) => {
                        console.log('---140--------', item)
                        additionalImages.push(item.banner)
                    })

                    setAdditionalInformation(additionalImages)
                    setGalleryImagesList(galleryImagesStack)
                    getData(header)
                    // setSubDomainNotFound(false)
                }
                setTemplateLoader(false)
            }
        } catch (err) {
            setTemplateLoader(false)
            // setSubDomainNotFound(false)
        }
    }
    async function getData(value) {
        try {
            const response = await apiGetMethod(`${allApiUrl.listingProduct}`, " ", value);
            if (response.status === 200) {
                let images = []
                response.data.product.forEach((productItem) => {
                    productItem.products.forEach((item) => {
                        if (Object.keys(item.image).length) {
                            images.push(item.image.banner)
                        }
                    })
                })
                setProductImage(images);
                setTemplateLoader(false)
            }
        } catch (err) {
            console.log('err----232---', err)
        }
    }
    let { headerDetails, contactDetail, locations, foodDeliveryPartners, footer, announcement, hygieneStandard } = apiWebsiteData
    if (templateLoader) {
        return <LoadingPage />
    }
    console.log('-------233------', token)
    // if (subDomainNotFound) {
    //     return <SubDomainNotFound subdomain={subdomainDataList} />
    // }
    console.log('apiWebsiteData', !Object.keys(apiWebsiteData).length)
    console.log('setTemplateLoader', templateLoader)
    console.log('hygieneStandard',)
    if (!Object.keys(apiWebsiteData).length)
        return (
            <div className="interFaceWrap">
                <div className={`interFaceWrap__inner `}>
                    <div className="commonOuter">
                        <div className="headingText headingText--textLeft"></div>
                        <div className="graphChart">
                            <div className="chart whiteBg">
                                <div className="chartHead">
                                    <div className="chartHead__left">
                                    </div>
                                    <div className="chartHead__right">
                                    </div>
                                </div>
                                <div className="chooseProductViewStats">
                                    <img src={chooseProductImg} alt="chooseProductImg" />
                                    <h4>No Subdomain Found</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    let client
    if (locations && locations?.enterLocation) {
        client = `center=${locations?.enterLocation?.latitude ? locations?.enterLocation?.latitude : 39.86223173133187},
        ${locations?.enterLocation?.longitude ? locations?.enterLocation?.longitude : -101.64156251308714}`
    }
    // console.log('headerDetails?.bannerImage?.banner', headerDetails?.bannerImage?.banner.trim())
    console.log('announcement', announcement)
    return (
        <React.Fragment>
            <main className="mainWrapper">
                <Modal className="commonModal restaurantMenuModal" centered backdrop="static" keyboard={false} show={show} onHide={handleClose}>
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                        <iframe src={`${url}menu-order?token=${token}`} title=""></iframe>
                    </Modal.Body>
                </Modal>
                <header className="topBar">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-6">
                                <div className="topBar__Logo">
                                    {/* <Link to="/"><img src={Logo} /></Link> */}
                                    <Link><img src={headerDetails?.logo?.banner || dummyLogo} /></Link>
                                </div>
                            </div>
                            {/* <div className="col-6">
                                <div className="topBar__Button">
                                    <Link className="commonButton commonButton--orange commonButton--lgSize" to="">Order Now</Link>
                                    <Button className="commonButton commonButton--orange commonButton--lgSize" type="button" onClick={handleShow}>Order Now</Button>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </header>
                <section
                    style={{ backgroundImage: `url(${headerDetails?.bannerImage?.banner ? headerDetails?.bannerImage?.banner : bannerImg})` }}
                    className="bannerSection commonPT">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="bannerSection__content">
                                    <h1 style={{ textTransform: 'capitalize' }}>{headerDetails?.branchDetails[0]?.label}</h1>
                                    <p>{headerDetails?.DescriptionOfRestaurant}</p>
                                    <h4>We offer Takeout & Food Delivery</h4>
                                    {/*<Link className="commonButton commonButton--orange commonButton--lgSize" to="">Order Now</Link> */}
                                    <Button className="commonButton commonButton--orange commonButton--lgSize" type="button" onClick={handleShow}>Order Now</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="aboutSection commonPT">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="aboutSection__img">
                                    <img src={ChefImg} />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="aboutSection__content">
                                    <h2 style={{ textTransform: 'capitalize' }}>About {headerDetails?.branchDetails[0]?.label}</h2>
                                    {/* <p>At Biryani Pointe - Lombard, IL we offer Indian, Pakistani, Asian Zabiha Halal meals of excellent quality and invite you to try our delicious food and authentic Hyderabadi Dum Biryani's, Murgh-e-Musallam, Butter Chicken, Chicken Masala, Boneless Chicken 65 Biryani & many more Homemade Style Curries</p>
                                    <p>The key to our success is simple: providing quality consistent food that taste great every single time. We pride ourselves on serving our customers delicious genuine dishes with many Family Packs, Half Trays & Full Tray Options to serve the entire family needs. Our Grill Combos & Special Chef Platters are juicy, tender and mouthwatering must try delicacy.</p>
                                    <p>Eat delicious food and leave the cooking for us. Grab a Mango Lassi. But most of all, relax! We thank you from the bottom of our hearts for your continued support.</p> */}
                                    <p>{showMore ? contactDetail?.aboutTheRestaurant : `${contactDetail?.aboutTheRestaurant.substring(0, 500)}`}
                                        {contactDetail.aboutTheRestaurant.length > 500 &&
                                            <span className='showMore' style={{ cursor: "pointer" }} onClick={() => setShowMore(!showMore)}>{showMore ? "Show less" : '...Show more'}</span>
                                        }
                                    </p>

                                    <ul>
                                        {contactDetail?.review ?
                                            <li><span><img src={ratingImg} /></span> {contactDetail?.review}</li> : ''
                                        }
                                        <li><span><img src={qualityImg} /></span> Quality Foods with discounts</li>
                                        {contactDetail?.openingDays.length == 1 ?
                                            <li><span><img src={timingImg} /> Opening Hours -</span> <strong>{contactDetail?.openingDays[0]?.label} - {contactDetail?.openingHours?.startTiming} {contactDetail?.openingHours.startPostMeridiem?.label} to {contactDetail?.openingHours?.endTiming} {contactDetail?.openingHours?.endPostMeridiem?.label}</strong></li>
                                            : <li><span><img src={timingImg} /> Opening Hours -</span> <strong>{contactDetail?.openingDays[0]?.label} to {contactDetail?.openingDays[contactDetail.openingDays.length - 1]?.label} - {contactDetail?.openingHours?.startTiming} {contactDetail?.openingHours.startPostMeridiem?.label} to {contactDetail?.openingHours?.endTiming} {contactDetail?.openingHours?.endPostMeridiem?.label}</strong></li>
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {productImage && productImage.length > 5 ?
                    <section className="exploreUs commonPT">
                        <div className="container">
                            <div className="commonHeading commonHeading--left">
                                <h2 class="text-center">Explore Us</h2>
                            </div>
                            <div className="flickitySlider">
                                <EmblaCarousel slides={productImage} options={OPTIONS} />
                            </div>
                        </div>
                    </section> : ''}
                {(showAwards && additionalInformation.length) ?
                    <section className="specialOffers awardsAndBatches commonPT">
                        <div className="container">
                            <div className="commonHeading commonHeading--center">
                                <h2>Awards And Batches</h2>
                            </div>
                            <div className="flickitySlider">
                                <EmblaCarousel slides={additionalInformation} options={OPTIONS} />
                            </div>
                        </div>
                    </section> : ''
                }
                {console.log('galleryImagesList', galleryImagesList)}
                {(showGallery && galleryImagesList.length) ?
                    <section className="specialOffers awardsAndBatches commonPT">
                        <div className="container">
                            <div className="commonHeading commonHeading--center">
                                <h2>Gallery</h2>
                            </div>
                            <div className="flickitySlider">
                                <EmblaCarousel slides={galleryImagesList} options={OPTIONS} />
                            </div>
                        </div>
                    </section> : ''
                }
                {(announcement.show === undefined || announcement.show === 1) ?
                    <section
                        style={{ backgroundImage: `url(${announcement?.announcementImage?.banner ? announcement?.announcementImage?.banner : onlineOrderImage})`, marginTop: "64px" }}
                        className="confirmSection onlineOrderSection smallCommonPT announcementImgSection">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="confirmSection__content">
                                        <h2>{announcement.announcementTitle}</h2>
                                        <p>{announcement.announcementDescription}</p>
                                        {announcement.announcementTitle && announcement.announcementDescription
                                            &&
                                            <Button className="commonButton commonButton--orange commonButton--lgSize" type="button" onClick={handleShow}>Order Now</Button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section> : ""
                }

                {(locations.show === undefined || locations.show === 1) ?
                    <section className="contactInfo commonPT">
                        <div className="container">
                            <div className="row g-0 align-items-center">
                                <div className="col-lg-5 contactInfo__content">
                                    {
                                        locations?.describeTheLocation || (locations && locations.deliveryFees && locations.deliveryFees.length > 0) ?
                                            <div className="contactInfo__contentInner">
                                                <h4 style={{ textTransform: 'capitalize' }}>Food Delivery In {headerDetails?.branchDetails[0]?.label}</h4>
                                                <p>{locations?.describeTheLocation}</p>

                                                {locations && locations.deliveryFees && locations.deliveryFees.length > 0 ?
                                                    <>
                                                        <h4>Delivery fee</h4>
                                                        <ul>
                                                            {
                                                                locations && locations.deliveryFees && locations.deliveryFees.map((item) => {
                                                                    return (
                                                                        <li>{item.zone}, Min - {item.min}, Fee - {item.fee}</li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    </> : ''
                                                }
                                            </div> : ''
                                    }
                                </div>
                                {locations && locations?.enterLocation ?
                                    <div className="col-lg-7 contactInfo__map">
                                        {client &&
                                            // <LoadMap data={locations?.enterLocation} />
                                            <iframe
                                                //  src={}
                                                src={`https://www.google.com/maps/embed/v1/view?key=${process.env.REACT_APP_GOOGLE_API_KEY}&${client}&zoom=18&maptype=satellite`}
                                                allowfullscreen=""
                                                loading="lazy"
                                                referrerpolicy="no-referrer-when-downgrade">
                                            </iframe>
                                        }
                                    </div> : <div></div>
                                }
                            </div>
                        </div>
                    </section>
                    : ""}
                {console.log('hygieneStandard', hygieneStandard)}
                {(hygieneStandard.show === 1 || hygieneStandard.show === undefined) ?
                    <section className="cusomerSafeSection commonPT">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <div className="cusomerSafeSection__img">
                                        <img src={
                                            hygieneStandard?.hygieneStandardImages?.banner ?
                                                hygieneStandard?.hygieneStandardImages?.banner :
                                                PlateImg} />
                                    </div>
                                </div>
                                <div className="col-lg-6 ps-lg-5">
                                    <div className="cusomerSafeSection__content">
                                        <h2>{hygieneStandard.hygieneStandardTitle}</h2>
                                        <p>{hygieneStandard.hygieneStandardDescription}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section> : ""}
                {console.log('565', foodDeliveryPartners)}
                {(foodDeliveryPartners.show === 1 || foodDeliveryPartners.show === undefined) ?
                    // <section className="cusomerSafeSection commonPY">
                    <div className="orderApps commonPT ">
                        <div className="commonHeading commonHeading--center">
                            <h2>Food Delivery Partners</h2>
                        </div>
                        <div className="container">
                            <div className="row justify-content-center">
                                {foodDeliveryPartners && foodDeliveryPartners.partners && foodDeliveryPartners.partners.map((item, index) => {
                                    let partnerImage
                                    if (item.partnerLogo && item.partnerLogo[0] && item.partnerLogo[0].banner) {
                                        partnerImage = item.partnerLogo[0].banner
                                    }
                                    return (
                                        <div className="col-xl-3 col-lg-6 orderApps__grid">
                                            <div className="orderApps__box">
                                                <img src={partnerImage} />
                                                <p>{item.description}</p>
                                                <a className="textButton" href={`https://${item.link}`} target='blank'>Order Now</a>
                                            </div>
                                        </div>
                                    )
                                })
                                }
                            </div>
                        </div>
                    </div>
                    // </section> 
                    : ''}
                {productImage && productImage.length > 5 ?
                    <section className="specialOffers authenticMouthwatering commonPT">
                        <div className="container">
                            <div className="commonHeading commonHeading--left">
                                <h2 className='text-center'>Authentic Mouthwatering Dishes</h2>
                            </div>
                            <div className="flickitySlider">
                                <EmblaCarousel slides={productImage} options={OPTIONS} />
                            </div>
                        </div>
                    </section> : ''
                }
                <footer className="footer mt-5">
                    <div className="container">
                        <div className="footer__inner">
                            <div className="socialMediaIcons">
                                {footer && footer.social.map((item) => {
                                    return (
                                        <div>{
                                            item &&
                                                item.socialImage &&
                                                item.socialImage[0] &&
                                                item.socialImage[0].banner ?
                                                <a href={`${item.label.includes('https') ? item.label : `https://${item.label}`}`} target='blank'>
                                                    <img
                                                        src={item.socialImage[0].banner}
                                                        alt="Testing"

                                                    />
                                                </a>
                                                : ''
                                        }
                                        </div>
                                    )
                                })
                                }
                            </div>
                            {
                                footer?.footerText ?
                                    <div className="copyright">
                                        <span>© {footer?.footerText}</span>
                                    </div> : ''
                            }
                            <div className="bankCards">
                                <Button className="bankCards__button" type="button"><img src={card1} alt="card1" /></Button>
                                <Button className="bankCards__button" type="button"><img src={card2} alt="card2" /></Button>
                                <Button className="bankCards__button" type="button"><img src={card3} alt="card3" /></Button>
                            </div>
                        </div>
                    </div>
                </footer>

            </main>
        </React.Fragment>
    )
}
export default Template