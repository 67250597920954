import React, { useEffect } from 'react';
import { useState } from 'react';
import { Button, Spinner, Table, Container, Row, Form, FloatingLabel, InputGroup } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { BsArrowDownUp, BsArrowDown, BsArrowUp } from 'react-icons/bs';
import { apiDeleteMethod, apiGetMethod, apiPostMethod, apiPutMethod } from '../../api/rest';
import { allApiUrl } from '../../api/apiRoute';
//Header
import Header from '../common/header';
import Sidebar from '../common/sidebar';
import toastMessages from '../toastMessages';
import { useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import SidebarTwo from '../common/sidebarTwo';

//Icons
// import arrowUpIcon from '../shared/assets/icons/arrow-up.svg';
// import arrowRightIcon from '../shared/assets/icons/right-arrow.svg';

function Employees() {
    const currBranch = useSelector((state) => state.counter.currBranch)
    const history = useHistory()
    const [isEmployeeTableRefreshed, setisEmployeeTableRefreshed] = useState(false)
    //Sidebar Toggle
    const [active, setActive] = React.useState(false)

    const [allEmployeeList, setAllEmployeeList] = useState([])
    const [loaderForFetchMore, setLoaderForFetchMore] = useState(true)
    const [lastEvaluatedKey, setLastEvaluatedKey] = useState('')
    const [searchedValue, setSearchedValue] = useState('')
    useEffect(() => {
        getAllEmployee()
        if (currBranch.label == "All") {   //eslint-disable-line
            history.push('/dashboard')
        }

    }, [isEmployeeTableRefreshed, currBranch.value])
    async function getAllEmployee() {
        const header = {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            branchid: currBranch.value
        };
        try {
            const response = await apiGetMethod(`${allApiUrl.view_all_employee}`, "", header);
            if (response.status === 200) {
                if (response.data.data.LastEvaluatedKey) {
                    setLastEvaluatedKey(response.data.data.LastEvaluatedKey.SK)
                }
                setAllEmployeeList(response.data.data.Items)
                if (response.data.data.Items.length < 5) {
                    setLoaderForFetchMore(false)
                }
                else {
                    setLoaderForFetchMore(true)
                }
            }
        } catch (err) {
            toastMessages("error", err.data.message);
            if (err.data.message == "Session Expired.") {   //eslint-disable-line
                localStorage.clear();
                history.push('/login')
            }
            console.log("err", err);
        }
    }
    async function onSearchHandler(e) {
        setSearchedValue(e.target.value)
        const header = {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            branchid: currBranch.value
        };
        try {
            let data = {
                fullname: e.target.value
            }
            const response = await apiPostMethod(allApiUrl.search_employee, data, header);
            if (response.status === 200) {
                if (response.data.message == "No records found.") {   //eslint-disable-line
                    setAllEmployeeList('');
                    setLoaderForFetchMore(false)
                    setLastEvaluatedKey('')
                }
                else {
                    setAllEmployeeList(response.data.data.Items);
                    if (response.data.data.LastEvaluatedKey) {
                        setLastEvaluatedKey(response.data.data.LastEvaluatedKey.SK)
                    }
                }
            }
        } catch (err) {
            toastMessages("error", err.data.message);
            if (err.data.message == "Session Expired.") {   //eslint-disable-line
                localStorage.clear();
                history.push('/login')
            }
            console.log("err", err);
        }
    }
    const [sidebarType, setSidebarType] = useState('singleSidebar')
    let sidebarTypeRedux = useSelector((state) => state.counter.sidebarType)

    return (
        <React.Fragment>
            <Header setActive={setActive} active={active} />
            <div className="interFaceWrap">
                <Sidebar active={active} activePage="employees" />
                <div className={`interFaceWrap__inner ${sidebarTypeRedux == false ? "doubleSidebar" : "singleSidebar"}`}>
                    <div className="commonOuter">
                        <div className="pageHeader">
                            <Container fluid className="px-0">
                                <Row className="align-items-center">
                                    <div className="col">
                                        <div className="headingText headingText--textLeft mb-0">
                                            <h1>Employees</h1>
                                        </div>
                                    </div>
                                    <div className="col-auto ms-auto">
                                        <div className="pageHeader__elements">
                                            <Form className="searchBarForm">
                                                <InputGroup className="searchBar">
                                                    <InputGroup.Text id="searchOrders"><i className="fa-solid fa-magnifying-glass"></i></InputGroup.Text>
                                                    <FloatingLabel controlId="floatingInput" label="Search">
                                                        <Form.Control type="search" placeholder="Search" value={searchedValue} onKeyDown={(e) => {
                                                            if (e.code === 'Enter') {
                                                                e.preventDefault()
                                                            }
                                                        }} onChange={onSearchHandler} autocomplete="off" />
                                                    </FloatingLabel>
                                                </InputGroup>
                                            </Form>
                                            <Link to="/add-employee" className="commonButton">Add Employee</Link>
                                        </div>
                                    </div>
                                </Row>
                            </Container>
                        </div>
                        <EmployeeTable
                            setAllEmployeeList={setAllEmployeeList}
                            allEmployeeList={allEmployeeList}
                            setisEmployeeTableRefreshed={setisEmployeeTableRefreshed}
                            isEmployeeTableRefreshed={isEmployeeTableRefreshed}
                            lastEvaluatedKey={lastEvaluatedKey}
                            setLastEvaluatedKey={setLastEvaluatedKey}
                            setLoaderForFetchMore={setLoaderForFetchMore}
                            loaderForFetchMore={loaderForFetchMore}
                        />
                    </div>
                </div>
                <SidebarTwo activePage="" setSidebarType={setSidebarType} sidebarType={sidebarType} />
            </div>
        </React.Fragment>
    )
}
export default Employees;

const EmployeeTable = ({
    allEmployeeList,
    setisEmployeeTableRefreshed,
    isEmployeeTableRefreshed,
    lastEvaluatedKey,
    setLastEvaluatedKey,
    setLoaderForFetchMore,
    loaderForFetchMore,
    setAllEmployeeList
}) => {
    const history = useHistory()
    const currBranch = useSelector((state) => state?.counter?.currBranch)
    function titleCase(str) {
        var splitStr = str.toLowerCase().split(' ');
        for (var i = 0; i < splitStr.length; i++) {
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        return splitStr.join(' ');
    }
    useEffect(() => {
        setSortingTypeIndex("Up");
        setSortingTypeName("Up");
        setSortingTypePin("Up")
    }, [currBranch.value])
    const [loader, setLoader] = useState(false)
    const [deletedId, setDeletedId] = useState('')
    const [sortingTypeIndex, setSortingTypeIndex] = useState("Up");
    const [sortingTypeName, setSortingTypeName] = useState("Up");
    const [sortingTypePin, setSortingTypePin] = useState("Up")
    async function onDeleteHandler(id) {
        setDeletedId(id)
        setLoader(true)
        const header = {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        };
        try {
            const response = await apiDeleteMethod(allApiUrl.delete_employee, id, header);
            if (response.status === 200) {
                setLoader(false)
                setisEmployeeTableRefreshed(!isEmployeeTableRefreshed)
                toastMessages("success", response.data.message);
            }
        } catch (err) {
            setLoader(false)
            toastMessages("error", err.data.message);
            setisEmployeeTableRefreshed(!isEmployeeTableRefreshed)
            console.log("err", err);
            if (err.data.message == "Session Expired.") {   //eslint-disable-line
                localStorage.clear();
                history.push('/login')
            }
        }
    }

    async function updateActiveStatus(e, value) {
        const header = {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        };
        try {
            const data = {
                userstatus: e.target.checked == true ? 1 : 0   //eslint-disable-line
            }
            const response = await apiPutMethod(
                allApiUrl.update_employee,
                data,
                header, value)
            if (response.status === 200) {
                toastMessages("success", response.data.message)
                setisEmployeeTableRefreshed(!isEmployeeTableRefreshed)
            }
        }
        catch (err) {
            toastMessages("error", err.data.message);
            setisEmployeeTableRefreshed(!isEmployeeTableRefreshed)
        }
    }
    const fetchMoreData = async () => {
        if (lastEvaluatedKey) {
            setLoaderForFetchMore(true)
            const header = {
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                branchid: currBranch.value
            }
            try {
                let start = ''
                if (lastEvaluatedKey) {
                    start = `&startKey=${lastEvaluatedKey}`
                }
                const response = await apiGetMethod(`${allApiUrl.view_all_employee}?${start}`, "", header);
                if (response.status === 200) {
                    if (response.data.data.Items.length < 1) {
                        setLoaderForFetchMore(false)
                    }
                    setAllEmployeeList(prevItems => prevItems.concat(response.data.data.Items));
                    if (response.data.data.LastEvaluatedKey) {
                        setLastEvaluatedKey(response.data.data.LastEvaluatedKey.SK)
                    }
                    else {
                        setLastEvaluatedKey('')
                    }
                    toastMessages("success", response.data.message);
                }
            } catch (err) {
                toastMessages("error", err.data.message);
                if (err.data.message == "Session Expired.") {   //eslint-disable-line
                    localStorage.clear();
                    history.push('/login')
                }
                console.log("err", err);
            }
        }
        else {
            setLoaderForFetchMore(false)
        }
    };


    const SortingComponent = ({ field, sortingType }) => {
        return (
            <div className="pointer">
                {sortingType === "Up" ? (
                    <BsArrowUp onClick={() => handleSorting("Down", field)} />
                ) : (
                    <BsArrowDown onClick={() => handleSorting("Up", field)} />
                )}
            </div>
        );
    };

    function handleSorting(arrow, field) {
        if (field === "index") {
            setSortingTypeIndex(arrow);
            const sortedData = allEmployeeList.slice().reverse();
            setAllEmployeeList(sortedData);
        }
        else if (field === "name") {
            const newSortingType = sortingTypeName === "Down" ? "Up" : "Down";
            setSortingTypeName(newSortingType);
            const sortedItems = [...allEmployeeList].sort((a, b) => {
                if (a.fullname < b.fullname) {
                    return newSortingType === "Up" ? -1 : 1;
                }
                if (a.fullname > b.fullname) {
                    return newSortingType === "Up" ? 1 : -1;
                }
                return 0;
            });
            setAllEmployeeList(sortedItems);
        }
        else if (field === "pin") {
            const newSortingTypePin = sortingTypePin === "Down" ? "Up" : "Down";
            setSortingTypePin(newSortingTypePin);
            const sortedItems = [...allEmployeeList].sort((a, b) => {
                if (a.pin < b.pin) {
                    return newSortingTypePin === "Up" ? -1 : 1;
                }
                if (a.pin > b.pin) {
                    return newSortingTypePin === "Up" ? 1 : -1;
                }
                return 0;
            });
            setAllEmployeeList(sortedItems);
        }
    }
    return (
        <div className="commonTable">
            <div className="whiteBg">
                <div className="headingText headingText--dflex headingText--textLeft headingText--border">
                    <h4>All Employees</h4>
                </div>
                <InfiniteScroll
                    dataLength={allEmployeeList.length}
                    next={fetchMoreData}
                    hasMore={loaderForFetchMore}
                    className="equalHeightTable">
                    <div className="tableFixHead">
                        <Table>
                            <thead>
                                <tr>
                                    <th>S.No.</th>
                                    <th><div className='display_flex'>
                                        Employee Name  <SortingComponent field='name' sortingType={sortingTypeName} />
                                    </div></th>
                                    <th>
                                        <div className='display_flex'>
                                            PIN <SortingComponent field='pin' sortingType={sortingTypePin} />
                                        </div>
                                    </th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    allEmployeeList && allEmployeeList.length > 0 && allEmployeeList.map((item, index) => {
                                        return (
                                            <tr>
                                                <td>{`#${index + 1}`}</td>
                                                <td>{titleCase(item.fullname)}</td>
                                                <td>{item.pin}</td>
                                                <td>
                                                    <div className="actionElements">
                                                        <Form><Form.Check type="switch" id="custom-switch" label=""
                                                            checked={item.userstatus == 1 ? true : false}   //eslint-disable-line
                                                            onChange={(e) => updateActiveStatus(e, item.id)}

                                                        /></Form>
                                                        <button
                                                            onClick={() => history.push(`/edit-employee?id=${item.id}`)}
                                                            className="edit btn"><span>Edit</span>
                                                            <i className="fa-solid fa-pen-to-square"></i>
                                                        </button>
                                                        <Button type="button" className="delete" onClick={() => onDeleteHandler(item.id)}>     <>{
                                                            deletedId == item.id &&   //eslint-disable-line
                                                                loader ? <Spinner
                                                                as="span"
                                                                className="delete"
                                                                animation="border"
                                                                size="sm"
                                                                role="status"
                                                                aria-hidden="true"
                                                            /> : <><span>Delete</span> <i className="fa-solid fa-trash-can"></i></>}
                                                        </></Button>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                    </div>
                </InfiniteScroll>
            </div>
        </div>
    )
}
export { EmployeeTable }