import React, { useEffect, useRef, useState } from "react";
import Select from 'react-select';
import { Button, Container, Row, Col, Form } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { smallStyle } from "../../features/helper";

import { apiGetMethod, apiPutMethod } from "../../api/rest";
import { allApiUrl } from "../../api/apiRoute";
import toastMessages from "../toastMessages";
import Header from '../common/header';
import Sidebar from '../common/sidebar';
import CommonButton from "../common-ui/Button";
import { countNumberInPhoneNumber } from "../../features/helper";
import { EmployeFields } from "../add-employee";
import Loader from "../shared/spinner";
import SidebarTwo from "../common/sidebarTwo";
import { changeTableManagement, changeWebsiteTemplate } from "../../features/todoSlice";

function EditUser() {
    //constants
    let dispatch = useDispatch()
    let sidebarToggle = useSelector((state) => state.counter.sidebarToggle)
    const currBranch = useSelector((state) => state.counter.currBranch)
    const branchArray = useSelector((state) => state?.counter?.branchArray)
    const [permissionData, setPermissionData] = useState({})
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const location = useLocation()
    let roleLevel = userInfo?.rolelevel
    let branches = branchArray.map((item) => item.value)
    let { id } = queryString.parse(location.search);
    const header = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        branchid: JSON.stringify(branches)
    };
    const typeOfUser = userInfo?.usertype?.split('#')[0]
    const isInitialRender = useRef(true);
    //useStates
    const [loader, setLoader] = useState({
        save: false,
        delete: false
    })
    const [sidebarType, setSidebarType] = useState('singleSidebar')
    const [currForm, setCurrForm] = useState([])
    const [isPasswordValid, setIsPasswordValid] = useState(false);
    const [isPhoneValid, setPhoneValid] = useState(false)
    const [error, setError] = useState(null);
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [phoneValidatError, setPhoneValidError] = useState(false);
    const [isLoading, setIsLoading] = useState(true)
    const history = useHistory()
    const [showPassword, setShowPassword] = useState(false)
    const [showPin, setShowPin] = useState(false)
    const [allBranchList, setAllBranchList] = useState([])
    const [form, setForm] = useState({
        fullname: '',
        username: '',
        email: '',
        password: '',
        pin: '',
        countrycode: '+1',
        phone: '',
        roleId: '',
        branch: []
    })
    const [errorInput, setErrorInput] = useState({
        fullname: false,
        email: false,
        pin: false,
        phone: false,
        branch: false
    })
    const [options, setOptions] = useState([
        { label: "Corporate Admin", value: "90" },
        { label: "Owner", value: "80" },
        { label: "Manager", value: "70" },
        { label: "Employee", value: "60" },
    ])
    const [active, setActive] = useState(false)
    //useEffects
    useEffect(() => {
        // if ((branchArray.length > 1) || (branchArray && branchArray[0].value == "All")) {
        //     history.push('/dashboard')
        // }
        if (isInitialRender.current) {
            isInitialRender.current = false;
        } else {
            if (branchArray.length > 1) {
                history.push('/users');
            }
        }
    }, [branchArray])
    useEffect(() => {
        getUserDetail()
        setShowPin(true)
        if (roleLevel < 90) {
            setOptions(options.filter((item) => parseInt(item.value) < roleLevel))
        }
    }, [currBranch.value, branchArray])
    useEffect(() => {
        getDisableStatus()
    }, [])

    //functions
    async function getDisableStatus() {
        const header = {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        };
        try {
            const response = await apiGetMethod(allApiUrl.viewSetting, "", header);
            if (response.status == 200) {
                if (response.data.Items.length != 0) {
                    //for table managment sidebar toggle functionality.
                    console.log('permissionData_810', permissionData, permissionData.tablemanagement == 0
                        // , permissionData.tablemanagement && permissionData.tablemanagement == 0
                    )
                    if (permissionData.tablemanagement == 0) {
                        console.log('812')
                        dispatch(changeTableManagement(false));
                    }
                    else {
                        if (response.data.Items[0].GeneralSettings.tablemanagement == 1) {
                            console.log('817')
                            dispatch(changeTableManagement(true));
                        } else {
                            console.log('820')
                            dispatch(changeTableManagement(false));
                        }
                    }
                    // for website template
                    if (permissionData.websitetemplate == 0) {
                        dispatch(changeWebsiteTemplate(false));
                    }
                    else {
                        if (response.data.Items[0].WebsiteTemplate.enableWebsiteTemplate == 1) {
                            //eslint-disable-line
                            dispatch(changeWebsiteTemplate(true));
                        } else {
                            dispatch(changeWebsiteTemplate(false));
                        }
                    }

                }
            }
        } catch (err) {
            console.log("err", err);
        }
    }
    async function getUserDetail() {
        try {
            const response = await apiGetMethod(allApiUrl.view_user, `/${id}`, header);
            if (response.status === 200) {
                let option = options.find((item) => item.label == response.data.data.Items[0].roleId)
                setForm({
                    ...response.data.data.Items[0],
                    id: id,
                    password: '',
                    pin: response.data.data.Items[0].roleId == "Corporate Admin" ? '' : response.data.data.Items[0].pin,
                    roleId: { value: option.value, label: option.label }

                })    //eslint-disable-line
                setCurrForm(response.data.data.Items[0])
                setIsEmailValid(true)
                setPhoneValid(true)
                if (typeOfUser == "USER") {
                    getAllBranch(response.data.data.Items[0])
                }
                else {
                    getAllBranch()
                }
                // setIsLoading(false)
            }
        } catch (err) {
            toastMessages("error", err.data.message);
            // setIsLoading(false)
        }
    }
    function clickHandlerPassword() {
        setShowPassword(!showPassword);
    }
    function clickHandlerPin() {
        setShowPin(!showPin);
    }
    function handlePasswordChange(event) {
        setForm({ ...form, password: event.target.value });
        setIsPasswordValid(validatePassword(event.target.value));
    }
    function validatePassword(password) {
        const regex =
            /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*_#?&])([a-zA-Z0-9@$!%*_#?&]{8,})$/;
        return regex.test(password);
    }
    function containsOnlySpecialChars(str) {
        return /^[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/.test(str); //eslint-disable-line
    }
    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }
    const emailValidation = (e) => {
        if (e.target.value.length < 81) {
            setForm({ ...form, email: e.target.value });
            setErrorInput({ ...errorInput, email: false })
            setError(null);
            if (isValidEmail(e.target.value)) {
                let check = e.target.value.split("@")[0];
                let isnum = /^\d+$/.test(check);

                if (isnum === true || containsOnlySpecialChars(check)) {
                    setIsEmailValid(false);
                    setError("The email address field must be a valid email");
                } else {
                    setIsEmailValid(true);
                    setError("email is valid");
                }
            } else {
                setIsEmailValid(false);
                setError("The email address field must be a valid email");
            }
        } else {
            setIsEmailValid(false);
            setError("Email cann't be greater than 80 chars.");
        }
    };
    function titleCase(str) {
        var splitStr = str.toLowerCase().split(' ');
        for (var i = 0; i < splitStr.length; i++) {
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        return splitStr.join(' ');
    }
    async function getAllBranch(Branches) {
        try {
            const response = await apiGetMethod(allApiUrl.active_branches, "", header);
            if (response.status === 200) {
                setForm((prev) => {
                    if (prev.branch && prev.branch.length > 0) {
                        prev.branch = prev.branch.filter(item => {
                            return response.data.data.Items.find(findItem => findItem.SK == item.value)   //eslint-disable-line
                        });
                    }
                    return { ...prev };
                });
                setForm((prev) => {
                    if (prev.branch && prev.branch.length > 0) {
                        prev.branch.map((item) => {   //eslint-disable-line
                            response.data.data.Items.forEach((findItem) => {
                                if (findItem.SK == item.value) {   //eslint-disable-line
                                    item.label = titleCase(findItem.branchname)
                                }
                            })
                        })
                    }
                    return { ...prev };
                });
                if (roleLevel < 90) {  //eslint-disable-line
                    let list = Branches.branch.filter((userInfo) => {
                        return response.data.data.Items.find((item) => item.SK == userInfo.value)
                    })
                    const data = []
                    list.forEach((listItem) => {
                        data.push({ value: listItem.value, label: titleCase(listItem.label) })
                    })
                    setAllBranchList(data)
                }
                else {
                    setAllBranchList(response.data.data.Items.map((item) => {
                        return (
                            {
                                label: titleCase(item.branchname),
                                value: item.SK
                            }
                        )

                    }))
                }
                setIsLoading(false)
            }
        } catch (err) {
            toastMessages("error", err.data.message);
            setIsLoading(false)

        }
    }
    async function updateUser() {
        try {
            let editUser = {
                ...form,
                branch: Array.isArray(form.branch) ? form.branch : [form.branch],
                roleId: form.roleId.label,
                countrycode: '+1'
            }
            if (!editUser.password) {
                delete editUser.password
            }
            const response = await apiPutMethod(
                allApiUrl.update_user,
                // `${form.roleId == "Employee" ? allApiUrl.update_user : allApiUrl.update_employee}`,
                editUser,
                header,
                id
            );
            if (response.status === 200) {
                setLoader({ ...loader, save: false })
                toastMessages("success", "You have updated the user detail successfully");
                setForm({})
                history.push('/users')
            }
        } catch (err) {
            setLoader({ ...loader, save: false })
            toastMessages("error", err.data.message);
        }
    }
    // console.log('form-----------264---', form)
    async function editUserHandler(e) {
        e.preventDefault()
        setLoader({ ...loader, save: true })
        if (form.roleId.label !== "Employee") {
            let isBranchEmpty = false
            let isPasswordEmpty = false
            if (form.roleId.label !== "Corporate Admin") {
                if ((form && form?.branch?.length == 0) || typeof form.branch == "undefined") {
                    isBranchEmpty = true
                }
            }
            if (currForm.roleId == "Employee") {
                if (form.password) {
                    isPasswordEmpty = false
                }
                // else {
                //     isPasswordEmpty = true
                // }
            }
            // if (currForm.roleId !== "Employee") {
            //     isPasswordEmpty = true
            // }
            // console.log('----285---', isBranchEmpty, isPasswordEmpty)
            if (form.email
                && form.fullname
                && (form.pin || form.roleId.label == "Corporate Admin")
                && form.phone && !isBranchEmpty && !isPasswordEmpty) {
                if (isPasswordValid && isEmailValid && isPhoneValid) {
                    updateUser()
                }
                else if (isEmailValid === false) {
                    setLoader({ ...loader, save: false });
                    toastMessages("error", "Email is not valid.");
                } else if (isPhoneValid === false) {
                    // console.log('277')
                    setLoader({ ...loader, save: false });
                    toastMessages("error", phoneValidatError);
                }
                else if (isPasswordValid == false && form.password) {   //eslint-disable-line
                    setLoader({ ...loader, save: false });
                    toastMessages("error", "The password is not valid");
                }
                else {
                    // console.log('282')
                    updateUser()
                }
            }
            else {
                toastMessages("error", "Please fill the required Fields")
                setLoader({ ...loader, save: false })
                const emptyFields = [];
                for (const property in form) {
                    if (form[property] === '') {
                        emptyFields.push(property)
                    }
                    if (property == "branch") {
                        if (typeof form[property] == "undefined" || !form[property]) {
                            emptyFields.push(property)
                        }
                    }
                }
                const errorInputCopy = { ...errorInput };
                let newEmptyField = []
                if (currForm.roleId == "Employee" && !form.password) {
                    newEmptyField = emptyFields
                }
                else {
                    newEmptyField = emptyFields && emptyFields.filter(e => e !== 'password');
                }
                newEmptyField.forEach(field => errorInputCopy[field] = true);
                setErrorInput(errorInputCopy);
            }
        }
        else {
            if (form.fullname && form.pin && form.branch.length > 0) {
                try {
                    let data = {
                        fullname: form.fullname.trim(),
                        pin: form.pin,
                        roleId: form.roleId.label,
                        branch: form.branch,
                        rolelevel: form.rolelevel,
                        permissiongranted: form.permissiongranted
                    }
                    const response = await apiPutMethod(
                        allApiUrl.update_user,
                        data,
                        header,
                        id
                    );
                    if (response.status === 200) {
                        toastMessages("success", "Employee Edited Successfully.");
                        setForm({ roleId: {} })
                        history.push('/users')
                        setLoader(false);
                    }
                } catch (err) {
                    toastMessages("error", err.data.message);
                    setLoader(false);
                    console.log("err", err);
                }
            }
            else {
                let employeeFields = ['fullname', 'pin', "branch"]
                let emptyFields = []
                employeeFields.forEach((property) => {
                    if (form[property] === '') {
                        emptyFields.push(property)
                    }
                    if (property == "branch") {
                        if (form[property].length == 0) {
                            emptyFields.push(property)
                        }
                    }
                })
                const errorInputCopy = { ...errorInput };
                emptyFields.forEach(field => errorInputCopy[field] = true);
                // console.log('366', errorInputCopy)
                setErrorInput(errorInputCopy);
                toastMessages("error", "Please fill the required fields")
                setLoader(false);
            }
        }
    }
    const handleOnChange = (value, country) => {
        setForm({ ...form, phone: value });
        setErrorInput({ ...errorInput, phone: false })
        const result = countNumberInPhoneNumber(country.format, ".");
        if (value.length - country.dialCode.length == result - country.dialCode.length || (result - country.dialCode.length) > 10) {
            setPhoneValid(true);
        } else {
            setPhoneValid(false);
            setPhoneValidError(`The Phone Number should be of ${result - country.dialCode.length} Digit`);
        };
    };
    let props = {
        setForm,
        form,
        errorInput,
        setErrorInput,
        smallStyle
    }
    let sidebarTypeRedux = useSelector((state) => state.counter.sidebarType)
    return (
        <React.Fragment>
            <Header setActive={setActive} active={sidebarToggle} />
            <div className="interFaceWrap">
                <Sidebar active={sidebarToggle} activePage="users" permissionData={permissionData} />
                <div className={`interFaceWrap__inner ${sidebarTypeRedux == false ? "doubleSidebar" : "singleSidebar"}`}>
                    <div className="commonOuter">
                        <div className="pageHeader">
                            <Container fluid className="px-0">
                                <Row className="align-items-center">
                                    <div className="col">
                                        <div className="headingText headingText--textLeft mb-0">
                                            <h1>Edit User</h1>
                                        </div>
                                    </div>
                                    <div className="col-auto ms-auto onlyButtons">
                                        <div className="pageHeader__elements">
                                            <Button type="button" className="commonButton commonButton--delete" onClick={() => history.push('/users')}>Cancel</Button>
                                            <CommonButton
                                                buttonName="Update"
                                                type="half"
                                                onClick={editUserHandler}
                                                loader={loader.save}
                                            />
                                        </div>
                                    </div>
                                </Row>
                            </Container>
                        </div>

                        <div className="selectEmployeeOpetions">
                            <Row>
                                <Col xxl={7} xl={8} lg={12}>
                                    <div className="whiteBg">
                                        {!isLoading ? <Form>
                                            <Row>
                                                <Col md={6}>
                                                    <Form.Group className="form-group">
                                                        <Form.Label>Select Role<sup>*</sup></Form.Label>
                                                        <Select
                                                            className="select2 singleSelect"
                                                            placeholder="Search"
                                                            options={options}
                                                            value={form.roleId}
                                                            onChange={(e) => {
                                                                let preSelectedBranch = allBranchList.find((item) => item.value == (branchArray && branchArray[0].value))
                                                                if (e.label == "Corporate Admin") {
                                                                    setForm({ ...form, roleId: e, branch: allBranchList, rolelevel: parseInt(e.value) })
                                                                }
                                                                else if (e.label == "Employee") {
                                                                    let branch = []
                                                                    if ((branchArray && branchArray[0].value) !== "All") {
                                                                        branch.push(preSelectedBranch)
                                                                    }
                                                                    setForm({ ...form, roleId: e, branch, rolelevel: parseInt(e.value) })
                                                                }
                                                                else {
                                                                    // console.log('---460---')
                                                                    if (currForm.roleId == "Employee") {
                                                                        // console.log('---462---')
                                                                        setForm({ ...form, roleId: e, email: '', phone: '', branch: preSelectedBranch, rolelevel: parseInt(e.value) })
                                                                    }
                                                                    else {
                                                                        if (e.label == "Manager") {

                                                                            // console.log('---467---', preSelectedBranch)
                                                                            setForm({ ...form, roleId: e, branch: [], rolelevel: parseInt(e.value) })
                                                                        }
                                                                        else {
                                                                            // console.log('---471---', allBranchList)
                                                                            setForm({ ...form, roleId: e, branch: allBranchList, rolelevel: parseInt(e.value) })
                                                                        }
                                                                    }
                                                                }
                                                            }}
                                                            labelledBy="Select" />
                                                    </Form.Group>
                                                </Col>
                                                {form.roleId.label === "Employee" ?
                                                    <Col md={6}>
                                                        <Form.Group className="form-group">
                                                            <Form.Label>Store Access<sup>*</sup></Form.Label>
                                                            <Select className="select2 multiSelect"
                                                                options={allBranchList}
                                                                value={form.branch}
                                                                onChange={(e) => {
                                                                    setErrorInput({ ...errorInput, branchAccess: false })
                                                                    setForm({ ...form, branch: [e] })
                                                                }}
                                                                labelledBy="Select" />
                                                            {errorInput.branch && <small style={smallStyle}>Store Access is required!</small>}
                                                        </Form.Group>
                                                    </Col> : <Col md={6}></Col>}
                                                {!Object.keys(form.roleId).length == 0 ? form.roleId.label !== "Employee" ?
                                                    <>
                                                        <Col md={6}>
                                                            <Form.Group className="form-group">
                                                                <Form.Label>Name<sup>*</sup></Form.Label>
                                                                <Form.Control type="text" placeholder=""
                                                                    value={(form.fullname)}
                                                                    className={`${errorInput.fullname ? "error" : ''}`}
                                                                    onChange={(e) => {
                                                                        const alphabetRegex = /^[a-zA-Z ]*$/;
                                                                        if (e.target.value.match(alphabetRegex)) {
                                                                            setErrorInput({ ...errorInput, fullname: false })
                                                                            setForm({
                                                                                ...form,
                                                                                fullname: e.target.value,
                                                                            });
                                                                        }
                                                                    }}
                                                                />
                                                                {errorInput.fullname && <small>Name is required!</small>}
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={6}>
                                                            <Form.Group className="form-group">
                                                                <Form.Label>Email<sup>*</sup></Form.Label>
                                                                <Form.Control
                                                                    type="email"
                                                                    placeholder=""
                                                                    className={`${errorInput.email ? "error" : ''} notCapital`}
                                                                    value={form.email}
                                                                    onChange={emailValidation}
                                                                />
                                                                {errorInput.email && <small>Email is required!</small>}
                                                                {error &&
                                                                    form.email !== "" &&
                                                                    error !== "email is valid" && (
                                                                        <small
                                                                            style={{
                                                                                color: "red",
                                                                            }}
                                                                        >
                                                                            {error}
                                                                        </small>
                                                                    )}
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={6}>
                                                            <Form.Group className="form-group">
                                                                <Form.Label>Password<sup>*</sup></Form.Label>
                                                                <div className="passwordWrap">
                                                                    <Form.Control
                                                                        className={`${!form.password && errorInput.password ? "error" : ''} notCapital`}
                                                                        autoComplete="new-password"
                                                                        type={`${!showPassword ? "password" : "text"}`}
                                                                        placeholder=""
                                                                        value={form.password}
                                                                        onChange={handlePasswordChange}
                                                                    />
                                                                    <Button className="showPassIcon" type="button">
                                                                        <i
                                                                            onClick={clickHandlerPassword}
                                                                            className={
                                                                                showPassword ? "fas fa-eye" : "fas fa-eye-slash"
                                                                            }
                                                                        ></i>
                                                                    </Button>
                                                                </div>
                                                                {!form.password && errorInput.password && <small style={smallStyle}>Password is required!</small>}
                                                                {!isPasswordValid && (
                                                                    <small>
                                                                        The password has to be at least 8 characters
                                                                        long, with at least 1 Uppercase letter, 1 Lowercase
                                                                        letter, 1 Number and 1 Special Character.
                                                                    </small>
                                                                )}
                                                            </Form.Group>
                                                        </Col>
                                                        {form.roleId.label !== "Corporate Admin" && <Col md={6}>
                                                            <Form.Group className="form-group">
                                                                <Form.Label>PIN<sup>*</sup></Form.Label>
                                                                <div className="passwordWrap">
                                                                    <Form.Control
                                                                        className={`${errorInput.pin ? "error" : ''} notCapital`}
                                                                        type={`${!showPin ? "password" : "text"}`}
                                                                        onWheel={(e) => e.target.blur()}
                                                                        onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
                                                                        placeholder=""
                                                                        value={form.pin}
                                                                        onChange={(e) => {
                                                                            const alphabetRegex = /^[0-9 ]*$/;
                                                                            if (e.target.value.match(alphabetRegex)) {
                                                                                if (e.target.value.length < 5) {
                                                                                    setErrorInput({ ...errorInput, pin: false })
                                                                                    setForm({
                                                                                        ...form,
                                                                                        pin: e.target.value,
                                                                                    });
                                                                                }
                                                                            }
                                                                        }}
                                                                    />
                                                                    <Button className="showPassIcon" type="button">
                                                                        <i
                                                                            onClick={clickHandlerPin}
                                                                            className={
                                                                                showPin ? "fas fa-eye" : "fas fa-eye-slash"
                                                                            }
                                                                        ></i>
                                                                    </Button>
                                                                </div>
                                                                {errorInput.pin && <small style={smallStyle}>Pin is required!</small>}
                                                            </Form.Group>
                                                        </Col>
                                                        }
                                                        <Col md={6}>
                                                            <Form.Group className="form-group">
                                                                <Form.Label>Phone Number<sup>*</sup></Form.Label>
                                                                <PhoneInput
                                                                    country="us"
                                                                    style={{ borderColor: "red" }}
                                                                    value={form.phone}
                                                                    onChange={handleOnChange}
                                                                    enableSearch={true}
                                                                    inputStyle={{ width: "100%" }}
                                                                />
                                                                {isPhoneValid === false && form.phone && (
                                                                    <small style={smallStyle}>
                                                                        {phoneValidatError}
                                                                    </small>
                                                                )}
                                                                {errorInput.phone && <small style={smallStyle}>Phone is required!</small>}
                                                            </Form.Group>
                                                        </Col>
                                                        {/* {console.log('{form.roleId.label', form.roleId)} */}
                                                        {form.roleId.label !== "Corporate Admin" && <Col md={6}>
                                                            <Form.Group className="form-group">
                                                                <Form.Label>Store Access<sup>*</sup></Form.Label>
                                                                {/* {console.log('637', form.roleId.label == "Manager" ? false : true)} */}
                                                                <Select className="select2 multiSelect"
                                                                    isMulti={form.roleId.label == "Manager" ? false : true}
                                                                    // menuIsOpen={true}
                                                                    isClearable={false}
                                                                    closeMenuOnSelect={form.roleId.label == "Manager" ? true : false}
                                                                    hideSelectedOptions={false}
                                                                    options={allBranchList}
                                                                    value={form.branch}
                                                                    onChange={(e) => {
                                                                        setErrorInput({ ...errorInput, branch: false })
                                                                        setForm({ ...form, branch: e })
                                                                    }}
                                                                    labelledBy="Select" />
                                                                {errorInput.branch && <small style={smallStyle}>Store Access is required!</small>}
                                                            </Form.Group>
                                                        </Col>}

                                                    </> : <EmployeFields  {...props} /> : <div></div>
                                                }

                                            </Row>
                                        </Form> : <div><Loader /></div>
                                        }
                                    </div>
                                </Col>
                                <Col xl={6} lg={4}></Col>
                            </Row>
                        </div >
                    </div>
                </div>
                <SidebarTwo activePage="" setSidebarType={setSidebarType} sidebarType={sidebarType}
                    setPermissionData={setPermissionData}
                />
            </div>
        </React.Fragment>
    )
}
export default EditUser;