export const countries = [
  {
    value: "Afghanistan",
    code: "AF",
    label: "Afghanistan",
  },
  {
    value: "Åland Islands",
    code: "AX",
    label: "Åland Islands",
  },
  {
    value: "Albania",
    code: "AL",
    label: "Albania",
  },
  {
    value: "Algeria",
    code: "DZ",
    label: "Algeria",
  },
  {
    value: "American Samoa",
    code: "AS",
    label: "American Samoa",
  },
  {
    value: "AndorrA",
    code: "AD",
    label: "AndorrA",
  },
  {
    value: "Angola",
    code: "AO",
    label: "Angola",
  },
  {
    value: "Anguilla",
    code: "AI",
    label: "Anguilla",
  },
  {
    value: "Antarctica",
    code: "AQ",
    label: "Antarctica",
  },
  {
    value: "Antigua and Barbuda",
    code: "AG",
    label: "Antigua and Barbuda",
  },
  {
    value: "Argentina",
    code: "AR",
    label: "Argentina",
  },
  {
    value: "Armenia",
    code: "AM",
    label: "Armenia",
  },
  {
    value: "Aruba",
    code: "AW",
    label: "Aruba",
  },
  {
    value: "Australia",
    code: "AU",
    label: "Australia",
  },
  {
    value: "Austria",
    code: "AT",
    label: "Austria",
  },
  {
    value: "Azerbaijan",
    code: "AZ",
    label: "Azerbaijan",
  },
  {
    value: "Bahamas",
    code: "BS",
    label: "Bahamas",
  },
  {
    value: "Bahrain",
    code: "BH",
    label: "Bahrain",
  },
  {
    value: "Bangladesh",
    code: "BD",
    label: "Bangladesh",
  },
  {
    value: "Barbados",
    code: "BB",
    label: "Barbados",
  },
  {
    value: "Belarus",
    code: "BY",
    label: "Belarus",
  },
  {
    value: "Belgium",
    code: "BE",
    label: "Belgium",
  },
  {
    value: "Belize",
    code: "BZ",
    label: "Belize",
  },
  {
    value: "Benin",
    code: "BJ",
    label: "Benin",
  },
  {
    value: "Bermuda",
    code: "BM",
    label: "Bermuda",
  },
  {
    value: "Bhutan",
    code: "BT",
    label: "Bhutan",
  },
  {
    value: "Bolivia",
    code: "BO",
    label: "Bolivia",
  },
  {
    value: "Bosnia and Herzegovina",
    code: "BA",
    label: "Bosnia and Herzegovina",
  },
  {
    value: "Botswana",
    code: "BW",
    label: "Botswana",
  },
  {
    value: "Bouvet Island",
    code: "BV",
    label: "Bouvet Island",
  },
  {
    value: "Brazil",
    code: "BR",
    label: "Brazil",
  },
  {
    value: "British Indian Ocean Territory",
    code: "IO",
    label: "British Indian Ocean Territory",
  },
  {
    value: "Brunei Darussalam",
    code: "BN",
    label: "Brunei Darussalam",
  },
  {
    value: "Bulgaria",
    code: "BG",
    label: "Bulgaria",
  },
  {
    value: "Burkina Faso",
    code: "BF",
    label: "Burkina Faso",
  },
  {
    value: "Burundi",
    code: "BI",
    label: "Burundi",
  },
  {
    value: "Cambodia",
    code: "KH",
    label: "Cambodia",
  },
  {
    value: "Cameroon",
    code: "CM",
    label: "Cameroon",
  },
  {
    value: "Canada",
    code: "CA",
    label: "Canada",
  },
  {
    value: "Cape Verde",
    code: "CV",
    label: "Cape Verde",
  },
  {
    value: "Cayman Islands",
    code: "KY",
    label: "Cayman Islands",
  },
  {
    value: "Central African Republic",
    code: "CF",
    label: "Central African Republic",
  },
  {
    value: "Chad",
    code: "TD",
    label: "Chad",
  },
  {
    value: "Chile",
    code: "CL",
    label: "Chile",
  },
  {
    value: "China",
    code: "CN",
    label: "China",
  },
  {
    value: "Christmas Island",
    code: "CX",
    label: "Christmas Island",
  },
  {
    value: "Cocos (Keeling) Islands",
    code: "CC",
    label: "Cocos (Keeling) Islands",
  },
  {
    value: "Colombia",
    code: "CO",
    label: "Colombia",
  },
  {
    value: "Comoros",
    code: "KM",
    label: "Comoros",
  },
  {
    value: "Congo",
    code: "CG",
    label: "Congo",
  },
  {
    value: "Congo, The Democratic Republic of the",
    code: "CD",
    label: "Congo, The Democratic Republic of the",
  },
  {
    value: "Cook Islands",
    code: "CK",
    label: "Cook Islands",
  },
  {
    value: "Costa Rica",
    code: "CR",
    label: "Costa Rica",
  },
  {
    value: "Cote D'Ivoire",
    code: "CI",
    label: "Cote D'Ivoire",
  },
  {
    value: "Croatia",
    code: "HR",
    label: "Croatia",
  },
  {
    value: "Cuba",
    code: "CU",
    label: "Cuba",
  },
  {
    value: "Cyprus",
    code: "CY",
    label: "Cyprus",
  },
  {
    value: "Czech Republic",
    code: "CZ",
    label: "Czech Republic",
  },
  {
    value: "Denmark",
    code: "DK",
    label: "Denmark",
  },
  {
    value: "Djibouti",
    code: "DJ",
    label: "Djibouti",
  },
  {
    value: "Dominica",
    code: "DM",
    label: "Dominica",
  },
  {
    value: "Dominican Republic",
    code: "DO",
    label: "Dominican Republic",
  },
  {
    value: "Ecuador",
    code: "EC",
    label: "Ecuador",
  },
  {
    value: "Egypt",
    code: "EG",
    label: "Egypt",
  },
  {
    value: "El Salvador",
    code: "SV",
    label: "El Salvador",
  },
  {
    value: "Equatorial Guinea",
    code: "GQ",
    label: "Equatorial Guinea",
  },
  {
    value: "Eritrea",
    code: "ER",
    label: "Eritrea",
  },
  {
    value: "Estonia",
    code: "EE",
    label: "Estonia",
  },
  {
    value: "Ethiopia",
    code: "ET",
    label: "Ethiopia",
  },
  {
    value: "Falkland Islands (Malvinas)",
    code: "FK",
    label: "Falkland Islands (Malvinas)",
  },
  {
    value: "Faroe Islands",
    code: "FO",
    label: "Faroe Islands",
  },
  {
    value: "Fiji",
    code: "FJ",
    label: "Fiji",
  },
  {
    value: "Finland",
    code: "FI",
    label: "Finland",
  },
  {
    value: "France",
    code: "FR",
    label: "France",
  },
  {
    value: "French Guiana",
    code: "GF",
    label: "French Guiana",
  },
  {
    value: "French Polynesia",
    code: "PF",
    label: "French Polynesia",
  },
  {
    value: "French Southern Territories",
    code: "TF",
    label: "French Southern Territories",
  },
  {
    value: "Gabon",
    code: "GA",
    label: "Gabon",
  },
  {
    value: "Gambia",
    code: "GM",
    label: "Gambia",
  },
  {
    value: "Georgia",
    code: "GE",
    label: "Georgia",
  },
  {
    value: "Germany",
    code: "DE",
    label: "Germany",
  },
  {
    value: "Ghana",
    code: "GH",
    label: "Ghana",
  },
  {
    value: "Gibraltar",
    code: "GI",
    label: "Gibraltar",
  },
  {
    value: "Greece",
    code: "GR",
    label: "Greece",
  },
  {
    value: "Greenland",
    code: "GL",
    label: "Greenland",
  },
  {
    value: "Grenada",
    code: "GD",
    label: "Grenada",
  },
  {
    value: "Guadeloupe",
    code: "GP",
    label: "Guadeloupe",
  },
  {
    value: "Guam",
    code: "GU",
    label: "Guam",
  },
  {
    value: "Guatemala",
    code: "GT",
    label: "Guatemala",
  },
  {
    value: "Guernsey",
    code: "GG",
    label: "Guernsey",
  },
  {
    value: "Guinea",
    code: "GN",
    label: "Guinea",
  },
  {
    value: "Guinea-Bissau",
    code: "GW",
    label: "Guinea-Bissau",
  },
  {
    value: "Guyana",
    code: "GY",
    label: "Guyana",
  },
  {
    value: "Haiti",
    code: "HT",
    label: "Haiti",
  },
  {
    value: "Heard Island and Mcdonald Islands",
    code: "HM",
    label: "Heard Island and Mcdonald Islands",
  },
  {
    value: "Holy See (Vatican City State)",
    code: "VA",
    label: "Holy See (Vatican City State)",
  },
  {
    value: "Honduras",
    code: "HN",
    label: "Honduras",
  },
  {
    value: "Hong Kong",
    code: "HK",
    label: "Hong Kong",
  },
  {
    value: "Hungary",
    code: "HU",
    label: "Hungary",
  },
  {
    value: "Iceland",
    code: "IS",
    label: "Iceland",
  },
  {
    value: "India",
    code: "IN",
    label: "India",
  },
  {
    value: "Indonesia",
    code: "ID",
    label: "Indonesia",
  },
  {
    value: "Iran, Islamic Republic Of",
    code: "IR",
    label: "Iran, Islamic Republic Of",
  },
  {
    value: "Iraq",
    code: "IQ",
    label: "Iraq",
  },
  {
    value: "Ireland",
    code: "IE",
    label: "Ireland",
  },
  {
    value: "Isle of Man",
    code: "IM",
    label: "Isle of Man",
  },
  {
    value: "Israel",
    code: "IL",
    label: "Israel",
  },
  {
    value: "Italy",
    code: "IT",
    label: "Italy",
  },
  {
    value: "Jamaica",
    code: "JM",
    label: "Jamaica",
  },
  {
    value: "Japan",
    code: "JP",
    label: "Japan",
  },
  {
    value: "Jersey",
    code: "JE",
    label: "Jersey",
  },
  {
    value: "Jordan",
    code: "JO",
    label: "Jordan",
  },
  {
    value: "Kazakhstan",
    code: "KZ",
    label: "Kazakhstan",
  },
  {
    value: "Kenya",
    code: "KE",
    label: "Kenya",
  },
  {
    value: "Kiribati",
    code: "KI",
    label: "Kiribati",
  },
  {
    value: "Korea, Democratic People'S Republic of",
    code: "KP",
    label: "Korea, Democratic People'S Republic of",
  },
  {
    value: "Korea, Republic of",
    code: "KR",
    label: "Korea, Republic of",
  },
  {
    value: "Kuwait",
    code: "KW",
    label: "Kuwait",
  },
  {
    value: "Kyrgyzstan",
    code: "KG",
    label: "Kyrgyzstan",
  },
  {
    value: "Lao People'S Democratic Republic",
    code: "LA",
    label: "Lao People'S Democratic Republic",
  },
  {
    value: "Latvia",
    code: "LV",
    label: "Latvia",
  },
  {
    value: "Lebanon",
    code: "LB",
    label: "Lebanon",
  },
  {
    value: "Lesotho",
    code: "LS",
    label: "Lesotho",
  },
  {
    value: "Liberia",
    code: "LR",
    label: "Liberia",
  },
  {
    value: "Libyan Arab Jamahiriya",
    code: "LY",
    label: "Libyan Arab Jamahiriya",
  },
  {
    value: "Liechtenstein",
    code: "LI",
    label: "Liechtenstein",
  },
  {
    value: "Lithuania",
    code: "LT",
    label: "Lithuania",
  },
  {
    value: "Luxembourg",
    code: "LU",
    label: "Luxembourg",
  },
  {
    value: "Macao",
    code: "MO",
    label: "Macao",
  },
  {
    value: "Macedonia, The Former Yugoslav Republic of",
    code: "MK",
    label: "Macedonia, The Former Yugoslav Republic of",
  },
  {
    value: "Madagascar",
    code: "MG",
    label: "Madagascar",
  },
  {
    value: "Malawi",
    code: "MW",
    label: "Malawi",
  },
  {
    value: "Malaysia",
    code: "MY",
    label: "Malaysia",
  },
  {
    value: "Maldives",
    code: "MV",
    label: "Maldives",
  },
  {
    value: "Mali",
    code: "ML",
    label: "Mali",
  },
  {
    value: "Malta",
    code: "MT",
    label: "Malta",
  },
  {
    value: "Marshall Islands",
    code: "MH",
    label: "Marshall Islands",
  },
  {
    value: "Martinique",
    code: "MQ",
    label: "Martinique",
  },
  {
    value: "Mauritania",
    code: "MR",
    label: "Mauritania",
  },
  {
    value: "Mauritius",
    code: "MU",
    label: "Mauritius",
  },
  {
    value: "Mayotte",
    code: "YT",
    label: "Mayotte",
  },
  {
    value: "Mexico",
    code: "MX",
    label: "Mexico",
  },
  {
    value: "Micronesia, Federated States of",
    code: "FM",
    label: "Micronesia, Federated States of",
  },
  {
    value: "Moldova, Republic of",
    code: "MD",
    label: "Moldova, Republic of",
  },
  {
    value: "Monaco",
    code: "MC",
    label: "Monaco",
  },
  {
    value: "Mongolia",
    code: "MN",
    label: "Mongolia",
  },
  {
    value: "Montserrat",
    code: "MS",
    label: "Montserrat",
  },
  {
    value: "Morocco",
    code: "MA",
    label: "Morocco",
  },
  {
    value: "Mozambique",
    code: "MZ",
    label: "Mozambique",
  },
  {
    value: "Myanmar",
    code: "MM",
    label: "Myanmar",
  },
  {
    value: "Namibia",
    code: "NA",
    label: "Namibia",
  },
  {
    value: "Nauru",
    code: "NR",
    label: "Nauru",
  },
  {
    value: "Nepal",
    code: "NP",
    label: "Nepal",
  },
  {
    value: "Netherlands",
    code: "NL",
    label: "Netherlands",
  },
  {
    value: "Netherlands Antilles",
    code: "AN",
    label: "Netherlands Antilles",
  },
  {
    value: "New Caledonia",
    code: "NC",
    label: "New Caledonia",
  },
  {
    value: "New Zealand",
    code: "NZ",
    label: "New Zealand",
  },
  {
    value: "Nicaragua",
    code: "NI",
    label: "Nicaragua",
  },
  {
    value: "Niger",
    code: "NE",
    label: "Niger",
  },
  {
    value: "Nigeria",
    code: "NG",
    label: "Nigeria",
  },
  {
    value: "Niue",
    code: "NU",
    label: "Niue",
  },
  {
    value: "Norfolk Island",
    code: "NF",
    label: "Norfolk Island",
  },
  {
    value: "Northern Mariana Islands",
    code: "MP",
    label: "Northern Mariana Islands",
  },
  {
    value: "Norway",
    code: "NO",
    label: "Norway",
  },
  {
    value: "Oman",
    code: "OM",
    label: "Oman",
  },
  {
    value: "Pakistan",
    code: "PK",
    label: "Pakistan",
  },
  {
    value: "Palau",
    code: "PW",
    label: "Palau",
  },
  {
    value: "Palestinian Territory, Occupied",
    code: "PS",
    label: "Palestinian Territory, Occupied",
  },
  {
    value: "Panama",
    code: "PA",
    label: "Panama",
  },
  {
    value: "Papua New Guinea",
    code: "PG",
    label: "Papua New Guinea",
  },
  {
    value: "Paraguay",
    code: "PY",
    label: "Paraguay",
  },
  {
    value: "Peru",
    code: "PE",
    label: "Peru",
  },
  {
    value: "Philippines",
    code: "PH",
    label: "Philippines",
  },
  {
    value: "Pitcairn",
    code: "PN",
    label: "Pitcairn",
  },
  {
    value: "Poland",
    code: "PL",
    label: "Poland",
  },
  {
    value: "Portugal",
    code: "PT",
    label: "Portugal",
  },
  {
    value: "Puerto Rico",
    code: "PR",
    label: "Puerto Rico",
  },
  {
    value: "Qatar",
    code: "QA",
    label: "Qatar",
  },
  {
    value: "Reunion",
    code: "RE",
    label: "Reunion",
  },
  {
    value: "Romania",
    code: "RO",
    label: "Romania",
  },
  {
    value: "Russian Federation",
    code: "RU",
    label: "Russian Federation",
  },
  {
    value: "RWANDA",
    code: "RW",
    label: "RWANDA",
  },
  {
    value: "Saint Helena",
    code: "SH",
    label: "Saint Helena",
  },
  {
    value: "Saint Kitts and Nevis",
    code: "KN",
    label: "Saint Kitts and Nevis",
  },
  {
    value: "Saint Lucia",
    code: "LC",
    label: "Saint Lucia",
  },
  {
    value: "Saint Pierre and Miquelon",
    code: "PM",
    label: "Saint Pierre and Miquelon",
  },
  {
    value: "Saint Vincent and the Grenadines",
    code: "VC",
    label: "Saint Vincent and the Grenadines",
  },
  {
    value: "Samoa",
    code: "WS",
    label: "Samoa",
  },
  {
    value: "San Marino",
    code: "SM",
    label: "San Marino",
  },
  {
    value: "Sao Tome and Principe",
    code: "ST",
    label: "Sao Tome and Principe",
  },
  {
    value: "Saudi Arabia",
    code: "SA",
    label: "Saudi Arabia",
  },
  {
    value: "Senegal",
    code: "SN",
    label: "Senegal",
  },
  {
    value: "Serbia and Montenegro",
    code: "CS",
    label: "Serbia and Montenegro",
  },
  {
    value: "Seychelles",
    code: "SC",
    label: "Seychelles",
  },
  {
    value: "Sierra Leone",
    code: "SL",
    label: "Sierra Leone",
  },
  {
    value: "Singapore",
    code: "SG",
    label: "Singapore",
  },
  {
    value: "Slovakia",
    code: "SK",
    label: "Slovakia",
  },
  {
    value: "Slovenia",
    code: "SI",
    label: "Slovenia",
  },
  {
    value: "Solomon Islands",
    code: "SB",
    label: "Solomon Islands",
  },
  {
    value: "Somalia",
    code: "SO",
    label: "Somalia",
  },
  {
    value: "South Africa",
    code: "ZA",
    label: "South Africa",
  },
  {
    value: "South Georgia and the South Sandwich Islands",
    code: "GS",
    label: "South Georgia and the South Sandwich Islands",
  },
  {
    value: "Spain",
    code: "ES",
    label: "Spain",
  },
  {
    value: "Sri Lanka",
    code: "LK",
    label: "Sri Lanka",
  },
  {
    value: "Sudan",
    code: "SD",
    label: "Sudan",
  },
  {
    value: "Surivalue",
    code: "SR",
    label: "Surivalue",
  },
  {
    value: "Svalbard and Jan Mayen",
    code: "SJ",
    label: "Svalbard and Jan Mayen",
  },
  {
    value: "Swaziland",
    code: "SZ",
    label: "Swaziland",
  },
  {
    value: "Sweden",
    code: "SE",
    label: "Sweden",
  },
  {
    value: "Switzerland",
    code: "CH",
    label: "Switzerland",
  },
  {
    value: "Syrian Arab Republic",
    code: "SY",
    label: "Syrian Arab Republic",
  },
  {
    value: "Taiwan, Province of China",
    code: "TW",
    label: "Taiwan, Province of China",
  },
  {
    value: "Tajikistan",
    code: "TJ",
    label: "Tajikistan",
  },
  {
    value: "Tanzania, United Republic of",
    code: "TZ",
    label: "Tanzania, United Republic of",
  },
  {
    value: "Thailand",
    code: "TH",
    label: "Thailand",
  },
  {
    value: "Timor-Leste",
    code: "TL",
    label: "Timor-Leste",
  },
  {
    value: "Togo",
    code: "TG",
    label: "Togo",
  },
  {
    value: "Tokelau",
    code: "TK",
    label: "Tokelau",
  },
  {
    value: "Tonga",
    code: "TO",
    label: "Tonga",
  },
  {
    value: "Trinidad and Tobago",
    code: "TT",
    label: "Trinidad and Tobago",
  },
  {
    value: "Tunisia",
    code: "TN",
    label: "Tunisia",
  },
  {
    value: "Turkey",
    code: "TR",
    label: "Turkey",
  },
  {
    value: "Turkmenistan",
    code: "TM",
    label: "Turkmenistan",
  },
  {
    value: "Turks and Caicos Islands",
    code: "TC",
    label: "Turks and Caicos Islands",
  },
  {
    value: "Tuvalu",
    code: "TV",
    label: "Tuvalu",
  },
  {
    value: "Uganda",
    code: "UG",
    label: "Uganda",
  },
  {
    value: "Ukraine",
    code: "UA",
    label: "Ukraine",
  },
  {
    value: "United Arab Emirates",
    code: "AE",
    label: "United Arab Emirates",
  },
  {
    value: "United Kingdom",
    code: "GB",
    label: "United Kingdom",
  },
  {
    value: "United States",
    code: "US",
    label: "United States",
  },
  {
    value: "United States Minor Outlying Islands",
    code: "UM",
    label: "United States Minor Outlying Islands",
  },
  {
    value: "Uruguay",
    code: "UY",
    label: "Uruguay",
  },
  {
    value: "Uzbekistan",
    code: "UZ",
    label: "Uzbekistan",
  },
  {
    value: "Vanuatu",
    code: "VU",
    label: "Vanuatu",
  },
  {
    value: "Venezuela",
    code: "VE",
    label: "Venezuela",
  },
  {
    value: "Viet Nam",
    code: "VN",
    label: "Viet Nam",
  },
  {
    value: "Virgin Islands, British",
    code: "VG",
    label: "Virgin Islands, British",
  },
  {
    value: "Virgin Islands, U.S.",
    code: "VI",
    label: "Virgin Islands, U.S.",
  },
  {
    value: "Wallis and Futuna",
    code: "WF",
    label: "Wallis and Futuna",
  },
  {
    value: "Western Sahara",
    code: "EH",
    label: "Western Sahara",
  },
  {
    value: "Yemen",
    code: "YE",
    label: "Yemen",
  },
  {
    value: "Zambia",
    code: "ZM",
    label: "Zambia",
  },
  {
    value: "Zimbabwe",
    code: "ZW",
    label: "Zimbabwe",
  },
];
export const domainSuffixList = [
  {
    value: ".ac",
    label: ".ac",
  },
  {
    value: ".app",
    label: ".app",
  },
  {
    value: ".website",
    label: ".website",
  },
  {
    value: ".ac.uk",
    label: ".ac.uk",
  },
  {
    value: ".ad",
    label: ".ad",
  },
  {
    value: ".ae",
    label: ".ae",
  },
  {
    value: ".aero",
    label: ".aero",
  },
  {
    value: ".af",
    label: ".af",
  },
  {
    value: ".ag",
    label: ".ag",
  },
  {
    value: ".ai",
    label: ".ai",
  },
  {
    value: ".al",
    label: ".al",
  },
  {
    value: ".am",
    label: ".am",
  },
  {
    value: ".an",
    label: ".an",
  },
  {
    value: ".ao",
    label: ".ao",
  },
  {
    value: ".aq",
    label: ".aq",
  },
  {
    value: ".ar",
    label: ".ar",
  },
  {
    value: ".arpa",
    label: ".arpa",
  },
  {
    value: ".as",
    label: ".as",
  },
  {
    value: ".asia",
    label: ".asia",
  },
  {
    value: ".at",
    label: ".at",
  },
  {
    value: ".au",
    label: ".au",
  },
  {
    value: ".aw",
    label: ".aw",
  },
  {
    value: ".ax",
    label: ".ax",
  },
  {
    value: ".az",
    label: ".az",
  },
  {
    value: ".ba",
    label: ".ba",
  },
  {
    value: ".bb",
    label: ".bb",
  },
  {
    value: ".bd",
    label: ".bd",
  },
  {
    value: ".be",
    label: ".be",
  },
  {
    value: ".bf",
    label: ".bf",
  },
  {
    value: ".bg",
    label: ".bg",
  },
  {
    value: ".bh",
    label: ".bh",
  },
  {
    value: ".bi",
    label: ".bi",
  },
  {
    value: ".biz",
    label: ".biz",
  },
  {
    value: ".bj",
    label: ".bj",
  },
  {
    value: ".bm",
    label: ".bm",
  },
  {
    value: ".bn",
    label: ".bn",
  },
  {
    value: ".bo",
    label: ".bo",
  },
  {
    value: ".br",
    label: ".br",
  },
  {
    value: ".bs",
    label: ".bs",
  },
  {
    value: ".bt",
    label: ".bt",
  },
  {
    value: ".bv",
    label: ".bv",
  },
  {
    value: ".bw",
    label: ".bw",
  },
  {
    value: ".by",
    label: ".by",
  },
  {
    value: ".bz",
    label: ".bz",
  },
  {
    value: ".ca",
    label: ".ca",
  },
  {
    value: ".cat",
    label: ".cat",
  },
  {
    value: ".cc",
    label: ".cc",
  },
  {
    value: ".cd",
    label: ".cd",
  },
  {
    value: ".cf",
    label: ".cf",
  },
  {
    value: ".cg",
    label: ".cg",
  },
  {
    value: ".ch",
    label: ".ch",
  },
  {
    value: ".ci",
    label: ".ci",
  },
  {
    value: ".ck",
    label: ".ck",
  },
  {
    value: ".cl",
    label: ".cl",
  },
  {
    value: ".cm",
    label: ".cm",
  },
  {
    value: ".cn",
    label: ".cn",
  },
  {
    value: ".co",
    label: ".co",
  },
  {
    value: ".co.uk",
    label: ".co.uk",
  },
  {
    value: ".com",
    label: ".com",
  },
  {
    value: ".coop",
    label: ".coop",
  },
  {
    value: ".cr",
    label: ".cr",
  },
  {
    value: ".cs",
    label: ".cs",
  },
  {
    value: ".cu",
    label: ".cu",
  },
  {
    value: ".cv",
    label: ".cv",
  },
  {
    value: ".cw",
    label: ".cw",
  },
  {
    value: ".cx",
    label: ".cx",
  },
  {
    value: ".cy",
    label: ".cy",
  },
  {
    value: ".cz",
    label: ".cz",
  },
  {
    value: ".dd",
    label: ".dd",
  },
  {
    value: ".de",
    label: ".de",
  },
  {
    value: ".dj",
    label: ".dj",
  },
  {
    value: ".dk",
    label: ".dk",
  },
  {
    value: ".dm",
    label: ".dm",
  },
  {
    value: ".do",
    label: ".do",
  },
  {
    value: ".dz",
    label: ".dz",
  },
  {
    value: ".ec",
    label: ".ec",
  },
  {
    value: ".edu",
    label: ".edu",
  },
  {
    value: ".ee",
    label: ".ee",
  },
  {
    value: ".eg",
    label: ".eg",
  },
  {
    value: ".eh",
    label: ".eh",
  },
  {
    value: ".er",
    label: ".er",
  },
  {
    value: ".es",
    label: ".es",
  },
  {
    value: ".et",
    label: ".et",
  },
  {
    value: ".eu",
    label: ".eu",
  },
  {
    value: ".fi",
    label: ".fi",
  },
  {
    value: ".firm",
    label: ".firm",
  },
  {
    value: ".fj",
    label: ".fj",
  },
  {
    value: ".fk",
    label: ".fk",
  },
  {
    value: ".fm",
    label: ".fm",
  },
  {
    value: ".fo",
    label: ".fo",
  },
  {
    value: ".fr",
    label: ".fr",
  },
  {
    value: ".fx",
    label: ".fx",
  },
  {
    value: ".ga",
    label: ".ga",
  },
  {
    value: ".gb",
    label: ".gb",
  },
  {
    value: ".gd",
    label: ".gd",
  },
  {
    value: ".ge",
    label: ".ge",
  },
  {
    value: ".gf",
    label: ".gf",
  },
  {
    value: ".gg",
    label: ".gg",
  },
  {
    value: ".gh",
    label: ".gh",
  },
  {
    value: ".gi",
    label: ".gi",
  },
  {
    value: ".gl",
    label: ".gl",
  },
  {
    value: ".gm",
    label: ".gm",
  },
  {
    value: ".gn",
    label: ".gn",
  },
  {
    value: ".gov",
    label: ".gov",
  },
  {
    value: ".gov.uk",
    label: ".gov.uk",
  },
  {
    value: ".gp",
    label: ".gp",
  },
  {
    value: ".gq",
    label: ".gq",
  },
  {
    value: ".gr",
    label: ".gr",
  },
  {
    value: ".gs",
    label: ".gs",
  },
  {
    value: ".gt",
    label: ".gt",
  },
  {
    value: ".gu",
    label: ".gu",
  },
  {
    value: ".gw",
    label: ".gw",
  },
  {
    value: ".gy",
    label: ".gy",
  },
  {
    value: ".hk",
    label: ".hk",
  },
  {
    value: ".hm",
    label: ".hm",
  },
  {
    value: ".hn",
    label: ".hn",
  },
  {
    value: ".hr",
    label: ".hr",
  },
  {
    value: ".ht",
    label: ".ht",
  },
  {
    value: ".hu",
    label: ".hu",
  },
  {
    value: ".id",
    label: ".id",
  },
  {
    value: ".ie",
    label: ".ie",
  },
  {
    value: ".il",
    label: ".il",
  },
  {
    value: ".im",
    label: ".im",
  },
  {
    value: ".in",
    label: ".in",
  },
  {
    value: ".info",
    label: ".info",
  },
  {
    value: ".int",
    label: ".int",
  },
  {
    value: ".io",
    label: ".io",
  },
  {
    value: ".iq",
    label: ".iq",
  },
  {
    value: ".ir",
    label: ".ir",
  },
  {
    value: ".is",
    label: ".is",
  },
  {
    value: ".it",
    label: ".it",
  },
  {
    value: ".je",
    label: ".je",
  },
  {
    value: ".jm",
    label: ".jm",
  },
  {
    value: ".jo",
    label: ".jo",
  },
  {
    value: ".jobs",
    label: ".jobs",
  },
  {
    value: ".jp",
    label: ".jp",
  },
  {
    value: ".ke",
    label: ".ke",
  },
  {
    value: ".kg",
    label: ".kg",
  },
  {
    value: ".kh",
    label: ".kh",
  },
  {
    value: ".ki",
    label: ".ki",
  },
  {
    value: ".km",
    label: ".km",
  },
  {
    value: ".kn",
    label: ".kn",
  },
  {
    value: ".kp",
    label: ".kp",
  },
  {
    value: ".kr",
    label: ".kr",
  },
  {
    value: ".kw",
    label: ".kw",
  },
  {
    value: ".ky",
    label: ".ky",
  },
  {
    value: ".kz",
    label: ".kz",
  },
  {
    value: ".la",
    label: ".la",
  },
  {
    value: ".lb",
    label: ".lb",
  },
  {
    value: ".lc",
    label: ".lc",
  },
  {
    value: ".li",
    label: ".li",
  },
  {
    value: ".lk",
    label: ".lk",
  },
  {
    value: ".lr",
    label: ".lr",
  },
  {
    value: ".ls",
    label: ".ls",
  },
  {
    value: ".lt",
    label: ".lt",
  },
  {
    value: ".ltd.uk",
    label: ".ltd.uk",
  },
  {
    value: ".lu",
    label: ".lu",
  },
  {
    value: ".lv",
    label: ".lv",
  },
  {
    value: ".ly",
    label: ".ly",
  },
  {
    value: ".ma",
    label: ".ma",
  },
  {
    value: ".mc",
    label: ".mc",
  },
  {
    value: ".md",
    label: ".md",
  },
  {
    value: ".me",
    label: ".me",
  },
  {
    value: ".me.uk",
    label: ".me.uk",
  },
  {
    value: ".mg",
    label: ".mg",
  },
  {
    value: ".mh",
    label: ".mh",
  },
  {
    value: ".mil",
    label: ".mil",
  },
  {
    value: ".mk",
    label: ".mk",
  },
  {
    value: ".ml",
    label: ".ml",
  },
  {
    value: ".mm",
    label: ".mm",
  },
  {
    value: ".mn",
    label: ".mn",
  },
  {
    value: ".mo",
    label: ".mo",
  },
  {
    value: ".mobi",
    label: ".mobi",
  },
  {
    value: ".mod.uk",
    label: ".mod.uk",
  },
  {
    value: ".mp",
    label: ".mp",
  },
  {
    value: ".mq",
    label: ".mq",
  },
  {
    value: ".mr",
    label: ".mr",
  },
  {
    value: ".ms",
    label: ".ms",
  },
  {
    value: ".mt",
    label: ".mt",
  },
  {
    value: ".mu",
    label: ".mu",
  },
  {
    value: ".museum",
    label: ".museum",
  },
  {
    value: ".mv",
    label: ".mv",
  },
  {
    value: ".mw",
    label: ".mw",
  },
  {
    value: ".mx",
    label: ".mx",
  },
  {
    value: ".my",
    label: ".my",
  },
  {
    value: ".mz",
    label: ".mz",
  },
  {
    value: ".na",
    label: ".na",
  },
  {
    value: ".name",
    label: ".name",
  },
  {
    value: ".nato",
    label: ".nato",
  },
  {
    value: ".nc",
    label: ".nc",
  },
  {
    value: ".ne",
    label: ".ne",
  },
  {
    value: ".net",
    label: ".net",
  },
  {
    value: ".net.uk",
    label: ".net.uk",
  },
  {
    value: ".nf",
    label: ".nf",
  },
  {
    value: ".ng",
    label: ".ng",
  },
  {
    value: ".nhs.uk",
    label: ".nhs.uk",
  },
  {
    value: ".ni",
    label: ".ni",
  },
  {
    value: ".nl",
    label: ".nl",
  },
  {
    value: ".no",
    label: ".no",
  },
  {
    value: ".nom",
    label: ".nom",
  },
  {
    value: ".np",
    label: ".np",
  },
  {
    value: ".nr",
    label: ".nr",
  },
  {
    value: ".nt",
    label: ".nt",
  },
  {
    value: ".nu",
    label: ".nu",
  },
  {
    value: ".nz",
    label: ".nz",
  },
  {
    value: ".om",
    label: ".om",
  },
  {
    value: ".org",
    label: ".org",
  },
  {
    value: ".org.uk",
    label: ".org.uk",
  },
  {
    value: ".pa",
    label: ".pa",
  },
  {
    value: ".pe",
    label: ".pe",
  },
  {
    value: ".pf",
    label: ".pf",
  },
  {
    value: ".pg",
    label: ".pg",
  },
  {
    value: ".ph",
    label: ".ph",
  },
  {
    value: ".pk",
    label: ".pk",
  },
  {
    value: ".pl",
    label: ".pl",
  },
  {
    value: ".plc.uk",
    label: ".plc.uk",
  },
  {
    value: ".pm",
    label: ".pm",
  },
  {
    value: ".pn",
    label: ".pn",
  },
  {
    value: ".post",
    label: ".post",
  },
  {
    value: ".pr",
    label: ".pr",
  },
  {
    value: ".pro",
    label: ".pro",
  },
  {
    value: ".ps",
    label: ".ps",
  },
  {
    value: ".pt",
    label: ".pt",
  },
  {
    value: ".pw",
    label: ".pw",
  },
  {
    value: ".py",
    label: ".py",
  },
  {
    value: ".qa",
    label: ".qa",
  },
  {
    value: ".re",
    label: ".re",
  },
  {
    value: ".ro",
    label: ".ro",
  },
  {
    value: ".rs",
    label: ".rs",
  },
  {
    value: ".ru",
    label: ".ru",
  },
  {
    value: ".rw",
    label: ".rw",
  },
  {
    value: ".sa",
    label: ".sa",
  },
  {
    value: ".sb",
    label: ".sb",
  },
  {
    value: ".sc",
    label: ".sc",
  },
  {
    value: ".sch.uk",
    label: ".sch.uk",
  },
  {
    value: ".sd",
    label: ".sd",
  },
  {
    value: ".se",
    label: ".se",
  },
  {
    value: ".sg",
    label: ".sg",
  },
  {
    value: ".sh",
    label: ".sh",
  },
  {
    value: ".si",
    label: ".si",
  },
  {
    value: ".sj",
    label: ".sj",
  },
  {
    value: ".sk",
    label: ".sk",
  },
  {
    value: ".sl",
    label: ".sl",
  },
  {
    value: ".sm",
    label: ".sm",
  },
  {
    value: ".sn",
    label: ".sn",
  },
  {
    value: ".so",
    label: ".so",
  },
  {
    value: ".sr",
    label: ".sr",
  },
  {
    value: ".ss",
    label: ".ss",
  },
  {
    value: ".st",
    label: ".st",
  },
  {
    value: ".store",
    label: ".store",
  },
  {
    value: ".su",
    label: ".su",
  },
  {
    value: ".sv",
    label: ".sv",
  },
  {
    value: ".sy",
    label: ".sy",
  },
  {
    value: ".sz",
    label: ".sz",
  },
  {
    value: ".tc",
    label: ".tc",
  },
  {
    value: ".td",
    label: ".td",
  },
  {
    value: ".tel",
    label: ".tel",
  },
  {
    value: ".tf",
    label: ".tf",
  },
  {
    value: ".tg",
    label: ".tg",
  },
  {
    value: ".th",
    label: ".th",
  },
  {
    value: ".tj",
    label: ".tj",
  },
  {
    value: ".tk",
    label: ".tk",
  },
  {
    value: ".tl",
    label: ".tl",
  },
  {
    value: ".tm",
    label: ".tm",
  },
  {
    value: ".tn",
    label: ".tn",
  },
  {
    value: ".to",
    label: ".to",
  },
  {
    value: ".tp",
    label: ".tp",
  },
  {
    value: ".tr",
    label: ".tr",
  },
  {
    value: ".travel",
    label: ".travel",
  },
  {
    value: ".tt",
    label: ".tt",
  },
  {
    value: ".tv",
    label: ".tv",
  },
  {
    value: ".tw",
    label: ".tw",
  },
  {
    value: ".tz",
    label: ".tz",
  },
  {
    value: ".ua",
    label: ".ua",
  },
  {
    value: ".ug",
    label: ".ug",
  },
  {
    value: ".uk",
    label: ".uk",
  },
  {
    value: ".um",
    label: ".um",
  },
  {
    value: ".us",
    label: ".us",
  },
  {
    value: ".uy",
    label: ".uy",
  },
  {
    value: ".uz",
    label: ".uz",
  },
  {
    value: ".va",
    label: ".va",
  },
  {
    value: ".vc",
    label: ".vc",
  },
  {
    value: ".ve",
    label: ".ve",
  },
  {
    value: ".vg",
    label: ".vg",
  },
  {
    value: ".vi",
    label: ".vi",
  },
  {
    value: ".vn",
    label: ".vn",
  },
  {
    value: ".vu",
    label: ".vu",
  },
  {
    value: ".web",
    label: ".web",
  },
  {
    value: ".wf",
    label: ".wf",
  },
  {
    value: ".ws",
    label: ".ws",
  },
  {
    value: ".xxx",
    label: ".xxx",
  },
  {
    value: ".ye",
    label: ".ye",
  },
  {
    value: ".yt",
    label: ".yt",
  },
  {
    value: ".yu",
    label: ".yu",
  },
  {
    value: ".za",
    label: ".za",
  },
  {
    value: ".zm",
    label: ".zm",
  },
  {
    value: ".zr",
    label: ".zr",
  },
  {
    value: ".zw",
    label: ".zw",
  },
];
