import React, { useState, useEffect, useRef } from 'react';
import QRCode from "qrcode.react";
import { Container, Row, Button, Form, InputGroup } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import Header from '../common/header';
import Sidebar from '../common/sidebar'
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { apiGetMethod, apiPostMethod } from '../../api/rest';
import { allApiUrl } from '../../api/apiRoute';
import Loader from '../shared/spinner';
import SidebarTwo from '../common/sidebarTwo';
import toastMessages from '../toastMessages';
import { FiCopy } from 'react-icons/fi';
export function ClipboardCopy({ copyText }) {
    // This is the function we wrote earlier
    async function copyTextToClipboard(text) {
        if ('clipboard' in navigator) {
            return await navigator.clipboard.writeText(text);
        } else {
            return document.execCommand('copy', true, text);
        }
    }

    // onClick handler function for the copy button
    const handleCopyClick = () => {
        // Asynchronously call copyTextToClipboard
        copyTextToClipboard(copyText)
            .then(() => {
                // If successful, update the isCopied state value
                toastMessages('success', 'text copied successfully')
                // }, 1500);
            })
            .catch((err) => {
                console.log(err);
            });

    }

    return (
        <div>
            <Form.Group className="form-group digitalMenuUrl">
                <Form.Label>
                    Menu Order Url
                </Form.Label>
                <div className='menuUrl'>
                    <Form.Control
                        className='notCapital'
                        width={20}
                        type="text"
                        placeholder=""
                        disabled
                        value={copyText}
                    />
                    <div className="iconSection">
                        <FiCopy
                            style={{
                                cursor: 'pointer',
                                marginLeft: '10px'
                            }}
                            onClick={handleCopyClick}
                        />
                    </div>
                </div>
            </Form.Group>
            {/* <button onClick={handleCopyClick}>
                    <span>{isCopied ? 'Copied!' : 'Copy'}</span>
                </button> */}

        </div>
    );
}

function DigitalMenu() {
    const inputRef = useRef(null)
    const history = useHistory()
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    let sidebarToggle = useSelector((state) => state.counter.sidebarToggle)
    let developerOrganizationKey = useSelector((state) => state.counter.organizationKey)
    const [sidebarType, setSidebarType] = useState('singleSidebar')
    let restaurant = developerOrganizationKey ? developerOrganizationKey?.split("#")[1] : userInfo?.PK.split("#")[1]
    let branchArray = useSelector((state) => state.counter.branchArray)
    let branches = branchArray.map((item) => item.value)
    const [tokenAuth, setTokenAuth] = useState('')
    const [showBlurText, setShowBlurText] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [show, setShow] = useState(false);
    const [permissionData, setPermissionData] = useState({})
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const header = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        branchid: JSON.stringify(branches)
    };
    useEffect(() => {
        if ((branchArray.length > 1) || (branchArray && branchArray[0].value == "All")) {
            history.push('/dashboard')
        }
    }, [branchArray])
    let url = ''
    if (process.env.REACT_APP_ENVIRONMENT == "development") {
        url = 'http://192.168.1.133:3000/'
    }
    else if (process.env.REACT_APP_ENVIRONMENT == "local") {
        // url = 'https://cloudpos-modifier.softuvo.click/'
        url = 'https://cloudpos.softuvo.click/'
    }
    else {
        url = 'https://portal.americloud.dev/'
    }
    useEffect(() => {
        getStripeKeys()
    }, [])
    async function getStripeKeys() {
        try {
            let data = {
                branchId: branches,
                restaurant: restaurant
            }
            const response = await apiPostMethod(`${allApiUrl.keys}`, data, header)
            if (response.status === 200) {
                try {
                    const response = await apiGetMethod(`${allApiUrl.linkCreate}`, "", header);
                    if (response.status === 200) {
                        setIsLoading(false)
                        let token = response.data.data.split('token=')[1]
                        setTokenAuth(token)
                    }
                } catch (err) {
                    setIsLoading(false)
                    console.log("err", err);
                }
            }
        } catch (err) {
            setIsLoading(false)
            setShowBlurText(true)
        }
    }
    let sidebarTypeRedux = useSelector((state) => state.counter.sidebarType)
    console.log('--76--', process.env.REACT_APP_ENVIRONMENT)
    console.log('-----79-----', tokenAuth)
    console.log('testing')
    const handleCopyClick = () => {
        if (inputRef.current) {
            inputRef.current.select();
            console.log('')
            document.execCommand('copy');
        }
    };

    return (
        <React.Fragment>
            <Header active={sidebarToggle} />

            <div className="interFaceWrap">
                <Sidebar
                    active={sidebarToggle}
                    activePage="digital-menu"
                    permissionData={permissionData} />
                {/* { */}
                <div className={`interFaceWrap__inner ${sidebarTypeRedux == false ? "doubleSidebar" : "singleSidebar"}`}>
                    <div className="commonOuter">
                        <div className="align-items-center row">
                            <div className="col">
                                <div className="headingText headingText--textLeft mb-0">
                                    <h1>Digital Menu</h1>
                                </div>
                            </div>
                        </div>
                        <div className="pageHeader">
                            <Container fluid className="px-0">
                                <Row className="align-items-center">
                                </Row>
                            </Container>
                        </div>
                        <div className="commonTable">
                            <div className="whiteBg">
                                {isLoading ? <Loader /> :
                                    <div className="qrCodeWrap">
                                        <div className={`qrCode ${showBlurText && "showError"}`}>
                                            <QRCode value={`${url}menu-order?token=${tokenAuth}`}
                                                style={{ height: 200, width: 200 }} />
                                        </div>
                                        {/* {!showBlurText && <p>To View The Menu, Scan This QR Code.</p>} */}
                                        {showBlurText && <div>
                                            <p>Your Stripe Keys Are Not Configured.Please Configure The Stripe Keys From Settings And Try again. </p>
                                        </div>}
                                        {!showBlurText &&
                                            <div style={{ marginTop: '22px' }}>
                                                {(branchArray && branchArray.length > 1) || (branchArray && branchArray[0] && branchArray[0].value !== "All") &&
                                                    <Button className="commonButton menuOrderButton" onClick={handleShow}>See Preview</Button>
                                                }
                                                <div>
                                                    <ClipboardCopy copyText={`${url}menu-order?token=${tokenAuth}`} />
                                                </div>
                                                {<Modal className="commonModal restaurantMenuModal" centered backdrop="static" keyboard={false} show={show} onHide={handleClose}>
                                                    <Modal.Header closeButton></Modal.Header>
                                                    <Modal.Body>
                                                        <iframe src={`${url}menu-order?token=${tokenAuth}`} title=""></iframe>
                                                    </Modal.Body>
                                                </Modal>}

                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                        </div>

                    </div>
                </div>
                {/* } */}
            </div>
            <SidebarTwo
                activePage=""
                setSidebarType={setSidebarType}
                sidebarType={sidebarType}
                setPermissionData={setPermissionData}
            />
        </React.Fragment >
    )
}

export default DigitalMenu;
