import { createSlice } from "@reduxjs/toolkit";

export const counterSlice = createSlice({
  name: "counter",
  initialState: {
    currBranch: { label: "All", value: "All" },
    branchArray: [{ label: "All", value: "All" }],
    disableTableManagment: true,
    disableWebsiteTemplate: true,
    isDeveloperMode: false,
    typeOfUser: "",
    sidebarToggle: false,
    sidebarType: true, //  for toggle two
    showDisplayMenu: true,
    List: {
      storeList: [],
      organizationList: [],
    },
    subdomainData: {
      isSubdomainAvaialble: true,
      data: {},
    },
    organizationKey: "",
  },
  reducers: {
    changeBranch: (state, action) => {
      state.currBranch = action.payload;
    },
    changeTableManagement: (state, action) => {
      state.disableTableManagment = action.payload;
    },
    changeWebsiteTemplate: (state, action) => {
      state.disableWebsiteTemplate = action.payload;
    },
    saveBranchArray: (state, action) => {
      state.branchArray = action.payload;
    },
    getDeveloperMode: (state, action) => {
      state.isDeveloperMode = action.payload;
    },
    setTypeOfUser: (state, action) => {
      state.typeOfUser = action.payload;
    },
    toggleSidebarFunctionality: (state, action) => {
      state.sidebarToggle = action.payload;
    },
    toggleSideBarFuntionalityTwo: (state, action) => {
      state.sidebarType = action.payload;
    },
    logoutHandlerFunctionality: (state) => {
      state.currBranch = { label: "All", value: "All" };
      state.disableTableManagment = true;
      state.disableWebsiteTemplate = true;
      state.isDeveloperMode = false;
      state.typeOfUser = "";
      state.sidebarToggle = false;
      state.sidebarType = true;
      state.List.storeList = [];
      state.List.organizationList = [];
      state.organizationKey = "";
    },
    savePreviousBranchList: (state, action) => {
      state.List.storeList = action.payload;
    },
    savePreviousOrganizationList: (state, action) => {
      state.List.organizationList = action.payload;
    },
    saveDomainData: (state, action) => {
      state.subdomainData = action.payload;
    },
    saveOrganizationKey: (state, action) => {
      state.organizationKey = action.payload;
    },
    toggleDigitalMenu: (state, action) => {
      state.showDisplayMenu = action.payload;
    },
  },
});
export const {
  changeBranch,
  changeTableManagement,
  changeWebsiteTemplate,
  saveBranchArray,
  getDeveloperMode,
  setTypeOfUser,
  toggleSidebarFunctionality,
  logoutHandlerFunctionality,
  toggleSideBarFuntionalityTwo,
  savePreviousBranchList,
  savePreviousOrganizationList,
  saveDomainData,
  saveOrganizationKey,
  toggleDigitalMenu,
} = counterSlice.actions;
export default counterSlice.reducer;
