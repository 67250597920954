import React, { useEffect, useState } from 'react';
import queryString from "query-string";

import { allApiUrl } from '../../api/apiRoute';
import { apiGetMethod } from '../../api/rest';
import Loader from '../shared/spinner';
import { useLocation } from 'react-router-dom';

const OrderConfirmation = () => {
    let location = useLocation()
    let { orderId } = queryString.parse(location.search);
    const [orderDetails, setOrderDetails] = useState({})
    useEffect(() => {
        getOrderDetail()
    }, [])
    async function getOrderDetail() {
        try {
            const response = await apiGetMethod(allApiUrl.receipt, `/${orderId}`, {});
            if (response.status === 200) {
                if (response.data && response.data.data[0]) {
                    setOrderDetails({ ...response.data.data[0], order: true })
                }
            }
        }
        catch (err) {
            setOrderDetails({ order: false })
        }
    }
    const divStyle = {
        boxSizing: 'border-box',
        WebkitTextSizeAdjust: 'none',
        msTextSizeAdjust: 'none',
        textSizeAdjust: 'none',
        background: '#f5f5f5',
        margin: 0,
        padding: 0,
        fontFamily: "'Noto Sans SC', sans-serif",
    }
    const emailTableStyle = {
        width: '100%',
        borderCollapse: 'collapse',
    };
    const thStyle = {
        fontSize: '15px',
        fontWeight: 600,
        color: '#575757',
        padding: '12px 10px',
        border: '1px solid #dddddd',
    };
    const tdStyle = {
        padding: '12px 10px',
        border: '1px solid #dddddd',
    };
    const tdH5Style = {
        fontSize: '14px',
        fontWeight: 400,
        color: '#575757',
        textTransform: 'capitalize'
    };
    if (!Object.keys(orderDetails).length) {
        return (
            <Loader />
        )
    }
    if (orderDetails.order) {
        return (
            <div style={divStyle}>

                <table
                    border="0"
                    cellpadding="0"
                    cellspacing="0"
                    height="100%"
                    width="100%"
                    id="emailWrap"
                >
                    <tr>
                        <td align="center" valign="middle">
                            <table
                                style={{
                                    width: '100%',
                                    maxWidth: '600px',
                                    textAlign: 'left',
                                    border: '1px solid #ddd',
                                }}
                                bgcolor="#fff"
                                border="0"
                                cellpadding="0"
                                cellspacing="0"
                                id="bodyTable"
                            >
                                <thead
                                    bgcolor="#fff"
                                    border="0"
                                    cellpadding="0"
                                    cellspacing="0"
                                    width="100%"
                                    id="emailHeader"
                                >
                                    <tr>
                                        <th
                                            colspan="1"
                                            style={{
                                                backgroundColor: '#557da1',
                                                color: '#ffffff',
                                                fontSize: '22px',
                                                fontWeight: 600,
                                                padding: '30px 30px',
                                            }}
                                        >
                                            Your order is complete
                                        </th>
                                    </tr>
                                </thead>
                                <tbody
                                    bgcolor="#fff"
                                    border="0"
                                    cellpadding="0"
                                    cellspacing="0"
                                    width="100%"
                                    id="emailContainer"
                                >
                                    <tr>
                                        <td
                                            style={{
                                                fontSize: '14px',
                                                fontWeight: 400,
                                                color: '#9d9d9d',
                                                padding: '20px 30px',
                                                textTransform: 'capitalize'
                                            }}
                                        >
                                            Hi there. Your recent order on {orderDetails?.branchName} has been
                                            completed. Your order details are shown below for your
                                            reference:
                                        </td>
                                    </tr>
                                    <tr>
                                        <td
                                            style={{
                                                fontSize: '20px',
                                                fontWeight: 600,
                                                color: '#557da1',
                                                padding: '0 30px',
                                            }}
                                        >
                                            Order #{orderDetails?.orderNumber}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ padding: '5px 30px 20px' }}>
                                            <table className="emailTable" style={emailTableStyle}>
                                                <thead>
                                                    <tr>
                                                        <th style={thStyle}>Product</th>
                                                        <th style={thStyle}>Quantity</th>
                                                        <th style={thStyle}>Price</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        orderDetails &&
                                                        orderDetails.product &&
                                                        orderDetails.product.map((item) => {
                                                            return (
                                                                <tr>
                                                                    <td style={tdStyle}>
                                                                        <h5 style={tdH5Style}>{item.productName}</h5>
                                                                        {item.modifier.length > 0
                                                                            && item.modifier.map((modifierItem, index) => {
                                                                                return (
                                                                                    <small style={{ display: 'block', ...tdH5Style, textTransform: 'capitalize' }} key={index}>
                                                                                        {index + 1}. {modifierItem.name} ${modifierItem.price}
                                                                                    </small>
                                                                                )
                                                                                // modifierItem.choices.map((choiceItem, index) => (                                                                               // <small style={{ display: 'block', ...tdH5Style }}>
                                                                                // ))
                                                                            })}
                                                                    </td>
                                                                    <td style={tdStyle}>{item.quantity}</td>
                                                                    <td style={tdStyle}>${parseInt(item.total).toFixed(2)}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <td colSpan="2" style={{ ...thStyle, fontWeight: 600 }}>
                                                            Payment Method:
                                                        </td>
                                                        <td style={thStyle}>{orderDetails?.transactionType}</td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan="2" style={{ ...thStyle, fontWeight: 600 }}>
                                                            Subtotal:
                                                        </td>
                                                        <td style={thStyle}>${orderDetails?.subTotal?.toFixed(2)}</td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan="2" style={{ ...thStyle, fontWeight: 600 }}>
                                                            {`Discount: (${orderDetails?.discountType})`}
                                                        </td>
                                                        <td style={thStyle}>${orderDetails.discount}</td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan="2" style={{ ...thStyle, fontWeight: 600 }}>
                                                            {`Tax: (${orderDetails?.taxPercentage})`}
                                                        </td>
                                                        <td style={thStyle}>${orderDetails?.tax?.toFixed(2)}</td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan="2" style={{ ...thStyle, fontWeight: 600 }}>
                                                            {`Gratuity: (${orderDetails?.gratuityType})`}
                                                        </td>
                                                        <td style={thStyle}>${orderDetails?.gratuity?.toFixed(2)}</td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan="2" style={{ ...thStyle, fontWeight: 600 }}>
                                                            Total:
                                                        </td>
                                                        <td style={thStyle}>${orderDetails?.finalTotal?.toFixed(2)}</td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ padding: '0 30px 30px' }}>
                                            <table>
                                                {orderDetails?.orderType?.firstName &&
                                                    <thead>
                                                        <tr>
                                                            <th
                                                                style={{
                                                                    fontSize: '20px',
                                                                    fontWeight: 600,
                                                                    color: '#557da1',
                                                                    padding: 0,
                                                                }}
                                                            >
                                                                Customer details
                                                            </th>
                                                        </tr>
                                                    </thead>}
                                                <tbody>
                                                    <tr>
                                                        {orderDetails?.orderType?.firstName &&
                                                            <td
                                                                style={{
                                                                    fontSize: '14px',
                                                                    fontWeight: 400,
                                                                    color: '#575757',
                                                                    padding: '5px 0 5px 20px',
                                                                }}
                                                            >
                                                                <span>Name: </span>
                                                                <a
                                                                    style={{
                                                                        color: '#557da1',
                                                                        textDecoration: 'none',
                                                                        textTransform: 'capitalize'
                                                                    }}
                                                                >
                                                                    {orderDetails.orderType.firstName + " " + orderDetails.orderType.lastName}
                                                                </a>
                                                            </td>}
                                                        {orderDetails?.orderType?.phoneNumber &&
                                                            <td
                                                                style={{
                                                                    fontSize: '14px',
                                                                    fontWeight: 400,
                                                                    color: '#575757',
                                                                    padding: '5px 0 5px 20px',
                                                                }}
                                                            >
                                                                <span>Phone Number: </span>
                                                                <a
                                                                    style={{
                                                                        color: '#557da1',
                                                                        textDecoration: 'none',

                                                                    }}
                                                                >
                                                                    {orderDetails.orderType.phoneNumber}
                                                                </a>
                                                            </td>}
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                                <tfoot
                                    border="0"
                                    cellpadding="0"
                                    cellspacing="0"
                                    width="100%"
                                    id="emailFooter"
                                >
                                    <tr>
                                        <td
                                            align="center"
                                            style={{
                                                color: '#557da1',
                                                fontSize: '14px',
                                                fontWeight: 400,
                                                padding: '20px 30px',
                                            }}
                                        >
                                            CloudPOS
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                        </td>
                    </tr>
                </table>
            </div>
        );
    }
    if (!orderDetails.order) {
        return (
            <table
                border="0"
                cellpadding="0"
                cellspacing="0"
                height="100%"
                width="100%"
                id="emailWrap"
            >
                <tr>
                    <td align="center" valign="middle">
                        <table
                            style={{
                                width: '100%',
                                maxWidth: '600px',
                                textAlign: 'left',
                                border: '1px solid #ddd',
                            }}
                            bgcolor="#fff"
                            border="0"
                            cellpadding="0"
                            cellspacing="0"
                            id="bodyTable"
                        >
                            <thead
                                bgcolor="#fff"
                                border="0"
                                cellpadding="0"
                                cellspacing="0"
                                width="100%"
                                id="emailHeader"
                            >
                                <tr>
                                    <th
                                        colspan="1"
                                        style={{
                                            backgroundColor: '#557da1',
                                            color: '#ffffff',
                                            fontSize: '22px',
                                            fontWeight: 600,
                                            padding: '30px 30px',
                                        }}
                                    >
                                        Your Order Doesn't Exist
                                    </th>
                                </tr>
                            </thead>
                            <tbody
                                bgcolor="#fff"
                                border="0"
                                cellpadding="0"
                                cellspacing="0"
                                width="100%"
                                id="emailContainer"
                            >
                                <tr>
                                    <td
                                        style={{
                                            fontSize: '14px',
                                            fontWeight: 400,
                                            color: '#9d9d9d',
                                            padding: '20px 30px',
                                            textTransform: 'capitalize'
                                        }}
                                    >
                                        Hi there, Your order Number {orderId} Does Not Exist
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot
                                border="0"
                                cellpadding="0"
                                cellspacing="0"
                                width="100%"
                                id="emailFooter"
                            >
                                <tr>
                                    <td
                                        align="center"
                                        style={{
                                            color: '#557da1',
                                            fontSize: '14px',
                                            fontWeight: 400,
                                            padding: '20px 30px',
                                        }}
                                    >
                                        CloudPOS
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </td>
                </tr>
            </table>


        )
    }
};
export default OrderConfirmation;
