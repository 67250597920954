import React, { useEffect, useState, useRef } from "react";
import { Button, Form, FloatingLabel, InputGroup } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RxCrossCircled } from "react-icons/rx";
//CSS
import "./sidebarTwo.scss";
import { apiGetMethod, apiPostMethod } from "../../../api/rest";
import { allApiUrl } from "../../../api/apiRoute";
import { c, titleCase } from "../../../features/helper";
import toastMessages from "../../toastMessages";
import {
  getDeveloperMode,
  setTypeOfUser,
  saveBranchArray,
  toggleSideBarFuntionalityTwo,
  savePreviousBranchList,
  savePreviousOrganizationList,
  logoutHandlerFunctionality,
  saveOrganizationKey,
  changeTableManagement,
  toggleDigitalMenu,
} from "../../../features/todoSlice";
import CustomTooltip from "../tooltip";
// import crossIcon from '../../shared/assets/images/cross-circle.png';

function SidebarTwo(props) {
  //---------------------For Organization-----------------------------------------

  //---------------------Constants------------------------------------------------
  let dispatch = useDispatch();
  let history = useHistory();
  let typeOfUser = useSelector((state) => state.counter.typeOfUser);
  let sidebarType = useSelector((state) => state.counter.sidebarType);
  let previousOrganizationList = useSelector(
    (state) => state.counter.List.organizationList
  );
  const body = document.body;
  //--------------------------useState--------------------------------------------
  const [allOrganizationList, setAllOrganizationList] = useState(
    previousOrganizationList && previousOrganizationList.length
      ? previousOrganizationList
      : []
  );
  const [active, setActive] = useState(true);
  //----------------useEffects----------------------------------------------------
  useEffect(() => {
    if (typeOfUser == "developerUser") {
      getAllActiveOrganization();
    }
  }, [props.isPageRefreshed]);
  //----------------Functions----------------------------------------------------
  async function getAllActiveOrganization() {
    const header = {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    };
    try {
      const response = await apiGetMethod(
        `${allApiUrl.activeOrganization}`,
        "",
        header
      );
      if (response.status === 200) {
        let allOrganizationListing = response.data.data.Items.map((item) => {
          return {
            label: titleCase(item.organizationname),
            value: item.SK,
            organization: item.organizationKey,
          };
        });
        await dispatch(savePreviousOrganizationList(allOrganizationListing));
        setAllOrganizationList(allOrganizationListing);

        // if (!response.data.data.Items.length) {
        //   props.setSidebarType('singleSidebar')
        // }
        // else {
        //   props.setSidebarType('doubleSidebar')
        // }
      }
    } catch (err) {
      toastMessages("error", err.data.message);
    }
  }
  async function setActiveOrganization(value) {
    props.setShowLoadingPage(true);
    const header = {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    };
    try {
      let data = {
        organization: value.organization,
      };
      const response = await apiPostMethod(
        `${allApiUrl.selectOrganization}`,
        data,
        header
      );
      if (response.status === 200) {
        localStorage.setItem("accessToken", response.data.token);
        localStorage.setItem(
          "userInfo",
          JSON.stringify(response.data.data.Items[0])
        );
        //save the orgnanization key in case of developer
        await dispatch(
          saveOrganizationKey(response.data.data.Items[0].organizationKey)
        );
        await dispatch(getDeveloperMode(true));
        await dispatch(saveBranchArray([{ value: "All", label: "All" }]));
        await dispatch(setTypeOfUser("organizationUser"));
        props.setShowLoadingPage(false);
        dispatch(toggleSideBarFuntionalityTwo(true));
        history.push("/dashboard");
        getProfile();
      }
    } catch (err) {
      history.push("/login");
      console.log("err", err);
    }
  }
  async function getProfile() {
    try {
      let header = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      };
      const response = await apiGetMethod(allApiUrl.profile, "", header);
      if (response.status === 200) {
        localStorage.setItem(
          "userInfo",
          JSON.stringify({
            ...userInfo,
            image: response.data.user.image,
          })
        );
      }
    } catch (err) {
      console.log("err", err);
    }
  }
  function onSearchHandler(e) {
    if (e.target.value) {
      setAllOrganizationList(
        allOrganizationList.filter((item) => {
          if (item.label.toLowerCase().includes(e.target.value.toLowerCase())) {
            return item;
          }
        })
      );
    } else {
      getAllActiveOrganization();
    }
  }
  //-----------------------------For Branches ------------------------------------
  //-----------------------------Constant-----------------------------------------
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  let rolelLevel = userInfo?.rolelevel;
  let branchArray = useSelector((state) => state.counter.branchArray);
  let previousStoreList = useSelector((state) => state.counter.List.storeList);
  let branches = branchArray && branchArray.map((item) => item.value);
  console.log('branchArray--164', branchArray)
  const header = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    branchid: JSON.stringify(branches),
  };
  //-----------------------------useStates------------------------------------
  const [AllBranchList, setAllBranchList] = useState(
    previousStoreList && previousStoreList.length ? previousStoreList : []
  );
  const [isStoreChanged, setIsStoreChanged] = useState(false)
  //-----------------------------useEffect------------------------------------
  useEffect(() => {
    if (typeOfUser == "organizationUser") {
      if (parseInt(rolelLevel) < 90) {
        getUserBranch();
      }
    }
  }, [props.isCategoryPageRefreshed, isStoreChanged]);
  useEffect(() => {
    if (typeOfUser == "organizationUser") {
      if (rolelLevel >= 90) {
        getOrgBranch();
      }
    }
  }, [props.isCategoryPageRefreshed, props.isPageRefreshed, isStoreChanged]);
  //-----------------------------Functions------------------------------------
  async function getUserBranch() {
    try {
      const response = await apiGetMethod(
        allApiUrl.active_branches,
        "",
        header
      );
      if (response.status === 200) {
        userDetail(response.data.data.Items);
      }
    } catch (err) {
      if (err.status == 403) {
        //eslint-disable-line
        toastMessages("error", err.data.message);
        localStorage.clear();
        body.classList.remove("dark-mode");
        // toggle.classList.remove("toggle-active");
        dispatch(logoutHandlerFunctionality());
        history.push("/login");
      }
      console.log("err", err);
    }
  }
  async function userDetail(active) {
    const response = await apiGetMethod(
      allApiUrl.view_user,
      `/${userInfo.SK}`,
      header
    );
    if (response.status === 200) {
      let branch = [];
      branch.push({ label: "All", value: "All" });
      response.data.data.Items[0].branch.forEach((userItem) => {
        active.forEach((item) => {
          if (userItem.value == item.SK) {
            branch.push({ label: titleCase(item.branchname), value: item.SK, permissionsgranted: item.permissionsgranted });
          }
        });
      });
      console.log('branch--228', branch)
      if (branch.length == 2) {
        if (branchArray[0].value !== branch[1].value) {
          console.log('--232-')
          await dispatch(saveBranchArray([
            { value: branch[1].value, label: branch[1].label },
          ]))
        }
        await dispatch(savePreviousBranchList(branch));
        setAllBranchList(branch.filter((item) => item.value !== "All"));

      } else {
        await dispatch(savePreviousBranchList(branch));
        setAllBranchList(branch);
      }
      /////////////////////////permission related changes/////////////////////////////////////////////////////
      if (branch.length == 2) {
        let permission = branch && branch[1] && branch[1].permissionsgranted
        console.log('246', permission)
        if (permission && permission.digitalmenu == 0) {
          dispatch(toggleDigitalMenu(false));
        } else {
          dispatch(toggleDigitalMenu(true));
        }
        props.setPermissionData(permission)
      }
      else {
        if (branchArray.length == 1) {
          let isXist = branch.find((item) => item.value == parseInt(branchArray[0].value)).permissionsgranted
          console.log('253', isXist)
          if (isXist && isXist.digitalmenu == 0) {
            dispatch(toggleDigitalMenu(false));
          } else {
            dispatch(toggleDigitalMenu(true));
          }
          props.setPermissionData(isXist)
        }
      }
      //////////////////////////////////////////////////////////////////////////////////////////////////////
    }
  }
  async function getOrgBranch() {
    try {
      const response = await apiGetMethod(
        allApiUrl.active_branches,
        "",
        header
      );
      if (response.status === 200) {
        if (response.data.data.Count == 0) {
          //eslint-disable-line
          // console.log('----saveBranchArray------224---')
          await dispatch(saveBranchArray([{ value: "All", label: "All" }]));
        }
        let branch = [
          { label: "All", value: "All" },
          ...response.data.data.Items.map((item) => {
            return {
              label: titleCase(item.branchname),
              value: item.SK,
            };
          }),
        ];
        console.log('branch.length', branch.length)
        if (branch.length == 2) {
          // console.log('----saveBranchArray------236---', branchArray, branch)
          if (branchArray[0].value !== branch[1].value) {
            //testing
            await dispatch(
              saveBranchArray([
                { value: branch[1].value, label: branch[1].label },
              ])
            );
          }
          branch = branch.filter((item) => item.value !== "All");
          await dispatch(savePreviousBranchList(branch));
          setAllBranchList(branch.filter((item) => item.value !== "All"));
        } else {
          await dispatch(savePreviousBranchList(branch));
          setAllBranchList(branch);
        }
        //
        if (branchArray.length == 1) {
          let currentBranchData = response.data.data.Items.find((item) => item.SK == parseInt(branchArray[0].value))
          currentBranchData = currentBranchData.permissionsgranted
          // --------------------------------------------------permission related changes --------------------------------------//
          if (currentBranchData && currentBranchData.digitalmenu == 0) {
            dispatch(toggleDigitalMenu(false));
          } else {
            dispatch(toggleDigitalMenu(true));
          }
          props.setPermissionData(currentBranchData)
          // -------------------------------------------------------------------------------------------------------------------//
        }
      }
    } catch (err) {
      if (err.status == 403) {
        //eslint-disable-line
        toastMessages("error", err.data.message);
        localStorage.clear();
        body.classList.remove("dark-mode");
        // toggle.classList.remove("toggle-active");
        dispatch(logoutHandlerFunctionality());
        history.push("/login");
      }
    }
  }
  async function onBranchSelectHandler(item) {
    // console.log('314')
    if (item.value == "All") {
      // console.log('----saveBranchArray------260---')
      await dispatch(saveBranchArray([{ value: "All", label: "All" }]));
    } else {
      setIsStoreChanged(!isStoreChanged)
      let ifDoesNotAlreadyExist = branchArray.findIndex(
        (findItem) => findItem.value == item.value
      );
      if (ifDoesNotAlreadyExist == -1) {
        let updatedList = branchArray.filter((item) => item.value !== "All");
        updatedList = [...updatedList, item];
        // console.log('----saveBranchArray------268---')
        // console.log("🚀 ~ file: index.jsx:255 ~ onBranchSelectHandler ~ updatedList:saveBranchArray")
        await dispatch(saveBranchArray(updatedList));
      } else {
        let updated = branchArray.filter(
          (_, index) => index !== ifDoesNotAlreadyExist
        );
        // console.log('updated', updated)
        if (!updated.length) {
          updated = [{ value: "All", label: "All" }];
        }
        // console.log('----saveBranchArray------279---')
        // console.log("🚀 ~ file: index.jsx:263 ~ onBranchSelectHandler ~ updated:saveBranchArray")
        await dispatch(saveBranchArray(updated));
      }
    }
  }
  let passingData = {
    setActive,
    active,
    onSearchHandler,
    allOrganizationList,
    setActiveOrganization,
    props,
    typeOfUser,
    AllBranchList,
    branchArray,
    saveBranchArray,
    dispatch,
    onBranchSelectHandler,
    sidebarType,
  };
  return <SideDrawer {...passingData} />;
}
const SideDrawer = ({
  setActive,
  active,
  onSearchHandler,
  allOrganizationList,
  setActiveOrganization,
  props,
  typeOfUser,
  AllBranchList,
  branchArray,
  saveBranchArray,
  dispatch,
  onBranchSelectHandler,
  sidebarType,

}) => {
  const [searchedListBranch, setSearchedListBranch] = useState([]);
  const inputRef = useRef();
  function onSearchBranch(e) {
    if (e.target.value) {
      setSearchedListBranch(
        AllBranchList.filter((item) => {
          if (item.label.toLowerCase().includes(e.target.value.toLowerCase())) {
            return item;
          }
        }).filter((item) => item.value !== "All")
      );
    } else {
      setSearchedListBranch([]);
    }
  }
  console.log("AllBranchList------339----", AllBranchList);
  async function searchBranchListing(item) {
    let doesNotExist = branchArray.find(
      (findItem) => findItem.value != item.value
    );
    if (doesNotExist) {
      let updatedList = [...branchArray, item];
      setSearchedListBranch([]);
      inputRef.current.value = "";
      if (branchArray && branchArray[0].value == "All") {
        updatedList = updatedList.filter(
          (filterItem) => filterItem.value !== "All"
        );
      }
      // console.log('updatedList', updatedList)
      // console.log("🚀 ~ file: index.jsx:327 ~ searchBranchListing ~ updatedList:saveBranchArray")
      // console.log('----saveBranchArray------345---')
      await dispatch(saveBranchArray(updatedList));
    } else {
      inputRef.current.value = "";
      setSearchedListBranch([]);
    }
  }
  return (
    <div>
      {typeOfUser == "developerUser" ? (
        // <div className={`${(typeOfUser == "developerUser" && !allOrganizationList.length) && "d-none"}`}>
        <div className="">
          <div
            className={`sidebarTwo ${sidebarType ? "slideSidebarToggle" : ""}`}
          >
            <div className="menuIcon">
              {console.log("sidebarType", sidebarType)}
              <div
                className={`menuIcon__button ${!sidebarType ? "active" : ""}`}
                onClick={() => {
                  setActive(!active);
                  console.log("active", active);
                  dispatch(
                    toggleSideBarFuntionalityTwo(
                      !sidebarType == true ? true : false
                    )
                  );
                }}
              >
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
            <div className="sidebarTwo__inner">
              <span className="crossIcon">
                <RxCrossCircled
                  size={24}
                  onClick={() => {
                    setActive(!active);
                    dispatch(toggleSideBarFuntionalityTwo(!sidebarType));
                  }}
                />
              </span>
              <Form className="searchBarForm">
                <InputGroup className="searchBar">
                  <InputGroup.Text id="searchOrders">
                    <i className="fa-solid fa-magnifying-glass"></i>
                  </InputGroup.Text>
                  <FloatingLabel controlId="floatingInput" label="Search">
                    <Form.Control
                      type="search"
                      placeholder="Search"
                      autoComplete="off"
                      onChange={onSearchHandler}
                    />
                  </FloatingLabel>
                  <div></div>
                </InputGroup>
              </Form>
              <div className="organizationList">
                <h3>Choose Organization</h3>
                <ul>
                  {allOrganizationList &&
                    allOrganizationList.length > 0 &&
                    allOrganizationList.map((item, index) => {
                      return (
                        <li key={index}>
                          <Button
                            style={{ textTransform: "capitalize" }}
                            type="button"
                            onClick={() => setActiveOrganization(item)}
                          >
                            {item.label}
                          </Button>
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="displaySidebarTwo">
          <div
            className={`sidebarTwo ${sidebarType ? "slideSidebarToggle" : ""}`}
          >
            <CustomTooltip placement="left" tooltipText="View All Store">
              <div className="menuIcon">
                <div
                  className={`menuIcon__button ${!sidebarType ? "active" : ""}`}
                  onClick={() => {
                    setActive(!active);
                    dispatch(
                      toggleSideBarFuntionalityTwo(
                        !sidebarType == true ? true : false
                      )
                    );
                  }}
                >
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            </CustomTooltip>
            <div className="sidebarTwo__inner">
              <span className="crossIcon">
                <RxCrossCircled
                  size={24}
                  onClick={() => {
                    setActive(!active);
                    dispatch(
                      toggleSideBarFuntionalityTwo(
                        !sidebarType == true ? true : false
                      )
                    );
                  }}
                />
              </span>
              <Form className="searchBarForm">
                <InputGroup className="searchBar">
                  <InputGroup.Text id="searchOrders">
                    <i className="fa-solid fa-magnifying-glass"></i>
                  </InputGroup.Text>
                  <FloatingLabel controlId="floatingInput" label="Search">
                    <Form.Control
                      type="search"
                      placeholder="Search"
                      autoComplete="off"
                      ref={inputRef}
                      onChange={onSearchBranch}
                    />
                  </FloatingLabel>
                </InputGroup>
                <div className="formDropdown">
                  <div
                    className={`outer ${!searchedListBranch.length ? "d-none" : ""
                      }`}
                  >
                    <ul>
                      {searchedListBranch.map((item, index) => {
                        return (
                          <li
                            key={index}
                            onClick={() => searchBranchListing(item)}
                          >
                            {item.label}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </Form>
              <div className="organizationList">
                <h3>Choose Stores</h3>
                <ul>
                  {AllBranchList.map((branchItem, index, branch) => {
                    let isExist = branchArray.find(
                      (item) => item.value == branchItem.value
                    );
                    if (
                      branch.length > 1 ||
                      !branch.find((item) => item.value == "All")
                    ) {
                      return (
                        <li key={index}>
                          <Button
                            disabled={AllBranchList.length == 1 ? true : false}
                            className={(isExist || isExist == 0) && "active"}
                            type="button"
                            onClick={() => onBranchSelectHandler(branchItem)}
                          >
                            {branchItem.label}
                          </Button>
                        </li>
                      );
                    } else {
                      return (
                        <li key={index}>
                          <p>No Store Available</p>
                        </li>
                      );
                    }
                  })}
                </ul>
                {AllBranchList.length !== 1 ? (
                  <Button
                    type="button"
                    className="commonButton commonButton--small w-100 mt-4"
                    onClick={async () => {
                      let updated = [{ value: "All", label: "All" }];
                      // console.log('----saveBranchArray------478---')
                      await dispatch(saveBranchArray(updated));
                    }}
                  >
                    Clear
                  </Button>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SidebarTwo;
