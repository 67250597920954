import { Spinner } from "react-bootstrap";

const Loader = ({ height }) => {
  return (
    <div style={{ height: height }}>
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  );
};
Loader.defaultProps = {
  height: "350px",
};
export default Loader;
