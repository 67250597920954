import React, { useState } from "react";
import Select from "react-select";
import {
  Button,
  Table,
  Container,
  Row,
  Col,
  Form,
  Modal,
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import QRCode from "qrcode.react";

//Header
import { v4 as uuidv4 } from "uuid";

import Header from "../common/header";
import Sidebar from "../common/sidebar";
import { apiGetMethod, apiPostMethod } from "../../api/rest";
import { useSelector } from "react-redux";
import { allApiUrl } from "../../api/apiRoute";
import toastMessages from "../toastMessages";
import { useEffect } from "react";
import CommonButton from "../common-ui/Button";
import Loader from "../shared/spinner";
import SidebarTwo from "../common/sidebarTwo";
const ViewQrCode = ({ show, handleClose, url, tokenAuth, qrData }) => {
  const [showMenu, setShowMenu] = useState(false);
  const handleCloseMenu = () => {
    // setIsQrCodeClicked(false)
    setShowMenu(false);
  }
  console.log('qrData', qrData)
  const handleShowMenu = () => setShowMenu(true);
  let urlData = `${url}menu-order?token=${tokenAuth}&&tableNumber=${qrData.tableNumber}`
  // &&label=${qrData.label}&&tablestatus=${qrData.tablestatus}&&location=${qrData.location};
  console.log("urlData", urlData)
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Table Reservation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="qrCodeWrap">
          <div className={`qrCode`} style={{ display: "flex", justifyContent: 'center' }}>
            <QRCode value={urlData} style={{ height: 200, width: 200 }} />
            {
              process.env.REACT_APP_ENVIRONMENT !== "production" &&
              <div>
                <Button
                  className="commonButton menuOrderButton"
                  onClick={handleShowMenu}
                >
                  See MENU & Order
                </Button>
                <Modal
                  className="commonModal restaurantMenuModal"
                  centered
                  backdrop="static"
                  keyboard={false}
                  show={showMenu}
                  onHide={handleCloseMenu}
                >
                  <Modal.Header closeButton></Modal.Header>
                  <Modal.Body>
                    <iframe src={urlData} title=""></iframe>
                  </Modal.Body>
                </Modal>
              </div>
            }
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};
function TableManagement() {
  const [show, setShow] = useState(false);
  const [qrData, setQrData] = useState({
    data: "",
    tableNumber: "",
  });
  const handleClose = () => {
    setQrData({
      // label: "",
      tableNumber: "",
      // tablestatus: "",
      // location: "",
    });
    setShow(false);
  };
  const handleShow = (data, index) => {
    console.log('data', data)
    let tableNumber = 0
    if (data.tablestatus) {
      tableNumber = data.tablenumber
    }
    setQrData({
      // label: data.label,
      tableNumber: tableNumber,
      // tablestatus: data.tablestatus,
      //location: data.location.label,
    });
    setShow(true);
  };
  const [loader, setLoader] = useState({
    reset: false,
    save: false,
  });
  const [tokenAuth, setTokenAuth] = useState("");

  const history = useHistory();
  const currBranch = useSelector((state) => state.counter.currBranch);
  const branchArray = useSelector((state) => state?.counter?.branchArray);
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  let developerOrganizationKey = useSelector(
    (state) => state.counter.organizationKey
  );

  let restaurant = developerOrganizationKey
    ? developerOrganizationKey?.split("#")[1]
    : userInfo?.PK.split("#")[1];

  let branches = branchArray.map((item) => item.value);
  const header = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    branchid: JSON.stringify(branches),
  };
  const [active, setActive] = React.useState(false);
  const [newValue, setNewValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState({
    tablecount: "1",
    layoutplan: Array.from({ length: 1 }).map(() => ({
      tablenumber: "",
      label: "",
      tablestatus: 0,
      location: "",
    })),
    layoutLocations: [],
  });
  // console.log('----form----37---', form)
  useEffect(() => {
    if (
      branchArray.length > 1 ||
      (branchArray && branchArray[0].value == "All")
    ) {
      history.push("/dashboard");
    }
    getAllTable();
  }, [currBranch.value, branchArray]);
  useEffect(() => {
    setForm((prev) => {
      const tableCount = parseInt(form.tablecount); // Parse tablecount as integer
      if (prev.layoutplan.length < tableCount) {
        // console.log('-----47------');
        const newTableCount = tableCount - prev.layoutplan.length;
        const newTables = Array.from({ length: newTableCount }).map(
          (_, index) => ({
            tablenumber: prev.layoutplan.length + index + 1,
            label: "",
            tablestatus: 0,
            location: "",
          })
        );
        prev.layoutplan = [...prev.layoutplan, ...newTables];
      } else {
        // console.log('-----57------', prev);
        prev.layoutplan = prev.layoutplan.map((table, index) => ({
          ...table,
          tablenumber: index + 1,
        }));
      }
      // console.log('prev', prev);
      return prev;
    });
  }, [form.tablecount]);
  async function getAllTable() {
    setIsLoading(true);
    try {
      const response = await apiGetMethod(
        `${allApiUrl.viewLayout}`,
        "",
        header
      );
      if (response.status === 200) {
        if (response.data.data.Items.length == 0) {
          //eslint-disable-line
          setForm({
            tablecount: "1",
            layoutplan: [
              {
                tablenumber: "1",
                label: "",
                location: "",
                tablestatus: 0,
              },
            ],
            layoutLocations: [],
          });
          // setIsLoading(false)
        } else {
          setForm(response.data.data.Items[0]);
          if (response.data.data.Items[0].layoutLocations) {
            // setIsLoading(false)
          }
        }
        setIsLoading(false);
        toastMessages("success", response.data.message);
      }
    } catch (err) {
      if (err.data.message !== "Please select specific branch") {
        toastMessages("error", err.data.message);
      }
      console.log("err", err);
    }
  }
  async function saveTableLayout(e, defaultState) {
    setIsLoading(true);
    e.preventDefault();
    if (defaultState) {
      setLoader({ ...loader, reset: true });
    } else {
      setLoader({ ...loader, save: true });
    }
    try {
      let updatedForm = {
        ...form,
        layoutplan: form.layoutplan.splice(0, parseInt(form.tablecount)),
        layoutLocations: form.layoutLocations.filter(
          (item) => item.value !== "AddNew"
        ),
      };
      const response = await apiPostMethod(
        allApiUrl.addTableLayout,
        defaultState ? defaultState : updatedForm,
        header
      );
      if (response.status === 200) {
        toastMessages("success", response.data.message);
        getAllTable();
        if (defaultState) {
          setLoader({ ...loader, reset: false });
        } else {
          setLoader({ ...loader, save: false });
        }
        // setIsLoading(false)
      }
    } catch (err) {
      if (defaultState) {
        setLoader({ ...loader, reset: false });
      } else {
        setLoader({ ...loader, save: false });
      }
      console.log("err", err);
      toastMessages("error", err.data.message);
      // setIsLoading(false)
    }
  }
  function titleCase(str) {
    var splitStr = str.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  }
  const decrement = (event, index) => {
    event.preventDefault();
    let backupData = [...form.layoutplan];
    backupData.splice(index, 1);
    backupData = backupData.map((table, index) => ({
      ...table,
      tablenumber: index + 1,
    }));
    setForm((prevData) => {
      return {
        ...prevData,
        layoutplan: backupData,
        tablecount: prevData.tablecount - 1,
      };
    });
  };
  const sortedOptions = form.layoutLocations.sort((a, b) =>
    a.value === "AddNew" ? 1 : -1
  );
  let sidebarToggle = useSelector((state) => state.counter.sidebarToggle);
  const [sidebarType, setSidebarType] = useState("singleSidebar");
  // const [isQrCodeClicked, setIsQrCodeClicked] = useState(false)
  let sidebarTypeRedux = useSelector((state) => state.counter.sidebarType);
  let url = "";
  if (process.env.REACT_APP_ENVIRONMENT == "development") {
    url = 'http://192.168.1.133:3000/'
  } else if (process.env.REACT_APP_ENVIRONMENT == "local") {
    // url = "https://cloudpos-modifier.softuvo.click/";
    url = 'https://cloudpos.softuvo.click/'
  } else {
    url = "https://portal.americloud.dev/";
  }
  useEffect(() => {
    getStripeKeys();
  }, []);
  async function getStripeKeys() {
    try {
      let data = {
        branchId: branches,
        restaurant: restaurant,
      };
      const response = await apiPostMethod(`${allApiUrl.keys}`, data, header);
      if (response.status === 200) {
        try {
          const response = await apiGetMethod(
            `${allApiUrl.linkCreate}`,
            "",
            header
          );
          if (response.status === 200) {
            let token = response.data.data.split("token=")[1];
            setTokenAuth(token);
          }
        } catch (err) {
          console.log("err", err);
        }
      }
    } catch (err) { }
  }
  return (
    <React.Fragment>
      <Header setActive={setActive} active={sidebarToggle} />
      <div className="interFaceWrap">
        <Sidebar active={sidebarToggle} activePage="table-management" />
        <div
          className={`interFaceWrap__inner ${sidebarTypeRedux == false ? "doubleSidebar" : "singleSidebar"
            }`}
        >
          <div className="commonOuter">
            {
              // isQrCodeClicked &&
              <ViewQrCode
                // setIsQrCodeClicked={setIsQrCodeClicked}
                show={show}
                setShow={setShow}
                handleClose={handleClose}
                url={url}
                tokenAuth={tokenAuth}
                qrData={qrData}
              />
            }
            <div className="pageHeader">
              <Container fluid className="px-0">
                <Row className="align-items-center">
                  <div className="col">
                    <div className="headingText headingText--textLeft mb-0">
                      <h1>Table Management</h1>
                    </div>
                  </div>
                  <div className="col-auto ms-auto onlyButtons">
                    <div className="pageHeader__elements">
                      <CommonButton
                        buttonName="Reset"
                        type="half"
                        loader={loader.reset}
                        onClick={(e) => {
                          let defaultState = {
                            tablecount: "1",
                            layoutplan: [
                              {
                                tablenumber: 1,
                                label: "",
                                tablestatus: 0,
                                location: "",
                              },
                            ],
                            layoutLocations: [],
                          };
                          saveTableLayout(e, defaultState);
                        }}
                      />
                      <CommonButton
                        buttonName="Save"
                        type="half"
                        loader={loader.save}
                        onClick={saveTableLayout}
                      />
                    </div>
                  </div>
                </Row>
              </Container>
            </div>
            <div className="selectEmployeeOpetions">
              <Row>
                <Col md={12}>
                  {isLoading ? (
                    <Loader />
                  ) : (
                    <div className="whiteBg">
                      <div className="noOfTables">
                        <Row className="align-items-center">
                          <div className="col">
                            <Form>
                              <Form.Group className="form-group group-flex">
                                <Form.Label>Number of Tables</Form.Label>
                                <Form.Control
                                  type="number"
                                  placeholder=""
                                  value={form?.tablecount}
                                  onChange={(e) => {
                                    // console.log('e.target.value', e.target.value)
                                    if (
                                      (e.target.value >= 1 &&
                                        e.target.value <= 100) ||
                                      !e.target.value
                                    ) {
                                      setForm({
                                        ...form,
                                        tablecount: e.target.value,
                                      });
                                    } else {
                                      toastMessages(
                                        "error",
                                        "Enter the number between 1 to 100 "
                                      );
                                    }
                                  }}
                                />
                              </Form.Group>
                            </Form>
                          </div>
                        </Row>
                      </div>
                      <div className="commonTable selectLocationTable">
                        <Table responsive>
                          <thead>
                            <tr>
                              <th>Table Number</th>
                              <th>Label</th>
                              <th>Location</th>
                              <th>Actions</th>
                              <th>Qr Code</th>
                            </tr>
                          </thead>
                          <tbody>
                            {form?.tablecount !== "" &&
                              [...Array(parseInt(form?.tablecount))].map(
                                (item, index) => {
                                  //eslint-disable-line
                                  return (
                                    <tr key={`${index}`}>
                                      <td>
                                        <Form.Group className="form-group">
                                          <Form.Control
                                            disabled
                                            type="number"
                                            placeholder=""
                                            value={index + 1}
                                          />
                                        </Form.Group>
                                      </td>
                                      <td>
                                        <Form.Group className="form-group">
                                          <Form.Control
                                            type="text"
                                            placeholder="Label"
                                            onChange={(e) => {
                                              setForm((prevData) => {
                                                prevData.layoutplan[
                                                  index
                                                ].label = e.target.value;
                                                return {
                                                  ...prevData,
                                                };
                                              });
                                            }}
                                            value={
                                              form.layoutplan[index]?.label
                                            }
                                          />
                                        </Form.Group>
                                      </td>
                                      <td>
                                        <div className="addLocation">
                                          <Select
                                            className="select2 singleSelect"
                                            onInputChange={(e) =>
                                              setNewValue(e)
                                            }
                                            placeholder="Select Location"
                                            noOptionsMessage={({
                                              inputValue,
                                            }) =>
                                              !inputValue ? (
                                                "No Options "
                                              ) : (
                                                <Button
                                                  onClick={() => {
                                                    if (newValue !== "") {
                                                      setForm((prevData) => {
                                                        const updatedLocations =
                                                          [
                                                            ...prevData.layoutLocations,
                                                            {
                                                              value:
                                                                titleCase(
                                                                  newValue
                                                                ),
                                                              label:
                                                                titleCase(
                                                                  newValue
                                                                ),
                                                            },
                                                          ];
                                                        return {
                                                          ...prevData,
                                                          layoutLocations:
                                                            updatedLocations,
                                                        };
                                                      });
                                                      setForm((prevData) => {
                                                        prevData.layoutplan[
                                                          index
                                                        ].location = {
                                                          value:
                                                            titleCase(newValue),
                                                          label:
                                                            titleCase(newValue),
                                                        };
                                                        return {
                                                          ...prevData,
                                                        };
                                                      });
                                                    }
                                                    setNewValue("");
                                                  }}
                                                  type="button"
                                                  className="commonButton commonButton--grey commonButton--small"
                                                >
                                                  <span
                                                    style={{
                                                      textAlign: "left",
                                                    }}
                                                  >
                                                    Add New
                                                  </span>{" "}
                                                  <i className="fa-solid fa-plus"></i>
                                                </Button>
                                              )
                                            }
                                            options={sortedOptions}
                                            value={
                                              form.layoutplan[index]?.location
                                            } //eslint-disable-line
                                            onChange={(e) => {
                                              setForm((prevData) => {
                                                prevData.layoutplan[
                                                  index
                                                ].location = e;
                                                return {
                                                  ...prevData,
                                                };
                                              });
                                            }}
                                            labelledBy="Select"
                                          />
                                        </div>
                                      </td>
                                      <td>
                                        <div className="actionElements">
                                          <Form>
                                            <Form.Check
                                              type="switch"
                                              name={`yesCheck-${index}${form.layoutplan[index]?.tablenumber}`}
                                              id={`yesCheck-${index}${form.layoutplan[index]?.tablenumber}`}
                                              label=""
                                              checked={
                                                form.layoutplan[index]
                                                  ?.tablestatus == 1
                                                  ? true
                                                  : false
                                              } //eslint-disable-line
                                              onChange={(e) => {
                                                setForm((prevData) => {
                                                  prevData.layoutplan[index].tablestatus = form.layoutplan[index]?.tablestatus == 1 ? 0 : 1;
                                                  return {
                                                    ...prevData,
                                                  };
                                                });
                                              }}
                                            />
                                          </Form>
                                          <Button
                                            type="button"
                                            className="delete"
                                            onClick={(event) => {
                                              if (form.tablecount > 1) {
                                                decrement(event, index);
                                              } else {
                                                toastMessages(
                                                  "error",
                                                  "Table Number cannot be Zero .Please disable Table Management from Settings."
                                                );
                                              }
                                            }}
                                          >
                                            <span>Delete</span>{" "}
                                            <i className="fa-solid fa-trash-can"></i>
                                          </Button>
                                        </div>
                                      </td>
                                      <td>
                                        {console.log('612', form.layoutplan[index].tablestatus)}
                                        <div className="actionElements">
                                          {form.layoutplan[index].tablestatus ?
                                            <button
                                              onClick={() => {
                                                // setIsQrCodeClicked(true)
                                                handleShow(
                                                  form.layoutplan[index]
                                                );
                                              }}
                                              className="edit btn"
                                            >
                                              <span>View</span>
                                            </button>
                                            : ''
                                          }
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                }
                              )}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  )}
                </Col>
                <Col xl={6} lg={4}></Col>
              </Row>
            </div>
          </div>
        </div>
        <SidebarTwo
          activePage=""
          setSidebarType={setSidebarType}
          sidebarType={sidebarType}
        />
      </div>
    </React.Fragment>
  );
}
export default TableManagement;
