import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
//CSS
import "../common/sidebar/sidebar.scss";
//Icons
// import chartPieIcon from "../../components/shared/assets/sidebar/chart-pie.svg";
import profileIcon from "../../components/shared/assets/sidebar/profile.svg";
import branchesIcon from "../../components/shared/assets/sidebar/branches.svg";
import CustomTooltip from "../common/tooltip";
import { titleCase } from "../../features/helper";

function DeveloperSidebar(props) {
  const history = useHistory();
  const [currTab, setCurrTab] = useState("");
  const [darkMode, setDarkMode] = React.useState(
    JSON.parse(localStorage.getItem("darkMode")) ? JSON.parse(localStorage.getItem("darkMode")) : false
  );
  let sidebarList = [
    //{
    //label: 'Add Organization', url: 'add-Organization', imgSrc: chartPieIcon
    //}
    , {
      label: 'Organizations', url: 'organizations', imgSrc: branchesIcon
    },
    {
      label: 'Profile', url: `profile?developerMode=true`, imgSrc: profileIcon
    }
  ]
  useEffect(() => {
    if (localStorage.getItem("darkMode") === null) {
      setDarkMode(false)
    }
  }, []);
  useEffect(() => {
    if (localStorage.getItem("darkMode") !== undefined) {
      localStorage.setItem("darkMode", false);
    }
  }, [darkMode]);
  useEffect(() => {
    localStorage.setItem("darkMode", darkMode);
  }, [darkMode]);
  useEffect(() => {
    const body = document.body;
    const toggle = document.querySelector(".toggle-inner");
    if (darkMode === true) {
      body.classList.add("dark-mode");
      toggle.classList.add("toggle-active");
    } else {
      body.classList.remove("dark-mode");
      toggle.classList.remove("toggle-active");
    }
  }, [darkMode]);
  useEffect(() => {
    setCurrTab(props.activePage);
  }, []);
  function activTabHandler(value) {
    setCurrTab(value);
    history.push(`/${value}`);
  }
  var oneMinutes = new Date(new Date().getTime() + 1 * 60 * 1000); //for 30 Second
  function darkModeHandler(mode) {
    // Cookies.set('darkMode', mode.toString(), { expires: oneMinutes })
    if (mode) {
      setDarkMode(true)
    } else {
      setDarkMode(false)
    }
  }
  // console.log('props.active---------58-----', props.active)
  return (
    <div className={`sidebar ${props.active ? "slideToggle" : ""}`}>
      <div className="sidebar__list">
        <ul>
          {sidebarList && sidebarList.map((item, index) => {
            // console.log('item.url', item.url.split('?')[0])
            return (
              <li onClick={() => activTabHandler(item.url)} key={`${index}`}>
                <Link className={currTab === item.url ? "active" : ''} to="/#">
                  {props.active ? <CustomTooltip tooltipText={props.active ? titleCase(item.url.split('?')[0]) : ''} placement="right">
                    <img src={item.imgSrc} alt="chartPieIcon" />
                  </CustomTooltip> : <img src={item.imgSrc} alt="chartPieIcon" />}
                  <span>{item.label}</span>
                </Link>
              </li>
            )
          })}
        </ul>
      </div>
      <div className="darkModeToggle">
        <h4>Dark Mode</h4>
        <div id="toggle" onClick={() => (darkMode === false) ?
          darkModeHandler(true) :
          darkModeHandler(false)}
        >
          <div className="toggle-inner" />
        </div>
      </div>
    </div>
  );
}
export default DeveloperSidebar;