import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from "query-string";
//Header
import Header from '../common/header';
import Sidebar from '../common/sidebar';
import { apiGetMethod, apiPutMethod } from "../../api/rest";
import { allApiUrl } from "../../api/apiRoute";
import toastMessages from "../toastMessages";
import CommonButton from "../common-ui/Button";
import { c, smallStyle } from "../../features/helper";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { countNumberInPhoneNumber } from "../../features/helper";
import { BranchFormField } from "../branches";
import Loader from "../shared/spinner";
import { useSelector, useDispatch } from "react-redux";
import { changeTableManagement, changeWebsiteTemplate, saveBranchArray, toggleDigitalMenu } from "../../features/todoSlice";
import SidebarTwo from "../common/sidebarTwo";
function EditBranch() {
    let dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation();
    let branchArray = useSelector((state) => state.counter.branchArray);
    let branches = branchArray.map((item) => item.value);
    let { id } = queryString.parse(location.search);
    const [form, setForm] = useState({
        branchname: "",
        address: "",
        city: "",
        stateId: "",
        id: id,
        country: "",
        zipcode: "",
        countrycode: "+1",
        phone: "",
        file: "",
        permissionsgranted: {
            tablemanagement: 0,
            digitalmenu: 0,
            allowedordertype: [],
            kitchendisplayapp: 0,
            sendtokitchen: 0,
            manualsettlement: 0,
            paymenttype: [],
            cashdrawer: 0,
            websitetemplate: 0,
            printer: 0,
            cardreader: 0
        }
    })
    console.log('form 53', form)
    const [isLoading, setIsLoading] = useState(true)
    const [currForm, setCurrForm] = useState([])
    const [active, setActive] = React.useState(false)
    const [loader, setLoader] = useState(false)
    const [preview, setPreview] = useState([]);
    const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(false);
    const [phoneValidatError, setPhoneValidError] = useState(false);
    const [isCategoryPageRefreshed, setIsCategoryPageRefreshed] = useState(false)
    const [permissionData, setPermissionData] = useState({})
    const [errorInput, setErrorInput] = useState({
        branchname: false,
        city: false,
        stateId: false,
        country: false,
        zipcode: false,
        phone: false,
        address: false
    })
    useEffect(() => {
        getBranchDetail()
        getPermission()
        getOrderData()
        // if ((branchArray.length > 1) || (branchArray && branchArray[0].value == "All")) {
        //     history.push('/dashboard')
        // }
    }, [branchArray])
    useEffect(() => {
        getDisableStatus()
    }, [permissionData])
    const [permissionType, setPermissionType] = useState([])
    const [orderType, setOrderType] = useState([])

    async function getOrderData() {
        const header = {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        };
        try {
            const response = await apiGetMethod(`${allApiUrl.orderTypes}`, "", header);
            if (response.status == 200) {
                let orderType = response.data.data.map((item) => {
                    return {
                        label: item.orderType,
                        value: item.orderTypeId,
                    }
                })
                setOrderType(orderType)
            }
        } catch (err) {
            console.log('err', err)
        }
    }
    async function getPermission() {
        const header = {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        };
        try {
            const response = await apiGetMethod(`${allApiUrl.paymentTypes}`, "", header);
            if (response.status == 200) {
                let permissionType = response.data.data.map((item) => {
                    return {
                        label: item.paymentType,
                        value: item.paymentTypeId,
                    }
                })
                setPermissionType(permissionType)
            }
        } catch (err) {
            console.log('err', err)
        }
    }
    async function getDisableStatus() {
        const header = {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        };
        try {
            const response = await apiGetMethod(allApiUrl.viewSetting, "", header);
            if (response.status == 200) {
                if (response.data.Items.length != 0) {
                    //for table managment sidebar toggle functionality.
                    console.log('permissionData_810', permissionData, permissionData.tablemanagement == 0
                        // , permissionData.tablemanagement && permissionData.tablemanagement == 0
                    )
                    if (permissionData.tablemanagement == 0) {
                        console.log('812')
                        dispatch(changeTableManagement(false));
                    }
                    else {
                        if (response.data.Items[0].GeneralSettings.tablemanagement == 1) {
                            console.log('817')
                            dispatch(changeTableManagement(true));
                        } else {
                            console.log('820')
                            dispatch(changeTableManagement(false));
                        }
                    }
                    // for website template
                    if (permissionData.websitetemplate == 0) {
                        dispatch(changeWebsiteTemplate(false));
                    }
                    else {
                        if (response.data.Items[0].WebsiteTemplate.enableWebsiteTemplate == 1) {
                            //eslint-disable-line
                            dispatch(changeWebsiteTemplate(true));
                        } else {
                            dispatch(changeWebsiteTemplate(false));
                        }
                    }

                }
            }
        } catch (err) {
            console.log("err", err);
        }
    }
    // const branchArray = useSelector((state) => state?.counter?.branchArray)
    const isInitialRender = useRef(true);
    // useEffect(() => {
    //     if (isInitialRender.current) {
    //         isInitialRender.current = false;
    //     } else {
    //         history.push('/stores');
    //     }
    // }, [branchArray])
    async function getBranchDetail() {
        const header = {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        };
        try {
            const response = await apiGetMethod(allApiUrl.viewBranch, `/${id}`, header);
            if (response.status === 200) {
                console.log('response.data.data.Items[0].permissionsgranted)', !response.data.data.Items[0].permissionsgranted)
                if (!response.data.data.Items[0].permissionsgranted) {
                    let permissionsgranted = {
                        digitalmenu: 0,
                        tablemanagement: 0,
                        manualsettlement: 0,
                        sendtokitchen: 0,
                        websitetemplate: 0,
                        cashdrawer: 0,
                        kitchendisplayapp: 0,
                        printer: 0,
                        cardreader: 0,
                        allowedordertype: [],
                        paymenttype: []
                    }
                    setForm({
                        ...response.data.data.Items[0], id: parseInt(id),
                        permissionsgranted: permissionsgranted,
                        country: {
                            value: response.data.data.Items[0].country,
                            label: response.data.data.Items[0].country
                        },
                    })
                }
                else {
                    // c('permissionsgranted', form.permissionsgranted, response.data.data.Items[0].permissionsgranted)
                    let permissionsgranted = { ...form.permissionsgranted, ...response.data.data.Items[0].permissionsgranted }
                    // c('permissionsgranted', permissionsgranted)
                    setForm({
                        ...response.data.data.Items[0], id: parseInt(id),
                        permissionsgranted,
                        country: {
                            value: response.data.data.Items[0].country,
                            label: response.data.data.Items[0].country
                        },
                    })
                }
                setCurrForm(response.data.data.Items[0])
                toastMessages("success", response.data.message);
                setIsCategoryPageRefreshed(!isCategoryPageRefreshed)
                setPreview(response.data.data.Items[0].image.banner)
                setIsLoading(false)
            }
        } catch (err) {
            setIsLoading(false)
        }
    }
    console.log('form---->', form)
    const tableManagement = useSelector((state) => state.counter.disableTableManagment)
    async function editBranchHandler(e) {
        e.preventDefault()
        setLoader(true)
        const header = {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        };
        try {
            if (form.branchname && form.address && form.city && form.zipcode && form.phone && form.country) {
                const { SK, PK, ...formData } = form;
                const formDataUpdated = new FormData();
                const fields = ["branchname", "address", "city", "stateId", "country", "zipcode", "phone", "file", 'deleteImage'];
                // fields.forEach(field => {
                //     if (formData[field] !== currForm[field]) {
                //         if (field === "city") {
                //             formDataUpdated.append(field, formData[field].toLowerCase());
                //         } else if (field === "phone") {
                //             formDataUpdated.append(field, formData[field]);
                //         } else if (field === "country") {
                //             formDataUpdated.append(field, formData[field].value);
                //         } else {
                //             formDataUpdated.append(field, formData[field]);
                //         }
                //     }
                // });
                formDataUpdated.append("branchname", formData.branchname);
                formDataUpdated.append("address", formData.address);
                formDataUpdated.append("city", formData.city.toLowerCase());
                formDataUpdated.append("stateId", formData.stateId);
                formDataUpdated.append("country", formData.country.value);
                formDataUpdated.append("zipcode", formData.zipcode);
                formDataUpdated.append("countrycode", formData.countrycode);
                formDataUpdated.append("phone", formData.phone);
                formDataUpdated.append('permissionsgranted', JSON.stringify(formData.permissionsgranted))
                formDataUpdated.append('deleteImage', typeof formData.deleteImage == "undefined" ? 0 : formData.deleteImage)
                if (formData.file) {
                    formDataUpdated.append("file", formData.file);
                }
                // console.log('formData.permissionsgranted.tablemanagement', formData.permissionsgranted.tablemanagement)
                // if table management permission is disabled then it will not check tableManagement redux value
                // if (!formData.permissionsgranted.tablemanagement) {
                //     dispatch(changeTableManagement(false));
                // } else {
                //     dispatch(changeTableManagement(true));
                // }
                //disable digital-menu 
                // if (!formData.permissionsgranted.digitalmenu) {
                //     dispatch(changeTableManagement(false));
                // } else {
                //     dispatch(changeTableManagement(true));
                // }
                // console.log('permissionsgranted', formData.permissionsgranted)
                const response = await apiPutMethod(
                    allApiUrl.update_branch,
                    formDataUpdated,
                    header,
                    id
                );
                if (response.status === 200) {
                    toastMessages("success", "Store Updated Successfully");
                    if (currForm.SK == (branchArray && branchArray[0].value)) {
                        dispatch(saveBranchArray([{ value: currForm.SK, label: form.branchname }]))
                    }
                    // if (!form.permissionsgranted.tablemanagement) {
                    //     dispatch(changeTableManagement(false))
                    // } else {
                    //     dispatch(changeTableManagement(true))
                    // }
                    // if (!form.permissionsgranted.digitalmenu) {
                    //     dispatch(toggleDigitalMenu(false))
                    // } else {
                    //     dispatch(toggleDigitalMenu(true))
                    // }
                    setForm({
                        branchname: "",
                        address: "",
                        city: "",
                        stateId: "",
                        id: id,
                        country: "",
                        zipcode: "",
                        countrycode: "+1",
                        phone: "",
                        file: "",
                        permissionsgranted: {
                            tablemanagement: 0,
                            digitalmenu: 0,
                            allowedordertype: [],
                            kitchendisplayapp: 0,
                            sendtokitchen: 0,
                            manualsettlement: 0,
                            paymenttype: [],
                            cashdrawer: 0,
                            websitetemplate: 0
                        }
                    })
                    setTimeout(() => {
                        history.push('/stores')
                    }, 1000)
                    setLoader(false);
                }
            }
            else {
                setLoader(false)
                const emptyFields = [];
                for (const property in form) {
                    if (form[property] === '') {
                        emptyFields.push(property)
                    }
                }
                const errorInputCopy = { ...errorInput };
                emptyFields.forEach(field => errorInputCopy[field] = true);
                setErrorInput(errorInputCopy);
                toastMessages("error", "Please fill the required fields")
            }

        } catch (err) {
            setLoader(false);
            console.log("err", err);
            if (err.data.message == "Session Expired.") {   //eslint-disable-line
                localStorage.clear();
                history.push('/login')
            }
            toastMessages("error", err.data.message);
        }
    }
    const handleOnChange = (value, country) => {
        setForm({ ...form, phone: value });
        const result = countNumberInPhoneNumber(country.format, ".");
        if (value.length - country.dialCode.length == result - country.dialCode.length || (result - country.dialCode.length) > 10) {
            setIsPhoneNumberValid(true);
        } else {
            setIsPhoneNumberValid(false);
            setPhoneValidError(`The Phone Number should be of ${result - country.dialCode.length} Digit`);
        };
    };
    let props = {
        form,
        setForm,
        smallStyle,
        errorInput,
        setErrorInput,
        handleOnChange,
        setPreview,
        preview,
        phoneValidatError,
        isPhoneNumberValid,
        permissionType,
        orderType
    }
    const [sidebarType, setSidebarType] = useState('singleSidebar')
    let sidebarToggle = useSelector((state) => state.counter.sidebarToggle)
    let sidebarTypeRedux = useSelector((state) => state.counter.sidebarType)

    return (
        <React.Fragment>
            <Header
                setActive={setActive}
                active={sidebarToggle}
                isCategoryPageRefreshed={isCategoryPageRefreshed}
                page="stores"
            />
            <div className="interFaceWrap">
                <Sidebar active={sidebarToggle} activePage="stores" permissionData={permissionData} />
                <div className={`interFaceWrap__inner ${sidebarTypeRedux == false ? "doubleSidebar" : "singleSidebar"}`}>
                    <div className="commonOuter">
                        <div className="pageHeader">
                            <Container fluid className="px-0">
                                <Row className="align-items-center">
                                    <div className="col">
                                        <div className="headingText headingText--textLeft mb-0">
                                            <h1>Edit Store</h1>
                                        </div>
                                    </div>
                                    <div className="col-auto ms-auto onlyButtons">
                                        <div className="pageHeader__elements">
                                            <Button type="button" className="commonButton commonButton--delete" onClick={() => history.push('/stores')}>Cancel</Button>
                                            <CommonButton
                                                buttonName="Update"
                                                type="half"
                                                onClick={editBranchHandler}
                                                loader={loader}
                                            />
                                        </div>
                                    </div>
                                </Row>
                            </Container>
                        </div>
                        <div className="selectEmployeeOpetions">
                            <Row>
                                <Col xxl={7} xl={8} lg={12}>
                                    <div className="whiteBg">
                                        {isLoading ? <Loader /> : <BranchFormField {...props} />}
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
                <SidebarTwo
                    activePage="stores"
                    setPermissionData={setPermissionData}
                    setSidebarType={setSidebarType}
                    sidebarType={sidebarType} />
            </div>
        </React.Fragment>
    )
}
export default EditBranch;