import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Button, Table, Container, Row, Form, FloatingLabel, InputGroup, Pagination, Spinner, Col } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { allApiUrl } from '../../api/apiRoute';
import { apiDeleteMethod, apiGetMethod, apiPostMethod, apiPutMethod } from '../../api/rest';
import Loader from '../shared/spinner';

import SidebarTwo from '../common/sidebarTwo';
//Header
import Header from './developerHeader';
import Sidebar from './developerSidebar';
import toastMessages from '../toastMessages';
import { v4 as uuidv4 } from 'uuid';
import CommonButton from '../common-ui/Button';
import { toast } from 'react-toastify';
import LoadingPage from '../loadingPage';
import { logoutHandlerFunctionality } from '../../features/todoSlice';

function Organization() {
    let dispatch = useDispatch()
    let branchArray = useSelector((state) => state.counter.branchArray)
    let sidebarToggle = useSelector((state) => state.counter.sidebarToggle)
    const history = useHistory()
    const [allCategoriesList, setAllCategoriesList] = useState([])
    const [lastEvaluatedKey, setLastEvaluatedKey] = useState('')
    const [loaderForFetchMore, setLoaderForFetchMore] = useState(true)
    const [loader, setLoader] = useState({
        active: false,
        delete: false,
        addOrganization: false
    })
    const [searchedValue, setSearchedValue] = useState('')
    const [isLoading, setIsLoading] = useState(true)
    const [deletedId, setDeletedId] = useState('')
    const [isPageRefreshed, setIsPageRefreshed] = useState(false)
    const [showLoadingPage, setShowLoadingPage] = useState(false)
    const [form, setForm] = useState({
        organizationname: "",
    });
    const [sidebarType, setSidebarType] = useState('singleSidebar')
    useEffect(() => {
        setIsLoading(true)
        getOrg()
    }, [isPageRefreshed])
    async function getOrg() {
        const header = {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        };
        try {
            const response = await apiGetMethod(`${allApiUrl.getAllOrganization}`, "", header);
            if (response.status === 200) {
                if (response.data.data.LastEvaluatedKey) {
                    setLastEvaluatedKey(response.data.data.LastEvaluatedKey.SK)
                }
                setAllCategoriesList(response.data.data.Items)
                setIsLoading(false)
                if (response.data.data.Items.length < 5) {
                    setLoaderForFetchMore(false)
                }
                else {
                    setLoaderForFetchMore(true)
                }
            }
        } catch (err) {
            setIsLoading(false)
            if (err.data.message == "Session Expired.") {
                await dispatch(logoutHandlerFunctionality())
                localStorage.clear();
                history.push('/login')
            }
            console.log("err", err);
        }
    }
    async function onSearchHandler(e) {
        setIsLoading(true)
        setSearchedValue(e.target.value)
        let branches = branchArray.map((item) => item.value)
        const header = {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            branchid: JSON.stringify(branches)
        };
        try {
            let data = {
                name: e.target.value,
            }
            const response = await apiPostMethod(allApiUrl.searchOrganization, data, header);
            if (response.status === 200) {
                if (response.data.message == "No records found.") {
                    setAllCategoriesList('');
                    setLoaderForFetchMore(false)
                    setLastEvaluatedKey('')
                }
                else {
                    setAllCategoriesList(response.data.data.Items);
                    if (response.data.data.LastEvaluatedKey) {
                        setLastEvaluatedKey(response.data.data.LastEvaluatedKey.SK)
                    }
                }
                setIsLoading(false)
            }
        } catch (err) {
            // toastMessages("error", err.data.message);
            setIsLoading(false)
            console.log("err", err);
        }
    }
    const fetchMoreData = async () => {
        if (lastEvaluatedKey) {
            setLoaderForFetchMore(true)
            let branches = branchArray.map((item) => item.value)
            const header = {
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                branchid: JSON.stringify(branches)
            };
            try {
                let start = ''
                if (lastEvaluatedKey) {
                    start = `&startKey=${lastEvaluatedKey}`
                }
                const response = await apiGetMethod(`${allApiUrl.getAllOrganization}?${start}`, "", header);
                if (response.status === 200) {
                    if (response.data.data.Items.length < 1) {
                        setLoaderForFetchMore(false)
                    }
                    setAllCategoriesList(prevItems => prevItems.concat(response.data.data.Items));
                    if (response.data.data.LastEvaluatedKey) {
                        setLastEvaluatedKey(response.data.data.LastEvaluatedKey.SK)
                    }
                    else {
                        setLastEvaluatedKey('')
                    }
                    toastMessages("success", response.data.message);
                }
            } catch (err) {
                toastMessages("error", err.data.message);
                if (err.data.message == "Session Expired." || err.data.message == "No such user found") {
                    localStorage.clear();
                    history.push('/login')
                }
                console.log("err", err);
            }
        }
        else {
            setLoaderForFetchMore(false)
        }
    };
    async function updateActiveStatus(e, value, organizationKey, type) {

        if (type == "delete") {
            setLoader({ ...loader, delete: true })
            setDeletedId(value)
        }
        if (type == "update") {
            setLoader({ ...loader, active: true })
        }
        const header = {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        };
        try {
            const data = {}
            if (type == "delete") {
                data['isdeleted'] = 1
                data["organizationKey"] = organizationKey
            }
            else {
                data["organizationKey"] = organizationKey
                data["organizationstatus"] = e.target.checked == true ? 1 : 0
            }
            const response = await apiPutMethod(allApiUrl.updateOrganization, data, header, value)
            if (response.status === 200) {
                setIsPageRefreshed(!isPageRefreshed)
                setLoader({
                    delete: false,
                    active: false
                })
                toastMessages("success", response.data.message)
            }
        }
        catch (err) {
            setLoader({
                delete: false,
                active: false
            })
            setIsPageRefreshed(!isPageRefreshed)
            toastMessages("error", err.data.message);
        }
    }
    async function onSubmit(e) {
        e.preventDefault();
        let header = {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        };
        setLoader({ ...loader, addOrganization: true });
        try {
            if (form.organizationname) {
                const response = await apiPostMethod(allApiUrl.createOrganization, form, header);
                if (response.status === 200) {
                    setLoader({ ...loader, addOrganization: false });
                    setForm({
                        organizationname: '',
                    });
                    setIsPageRefreshed(!isPageRefreshed)
                    toastMessages("success", "Organization Created Successfully");
                }
            }
            else {
                toastMessages("error", "Organization name is required")
                setLoader({ ...loader, addOrganization: false });
            }
        } catch (err) {
            setLoader({ ...loader, addOrganization: false });
            toastMessages("error", err.data.message);
        }
    }
    // console.log('sidebarType======>', sidebarType)
    let sidebarTypeRedux = useSelector((state) => state.counter.sidebarType)
    // console.log('======221======', sidebarTypeRedux)
    return (
        <React.Fragment>
            {showLoadingPage ? <LoadingPage /> :
                <>
                    <Header
                        active={sidebarToggle}
                        page="organizations"
                        isPageRefreshed={isPageRefreshed}
                        showLoadingPage={showLoadingPage}
                        setShowLoadingPage={setShowLoadingPage}
                    />
                    <div className="interFaceWrap">
                        <Sidebar active={sidebarToggle} activePage="organizations" />
                        <div className={`interFaceWrap__inner ${sidebarTypeRedux == false ? "doubleSidebar" : "singleSidebar"}`}>
                            <div className="commonOuter">
                                <div className="pageHeader">
                                    <Container fluid className="px-0">
                                        <Row className="align-items-center">
                                            <div className="col">
                                                <div className="headingText headingText--textLeft mb-0">
                                                    <h1>Add Organization</h1>
                                                </div>
                                            </div>
                                            <div className="col-auto ms-auto onlyButtons">
                                                <div className="pageHeader__elements">
                                                    <CommonButton
                                                        buttonName="Save"
                                                        type="Width"
                                                        loader={loader.addOrganization}
                                                        onClick={(e) => onSubmit(e)}

                                                    />
                                                </div>
                                            </div>
                                        </Row>
                                    </Container>
                                </div>
                                <div className="selectEmployeeOpetions">
                                    <Row>
                                        <Col xxl={7} xl={8} lg={12}>
                                            <div className="whiteBg">
                                                <div className="selectModifierOpetions">
                                                    <Row>
                                                        <Col xl={6} lg={8}>
                                                            <Form>
                                                                <Form.Group className="form-group">
                                                                    <Form.Label>Organization Name<sup>*</sup></Form.Label>
                                                                    <Form.Control
                                                                        required
                                                                        className="formField"
                                                                        type="text"
                                                                        value={form.organizationname}
                                                                        onKeyDown={(e) => {
                                                                            if (e.code === 'Enter') {
                                                                                e.preventDefault()
                                                                            }
                                                                        }}
                                                                        onChange={(e) => {
                                                                            // const alphabetRegex = /^[a-zA-Z0-9 -!@#$%^&*(),.?":{}|<>]*$/;
                                                                            // if (e.target.value.match(alphabetRegex)) {
                                                                            if (e.target.value.length <= 59) {
                                                                                setForm({
                                                                                    ...form,
                                                                                    organizationname: e.target.value,
                                                                                });
                                                                            }
                                                                            // }
                                                                        }}
                                                                    />
                                                                </Form.Group>
                                                            </Form>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xl={6} lg={4}></Col>
                                    </Row>
                                </div>
                                <div>
                                    <div className="headingText headingText--dflex headingText--textLeft headingText--border">
                                        <h4>All Organizations</h4>
                                        <Form className="searchBarForm">
                                            <InputGroup className="searchBar">
                                                <InputGroup.Text id="searchOrders"><i className="fa-solid fa-magnifying-glass"></i></InputGroup.Text>
                                                <FloatingLabel controlId="floatingInput" label="Search Organization Name">
                                                    <Form.Control type="search" placeholder="Search" onKeyDown={(e) => {
                                                        if (e.code === 'Enter') {
                                                            e.preventDefault()
                                                        }
                                                    }} onChange={onSearchHandler} value={searchedValue} />
                                                </FloatingLabel>
                                            </InputGroup>
                                        </Form>
                                    </div>
                                    <div className="commonTable">
                                        <div className="whiteBg">
                                            <InfiniteScroll
                                                dataLength={allCategoriesList.length}
                                                next={fetchMoreData}
                                                hasMore={loaderForFetchMore}
                                                className="equalHeightTable">
                                                <div className="tableFixHead">
                                                    {isLoading ? <Loader /> : <Table>
                                                        <thead>
                                                            <tr key={uuidv4()}>
                                                                <th>S.No.</th>
                                                                <th><div className='display_flex'>
                                                                    Organization Name
                                                                    {/* <SortingComponent field='name' sortingType={sortingTypeName} /> */}
                                                                </div></th>
                                                                {/* <th>Username</th> */}
                                                                <th>Actions</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {allCategoriesList && allCategoriesList.length > 0 ? allCategoriesList.map((item, index) => {
                                                                return (
                                                                    <tr key={item.SK}>
                                                                        <td>#{index + 1}</td>
                                                                        <td style={{ textTransform: 'capitalize' }}><div className="organizationName">{item.organizationname}</div></td>
                                                                        {/* <td><div className="descriptionText">{item.username}</div></td> */}
                                                                        <td>
                                                                            <div className="actionElements" >
                                                                                <Form >
                                                                                    <Form.Check type="switch" id="custom-switch" label=""
                                                                                        checked={item.organizationstatus == 1 ? true : false} //eslint-disable-line
                                                                                        onChange={(e) => updateActiveStatus(e, item.SK, item.organizationKey, "update")}

                                                                                    />
                                                                                </Form>
                                                                                <button onClick={() => history.push(`/editOrganization?id=${item.SK}&organizationKey=${item.organizationKey.split("#")[1]}`)} className="edit btn" ><span>Edit</span> <i className="fa-solid fa-pen-to-square"></i></button>
                                                                                <Button type="button" className="delete" onClick={() => updateActiveStatus(true, item.SK, item.organizationKey, "delete")}>
                                                                                    <>
                                                                                        {
                                                                                            deletedId == item.SK && //eslint-disable-line
                                                                                                loader.delete ? <Spinner
                                                                                                as="span"
                                                                                                className="delete"
                                                                                                animation="border"
                                                                                                size="sm"
                                                                                                role="status"
                                                                                                aria-hidden="true"
                                                                                            /> : <><span>Delete</span> <i className="fa-solid fa-trash-can"></i></>}
                                                                                    </></Button>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }) :
                                                                <tr key={uuidv4()}><td colSpan={3} className='text-center'>No Organization Available</td></tr>
                                                            }
                                                        </tbody>
                                                    </Table>}


                                                </div>
                                            </InfiniteScroll>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <SidebarTwo
                            activePage=""
                            setSidebarType={setSidebarType}
                            sidebarType={sidebarType}
                            isPageRefreshed={isPageRefreshed}
                            showLoadingPage={showLoadingPage}
                            setShowLoadingPage={setShowLoadingPage}
                        />
                    </div>
                </>
            }


        </React.Fragment >
        // </LoadingRoute>

    )
}
export default Organization;
