import React, { useRef } from "react";
import {
    StandaloneSearchBox,
    useJsApiLoader,
} from "@react-google-maps/api";
const PlaceComponent = () => {
    const inputRef = useRef();
    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
        libraries: ['places'],
    });

    const handlePlaceChanged = () => {
        const [place] = inputRef.current.getPlaces();
        if (place) {
            console.log(place.formatted_address);
            console.log(place.geometry.location.lat());
            console.log(place.geometry.location.lng());
        }
    };
    return (
        isLoaded && (
            <StandaloneSearchBox
                onLoad={(ref) => (inputRef.current = ref)}
                onPlacesChanged={handlePlaceChanged}
            >
                <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Location"
                />
            </StandaloneSearchBox>
        )
    );
};

export default PlaceComponent;