import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Button, Spinner, Table, Container, Row, Form, FloatingLabel, InputGroup } from 'react-bootstrap';
import dummyProduct from '../../components/shared/assets/images/dummyProduct.jpg'
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector } from 'react-redux';
import { BsArrowDownUp, BsArrowDown, BsArrowUp } from 'react-icons/bs';
import { Link, useHistory } from 'react-router-dom';
import { allApiUrl } from '../../api/apiRoute';
import { apiDeleteMethod, apiGetMethod, apiPostMethod, apiPutMethod } from '../../api/rest';
import Loader from '../shared/spinner';
//Header
import Header from '../common/header';
import Sidebar from '../common/sidebar';
import toastMessages from '../toastMessages';
import { v4 as uuidv4 } from 'uuid';
import SidebarTwo from '../common/sidebarTwo';
function Products() {
    const history = useHistory()
    const [permissionData, setPermissionData] = useState({})
    const branchArray = useSelector((state) => state.counter.branchArray)
    let branches = branchArray.map((item) => item.value)
    const currBranch = useSelector((state) => state.counter.currBranch)
    const [active, setActive] = React.useState(false)
    const [productList, setProductList] = useState([])
    const [lastEvaluatedKey, setLastEvaluatedKey] = useState('')
    const [loaderForFetchMore, setLoaderForFetchMore] = useState(true)
    const [isPageRefreshed, setIsPageRefreshed] = useState(false)
    const [loader, setLoader] = useState(false)
    const [deletedId, setDeletedId] = useState('')
    const [searchedValue, setSearchedValue] = useState('')
    const [sortingTypeIndex, setSortingTypeIndex] = useState("Up");
    const [sortingTypeName, setSortingTypeName] = useState("Up");
    const [sortingTypeDescription, setSortingTypeDescription] = useState("Up")
    const [isLoading, setIsLoading] = useState(true)
    const header = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        branchid: JSON.stringify(branches)
    };
    // console.log('----40----', branchArray)
    useEffect(() => {
        if (branchArray) {
            // console.log('---43----', branchArray)
            getAllProducts()
            if ((branchArray.length > 1)
                || (branchArray && branchArray[0].value == "All")) {
                history.push('/dashboard')
            }
            setSortingTypeIndex("Up");
            setSortingTypeName("Up");
            setSortingTypeDescription("Up")
        }
    }, [branchArray, isPageRefreshed])

    async function getAllProducts() {
        try {
            const response = await apiGetMethod(`${allApiUrl.view_all_product}`, "", header);
            if (response.status === 200) {
                if (response.data.categories.LastEvaluatedKey) {
                    setLastEvaluatedKey(response.data.categories.LastEvaluatedKey.SK)
                }
                setProductList(response.data.categories.Items)
                setIsLoading(false)
                // getAllCategories(response)

                if (response.data.categories.Items.length < 5) {
                    setLoaderForFetchMore(false)
                }
                else {
                    setLoaderForFetchMore(true)
                }
                toastMessages("success", response.data.message);
            }
        } catch (err) {
            if (err.data.message == "Session Expired.") { //eslint-disable-line
                localStorage.clear();
                history.push('/login')
            }
            console.log("err", err);
        }
    }
    async function updateActiveStatus(e, value) {
        const header = {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        };
        try {
            const data = {
                visibility: e.target.checked == true ? 1 : 0 //eslint-disable-line
            }
            const response = await apiPutMethod(
                allApiUrl.update_product,
                data,
                header, value)
            if (response.status === 200) {
                setIsPageRefreshed(!isPageRefreshed)
                toastMessages("success", response.data.message)
                setLoaderForFetchMore(true)
            }
        }
        catch (err) {
            setIsPageRefreshed(!isPageRefreshed)
            toastMessages("error", err.data.message);
        }
    }
    async function onDeleteHandler(id) {
        setDeletedId(id)
        setLoader(true)
        const header = {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        };
        try {
            const response = await apiDeleteMethod(allApiUrl.delete_product, id, header);
            if (response.status === 200) {
                setLoader(false)
                setIsPageRefreshed(!isPageRefreshed)
                toastMessages("success", response.data.message);
            }
        } catch (err) {
            setLoader(false)
            toastMessages("error", err.data.message);
            setIsPageRefreshed(!isPageRefreshed)
            console.log("err", err);
            if (err.data.message == "Session Expired.") { //eslint-disable-line
                localStorage.clear();
                history.push('/login')
            }
        }
    }
    const fetchMoreData = async () => {
        if (lastEvaluatedKey) {
            setLoaderForFetchMore(true)
            try {
                let start = ''
                if (lastEvaluatedKey) {
                    start = `&startKey=${lastEvaluatedKey}`
                }
                const response = await apiGetMethod(`${allApiUrl.view_all_product}?${start}`, "", header);
                if (response.status === 200) {
                    if (response.data.categories.Items.length < 1) {
                        setLoaderForFetchMore(false)
                    }
                    setProductList(prevItems => prevItems.concat(response.data.categories.Items));
                    // getAllCategories()
                    if (response.data.categories.LastEvaluatedKey) {
                        setLastEvaluatedKey(response.data.categories.LastEvaluatedKey.SK)
                    }
                    else {
                        setLastEvaluatedKey('')
                    }
                    toastMessages("success", response.data.message);
                }
            } catch (err) {
                toastMessages("error", err.data.message);
                if (err.data.message == "Session Expired.") { //eslint-disable-line
                    localStorage.clear();
                    history.push('/login')
                }
                console.log("err", err);
            }
        }
        else {
            setLoaderForFetchMore(false)
        }

    };
    async function onSearchHandler(e) {
        setSearchedValue(e.target.value)
        try {
            let data = {
                productname: e.target.value
            }
            const response = await apiPostMethod(allApiUrl.search_product, data, header);
            if (response.status === 200) {
                if (response.data.message == "No records found.") { //eslint-disable-line
                    setProductList('');
                    setLoaderForFetchMore(false)
                    setLastEvaluatedKey('')
                }
                else {
                    if (response.data.data.LastEvaluatedKey) {
                        setLastEvaluatedKey(response.data.data.LastEvaluatedKey.SK)
                    }
                    setProductList(response.data.data.Items);
                }
            }
        } catch (err) {
            toastMessages("error", err.data.message);
            console.log("err", err);
        }
    }
    function titleCase(str) {
        var splitStr = str.toLowerCase().split(' ');
        // console.log('splitstr', splitStr)
        for (var i = 0; i < splitStr.length; i++) {
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        return splitStr.join(' ');
    }
    const SortingComponent = ({ field, sortingType }) => {
        return (
            <div className="pointer">
                {sortingType === "Up" ? (
                    <BsArrowUp onClick={() => handleSorting("Down", field)} />
                ) : (
                    <BsArrowDown onClick={() => handleSorting("Up", field)} />
                )}
            </div>
        );
    };

    function handleSorting(arrow, field) {
        if (field === "index") {
            // setSortingTypeIndex(arrow);
            // const sortedData = allEmployeeList.slice().reverse();
            // setAllEmployeeList(sortedData);
        }
        else if (field === "name") {
            const newSortingType = sortingTypeName === "Down" ? "Up" : "Down";
            setSortingTypeName(newSortingType);
            const sortedItems = [...productList].sort((a, b) => {
                if (a.productname < b.productname) {
                    return newSortingType === "Up" ? -1 : 1;
                }
                if (a.productname > b.productname) {
                    return newSortingType === "Up" ? 1 : -1;
                }
                return 0;
            });
            setProductList(sortedItems);
        }
        // else if (field === "description") {
        //     const newSortingTypeDescription = sortingTypeDescription === "Down" ? "Up" : "Down";
        //     setSortingTypeDescription(newSortingTypeDescription);
        //     const sortedItems = [...allCategoriesList].sort((a, b) => {
        //         if (a.description < b.description) {
        //             return newSortingTypeDescription === "Up" ? -1 : 1;
        //         }
        //         if (a.description > b.description) {
        //             return newSortingTypeDescription === "Up" ? 1 : -1;
        //         }
        //         return 0;
        //     });
        //     setAllCategoriesList(sortedItems);
        // }
    }
    let sidebarToggle = useSelector((state) => state.counter.sidebarToggle)
    const [sidebarType, setSidebarType] = useState('singleSidebar')
    let sidebarTypeRedux = useSelector((state) => state.counter.sidebarType)
    return (
        <React.Fragment>
            <Header setActive={setActive} active={sidebarToggle} />
            <div className="interFaceWrap">
                <Sidebar active={sidebarToggle} activePage="products" permissionData={permissionData} />
                <div className={`interFaceWrap__inner ${sidebarTypeRedux == false ? "doubleSidebar" : "singleSidebar"}`}>
                    <div className="commonOuter">
                        <div className="pageHeader">
                            <Container fluid className="px-0">
                                <Row className="align-items-center">
                                    <div className="col">
                                        <div className="headingText headingText--textLeft mb-0">
                                            <h1>Products</h1>
                                        </div>
                                    </div>
                                    <div className="col-auto ms-auto">
                                        <div className="pageHeader__elements">
                                            <Form className="searchBarForm">
                                                <InputGroup className="searchBar">
                                                    <InputGroup.Text id="searchOrders"><i className="fa-solid fa-magnifying-glass"></i></InputGroup.Text>
                                                    <FloatingLabel controlId="floatingInput" label="Search Product Name">
                                                        <Form.Control type="search" placeholder="Search Product Name" value={searchedValue} onKeyDown={(e) => {
                                                            if (e.code === 'Enter') {
                                                                e.preventDefault()
                                                            }
                                                        }} onChange={onSearchHandler} />
                                                    </FloatingLabel>
                                                </InputGroup>
                                            </Form>
                                            <Link to="/add-product" className="commonButton">Add Product</Link>
                                        </div>
                                    </div>
                                </Row>
                            </Container>
                        </div>
                        <div className="commonTable">
                            <div className="whiteBg">
                                <div className="headingText headingText--dflex headingText--textLeft headingText--border">
                                    <h4>All Products</h4>
                                </div>
                                <InfiniteScroll
                                    dataLength={productList.length}
                                    next={fetchMoreData}
                                    hasMore={loaderForFetchMore}
                                    className="equalHeightTable">
                                    <div className="tableFixHead">
                                        {!productList.length && isLoading ? <Loader /> : <Table>
                                            <thead>
                                                <tr key={uuidv4()}>
                                                    <th>S.No.</th>
                                                    <th>Product Image</th>
                                                    <th>
                                                        <div className='display_flex'>
                                                            Name<SortingComponent field='name' sortingType={sortingTypeName} />
                                                        </div>
                                                    </th>
                                                    <th>Categories</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {productList && productList.length > 0 ? productList.map((item, index, array) => {
                                                    return (
                                                        <tr key={item.SK}>
                                                            <td>#{index + 1}</td>
                                                            <td >{item?.image?.thumbnail ? <img src={item?.image?.thumbnail} height={40} width={50} className='images_thumbnail productImg' /> : <img src={dummyProduct} height={40} width={50} className='images_thumbnail' />}</td>
                                                            <td >{titleCase(item.productname)}</td>
                                                            <td >{item.category.map((itemCategory, idx) => {
                                                                return titleCase(itemCategory.label) + `${idx == item.category.length - 1 ? "" : ","}` //eslint-disable-line
                                                            })}</td>

                                                            <td>
                                                                <div className="actionElements">
                                                                    <Form><Form.Check type="switch" id="custom-switch" label=""
                                                                        checked={item.visibility == 1 ? true : false} //eslint-disable-line
                                                                        onChange={(e) => updateActiveStatus(e, item.SK)}

                                                                    /></Form>
                                                                    <button onClick={() => history.push(`/edit-product?id=${item.SK}`)} className="edit btn" ><span>Edit</span> <i className="fa-solid fa-pen-to-square"></i></button>
                                                                    <Button type="button" className="delete" onClick={() => onDeleteHandler(item.SK)}>
                                                                        <>{
                                                                            deletedId == item.SK && //eslint-disable-line
                                                                                loader ? <Spinner
                                                                                as="span"
                                                                                className="delete"
                                                                                animation="border"
                                                                                size="sm"
                                                                                role="status"
                                                                                aria-hidden="true"
                                                                            /> : <><span>Delete</span> <i className="fa-solid fa-trash-can"></i></>}
                                                                        </>
                                                                    </Button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                }) :
                                                    <tr key={uuidv4()}><td
                                                        colSpan={5}
                                                        className='text-center'
                                                    >
                                                        No Products Available.
                                                    </td></tr>
                                                }
                                            </tbody>
                                        </Table>}

                                    </div>
                                </InfiniteScroll>
                            </div>
                        </div>
                    </div>
                </div>
                <SidebarTwo activePage="" setSidebarType={setSidebarType} sidebarType={sidebarType} setPermissionData={setPermissionData} />
            </div>
        </React.Fragment >
    )
}
export default Products;