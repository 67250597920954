import Dashboard from "../dashboard";
import Stores from "../branches";
import EditStore from "../edit-branch";
import EditUser from "../edit-user";
import Users from "../users";
import Url from "../url";
import Products from "../products";
import AddNewProduct from "../add-product";
import EditProduct from "../edit-product";
import Orders from "../orders";
import ViewOrder from "../view-order";
import choicesAddons from "../choices-addons";
import AddNewChoicesAddons from "../add-choices-addons";
import EditChoicesAddons from "../edit-choices-addons";
import TableManagement from "../table-management";
import Employees from "../employees";
import AddNewEmployee from "../add-employee";
import EditEmployee from "../edit-employee";
import Transactions from "../transactions";
import Categories from "../categories";
import AddNewCategories from "../add-category";
import EditCategories from "../edit-category";
import Reports from "../reports";
import Profile from "../profile";
import Settings from "../settings";
import MenuOrder from "../menu-order";
import PageNotFound from "../page-not-found";
// import LoadingPage from "../loadingPage";
import DigitalMenu from '../digital-menu'
import DigitalMenuList from '../digital-menu-list'
import { Login } from "../../components/auth";
//Developer Module
// import AddOrganization from "../developer/addOrganization";
import Organization from "../developer/organizations";
import EditOrganization from "../developer/editOrganization";
import Receipt from "../receipt"
import Template from "../template";
import WebsiteBuilder from "../website-builder";
import Preview from "../template/preview.jsx";
import Map from "../mapComponent/Map";
import PlaceComponent from "../mapComponent/autoComplete";
const routes = [
  {
    path: "/dashboard",
    pageName: "Dashboard",
    component: Dashboard,
  }, {
    path: "/stores",
    pageName: "Stores",
    component: Stores,
  }, {
    path: "/edit-store",
    pageName: "EditStore",
    component: EditStore,
  }, {
    path: "/users",
    pageName: "Users",
    component: Users,
  },
  {
    path: "/digital-menu",
    pageName: "DigitalMenu",
    component: DigitalMenu,
  },
  {
    path: "/digital-menu-list",
    pageName: "DigitalMenuList",
    component: DigitalMenuList,
  },
  {
    path: "/url",
    pageName: "Url",
    component: Url,
  },
  {
    path: "/edit-user",
    pageName: "EditUser",
    component: EditUser,
  },
  {
    path: "/",
    pageName: "Login",
    component: Login,
  }, {
    path: "/products",
    pageName: "Products",
    component: Products,
  }, {
    path: "/add-product",
    pageName: "AddNewProduct",
    component: AddNewProduct,
  }, {
    path: "/edit-product",
    pageName: "EditProduct",
    component: EditProduct,
  }, {
    path: "/orders",
    pageName: "Orders",
    component: Orders,
  }, {
    path: "/view-order",
    pageName: "ViewOrder",
    component: ViewOrder,
  }, {
    path: "/modifiers",
    pageName: "choicesAddons",
    component: choicesAddons,
  }, {
    path: "/add-modifiers",
    pageName: "AddNewChoicesAddons",
    component: AddNewChoicesAddons,
  }, {
    path: "/edit-modifiers",
    pageName: "EditChoicesAddons",
    component: EditChoicesAddons,
  }, {
    path: "/table-management",
    pageName: "TableManagement",
    component: TableManagement,
  }, {
    path: "/employees",
    pageName: "Employees",
    component: Employees,
  }, {
    path: "/add-employee",
    pageName: "AddNewEmployee",
    component: AddNewEmployee,
  }, {
    path: "/edit-employee",
    pageName: "EditEmployee",
    component: EditEmployee,
  }, {
    path: "/transactions",
    pageName: "Transactions",
    component: Transactions,
  }, {
    path: "/categories",
    pageName: "Categories",
    component: Categories,
  }, {
    path: "/add-category",
    pageName: "AddNewCategories",
    component: AddNewCategories,
  }, {
    path: "/edit-category",
    pageName: "EditCategories",
    component: EditCategories,
  }, {
    path: "/reports",
    pageName: "Reports",
    component: Reports,
  }, {
    path: "/profile",
    pageName: "Profile",
    component: Profile,
  }, {
    path: "/settings",
    pageName: "Settings",
    component: Settings,
  }, {
    path: "/menu-order",
    pageName: "MenuOrder",
    component: MenuOrder,
  },
  {
    path: "/receipt",
    pageName: "Receipt",
    component: Receipt,
  },
  {
    path: "/website-builder",
    pageName: "websiteBuilder",
    component: WebsiteBuilder,
  },
  {
    path: "/preview",
    pageName: "preview",
    component: Preview,
  },
  //Developer Routes
  {
    path: "/editOrganization",
    pageName: "editOrganization",
    component: EditOrganization
  },
  {
    path: "/organizations",
    pageName: "organizations",
    component: Organization
  },
  {
    path: "/template",
    pageName: "template",
    component: Template
  },
  {
    path: "/map",
    pageName: "map",
    component: Map
  },
  {
    path: '/autocomplete',
    pageName: 'autoComplete',
    component: PlaceComponent
  },
  {
    path: "*",
    pageName: "PageNotFound",
    component: PageNotFound,
  },
];

export default routes;
