import React, { useCallback, useState, useEffect, memo, useRef } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
const LoadMap = ({ width = "100%", height = "222px", data }) => {
  const containerStyle = {
    width: width,
    height: height,
    borderRadius: "15px",
  };

  const center = {
    lat: 39.86223173133187,
    lng: -101.64156251308714,
  };
  const origins = [
    {
      id: 1,
      title: data.formatted_address,
      coordinates: {
        lat: data.latitude ? data.latitude : 39.86223173133187,
        lng: data.longitude ? data.longitude : -101.64156251308714, // Use "lng" instead of "lon"
      },
    },
  ];

  const [map, setMap] = useState(null);

  const onLoad = (map) => {
    console.log("map---->", map);
    setMap(map);
  };

  useEffect(() => {
    if (map) {
      const bounds = new window.google.maps.LatLngBounds();
      origins.forEach(({ coordinates }) => {
        bounds.extend(coordinates);
      });
      map.fitBounds(bounds, { top: 50, right: 50, bottom: 50, left: 50 });
    }
  }, [map, origins]);
  return (
    <div>
      <LoadScript
        googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
        libraries={["places"]}
      >
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={5}
          onLoad={onLoad}
        >
          {origins.map(({ coordinates: { lat, lng }, id }) => (
            <Marker key={id} position={{ lat, lng }} />
          ))}
        </GoogleMap>
      </LoadScript>
    </div>
  );
};

export default memo(LoadMap);
