import React, { useState, useEffect } from "react";
import {
  Button, Row, Col, Form, Spinner, Tooltip, OverlayTrigger
} from "react-bootstrap";
import { allApiUrl } from "../../api/apiRoute";
import { apiGetMethod, apiPutMethod } from "../../api/rest";
import toastMessages from "../toastMessages";
import queryString from "query-string";
//Header
import Header from "../common/header";
import Sidebar from "../common/sidebar";
import DeveloperHeader from '../developer/developerHeader';
import DeveloperSidebar from '../developer/developerSidebar';
import { c, smallStyle } from "../../features/helper";
//Images
import userImg from "../shared/assets/images/people.png";
import CommonButton from "../common-ui/Button";
import "react-phone-input-2/lib/bootstrap.css";
import PhoneInput from "react-phone-input-2";
import { countNumberInPhoneNumber } from "../../features/helper";
import Loader from "../shared/spinner";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from 'uuid';
import SidebarTwo from "../common/sidebarTwo";
import { useLocation } from "react-router-dom";
import { changeTableManagement, changeWebsiteTemplate } from "../../features/todoSlice";
const CssTooltip = () => {
  return (
    <div className="tooltip">Hover over me
      <span className="tooltiptext">Tooltip text</span>
    </div>

  )
}
function Profile() {
  let dispatch = useDispatch()
  const [permissionData, setPermissionData] = useState({})
  const branchArray = useSelector((state) => state.counter.branchArray)
  let branches = branchArray.map((item) => item.value)
  const header = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    branchid: JSON.stringify(branches)
  };
  let location = useLocation()
  let { developerMode } = queryString.parse(location.search);
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [active, setActive] = React.useState(false);
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(false);
  const [phoneValidatError, setPhoneValidError] = useState(false);
  const [errorMessageCatch, setErrorMessageCatch] = useState('')
  const [isValid, setIsValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const [form, setForm] = useState({
    file: "",
    newPassword: "",
    confirmPassword: "",
    email: "",
    phone: "",
    countrycode: '+1',
    image: "",
    deleteImage: 0
  });
  const [sidebarType, setSidebarType] = useState('singleSidebar')
  const [preview, setPreview] = useState([]);
  const [showRedText, setShowRedText] = useState(false);
  const [loader, setLoader] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [showPassOldPassword, setShowPassOldPassword] = useState(false);
  const [showLoadingPage, setShowLoadingPage] = useState(false)
  // c('hellosdfd')
  useEffect(() => {
    setIsLoading(true)
    getProfile();
  }, []);
  useEffect(() => {
    getDisableStatus()
  }, [permissionData])
  async function getDisableStatus() {
    const header = {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      branchid: JSON.stringify(branches),
    };
    try {
      const response = await apiGetMethod(allApiUrl.viewSetting, "", header);
      if (response.status == 200) {
        if (response.data.Items.length != 0) {
          //for table managment sidebar toggle functionality.
          if (permissionData.tablemanagement == 0) {
            console.log('116')
            dispatch(changeTableManagement(false));
            console.log('116')
          }
          else {
            console.log('116')
            if (response.data.Items[0].GeneralSettings.tablemanagement == 1) {
              console.log('116')
              dispatch(changeTableManagement(true));
            } else {
              console.log('116')
              dispatch(changeTableManagement(false));
            }
          }
          // for website builder
          if (permissionData.websitetemplate == 0) {
            dispatch(changeWebsiteTemplate(false));
          }
          else {
            if (response.data.Items[0].WebsiteTemplate.enableWebsiteTemplate == 1) {
              //eslint-disable-line
              dispatch(changeWebsiteTemplate(true));
            } else {
              dispatch(changeWebsiteTemplate(false));
            }
          }

        }
      }
    } catch (err) {
      console.log("err", err);
    }
  }
  async function onSubmit(e) {
    console.log('testing')
    setLoader(true);
    e.preventDefault();
    try {
      // c('71', isPhoneNumberValid)
      if (
        (isValid == true || form.newPassword.length > 0 == false) && //eslint-disable-line
        isPhoneNumberValid
      ) {
        // c('75')
        const formData = new FormData();
        if (form.email !== userInfo.email) {
          formData.append("email", form.email);
        }
        if (form.phone !== userInfo.phone) {
          formData.append("phone", form.phone);
          formData.append("countrycode", form.countrycode);
        }
        if (form && form.file) {
          formData.append("file", form.file);
        }
        if (form && form.newPassword) {
          formData.append("newPassword", form.newPassword);
          formData.append("confirmPassword", form.confirmPassword);
        }
        formData.append('deleteImage', form.deleteImage)
        const response = await apiPutMethod(
          allApiUrl.update_profile,
          formData,
          header
        );
        if (response.status === 200) {
          getProfile();
          setLoader(false);
          setShowRedText(false);
          toastMessages("success", "Profile Updated Successfully");
        }
      }
      else if (isPhoneNumberValid == false) {
        setLoader(false);
        toastMessages("error", phoneValidatError)
      }
      else {
        setLoader(false);
        setShowRedText(true);
        toastMessages("error", "The New Password is not valid");
      }
    } catch (err) {
      toastMessages("error", err.data.message);
      setLoader(false);
      setErrorMessageCatch(err.data.message)
      console.log("err", err);
    }
  }
  function clickHandler(type) {
    if (type == "old") {
      setShowPassOldPassword(!showPassOldPassword);
    }
    else {
      setShowPass(!showPass);
    }
  }
  async function getProfile() {
    try {
      const response = await apiGetMethod(allApiUrl.profile, "", header);
      if (response.status === 200) {
        validatePhone(response.data.user.phone);
        setForm({
          ...form,
          email: response.data.user.email,
          phone: response.data.user.phone,
          countrycode: response.data.user.countrycode,
          pin: response.data.user.pin,
          newPassword: '',
          confirmPassword: '',
          deleteImage: 0,
          file: ''
        });
        // setIsPhoneNumberValid(true);
        setPreview(response.data.user.image || userImg);
        localStorage.setItem(
          "userInfo",
          JSON.stringify({
            ...userInfo,
            phone: response.data.user.phone,
            email: response.data.user.email,
            image: response.data.user.image,
            countrycode: response.data.user.countrycode,
            pin: response.data.user.pin
          })
        );
        setIsLoading(false)
      }
    } catch (err) {
      setIsLoading(false)
      // toastMessages("error", err.data.message);
      console.log("err", err);
    }
  }
  function handlePasswordChange(event) {
    setShowRedText(false)
    setForm({ ...form, newPassword: event.target.value });
    setIsValid(validatePassword(event.target.value));
  }
  function validatePassword(password) {
    const regex =
      /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*_#?&])([a-zA-Z0-9@$!%*_#?&]{8,})$/;
    return regex.test(password);
  }
  const validatePhone = (value) => {
    const newValue = value;
    const regex = /^[0-9+-]*$/;
    if (regex.test(newValue)) {
      setForm({ ...form, phone: newValue });
      // if (isBetween8And12Digits(newValue)) {
      setIsPhoneNumberValid(true);
      setPhoneValidError("Phone is valid");
      // }
      // else {
      // c('186')
      // }
    }
  };
  function isBetween8And12Digits(num) {
    const numAsString = num.toString();
    return numAsString.length >= 8 && numAsString.length <= 12;
  }
  const handleOnChange = (value, country) => {
    setForm({ ...form, phone: value });
    console.log('-------186------', value, country.format)
    const result = countNumberInPhoneNumber(country.format, ".");
    var zeroCount = value.toString().replace("0.", "").split("0").length - 1;
    if (zeroCount > 9) {
      // c('196')
      setIsPhoneNumberValid(false);
      setPhoneValidError(
        `Enter a valid phone number`
      )
    }
    else {
      if (value.length - country.dialCode.length == result - country.dialCode.length || (result - country.dialCode.length) > 10) {
        setIsPhoneNumberValid(true);
        // c('205')
      } else {
        // c('207')
        setIsPhoneNumberValid(false);
        setPhoneValidError(`The Phone Number should be of ${result - country.dialCode.length} Digit`);
      };
    }
  };


  let sidebarToggle = useSelector((state) => state.counter.sidebarToggle)
  let sidebarTypeRedux = useSelector((state) => state.counter.sidebarType)
  console.log('permissionData from profile', permissionData)
  const tooltip = (
    <Tooltip id="tooltip">
      <strong>Holy guacamole!</strong> Check this info.
    </Tooltip>
  );


  return (
    <React.Fragment>
      {developerMode == "true"
        ? <DeveloperHeader
          setActive={setActive}
          showLoadingPage={showLoadingPage}
          setShowLoadingPage={setShowLoadingPage}
          active={sidebarToggle}
          page="profile" />
        : <Header
          setActive={setActive}
          active={sidebarToggle}
          page="profile"
        />}
      <div className="interFaceWrap">
        {developerMode == "true"
          ? <DeveloperSidebar active={sidebarToggle} activePage="profile?developerMode=true" />
          : <Sidebar active={sidebarToggle} activePage="profile" permissionData={permissionData} />}
        <div className={`interFaceWrap__inner ${sidebarTypeRedux == false ? "doubleSidebar" : "singleSidebar"}`}>
          <div className="commonOuter">
            <div className="pageHeader">
              <div className="headingText headingText--textLeft mb-0">
                <h1>Profile</h1>
              </div>
            </div>
            <Row>
              <Col md={3}>
                <div className="whiteBg whiteBg--border"
                >
                  <div className="prfileUpload">
                    {preview && preview.length > 0 ? (
                      <div className="prfileUpload__inner">
                        <img className="profileImg" src={preview} alt="userImg" />
                        <Button type="button" className="trashButton pointer" onClick={() => {
                          setForm({ ...form, deleteImage: 1 })
                          setPreview(userImg)
                        }}>
                          <i className="fa-solid fa-trash-can"></i>
                        </Button>
                      </div>
                    ) : (
                      <Spinner as="span" />
                    )}

                    <div className="fileButton">
                      <Form.Group className="fileButton__btn"
                      >
                        <Form.Control
                          type="file"
                          id={uuidv4()}
                          onChange={(e) => {
                            if (e) {
                              const file = e.target.files;
                              if (file) {
                                setForm({ ...form, file: file[0], deleteImage: 0 });
                              }
                              setPreview(URL.createObjectURL(file[0]));
                            }
                          }}
                        />
                        <Form.Label>Upload</Form.Label>
                      </Form.Group>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={9} className="profileContent">
                <div className="whiteBg whiteBg--border">
                  {isLoading ? <Loader /> :
                    <Form onSubmit={onSubmit}>
                      <Row>
                        <Col md={12}>
                          <Row className="align-items-end">
                            <Col md={6} className="form-group">
                              <Form.Group>
                                <Form.Label>Username:</Form.Label>
                                <Form.Control
                                  disabled
                                  type="text"
                                  placeholder="John Doe"
                                  value={userInfo && userInfo?.username?.trim()}
                                />
                              </Form.Group>
                            </Col>
                            <Col md={6} className="form-group text-start">
                              <span className="subLabel">
                                Username cannot be changed
                              </span>
                            </Col>
                          </Row>
                        </Col>
                        <Col md={6}>
                          <Form.Group className="form-group">
                            <Form.Label>New Password:</Form.Label>
                            <div className="passwordWrap">
                              <Form.Control
                                inputattrs="{ 'data-lpignore': 'true' }"
                                className={`${!isValid && showRedText && "error"} notCapital`}
                                autoComplete="new-password"
                                required={form?.confirmPassword ? true : false}
                                value={form.newPassword}
                                onChange={handlePasswordChange}
                                type={`${!showPass ? "password" : "text"}`}
                                placeholder="**********"
                              />

                              <Button className="showPassIcon" type="button">
                                <i
                                  onClick={() => clickHandler('new')}
                                  className={
                                    showPass ? "fas fa-eye" : "fas fa-eye-slash"
                                  }
                                ></i>
                              </Button>


                            </div>
                            {!isValid && (
                              <small
                                style={{
                                  color: `${showRedText == true ? "red" : ""}`, //eslint-disable-line
                                }}
                              >
                                The password has to be at least 8 characters
                                long, with at least 1 Uppercase letter, 1 Lowercase
                                letter, 1 Number and 1 Special Character.
                              </small>
                            )}
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group className="form-group">
                            {/* <CssTooltip /> */}
                            <Form.Label>Confirm Old Password:</Form.Label>
                            <div className="passwordWrap">
                              <Form.Control
                                autoComplete="new-password"
                                className={`${errorMessageCatch == "Incorrect Current Password." && "error"} notCapital`}
                                required={form?.newPassword ? true : false}
                                type={`${!showPassOldPassword ? "password" : "text"
                                  }`}
                                placeholder="**********"
                                value={form.confirmPassword}
                                onChange={(e) => {
                                  setErrorMessageCatch('')
                                  setForm({
                                    ...form,
                                    confirmPassword: e.target.value,
                                  })
                                }
                                }
                              />
                              <Button className="showPassIcon" type="button">
                                <i
                                  onClick={() => clickHandler('old')}
                                  className={
                                    showPassOldPassword ? "fas fa-eye" : "fas fa-eye-slash"
                                  }
                                ></i>
                              </Button>
                            </div>
                            {errorMessageCatch == "Incorrect Current Password." && <small style={smallStyle}>{errorMessageCatch}</small>}
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group className="form-group">
                            <Form.Label>Phone Number:</Form.Label>
                            <PhoneInput
                              value={form.phone}
                              onChange={handleOnChange}
                              enableSearch={true}
                              inputStyle={{ width: "100%" }}
                            />

                            {isPhoneNumberValid == false && form.phone && ( //eslint-disable-line
                              <small style={{ color: "red" }}>
                                {phoneValidatError}
                              </small>
                            )}

                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group className="form-group">
                            <Form.Label>Email:</Form.Label>
                            <Form.Control
                              required
                              className={`${errorMessageCatch == "Invalid Email" && "error"} notCapital`}
                              type="email"
                              placeholder="Email@mail.com"
                              value={form.email}
                              onChange={(e) => {
                                setErrorMessageCatch('')
                                setForm({ ...form, email: e.target.value })
                              }
                              }
                            />
                            {
                              errorMessageCatch == "Invalid Email" && <small style={smallStyle}>{errorMessageCatch}</small>
                            }
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                        </Col>
                        <Col md={12} className="buttonOuter text-end">
                          <CommonButton
                            buttonName="Submit"
                            type="HalfWidth"
                            loader={loader}
                          // onClick={onSubmit}
                          />
                        </Col>
                      </Row>
                    </Form>}
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <SidebarTwo
          activePage=""
          setSidebarType={setSidebarType}
          sidebarType={sidebarType}
          showLoadingPage={showLoadingPage}
          setShowLoadingPage={setShowLoadingPage}
          setPermissionData={setPermissionData}
        />
      </div>
    </React.Fragment >
  );
}
export default Profile;
