import React, { useEffect } from 'react';
import { useState } from 'react';
import { Button, Table, Row, Col, Form, Spinner } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import dummyProduct from '../../components/shared/assets/images/dummyProduct.jpg'

import { type } from '@testing-library/user-event/dist/type';
import { apiGetMethod } from '../../api/rest';
import { allApiUrl } from '../../api/apiRoute';
import queryString from "query-string";
import "./css/digitalMenuStyle.scss";

function DigitalMenuList() {
    const location = useLocation()
    // let branchId = 1675336560005
    // let restaurant = "AKASHBHATT"
    let { token } = queryString.parse(location.search);
    // console.log('branchId', branchId, restaurant)
    const [productCategoryWise, setProductCategoryWise] = useState([])
    const [modifierList, setModifierList] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [form, setForm] = useState({
        // branchid: branchId,
        orderDate: Date.now(),
        // restaurant: restaurant,
        origin: 'webApplication',
        orderType: {
            type: "",
            name: "",
            firstName: "",
            lastName: "",
            phoneNumber: "",
            noOfGuest: "",
            date: {},
            time: {},
            timeObject: {},
            dateObject: {},
            note: "",
            email: ''
        },
        paymentMethod: {
            fullName: "",
            cardNumber: "",
            expiryDate: '',
            cvc: ""
        },
        order: {
            productName: '',
            productSize: '',
            productPrice: '',
            productId: '',
            modifier: [],
            note: '',
            quantity: 1,
            total: '',
        },
        tax: '',
        subTotal: "",
        discount: "",
        gratuity: "",
        taxPercentage: "",
        finalTotal: "",
        table: "",
        status: "",
        userRoleID: 'Online Order'
    })
    useEffect(() => {
        getData()
        getAllModifier()
        let userInformation = JSON.parse(localStorage.getItem('userInformation'))
        if (userInformation) {
            setForm(userInformation)
        }
    }, [])
    async function getAllModifier() {
        const header = {
            Authorization: `Bearer ${token}`,
        };
        try {
            const response = await apiGetMethod(`${allApiUrl.view_all_modifier_digital}`, "", header);
            if (response.status === 200) {
                setModifierList(response.data.categories.Items)
            }
        } catch (err) {
            console.log("err", err);
        }
    }
    async function getData() {
        const header = {
            Authorization: `Bearer ${token}`,
        };
        try {
            const response = await apiGetMethod(`${allApiUrl.listingProduct}`, " ", header);
            if (response.status === 200) {
                setProductCategoryWise(response.data.product)
                setIsLoading(false)
            }
        } catch (err) {
            setIsLoading(false)
        }
    }
    ///////////////////////////////// //UI Related State///////////////////////////////////////////////
    const [activeProduct, setActiveProduct] = useState(null)
    //////////////////////////////////////////////////////////////////////////////////////////////////
    const [activePage, setActivePage] = useState('menuListing')
    const [grandTotal, setGrandTotal] = useState(0)
    const [isModifierValueUpdated, setIsModifierValueUpdated] = useState(false)
    const [cartItem, setCartItem] = useState([])
    const [subTotal, setSubTotal] = useState(0)
    const [tax, setTax] = useState(0)
    // validation
    const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(false);
    const [phoneValidatError, setPhoneValidError] = useState(false);
    const [error, setError] = useState(null);
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [paymentMethodValid, setPaymentMethodValid] = useState({
        cardNumber: false,
        expiryDate: { status: false, error: '' },
        cvc: false
    })
    function titleCase(str) {
        var splitStr = str.toLowerCase().split(' ');
        for (var i = 0; i < splitStr.length; i++) {
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        return splitStr.join(' ');
    }
    // console.log('form', form)
    const [selectedProduct, setSelectedProduct] = useState(null)
    const [isProductSelected, setIsProductSelected] = useState(false)
    const [currentChoice, setCurrentChoices] = useState([])
    const [refresh, setRefresh] = useState(false)
    //For Getting initial data of Sizes.
    useEffect(() => {
        // console.log('Clicked    394')
        // console.log('useEffect 4')
        // console.log('selectedProduct', selectedProduct)
        if (selectedProduct && modifierList.length > 0) {
            let { size: productSize, price: productPrice, choices } = selectedProduct.productsize.parameters.find((item) => item.default == 1)
            // if (form.order.productSize === "") {
            let initialTotal = parseFloat(productPrice.replace('$', ''))
            modifierList.filter(item => choices.some(c => c.value === item.SK)).forEach((item) => {
                item.choices.forEach((value) => {
                    if (value.preselect == 1) {
                        initialTotal += parseFloat(value.price.replace('$', ''))
                    }
                })
            })
            let data = modifierList.filter(item => choices.some(c => c.value === item.SK))
            data = data.map(modifier => ({
                ...modifier,
                choices: modifier.choices.map(choice => ({
                    ...choice,
                    checked: choice.preselect === 1
                }))
            }));
            setGrandTotal(parseFloat(initialTotal))
            setCurrentChoices(data)
            setForm({
                ...form,
                order: {
                    ...form.order,
                    productName: selectedProduct.productname,
                    productPrice, productSize,
                    modifier: data,
                    productId: selectedProduct.SK,
                    categoryId: selectedProduct.categoryId
                }
            })
        }

    }, [selectedProduct, modifierList, isProductSelected])
    useEffect(() => {
        if (selectedProduct && modifierList.length > 0) {
            let choices = selectedProduct.productsize.parameters.find((item) => (item.size).toLowerCase() == (form.order.productSize).toLowerCase()).choices
            let newPrice = parseFloat(selectedProduct.productsize.parameters.find((item) => (item.size).toLowerCase() == (form.order.productSize).toLowerCase()).price.replace('$', ''))
            modifierList.filter(item => choices.some(c => c.value === item.SK)).forEach((item) => {
                item.choices.forEach((value) => {
                    if (value.preselect == 1) {
                        newPrice += parseFloat(value.price.replace('$', ''))
                    }
                })
            })
            let data = modifierList.filter(item => choices.some(c => c.value === item.SK))
            data = data.map(modifier => ({
                ...modifier,
                choices: modifier.choices.map(choice => ({
                    ...choice,
                    checked: choice.preselect === 1
                }))
            }));
            setCurrentChoices(data)
            setForm({ ...form, order: { ...form.order, modifier: data } })
            setGrandTotal(parseFloat(newPrice))
        }
    }, [refresh])
    useEffect(() => {
        let total = 0
        if (form.order) {
            total = parseFloat(form.order.productPrice.replace('$', ''))
            form.order.modifier.forEach((item) => {
                item.choices.forEach((choiceItem) => {
                    if (choiceItem.checked) {
                        const price = parseFloat(choiceItem.price.replace('$', ''));
                        if (!isNaN(price)) {
                            total += price;
                        }
                    }
                })
            })
            setGrandTotal(total);
            if (form.order.quantity > 1) {
                setGrandTotal(total * form.order.quantity)
            }
        }
    }, [isModifierValueUpdated])
    useEffect(() => {
        if (activeProduct !== null)
            document.getElementById(activeProduct).scrollIntoView({
                block: "center",
            });
    }, [activeProduct])
    return (
        <React.Fragment>
            <div className="digitalMenuOrderWrap">
                <div className="menuOrderInner">
                    <div className="menuHeader">
                        <div className="menuHeader__title" >{''}</div>
                        <div className="menuHeader__buttons">
                            <Button className={`menuButton ${activePage == "menuListing" ? "active" : ""}`} type="button" onClick={() => {
                                setActivePage("menuListing")
                            }}> <i className="fa-solid fa-utensils"></i>
                            </Button>
                        </div>
                    </div>{/* Header End */}

                    <div className={`menuListing ${activePage == "menuListing" ? "show" : "hide"}`}>
                        {isLoading && <div>
                            <p>We are syncing the product</p>
                            <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>}
                        {!isLoading && productCategoryWise && productCategoryWise.length == 0 && <p>No Product Found.</p>}
                        {!isLoading && productCategoryWise && productCategoryWise.length > 0 && productCategoryWise.map((item) => {
                            if (item.products && item.products.length > 0) {
                                return (
                                    <div className="menuListing__inner">
                                        <div className="menuListing__title"
                                        >
                                            <h3>{item.category.name}</h3>
                                            <p>{item.category.description}</p>
                                        </div>
                                        <div className="menuListing__body"
                                            onClick={(e) => {
                                                if (activeProduct !== null) {
                                                }
                                            }}
                                        >
                                            {item.products.map((productItem, index) => {
                                                const { productsize: { parameters } } = productItem
                                                const { productsize: { type: productType } } = productItem
                                                let price = parameters[0].price
                                                return (
                                                    <div className={`menuItem ${`${productItem.productname}-${item.category.name}` == activeProduct ? "activeTab" : activeProduct !== null ? "inactiveTab" : ''}`}
                                                        onClick={() => {
                                                            if (activeProduct == null) {
                                                                setIsProductSelected(!isProductSelected)
                                                                productItem.categoryId = item.category.id
                                                                setSelectedProduct(productItem)
                                                                setActiveProduct(`${productItem.productname}-${item.category.name}`)
                                                            }
                                                        }
                                                        } >
                                                        <div className="menuItem__inner" onClick={() => {
                                                            setActiveProduct(null)
                                                        }}>
                                                            <div className="menuItem__img">
                                                                <img src={productItem?.image?.thumbnail ? productItem.image?.thumbnail : dummyProduct} alt="" />
                                                            </div>
                                                            <div className="menuItem__description">
                                                                <h4>{titleCase(productItem.productname)}</h4>
                                                                <p>{productItem.description !== "undefined" && productItem.description}</p>
                                                                <div className='price'>${price.replace('$', '')}</div>
                                                                <div className="accordionArrow"><i class="fa-solid fa-chevron-down"></i></div>
                                                            </div>
                                                        </div>
                                                        {(`${productItem.productname}-${item.category.name}` == activeProduct) &&
                                                            <div className={`itemCartInfo ${`${productItem.productname}-${item.category.name}` == activeProduct ? "newAdded" : ''}`} id={`${productItem.productname}-${item.category.name}`}>
                                                                <Button type="button" className="closeButton"
                                                                    onClick={() => {
                                                                        setActiveProduct(null)
                                                                    }}
                                                                ><i className="fa-solid fa-xmark" onClick={() => {
                                                                }}></i></Button>
                                                                <div className="itemCartInfo__body">
                                                                    <div className="itemCartInfoImg">
                                                                        <img src={productItem?.image?.thumbnail ? productItem.image.thumbnail : dummyProduct} alt="dummyProduct" />
                                                                    </div>
                                                                    <div className="itemCartInfoDescription">
                                                                        <p>{productItem.description !== "undefined" && productItem.description}</p>
                                                                    </div>
                                                                    <div className="itemSelectInfo">
                                                                        <Form>
                                                                            <div className="itemSelectInfo__inner">
                                                                                <h3 className="itemTitle">Size<span className="requiredAddon"> (Required)</span></h3>
                                                                                <div className="commonCheckOptions">
                                                                                    <Form.Group className="form-group formCheckbox">
                                                                                        {productItem.productsize.parameters.map((prodItem) => {
                                                                                            return (
                                                                                                <Form.Check
                                                                                                    checked={form?.order?.productSize == prodItem.size ? true : false}
                                                                                                    type='checkbox'
                                                                                                    onClick={() => {
                                                                                                        let productPrice = prodItem.price
                                                                                                        let productSize = prodItem.size
                                                                                                        setRefresh(!refresh)
                                                                                                        setForm({ ...form, order: { ...form.order, productPrice, productSize, quantity: 1 } })
                                                                                                    }}
                                                                                                    id={`${prodItem.size}-($${prodItem.price})-${type}`
                                                                                                    }
                                                                                                    label={`${titleCase(prodItem.size)} ($${prodItem.price.replace('$', '')})`}
                                                                                                />
                                                                                            )
                                                                                        })}
                                                                                    </Form.Group>
                                                                                </div>
                                                                            </div>
                                                                            <div className="itemSelectInfo__inner">
                                                                                {currentChoice && currentChoice.length > 0 && <h3 className="itemTitle">Choices & Addon's <span className="requiredAddon"></span></h3>}
                                                                                <div className="commonCheckOptions">
                                                                                    {
                                                                                        currentChoice.map((choiceItem) => {
                                                                                            let forceminimum
                                                                                            let forcemaximum
                                                                                            if (choiceItem.modifierstatus.modifiretype == "mandatory") {
                                                                                                if (choiceItem.modifierstatus.forceminimum !== "0") {
                                                                                                    forceminimum = choiceItem.modifierstatus.forceminimum
                                                                                                }
                                                                                                if (choiceItem.modifierstatus.forcemaximum !== "0") {
                                                                                                    forcemaximum = choiceItem.modifierstatus.forcemaximum
                                                                                                }
                                                                                            }
                                                                                            let show
                                                                                            if (forceminimum) {
                                                                                                show = `(Minimum ${forceminimum} required)`
                                                                                            }
                                                                                            if (forcemaximum) {
                                                                                                show = `(Maximum ${forcemaximum} allowed)`
                                                                                            }
                                                                                            if (forceminimum && forcemaximum) {
                                                                                                show = `(Minimum ${forceminimum} & Maximum ${forcemaximum} allowed)`
                                                                                            }
                                                                                            return (
                                                                                                <Form.Group className="form-group formCheckbox">
                                                                                                    <Form.Label>{titleCase(choiceItem.modifiername)}{" "}<small>{show}</small></Form.Label>
                                                                                                    {choiceItem.choices.map((singleChoice) => {
                                                                                                        return (
                                                                                                            <p>{`${titleCase(singleChoice.name)} (${singleChoice.price})`}</p>
                                                                                                        )
                                                                                                    })
                                                                                                    }
                                                                                                </Form.Group>
                                                                                            )
                                                                                        })
                                                                                    }

                                                                                </div>
                                                                            </div>
                                                                        </Form>
                                                                    </div>
                                                                </div>
                                                            </div>}
                                                    </div>
                                                )
                                            })
                                            }
                                        </div>
                                    </div>
                                )
                            }
                        })}
                    </div>{/* Order Menu Listing End */}
                </div>
            </div>
        </React.Fragment >
    )

}
export default DigitalMenuList;