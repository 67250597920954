import React from "react";
import { Switch, Redirect, Route } from "react-router-dom";

// ROUTES
import routes from "../../routes";
function Container(props) {
  return (
    <React.Fragment>
      <Switch>
        {routes.map((route, key) => {
          if (route.exact) {
            return (
              <Redirect
                key={key}
                exact
                path={route.path}
                render={(props) => {
                  return (
                    <route.component {...props} pageName={route.pageName} />
                  );
                }}
              />
            );
          } else if (route.to) {
            return (
              <Redirect
                key={key}
                exact
                path={route.path}
                to={route.to}
                render={(props) => {
                  return (
                    <route.component {...props} pageName={route.pageName} />
                  );
                }}
              />
            );
          } else {
            return (
              <Route
                key={key}
                exact
                path={route.path}
                render={(props) => {
                  return (
                    <route.component {...props} pageName={route.pageName} />
                  );
                }}
              />
            );
          }
        })}
      </Switch>
    </React.Fragment>
  );
}
export default Container;
