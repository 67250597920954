import { Form } from "react-bootstrap";

const InputButton = ({ placeholder, disabled, type, onChange, value, onBlur }) => {
  const onKeyDown = (evt) => (evt.key === "e" || evt.key === "E") && evt.preventDefault();

  return (
    <Form.Control
      onBlur={onBlur}
      type={type}
      placeholder={placeholder}
      disabled={disabled}
      onChange={onChange}
      value={value}
      onKeyDown={(type = "number" ? onKeyDown : null)}
      onWheel={(e) => e.target.blur()}
    />
  );
};
InputButton.defaultProps = {
  onKeyDown: () => { },
  onBlur: () => { }
};
export default InputButton;
