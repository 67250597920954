import { useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { CSVLink } from "react-csv";
import toastMessages from "../toastMessages";
const CommonButton = ({ buttonName, type, loader, onClick, disabled }) => {
  return (
    <>
      <Button
        disabled={disabled}
        className={`
         commonButton ${(buttonName == "Reset" || buttonName == "Remove All") && "commonButton--delete"}
         ${type === "fullWidth" && "commonButton--fullWidth"}
         `}
        type="submit"
        onClick={onClick}
      >
        {buttonName}{" "}
        {loader && (
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        )}
      </Button>
    </>
  );
};
export default CommonButton;

export const ExportCsv = ({
  filename,
  csvData
}) => {
  const [isExported, setIsExported] = useState(false)
  return (
    <CSVLink
      onClick={() => {
        setIsExported(true)
        setTimeout(() => {
          setIsExported(false)
        }, 1000)
        toastMessages("success", "Report Downloaded Successfully")
      }}
      className="commonButton"
      filename={`${filename}.csv`}
      data={csvData}
    ><i className="fa-solid fa-cloud-arrow-down"></i> <span>Export CSV{" "}
        {isExported &&
          <Spinner
            as="span"
            className="delete"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />}
      </span>
    </CSVLink>
  )
}