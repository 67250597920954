import React, { useState } from "react";
import { useEffect } from "react";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import CommonButton from "../common-ui/Button";
import chooseProductImg from "../shared/assets/images/choose-product-img.svg";
function PageNotFound() {
    let history = useHistory();
    const [loader, setLoader] = useState(false);
    function onReturnHandler() {
        setLoader(true);
        history.goBack();
    }
    return (
        <React.Fragment>
            <div className="interFaceWrap">
                <div className={`interFaceWrap__inner `}>
                    <div className="commonOuter">
                        <div className="headingText headingText--textLeft"></div>
                        <div className="graphChart">
                            <div className="chart whiteBg">
                                <div className="chartHead">
                                    <div className="chartHead__left">
                                    </div>
                                    <div className="chartHead__right">
                                    </div>
                                </div>
                                <div className="chooseProductViewStats">
                                    <img src={chooseProductImg} alt="chooseProductImg" />
                                    <h4>Page Not Found</h4>
                                </div>
                                <CommonButton
                                    buttonName="Go Back"
                                    type="2d"
                                    loader={loader}
                                    onClick={onReturnHandler}
                                    disabled={false}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default PageNotFound;
