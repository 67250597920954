import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const CustomTooltip = ({ children, tooltipText, placement = 'left' }) => {
    const tooltip = () => (
        < Tooltip id="tooltip" >
            <small>{tooltipText}</small>
        </Tooltip >
    );
    return (
        <OverlayTrigger
            placement={placement} overlay={tooltip()}>
            {children}
        </OverlayTrigger>
    )
}
export default CustomTooltip