import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Button, Table, Container, Row, Form, FloatingLabel, InputGroup, Pagination, Spinner } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector } from 'react-redux';
import { BsArrowDownUp, BsArrowDown, BsArrowUp } from 'react-icons/bs';
import { Link, useHistory } from 'react-router-dom';
import { allApiUrl } from '../../api/apiRoute';
import { apiDeleteMethod, apiGetMethod, apiPostMethod } from '../../api/rest';
import Loader from '../shared/spinner';
//Header
import Header from '../common/header';
import Sidebar from '../common/sidebar';
import toastMessages from '../toastMessages';
import { v4 as uuidv4 } from 'uuid';
import SidebarTwo from '../common/sidebarTwo';

function Categories() {
    let branchArray = useSelector((state) => state.counter.branchArray)
    const history = useHistory()
    let branchid = useSelector((state) => state.counter.currBranch)
    //Sidebar Toggle
    const [active, setActive] = React.useState(false)
    const [allCategoriesList, setAllCategoriesList] = useState([])
    const [isCategoryPageRefreshed, setIsCategoryPageRefreshed] = useState(false)
    const [lastEvaluatedKey, setLastEvaluatedKey] = useState('')
    const [loaderForFetchMore, setLoaderForFetchMore] = useState(true)
    const [searchedValue, setSearchedValue] = useState('')
    const [isLoading, setIsLoading] = useState(true)
    useEffect(() => {
        if ((branchArray.length > 1) || (branchArray && branchArray[0].value == "All")) {
            history.push('/dashboard')
        }
        setIsLoading(true)
        getAllCategories()
    }, [isCategoryPageRefreshed, branchid, branchArray])
    async function getAllCategories() {
        let branches = branchArray.map((item) => item.value)
        const header = {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            branchid: JSON.stringify(branches)
        };
        try {
            const response = await apiGetMethod(`${allApiUrl.view_all_categories}`, "", header);
            if (response.status === 200) {
                if (response.data.categories.LastEvaluatedKey) {
                    setLastEvaluatedKey(response.data.categories.LastEvaluatedKey.SK)
                }
                setAllCategoriesList(response.data.categories.Items)
                setIsLoading(false)
                if (response.data.categories.Items.length < 5) {
                    setLoaderForFetchMore(false)
                }
                else {
                    setLoaderForFetchMore(true)
                }
                // setIsCategoryPageRefreshed(false)
                toastMessages("success", response.data.message);
            }
        } catch (err) {
            // setIsCategoryPageRefreshed(false)
            console.log("err", err);
        }
    }
    async function onSearchHandler(e) {
        setSearchedValue(e.target.value)
        let branches = branchArray.map((item) => item.value)
        const header = {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            branchid: JSON.stringify(branches)
        };
        try {
            let data = {
                categoryname: e.target.value,
            }
            const response = await apiPostMethod(allApiUrl.search_category, data, header);
            if (response.status === 200) {
                if (response.data.message == "No records found.") {
                    setAllCategoriesList('');
                    setLoaderForFetchMore(false)
                    setLastEvaluatedKey('')
                }
                else {
                    setAllCategoriesList(response.data.data.Items);
                    if (response.data.data.LastEvaluatedKey) {
                        setLastEvaluatedKey(response.data.data.LastEvaluatedKey.SK)
                    }
                }
            }
        } catch (err) {
            console.log("err", err);
        }
    }
    let sidebarToggle = useSelector((state) => state.counter.sidebarToggle)
    const [sidebarType, setSidebarType] = useState('singleSidebar')
    let sidebarTypeRedux = useSelector((state) => state.counter.sidebarType)
    const [permissionData, setPermissionData] = useState({})
    return (
        <React.Fragment>
            <Header setActive={setActive} active={sidebarToggle} page="categories" />
            <div className="interFaceWrap">
                <Sidebar active={sidebarToggle} activePage="categories" permissionData={permissionData} />
                <div className={`interFaceWrap__inner ${sidebarTypeRedux == false ? "doubleSidebar" : "singleSidebar"}`}>
                    <div className="commonOuter">
                        <div className="pageHeader">
                            <Container fluid className="px-0">
                                <Row className="align-items-center">
                                    <div className="col">
                                        <div className="headingText headingText--textLeft headingText--rightPadding mb-0">
                                            <h1>Product Categories</h1>
                                        </div>
                                    </div>
                                    <div className="col-auto ms-auto">
                                        <div className="pageHeader__elements">
                                            <Form className="searchBarForm">
                                                <InputGroup className="searchBar">
                                                    <InputGroup.Text id="searchOrders"><i className="fa-solid fa-magnifying-glass"></i></InputGroup.Text>
                                                    <FloatingLabel controlId="floatingInput" label="Search Category Name">
                                                        <Form.Control type="search" placeholder="Search" onKeyDown={(e) => {
                                                            if (e.code === 'Enter') {
                                                                e.preventDefault()
                                                            }
                                                        }} onChange={onSearchHandler} value={searchedValue} />
                                                    </FloatingLabel>
                                                </InputGroup>
                                            </Form>
                                            <Link to="/add-category" className="commonButton">Create Category</Link>
                                        </div>
                                    </div>
                                </Row>
                            </Container>
                        </div>
                        <CategoriesTable
                            branchArray={branchArray}
                            isLoading={isLoading}
                            allCategoriesList={allCategoriesList}
                            setAllCategoriesList={setAllCategoriesList}
                            setIsCategoryPageRefreshed={setIsCategoryPageRefreshed}
                            isCategoryPageRefreshed={isCategoryPageRefreshed}
                            lastEvaluatedKey={lastEvaluatedKey}
                            setLastEvaluatedKey={setLastEvaluatedKey}
                            setLoaderForFetchMore={setLoaderForFetchMore}
                            loaderForFetchMore={loaderForFetchMore}
                        />
                    </div>
                </div>
                <SidebarTwo activePage="" setSidebarType={setSidebarType} sidebarType={sidebarType} setPermissionData={setPermissionData} />
            </div>
        </React.Fragment>
    )
}
export default Categories;

const CategoriesTable = ({
    allCategoriesList,
    setAllCategoriesList,
    setIsCategoryPageRefreshed,
    isCategoryPageRefreshed,
    lastEvaluatedKey,
    setLastEvaluatedKey,
    setLoaderForFetchMore,
    loaderForFetchMore,
    isLoading,
    branchArray
}) => {
    const currBranch = useSelector((state) => state.counter.currBranch)
    const history = useHistory()
    const [loader, setLoader] = useState(false)
    const [deletedId, setDeletedId] = useState('')
    const [sortingTypeIndex, setSortingTypeIndex] = useState("Up");
    const [sortingTypeName, setSortingTypeName] = useState("Up");
    const [sortingTypeDescription, setSortingTypeDescription] = useState("Up")
    console.log("1", isLoading)
    useEffect(() => {
        setSortingTypeIndex("Up");
        setSortingTypeName("Up");
        setSortingTypeDescription("Up")
    }, [currBranch.value, branchArray])
    async function onDeleteHandler(id) {
        setDeletedId(id)
        setLoader(true)
        let branches = branchArray.map((item) => item.value)
        const header = {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            branchid: JSON.stringify(branches)
        };
        try {
            const response = await apiDeleteMethod(allApiUrl.delete_category, id, header);
            if (response.status === 200) {
                setLoader(false)
                setIsCategoryPageRefreshed(!isCategoryPageRefreshed)
                toastMessages("success", response.data.message);
            }
        } catch (err) {
            setLoader(false)
            toastMessages("error", err.data.message);
            setIsCategoryPageRefreshed(!isCategoryPageRefreshed)
            console.log("err", err);
        }
    }
    const fetchMoreData = async () => {
        if (lastEvaluatedKey) {
            setLoaderForFetchMore(true)
            let branches = branchArray.map((item) => item.value)
            const header = {
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                branchid: JSON.stringify(branches)
            };
            try {
                let start = ''
                if (lastEvaluatedKey) {
                    start = `&startKey=${lastEvaluatedKey}`
                }
                const response = await apiGetMethod(`${allApiUrl.view_all_categories}?${start}`, "", header);
                if (response.status === 200) {
                    if (response.data.categories.Items.length < 1) {
                        setLoaderForFetchMore(false)
                    }
                    setAllCategoriesList(prevItems => prevItems.concat(response.data.categories.Items));
                    if (response.data.categories.LastEvaluatedKey) {
                        setLastEvaluatedKey(response.data.categories.LastEvaluatedKey.SK)
                    }
                    else {
                        setLastEvaluatedKey('')
                    }
                    toastMessages("success", response.data.message);
                }
            } catch (err) {
                console.log("err", err);
            }
        }
        else {
            setLoaderForFetchMore(false)
        }

    };
    function titleCase(str) {
        var splitStr = str.toLowerCase().split(' ');
        for (var i = 0; i < splitStr.length; i++) {
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        return splitStr.join(' ');
    }
    const SortingComponent = ({ field, sortingType }) => {
        return (
            <div className="pointer">
                {sortingType === "Up" ? (
                    <BsArrowUp onClick={() => handleSorting("Down", field)} />
                ) : (
                    <BsArrowDown onClick={() => handleSorting("Up", field)} />
                )}
            </div>
        );
    };
    function handleSorting(arrow, field) {
        if (field === "index") {
            // setSortingTypeIndex(arrow);
            // const sortedData = allEmployeeList.slice().reverse();
            // setAllEmployeeList(sortedData);
        }
        else if (field === "name") {
            const newSortingType = sortingTypeName === "Down" ? "Up" : "Down";
            setSortingTypeName(newSortingType);
            const sortedItems = [...allCategoriesList].sort((a, b) => {
                if (a.categoryname < b.categoryname) {
                    return newSortingType === "Up" ? -1 : 1;
                }
                if (a.categoryname > b.categoryname) {
                    return newSortingType === "Up" ? 1 : -1;
                }
                return 0;
            });
            setAllCategoriesList(sortedItems);
        }
        else if (field === "description") {
            const newSortingTypeDescription = sortingTypeDescription === "Down" ? "Up" : "Down";
            setSortingTypeDescription(newSortingTypeDescription);
            const sortedItems = [...allCategoriesList].sort((a, b) => {
                if (a.description < b.description) {
                    return newSortingTypeDescription === "Up" ? -1 : 1;
                }
                if (a.description > b.description) {
                    return newSortingTypeDescription === "Up" ? 1 : -1;
                }
                return 0;
            });
            setAllCategoriesList(sortedItems);
        }
    }
    return (
        <div className="commonTable">
            <div className="whiteBg">
                <div className="headingText headingText--dflex headingText--textLeft headingText--border">
                    <h4>All Product Categories</h4>
                </div>
                <InfiniteScroll
                    dataLength={allCategoriesList.length}
                    next={fetchMoreData}
                    hasMore={loaderForFetchMore}
                    className="equalHeightTable">
                    <div className="tableFixHead">
                        {!allCategoriesList.length && isLoading ? <Loader /> : <Table>
                            <thead>
                                <tr key={uuidv4()}>
                                    <th>S.No.</th>
                                    <th><div className='display_flex'>
                                        Category Name <SortingComponent field='name' sortingType={sortingTypeName} />
                                    </div></th>
                                    <th>Description</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {allCategoriesList && allCategoriesList.length > 0 ? allCategoriesList.map((item, index) => {
                                    return (
                                        <tr key={item.SK}>
                                            <td>#{index + 1}</td>
                                            <td>{titleCase(item.categoryname)}</td>
                                            <td><div className="descriptionText">{item.description}</div></td>
                                            <td>
                                                <div className="actionElements">
                                                    <button className="edit btn" onClick={() => history.push(`/edit-category?id=${item.SK}`)}><span>Edit</span> <i className="fa-solid fa-pen-to-square"></i></button>
                                                    <Button type="button" className="delete" onClick={() => onDeleteHandler(item.SK)}>
                                                        <>{
                                                            deletedId == item.SK &&
                                                                loader ? <Spinner
                                                                as="span"
                                                                className="delete"
                                                                animation="border"
                                                                size="sm"
                                                                role="status"
                                                                aria-hidden="true"
                                                            /> : <><span>Delete</span> <i className="fa-solid fa-trash-can"></i></>}
                                                        </>
                                                    </Button>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                }) :
                                    <tr key={uuidv4()}><td colSpan={4} className='text-center'>No Categories Available</td></tr>
                                }
                            </tbody>
                        </Table>}


                    </div>
                </InfiniteScroll>
            </div>
        </div>
    )
}
export { CategoriesTable }