import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Table, Button, Container, Row, Col, Form, Breadcrumb, Nav, Tab, InputGroup, FloatingLabel, Spinner } from 'react-bootstrap';
import queryString from "query-string";
import Header from '../common/header';
import Sidebar from '../common/sidebar';
import toastMessages from '../toastMessages';
import { apiGetMethod, apiPostMethod } from '../../api/rest';
import { allApiUrl } from '../../api/apiRoute';
import { titleCase } from '../../features/helper';
import Loader from '../shared/spinner';
import { v4 as uuidv4 } from "uuid"
import SidebarTwo from '../common/sidebarTwo';
import { formatDate_mm_dd_yyyy } from '../../features/helper';
import refund1 from '../shared/assets/images/refund1.svg';
import { CustomModal } from '../common-ui/Modal';
// import CommonButton from '../common-ui/Button';
import CustomTooltip from '../common/tooltip';
import CommonButton from '../common-ui/Button';
const CollapsibleChoices = ({ choices, note }) => {
    console.log('choices', choices)
    console.log('note---23', note)
    const [isCollapsed, setIsCollapsed] = useState(true);
    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };
    return (
        <div>
            <div onClick={toggleCollapse}
                style={{ display: 'flex', cursor: "pointer", marginTop: "4px", color: `${isCollapsed ? "" : "green"}` }}>
                <span >
                    {isCollapsed ? 'Show Choices' : 'Hide Choices'}
                </span>
                <div style={{
                    fontSize: "12px",
                    marginTop: "3px",
                    marginLeft: "4px"
                }}>
                    <i className={`fa-solid fa-chevron-${isCollapsed ? "down" : "up"}`} style={{}}></i>
                </div>
            </div>
            {!isCollapsed && (
                <div>
                    <>
                        {choices.map((item, idx) => {
                            console.log('item', item)
                            return (
                                <div key={uuidv4()}>
                                    <small style={{ display: 'block' }}>
                                        {item.name + `($${typeof item.price == "string" ? item.price.replace('$', '') : item.price})`}
                                    </small>
                                </div>
                            )
                        }
                        )}
                        {/* {note && <small><span className='darkText'>Note:</span>{note}</small>} */}
                    </>
                </div>
            )}
        </div>
    );
}

function ViewOrder() {
    let location = useLocation()
    let history = useHistory()
    const [permissionData, setPermissionData] = useState([])
    let currBranch = useSelector((state) => state.counter.currBranch)
    let sidebarToggle = useSelector((state) => state.counter.sidebarToggle)
    const branchArray = useSelector((state) => state.counter.branchArray)
    const [modalShow, setModalShow] = React.useState({ status: false, page: 'refundButton' });
    const [sidebarType, setSidebarType] = useState('doubleSidebar')
    let sidebarTypeRedux = useSelector((state) => state.counter.sidebarType)
    const [isRefundInitiated, setIsRefundInitiated] = useState(false)
    const [refundLoader, setRefundLoader] = useState(false)
    let branches = branchArray.map((item) => item.value)
    const header = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        branchid: JSON.stringify(branches)
    };
    let { orderId } = queryString.parse(location.search);
    const [isLoading, setIsLoading] = useState(false)
    useEffect(() => {
        getOrderDetail()
        if ((branchArray.length > 1)
            || (branchArray && branchArray[0].value == "All")) {
            history.push('/orders')
        }
    }, [currBranch.value, branchArray, isRefundInitiated])
    const [orderDetails, setOrderDetails] = useState({})
    async function getOrderDetail() {
        setIsLoading(true)
        try {
            const response = await apiGetMethod(allApiUrl.viewOrder, `/${orderId}`, header);
            if (response.status === 200) {
                let orderDetails = response.data.data[0]
                if (response.data.data[0].refund && !Object.keys(response.data.data[0].refund).length || !response.data.data[0].refund) {
                    // console.log('----93-----')
                    orderDetails = response.data.data[0]
                    delete orderDetails.refund
                }
                setOrderDetails(response.data.data[0])
                // console.log('-------100-------')
                /////////////////////////////////////////////////////////////////////////////////
                // let refundInfo = []
                // // console.log('-----101----', orderDetails)
                // orderDetails.refund &&
                //     orderDetails.refund.splitRefundInfo &&
                //     orderDetails.refund.splitRefundInfo.length > 0 &&
                //     orderDetails.refund.splitRefundInfo.forEach((item) => {
                //         refundInfo.push({
                //             userReceipt: item.userReceipt,
                //             refundAmount: item.refundAmount
                //         })
                //     })
                // // console.log('-----327----', refundInfo)
                // orderDetails.splitOrderData &&
                //     orderDetails.splitOrderData.splitItems &&
                //     orderDetails.splitOrderData.splitItems.forEach((item) => {
                //         // console.log('------328------', item)
                //         // let amountAfterGrautity = item.splitItemAmount - parseFloat(item.splitGratuityAmount)
                //         refundInfo.forEach((refundItem) => {
                //             if (refundItem.userReceipt == item.SplitRecieptName) {
                //                 item.splitItemAmount = (item.splitItemAmount - refundItem.refundAmount)
                //             }
                //         })
                //         item.splitItemAmount = item.splitItemAmount - parseFloat(item.splitGratuityAmount)
                //     })
                // // console.log('120')
                ///////////////////////??????????????????????????????????????????????????????????????//
                if ((response.data.data[0].branchId !== (branchArray && branchArray[0].value)) || (branchArray.length > 1) || (branchArray && branchArray[0].value == "All")) {
                    history.push('/orders')
                }
            }
            setIsLoading(false)
        } catch (err) {
            history.push('/orders')
            toastMessages("error", err.data.message);
            console.log("err", err);
        }
    }

    let InputField = () => {
        const [currentTab, setCurrentTab] = useState('Amount')
        const [refund, setRefund] = useState({
            orderNumber: orderDetails.orderNumber,
            refund: {
                refundAmount: '',
                refundDate: new Date().getTime(),
                refundItems: []
            }
        })
        const [isActiveChanged, setIsActiveChanged] = useState(false)
        const [refundTotalAmount, setRefundTotalAmount] = useState(0)
        useEffect(() => {
            if (orderDetails.chargeId) {
                setRefund({ ...refund, chargeId: orderDetails.chargeId })
            }
        }, [])
        function handleRefundAmount(e) {
            const alphabetRegex = /^[0-9.]*$/;
            if (e.target.value.match(alphabetRegex)) {
                setRefund((prev) => {
                    prev.refund.refundAmount = e.target.value
                    return { ...prev }
                });
                setRefund({ ...refund, orderStatus: "Partial Refunded" })
            }
        }
        async function onRefundHandler(e) {
            e.preventDefault()
            let obj = refund
            let orderTotal
            if (orderDetails && orderDetails.refund) {
                // orderTotal = (orderDetails.finalTotal - orderDetails.gratuity - orderDetails.refund.refundAmount).toFixed(2)
                orderTotal = (orderDetails.finalTotal - orderDetails.refund.refundAmount).toFixed(2)
            } else {
                // orderTotal = (orderDetails.finalTotal - orderDetails.gratuity).toFixed(2)
                orderTotal = (orderDetails.finalTotal).toFixed(2)
            }
            if (parseFloat(refund.refund.refundAmount) > parseFloat(orderTotal) || !refund.refund.refundAmount || refund.refund.refundAmount == 0) {
                if (!refund.refund.refundAmount || refund.refund.refundAmount == 0) {
                    toastMessages("error", "refund amount cannot be empty or zero")
                } else {
                    toastMessages('error', "Refund amount cannot be greater than the order total")
                }
            }
            else {
                //filter the selected item
                if (currentTab == "Item") {
                    obj.refund.refundItems = obj.refund.refundItems.filter((item) => item.active == true)
                }
                //  remove item in case of refund by amout 
                if (currentTab == "Amount") {
                    obj.refund.refundItems = []
                }
                //removing hte acitve and default quantity in case of refund by item
                else {
                    obj.refund.refundItems.forEach((item) => {
                        delete item.active
                        delete item.defaultQuantity
                    })
                }
                setRefundLoader(true)
                try {
                    const response = await apiPostMethod(allApiUrl.refundOrder, refund, header);
                    if (response.status === 200) {
                        toastMessages("success", "Refund Initiated Successfully");
                        obj = {}
                        setRefund({
                            orderNumber: orderDetails.orderNumber,
                            refund: {
                                refundAmount: '',
                                refundDate: new Date().getTime(),
                                refundItems: []
                            }
                        })
                        setRefundLoader(false)
                        setIsRefundInitiated(!isRefundInitiated)
                        setModalShow({ ...modalShow, status: false })
                    }

                } catch (err) {
                    setRefundLoader(false)
                    setModalShow({ ...modalShow, status: false })
                    toastMessages("error", err.data.message);
                }
            }
        }
        useEffect(() => {
            let orderTotal = 0
            let refundItemArray = []
            let discountPercentage = 0
            let singleItemPriceAfterDiscount = 0
            orderDetails && orderDetails.product.map((item) => {
                orderTotal = 0
                orderDetails.product.forEach((productItem) => {
                    orderTotal += parseFloat(productItem.total)
                })
                let initialQuantity = item.quantity
                let productPrice = 0
                if (item.modifier.length > 0) {
                    item.modifier.forEach((modifierItem) => {
                        console.log('modifierItem', modifierItem)
                        productPrice = productPrice + (typeof modifierItem.price == "string" ? parseFloat(modifierItem.price.replace('$', '')) : parseFloat(modifierItem.price))
                    })
                }
                if (orderDetails.discount) {
                    discountPercentage = (parseFloat(orderDetails.discount) / orderTotal) * 100
                    singleItemPriceAfterDiscount = (parseFloat(productPrice + parseFloat(item.productPrice)) -
                        ((parseFloat(discountPercentage) / 100) * parseFloat(productPrice + parseFloat(item.productPrice)))
                    )
                }
                else {
                    singleItemPriceAfterDiscount = parseFloat(productPrice + parseFloat(item.productPrice))
                }
                //Tax Calculation 
                let taxPercentage = parseFloat(orderDetails.taxPercentage.split('%')[0])
                singleItemPriceAfterDiscount = singleItemPriceAfterDiscount + ((taxPercentage / 100) * singleItemPriceAfterDiscount)
                //Grautity Calculation 
                let gratuityType = parseFloat(orderDetails.gratuityType.split('%')[0])
                singleItemPriceAfterDiscount = singleItemPriceAfterDiscount + ((gratuityType / 100) * singleItemPriceAfterDiscount)

                refundItemArray.push({
                    itemName: item.productName,
                    itemPrice: (singleItemPriceAfterDiscount).toFixed(2),
                    itemQuantity: initialQuantity,
                    defaultQuantity: item.quantity,
                    itemRefundPrice: (singleItemPriceAfterDiscount * initialQuantity).toFixed(2),
                    active: false
                })
            })
            if (orderDetails.refund) {
                let refundInformation = orderDetails.refund.refundInfo || orderDetails.refund.refundId
                refundInformation && refundInformation.length >= 1 && refundInformation.forEach((item) => {
                    item.refundItems && item.refundItems.length > 0 && item.refundItems.forEach((refundObj) => {
                        let isFoundIndex = refundItemArray.findIndex((findRefund) => findRefund.itemName == refundObj.itemName)
                        refundItemArray[isFoundIndex].itemQuantity = refundItemArray[isFoundIndex].itemQuantity - refundObj.itemQuantity
                        refundItemArray[isFoundIndex].itemRefundPrice = refundItemArray[isFoundIndex].itemRefundPrice - refundObj.itemRefundPrice
                        refundItemArray[isFoundIndex].defaultQuantity = refundItemArray[isFoundIndex].itemQuantity
                    })
                })
            }
            // console.log('222', refundItemArray)
            refundItemArray = refundItemArray.filter((removingItem) => !(removingItem.itemQuantity == 0))
            setRefund((prev) => {
                // prev.refund.refundAmount =
                prev.refund.refundItems = refundItemArray
                return { ...prev }
            })
        }, [])
        function increaseQuantity(index) {
            setIsActiveChanged(!isActiveChanged)
            setRefund((prev) => {
                prev.refund.refundItems[index].itemQuantity = prev.refund.refundItems[index].itemQuantity + 1
                prev.refund.refundItems[index].itemRefundPrice =
                    (parseFloat(prev.refund.refundItems[index].itemRefundPrice) + parseFloat(prev.refund.refundItems[index].itemPrice)).toFixed(2)
                return { ...prev }
            })
        }
        function decreaseQuantity(index) {
            setIsActiveChanged(!isActiveChanged)
            setRefund((prev) => {
                prev.refund.refundItems[index].itemQuantity = prev.refund.refundItems[index].itemQuantity - 1
                prev.refund.refundItems[index].itemRefundPrice =
                    (parseFloat(prev.refund.refundItems[index].itemRefundPrice - parseFloat(prev.refund.refundItems[index].itemPrice))).toFixed(2)
                return { ...prev }
            })
        }
        function onActiveHandler(index) {
            setIsActiveChanged(!isActiveChanged)
            setRefund((prev) => {
                prev.refund.refundItems[index].active = !(prev.refund.refundItems[index].active)
                return { ...prev }
            })
        }
        useEffect(() => {
            let refundItem = 0
            refund.refund.refundItems.forEach((item) => {
                if (item.active) {
                    refundItem = parseFloat(refundItem + parseFloat(item.itemRefundPrice))
                }
            })
            // let roundOfCorrection = Math.abs(((orderDetails.finalTotal - orderDetails.gratuity) - refundItem).toFixed(2))
            // if (roundOfCorrection <= 0.05) {
            //     refundItem = (orderDetails.finalTotal - orderDetails.gratuity)
            // }
            let roundOfCorrection = Math.abs(((orderDetails.finalTotal) - refundItem).toFixed(2))
            if (roundOfCorrection <= 0.05) {
                refundItem = (orderDetails.finalTotal)
            }
            // console.log('refund', refund)
            setRefundTotalAmount(refundItem)
            setRefund((prev) => {
                // console.log('---274---', prev)
                prev.refund.refundAmount = parseFloat(refundItem).toFixed(2)
                prev.orderStatus = 'Partial Refunded'
                return { ...prev }
            })
        }, [isActiveChanged])
        useEffect(() => {
            if (currentTab == "Amount") {
                let updated
                orderDetails && orderDetails.refund ?
                    // updated = (orderDetails.finalTotal - orderDetails.gratuity - orderDetails.refund.refundAmount).toFixed(2) :
                    // updated = (orderDetails.finalTotal - orderDetails.gratuity).toFixed(2)
                    updated = (orderDetails.finalTotal - orderDetails.refund.refundAmount).toFixed(2) :
                    updated = (orderDetails.finalTotal).toFixed(2)
                setRefund((prev) => {
                    prev.refund.refundAmount = updated
                    return { ...prev }
                })
            }
            else {
                setRefund((prev) => {
                    prev.refund.refundAmount = 0
                    return { ...prev }
                })
            }
        }, [currentTab])
        let SplitRefundScreen = () => {
            useEffect(() => {
                let refundedAmount = 0
                if (orderDetails.refund) {
                    refundedAmount = orderDetails.refund.refundAmount
                }
                setSplitRefundInformation({
                    ...splitRefundInformation,
                    splitRefund: {
                        refundAmount: (orderDetails.finalTotal - refundedAmount).toFixed(2)
                    }
                })
            }, [])
            function handleSplitRefundAmount(e) {
                const alphabetRegex = /^[0-9.]*$/;
                if (e.target.value.match(alphabetRegex)) {
                    setSplitRefundInformation((prev) => {
                        prev.splitRefund.refundAmount = e.target.value
                        return { ...prev }
                    });
                }
            }
            const [splitLoader, setSplitLoader] = useState(false)
            const [splitRefundInformation, setSplitRefundInformation] = useState({
                "orderNumber": orderDetails.orderNumber,
                "orderStatus": "Partial Refunded",
                "splitRefund": {
                    "refundAmount": 0
                }
            })
            const onSplitRefundHandler = async (e) => {
                e.preventDefault()
                setSplitLoader(true)
                try {
                    const response = await apiPostMethod(allApiUrl.splitRefund, splitRefundInformation, header)
                    if (response.status == 200) {
                        toastMessages("success", "Refunded Successfully")
                        setIsRefundInitiated(!isRefundInitiated)
                        setSplitLoader(false)
                    }
                }
                catch (err) {
                    setSplitLoader(false)
                    console.log('err', err)
                }
            }
            return (
                <div className="refundMenuModal">
                    <Tab.Container id="settingsDetailsTab" defaultActiveKey="general">
                        <Tab.Content className="commonTabsContent">
                            <div className="commonTable">
                                <Table responsive striped>
                                    <thead>
                                        <tr>
                                            <th>Receipt#</th>
                                            <th></th>
                                            <th>Type</th>
                                            <th></th>
                                            <th>Total</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {orderDetails?.splitOrderData?.splitItems?.map((item) => {
                                            console.log('splitItemAmount', item)
                                            let reciptNumber = parseInt(item.SplitRecieptName.split('#')[1]) + 1
                                            return (
                                                <tr key={item.splitPaymentChargeID}>
                                                    <td>{"Receipt" + " #" + reciptNumber}</td>
                                                    <td></td>
                                                    <td><div>{item.splitPaymentType}</div> </td>
                                                    <td></td>
                                                    <td><div>${item.splitItemAmount}</div> </td>
                                                </tr>
                                            )
                                        })
                                        }
                                        <br />
                                    </tbody>
                                </Table>
                            </div>
                            <Tab.Pane eventKey="general">
                                <Form>
                                    <Form.Group className="form-group">
                                        {<div className="d-flex mt-1">
                                            <Form.Control
                                                className="me-3"
                                                type="text"
                                                placeholder="$10"
                                                value={splitRefundInformation.splitRefund.refundAmount}
                                                onChange={handleSplitRefundAmount}
                                                required />
                                            <CommonButton
                                                buttonName="Refund"
                                                type="half"
                                                loader={false}
                                                onClick={(e) => onSplitRefundHandler(e)}
                                            />
                                        </div>
                                        }
                                    </Form.Group>
                                    <div style={{
                                        marginBottom: "12px"
                                    }}>
                                        {splitLoader ? <h6 className='refundClass'>Refunding Please Wait......</h6> :
                                            orderDetails && orderDetails.refund ?
                                                <h6>Order Total:{(orderDetails.finalTotal - orderDetails.refund.refundAmount).toFixed(2)}</h6>
                                                : <h6>Order Total:{(orderDetails.finalTotal).toFixed(2)}</h6>}
                                    </div>
                                </Form>
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </div >
            )
        }
        return (
            <div className="refundMenuModal">
                {orderDetails.splitOrderData && Object.keys(orderDetails.splitOrderData).length ? <SplitRefundScreen /> :
                    <Tab.Container id="settingsDetailsTab" defaultActiveKey="general">
                        <Nav className="commonTabs">
                            <Nav.Item onClick={() => {
                                setCurrentTab('Amount')
                            }}>
                                <Nav.Link eventKey="general">Refund By Amount</Nav.Link>
                            </Nav.Item>
                            <Nav.Item onClick={() => {
                                setCurrentTab('Item')
                            }
                            }>
                                <Nav.Link eventKey="orders">Refund By Items</Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <Tab.Content className="commonTabsContent">
                            <Tab.Pane eventKey="general">
                                <Form>

                                    <Form.Group className="form-group">
                                        {refundLoader ?
                                            <div className="d-flex mt-1 justify-content-center">
                                                <Spinner animation="border" role="status"
                                                //  style={{
                                                //     justifyContent: 'center'
                                                // }}
                                                >
                                                    <span className="visually-hidden">Loading...</span>
                                                </Spinner>
                                            </div> :
                                            <div className="d-flex mt-1">
                                                <Form.Control
                                                    className="me-3"
                                                    type="text"
                                                    placeholder="$10"
                                                    value={refund.refund.refundAmount}
                                                    onChange={(e) => handleRefundAmount(e)}
                                                    required />
                                                <CommonButton
                                                    buttonName="Refund"
                                                    type="half"
                                                    loader={false}
                                                    onClick={(e) => onRefundHandler(e)}
                                                />
                                            </div>
                                        }
                                    </Form.Group>
                                    <div style={{
                                        marginBottom: "12px"
                                    }}>
                                        {refundLoader ? <h6 className='refundClass'>Refunding Please Wait......</h6> :
                                            orderDetails && orderDetails.refund ?
                                                // <h6>Order Total:{(orderDetails.finalTotal - orderDetails.gratuity - orderDetails.refund.refundAmount).toFixed(2)}</h6>
                                                // : <h6>Order Total:{(orderDetails.finalTotal - orderDetails.gratuity).toFixed(2)}</h6>
                                                <h6>Order Total:{(orderDetails.finalTotal - orderDetails.refund.refundAmount).toFixed(2)}</h6>
                                                : <h6>Order Total:{(orderDetails.finalTotal).toFixed(2)}</h6>
                                        }
                                    </div>
                                </Form>
                            </Tab.Pane>
                            <Tab.Pane eventKey="orders">
                                <Form >
                                    <Form.Group className="form-group">
                                        <div className="commonTable">
                                            <Table responsive>
                                                <thead>
                                                    <tr> <th colSpan="4">Select items to be refunded</th>   </tr>
                                                </thead>
                                                <tbody>
                                                    {refund && refund.refund.refundItems.length > 0 && refund.refund.refundItems.map((item, index) => {
                                                        return (
                                                            <tr>
                                                                <td><div className={`${item.active ? "checkIconGreen" : "checkIconBlack"}`}><i class="fa-regular fa-circle-check"
                                                                    onClick={() => onActiveHandler(index)}
                                                                ></i></div></td>
                                                                <td>
                                                                    <div
                                                                        className="refundItem"
                                                                        style={{ textTransform: 'capitalize' }}
                                                                    >
                                                                        <h6>{item.itemName}</h6>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="quantity-input">
                                                                        <button
                                                                            disabled={!item.active || item.itemQuantity <= 1}
                                                                            type="button"
                                                                            className="quantity-input__modifier quantity-input__modifier--left"
                                                                            onClick={() => decreaseQuantity(index)}
                                                                        >
                                                                            <i class="fa-solid fa-minus"></i>
                                                                        </button>
                                                                        <input
                                                                            disabled={true}
                                                                            className={`quantity-input__screen ${!item.active && "showError"}`}
                                                                            type="text"
                                                                            value={item.itemQuantity}
                                                                        />
                                                                        <button
                                                                            disabled={!item.active || (item.defaultQuantity == item.itemQuantity)}
                                                                            type="button"
                                                                            className="quantity-input__modifier quantity-input__modifier--right"
                                                                            onClick={() => increaseQuantity(index)}
                                                                        >
                                                                            <i class="fa-solid fa-plus"></i>
                                                                        </button>
                                                                    </div>
                                                                </td>
                                                                <td><strong>${(item.itemQuantity * item.itemPrice).toFixed(2)}</strong></td>
                                                            </tr>
                                                        )
                                                    })
                                                    }
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        {orderDetails && orderDetails.refund ?
                                                            <td>Order Total: <strong>{(orderDetails.finalTotal - orderDetails.refund.refundAmount).toFixed(2)}</strong></td>
                                                            :
                                                            <td>Order Total: <strong>{(orderDetails.finalTotal).toFixed(2)}</strong></td>
                                                        }
                                                        {/* {orderDetails && orderDetails.refund ?
                                                            <td>Order Total: <strong>{(orderDetails.finalTotal - orderDetails.gratuity - orderDetails.refund.refundAmount).toFixed(2)}</strong></td>
                                                            :
                                                            <td>Order Total: <strong>{(orderDetails.finalTotal - orderDetails.gratuity).toFixed(2)}</strong></td>

                                                        } */}
                                                        <td colSpan="2"></td>
                                                        <td>Refund: <strong>{refundTotalAmount.toFixed(2)}</strong></td>
                                                    </tr>
                                                </tfoot>
                                            </Table>
                                            <div className="buttonOuter">
                                                <CommonButton buttonName="Refund" type="half" loader={false} onClick={(e) => onRefundHandler(e)} />
                                            </div>
                                        </div>
                                    </Form.Group>
                                </Form>
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                }
            </div >
        )
    }
    let RefundDisplay = () => {
        return (
            <div className="orderViewPrice">
                <div className="whiteBg small">
                    <div className="commonTable">
                        <Table responsive striped>
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    {orderDetails &&
                                        orderDetails.refund &&
                                        orderDetails.refund.refundInfo.length > 0 ? <th></th> : <th>Type</th>
                                    }
                                    <th>Amount</th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th>Date</th>

                                </tr>
                            </thead>
                            <tbody>
                                {orderDetails &&
                                    orderDetails.refund &&
                                    orderDetails.refund.refundInfo.length > 0 ?
                                    orderDetails.refund.refundInfo.map((refundItem, index, array) => {
                                        return (
                                            <tr key={`${uuidv4()}-${index}`}>
                                                <td>{index + 1}</td>
                                                <td></td>
                                                <td><div>${parseFloat(refundItem.amount).toFixed(2)}</div> </td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td><div>
                                                    {formatDate_mm_dd_yyyy(refundItem.refundDate, true)}
                                                </div> </td>
                                            </tr>
                                        )
                                    }) : orderDetails &&
                                        orderDetails.refund &&
                                        orderDetails.refund.splitRefundInfo.length > 0 ?
                                        orderDetails.refund.splitRefundInfo.map((item, index, arrayList) => {
                                            function groupRefundInfoByReceipt(refundInfo) {
                                                const groupedData = {};

                                                for (const entry of refundInfo) {
                                                    const userReceipt = entry.userReceipt;

                                                    if (!groupedData[userReceipt]) {
                                                        groupedData[userReceipt] = [];
                                                    }
                                                    groupedData[userReceipt].push(entry);
                                                }
                                                const resultArray = [];
                                                for (const receipt in groupedData) {
                                                    if (groupedData.hasOwnProperty(receipt)) {
                                                        resultArray.push({ userReceipt: receipt, refunds: groupedData[receipt] });
                                                    }
                                                }
                                                return resultArray;
                                            }
                                            let splitRefundHistory = groupRefundInfoByReceipt(arrayList)
                                            let reciptNumber = parseInt(item.userReceipt.split('#')[1]) + 1
                                            return (
                                                <tr key={`${uuidv4()}-${index}`}>
                                                    <td>{index + 1}</td>
                                                    <td> <div style={{ fontWeight: 500 }}>{"Receipt" + " #" + reciptNumber}</div> </td>
                                                    <td>${item.amount}</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>{formatDate_mm_dd_yyyy(parseInt(item.refundDate), true)} </td>
                                                </tr>
                                            )
                                        }) :
                                        <tr key={`${uuidv4()}`}>
                                            <td></td>
                                            <td></td>
                                            <td>No Refund Available</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                }
                                <br />
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <React.Fragment>
            <Header active={sidebarToggle} />
            <CustomModal
                show={modalShow.status}
                Title={`Refund ${modalShow.page == "refundDisplay" ? "History" : "Menu"}`}
                onHide={() => setModalShow({ ...modalShow, status: false })}
            >
                {modalShow.page == "refundDisplay" ? <RefundDisplay /> : <InputField />}
            </CustomModal>

            <div className="interFaceWrap">
                <Sidebar active={sidebarToggle} activePage="orders" permissionData={permissionData} />
                <div className={`interFaceWrap__inner ${sidebarTypeRedux == false ? "doubleSidebar" : "singleSidebar"}`}>
                    <div className="commonOuter">
                        <div className="pageHeader">
                            <Container fluid className="px-0">
                                <Row className="align-items-center">
                                    <div className="col">
                                        <div className="headingText headingText--textLeft mb-0">
                                            <h1>View Order</h1>
                                        </div>
                                    </div>
                                    <div className="col-auto ms-auto onlyButtons">
                                        <div className="pageHeader__elements">
                                            {(orderDetails.taxPercentage && (orderDetails.orderStatus == "Completed" || orderDetails.orderStatus == "Fulfilled" || orderDetails.orderStatus == "Partial Refunded")) &&
                                                <Button
                                                    className="commonButton"
                                                    onClick={() => setModalShow({ page: 'refundButton', status: true })}>
                                                    <img src={(refund1)}
                                                    />
                                                    <p>Refund</p>
                                                </Button>
                                            }
                                        </div>
                                    </div>
                                </Row>
                            </Container>
                        </div>

                        {
                            isLoading ? <Loader /> : <div className="pageBody">
                                <Row>
                                    <Col md={8}>
                                        <div className="viewOrder">
                                            <div className="orderListing">
                                                <div className="commonBgHead commonBgHead--bottomRadiusNone">Order - #{orderDetails?.orderId}</div>
                                                <div className="whiteBg whiteBg--topRadiusNone small">
                                                    <div className="orderListing__list">
                                                        <ul>
                                                            <li key="Type" ><strong>Type:</strong> {orderDetails?.orderType?.type}</li>
                                                            <li key="Status"><strong>Status:</strong> {orderDetails?.orderStatus ? titleCase(orderDetails?.orderStatus) || "" : ''}</li>
                                                            <li key="Transaction Type"><strong>Transaction Type:</strong>
                                                                {orderDetails?.transactionType == "Split" ? orderDetails?.splitOrderData?.splitType : orderDetails?.transactionType}
                                                            </li>
                                                            {(orderDetails?.orderType?.type == "Dine-In" || orderDetails?.orderType?.type == "Dine-in") &&
                                                                <li key="Table Number"><strong>Table Number:</strong> {orderDetails.tableNumber || orderDetails.table}</li>}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="orderViewPrice">
                                                <div className="whiteBg small">
                                                    <div className="commonTable">
                                                        <Table responsive striped>
                                                            <thead>
                                                                <tr>
                                                                    {/* <th>STATUS</th> */}
                                                                    <th>ITEM</th>
                                                                    <th></th>
                                                                    <th></th>
                                                                    <th>COST</th>
                                                                    <th>QUANTITY</th>
                                                                    <th>TOTAL</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {orderDetails && orderDetails.product && orderDetails.product.map((productItem, index) => {
                                                                    console.log('productItem', productItem)
                                                                    let total = 0
                                                                    if (typeof productItem.productPrice == "string") {
                                                                        total = parseFloat(productItem.productPrice.replace('$', ''))
                                                                    } else {
                                                                        total = parseFloat(productItem.productPrice)
                                                                    }
                                                                    let choices = []
                                                                    if (productItem.modifier) {
                                                                        productItem.modifier.forEach((choiceItem) => {

                                                                            if (typeof choiceItem.price == "string") {
                                                                                total += parseFloat(choiceItem.price.replace('$', ''));
                                                                            } else {
                                                                                total += parseFloat(choiceItem.price);
                                                                            }
                                                                        })
                                                                    }
                                                                    productItem.modifier.forEach((modifierItem) => {
                                                                        return (
                                                                            choices.push({ name: modifierItem.name, price: modifierItem.price })
                                                                        )
                                                                    })
                                                                    console.log("choices", choices)
                                                                    // let refund = { refundAmount: '', refundQuantity: '' }
                                                                    // if (orderDetails.hasOwnProperty('refund')) {
                                                                    //     orderDetails.refund.refundItems.forEach((item) => {
                                                                    //         // console.log('148', parseFloat(item.Price) == parseFloat(total), parseFloat(item.Price), parseFloat(total))
                                                                    //         if (parseFloat(item.itemPrice).toFixed(2) == parseFloat(total).toFixed(2)) {
                                                                    //             refund = { refundQuantity: item.itemQuantity, refundAmount: item.itemRefundPrice }
                                                                    //         }
                                                                    //     })
                                                                    // }
                                                                    // console.log('----155-----', choices)
                                                                    console.log('total', total)
                                                                    return (
                                                                        <tr key={`${uuidv4()}-${index}`}>
                                                                            <td>
                                                                                <div style={{ fontWeight: 500, textDecoration: `${productItem?.isAcceptedDeclined == "declined" ? 'line-through' : ''}` }}> {`${titleCase(productItem?.productName)}`}</div>
                                                                                {productItem.note && <small><span className='darkText'>Note:</span>{productItem.note}</small>}
                                                                                {choices && choices.length > 0 &&
                                                                                    <div>
                                                                                        <CollapsibleChoices choices={choices} note={productItem.note} />
                                                                                    </div>}
                                                                            </td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td>${total.toFixed(2)}</td>
                                                                            <td>
                                                                                <div>
                                                                                    <span style={{ color: "grey" }}>x</span> {productItem.quantity}
                                                                                </div>
                                                                                {/* {refund.refundQuantity && <div style={{ color: 'red' }}><TbArrowBearLeft size={10} />{(refund.refundQuantity)}</div>} */}
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                    ${(total * productItem.quantity).toFixed(2)}
                                                                                </div>
                                                                                {/* {refund.refundAmount && <div className="dollerArrowIcon" style={{ color: 'red' }}><TbArrowBearLeft size={10} />$ {parseFloat(refund.refundAmount).toFixed(2)}</div>} */}
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                                }
                                                                <br />
                                                            </tbody>
                                                            <tfoot >
                                                                <tr>
                                                                    <td>&nbsp;</td>
                                                                    <td>&nbsp;</td>
                                                                    <td>&nbsp;</td>
                                                                    <td>&nbsp;</td>
                                                                    <td className="text-end">Subtotal:</td>
                                                                    <td className="text-end"><span className="subtotal">${(orderDetails && orderDetails.subTotal ? orderDetails.subTotal.toFixed(2) : 0)}</span></td>
                                                                </tr>

                                                                <tr>
                                                                    <td>&nbsp;</td>
                                                                    <td>&nbsp;</td>
                                                                    <td>&nbsp;</td>
                                                                    <td>&nbsp;</td>
                                                                    <td className="text-end">Discount : {orderDetails && orderDetails.discountType
                                                                        ? (orderDetails.discountType == "0$" || orderDetails.discountType == "0%" ||
                                                                            orderDetails.discountType == "$" || orderDetails.discountType == "%")
                                                                            ? '0%' : orderDetails.discountType : 0}</td>
                                                                    <td className="text-end"><span className="discount">-${(parseFloat(orderDetails?.discount).toFixed(2) || 0)}</span></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>&nbsp;</td>
                                                                    <td>&nbsp;</td>
                                                                    <td>&nbsp;</td>
                                                                    <td>&nbsp;</td>
                                                                    <td className="text-end">Tax : {orderDetails && orderDetails.taxPercentage || 0} </td>
                                                                    <td className="text-end">{orderDetails?.tax ? <span className="salesTax">${(Math.round(orderDetails && orderDetails?.tax * 100) / 100).toFixed(2) || 0.00}</span> : <span>0.00</span>}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>&nbsp;</td>
                                                                    <td>&nbsp;</td>
                                                                    <td>&nbsp;</td>
                                                                    <td>&nbsp;</td>
                                                                    <td className="text-end">Gratuity: {orderDetails && orderDetails.gratuityType || 0}</td>
                                                                    <td className="text-end">{orderDetails?.gratuity ? <span className="salesTax">${(Math.round(orderDetails && orderDetails?.gratuity * 100) / 100).toFixed(2) || 0.00}</span> : <span>$0.00</span>}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>&nbsp;</td>
                                                                    <td>&nbsp;</td>
                                                                    <td>&nbsp;</td>
                                                                    <td>&nbsp;</td>
                                                                    <td className="text-end">Order Total:</td>
                                                                    <td className="text-end">{orderDetails?.subTotal
                                                                        ? orderDetails?.refund ?
                                                                            <div>
                                                                                <span className="orderTotal" style={{ textDecoration: 'line-through' }}>${parseFloat(orderDetails?.finalTotal).toFixed(2) || 0}</span>
                                                                                <span>&nbsp;</span>
                                                                                <span>${parseFloat(orderDetails?.finalTotal - orderDetails?.refund?.refundAmount).toFixed(2)}</span>
                                                                            </div> :
                                                                            <span className="orderTotal">${parseFloat(orderDetails?.finalTotal).toFixed(2) || 0}</span>
                                                                        : <span>$0.00</span>
                                                                    }</td></tr>
                                                                <tr>
                                                                    <td>&nbsp;</td>
                                                                    <td>&nbsp;</td>
                                                                    <td>&nbsp;</td>
                                                                    <td>&nbsp;</td>
                                                                    <td className="red text-end">Refunded:</td>
                                                                    <td className="red text-end"><span className="refunded">-${orderDetails?.refund ?
                                                                        parseFloat(orderDetails?.refund?.refundAmount).toFixed(2) : "0.00"}</span>
                                                                        <CustomTooltip
                                                                            placement='top'
                                                                            tooltipText="View The Refund History"
                                                                        >
                                                                            <span className="infoIcon"
                                                                                style={{ cursor: 'pointer', marginLeft: '12px' }}
                                                                                onClick={() => setModalShow({ page: 'refundDisplay', status: true })}
                                                                            ><i className="fa-solid fa-circle-info"></i></span>
                                                                        </CustomTooltip>
                                                                    </td>
                                                                </tr>
                                                            </tfoot>
                                                        </Table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="orderDetail d-none">
                                                <div className="commonBgHead commonBgHead--bottomRadiusNone">Order Detail</div>
                                                <div className="whiteBg whiteBg--topRadiusNone small">
                                                    <Form className="half">
                                                        <Form.Group className="form-group group-flex">
                                                            <Form.Label>Online Order ID</Form.Label>
                                                            <Form.Control type="number" disabled value={orderDetails?.orderId} />
                                                        </Form.Group>
                                                        <Form.Group className="form-group group-flex">
                                                            <Form.Label>Online Order Type</Form.Label>
                                                            <Form.Control type="text" disabled value={orderDetails?.orderType?.type} />
                                                        </Form.Group>
                                                        <Form.Group className="form-group group-flex">
                                                            <Form.Label>Local Order Number</Form.Label>
                                                            <Form.Control type="number" disabled value={orderDetails?.localOrder} />
                                                        </Form.Group>
                                                    </Form>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div className="mt-md-0 mt-4">
                                            <div className="commonBgHead commonBgHead--bottomRadiusNone">Customer Details</div>
                                            <div className="whiteBg whiteBg--minHeight whiteBg--topRadiusNone small">
                                                <Form>
                                                    <Form.Group className="form-group">
                                                        <Form.Label>Name</Form.Label>
                                                        {/* {console.log('orderDetails', orderDetails)} */}
                                                        <Form.Control type="text"
                                                            disabled
                                                            value={orderDetails?.orderType?.name || "-"}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group className="form-group">
                                                        <Form.Label>Email</Form.Label>
                                                        <Form.Control type="email" className='notCapital' disabled value={orderDetails?.orderType?.email} />
                                                    </Form.Group>
                                                    <Form.Group className="form-group">
                                                        <Form.Label>Phone</Form.Label>
                                                        <Form.Control type="text" disabled value={orderDetails?.orderType?.phoneNumber} />
                                                    </Form.Group>
                                                    <Form.Group className="form-group">
                                                        <Form.Label># of Guests</Form.Label>
                                                        <Form.Control type="number" disabled value={orderDetails?.orderType?.noOfGuest} />
                                                    </Form.Group>
                                                    <Form.Group className="form-group">
                                                        <Form.Label>Register ID</Form.Label>
                                                        <Form.Control type="text" disabled value={orderDetails?.registerId} />
                                                    </Form.Group>
                                                    <Form.Group className="form-group">
                                                        <Form.Label>Employee Name</Form.Label>
                                                        <Form.Control type="text" disabled value={orderDetails?.employeeName ? (titleCase(orderDetails?.employeeName)) : "  "} />
                                                    </Form.Group>
                                                    <Form.Group className="form-group">
                                                        <Form.Label>{orderDetails && orderDetails?.orderType && orderDetails?.orderType?.type == "Delivery" ? "Address" : "Order Notes"}</Form.Label>
                                                        <Form.Control as="textarea" disabled value={orderDetails?.orderType?.type == "Delivery" && orderDetails?.origin == "webApplication" ?
                                                            orderDetails?.orderType?.streetName + " " + orderDetails?.orderType?.town + " " + orderDetails?.orderType?.postCode
                                                            : orderDetails?.orderType?.note} />
                                                    </Form.Group>
                                                </Form>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        }

                    </div >
                </div >
                <SidebarTwo activePage="" setSidebarType={setSidebarType} sidebarType={sidebarType} setPermissionData={setPermissionData} />
            </div >
        </React.Fragment >
    )
}
export default ViewOrder;
