import React, { useState } from "react";
import { Button, Container, Row } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from "query-string";

//Header
import Header from '../common/header';
import Sidebar from '../common/sidebar';
import { CategoriesItem } from "../add-category";
import { useEffect } from "react";
import { apiGetMethod, apiPutMethod } from "../../api/rest";
import { allApiUrl } from "../../api/apiRoute";
import toastMessages from "../toastMessages";
import CommonButton from "../common-ui/Button";
import { useSelector } from "react-redux";
import SidebarTwo from "../common/sidebarTwo";
import Loader from "../shared/spinner";

function EditCategories() {
    let branchArray = useSelector((state) => state.counter.branchArray)
    const [sidebarType, setSidebarType] = useState('singleSidebar')
    const [permissionData, setPermissionData] = useState({})
    const [isLoading, setIsLoading] = useState(true)
    let branches = branchArray.map((item) => item.value)
    const header = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        branchid: JSON.stringify(branches)
    };
    let branchid = useSelector((state) => state.counter.currBranch)
    const location = useLocation();
    const history = useHistory()
    const [active, setActive] = React.useState(false);
    useEffect(() => {
        getCategoryDetail()
    }, [branchid.value, branchArray])
    let { id } = queryString.parse(location.search);
    const [loader, setLoader] = useState({
        save: false,
        delete: false
    })
    const [category, setCategory] = useState({
        categoryname: '',
        description: '',
    });
    const [errorInput, setErrorInput] = useState({
        categoryname: false,
        description: false
    })
    const [currCategory, setCurrCategory] = useState({
    })
    async function onDeleteHandler() {
        history.push('/categories')
    }
    async function updateCategoryHandler(e) {
        e.preventDefault()
        setLoader({ ...loader, save: true })
        if (category.categoryname) {
            try {
                const { SK, PK, branchId, ...categoryData } = category
                const formData = {}
                if (category.categoryname !== currCategory.categoryname) {
                    formData['categoryname'] = category.categoryname.replace(/\//g, "/ ")
                }
                if (category.description !== currCategory.description) {
                    formData['description'] = category.description
                }
                const response = await apiPutMethod(
                    allApiUrl.update_category,
                    formData,
                    header,
                    id
                );
                if (response.status === 200) {
                    toastMessages("success", response.data.message);
                    setCategory({
                        categoryname: '',
                        description: ''
                    });
                    setTimeout(() => {
                        history.push('/categories')
                    }, 1000)
                    setLoader({ ...loader, save: false });
                }
            }
            catch (err) {
                setLoader({ ...loader, save: false });
                toastMessages("error", err.data.message);
            }
        }
        else {
            setLoader({ ...loader, save: false });
            const emptyFields = [];
            for (const property in category) {
                if (category[property] === '') { emptyFields.push(property) }
            }
            const errorInputCopy = { ...errorInput };
            emptyFields.forEach(field => errorInputCopy[field] = true);
            setErrorInput(errorInputCopy)
            toastMessages("error", "Please fill the required field")
        }
    }
    async function getCategoryDetail() {
        try {
            const response = await apiGetMethod(allApiUrl.view_category, `/${id}`, header);
            if (response.status === 200) {
                setCategory({ ...response.data.data.Items[0], id: parseInt(id) })
                setCurrCategory(response.data.data.Items[0])
                setIsLoading(false)
                if ((branchArray.length > 1)
                    || (branchArray && branchArray[0].value == "All")
                    || (branchArray && branchArray[0].value !== response.data.data.Items[0].branchId)) {
                    history.push('/categories')
                }
                toastMessages("success", response.data.message);
            }
        } catch (err) {
            toastMessages("error", err.data.message);
            if (err.data.message == "Session Expired.") {
                localStorage.clear();
                history.push('/login')
            }
        }
    }
    let props = {
        setCategory,
        category,
        errorInput,
        setErrorInput
    }
    let sidebarToggle = useSelector((state) => state.counter.sidebarToggle)
    let sidebarTypeRedux = useSelector((state) => state.counter.sidebarType)

    return (
        <React.Fragment>
            <Header setActive={setActive} active={sidebarToggle} page="categories" />
            <div className="interFaceWrap">
                <Sidebar active={sidebarToggle} activePage="categories" permissionData={permissionData} />
                <div className={`interFaceWrap__inner ${sidebarTypeRedux == false ? "doubleSidebar" : "singleSidebar"}`}>
                    <div className="commonOuter">
                        <div className="pageHeader">
                            <Container fluid className="px-0">
                                <Row className="align-items-center">
                                    <div className="col">
                                        <div className="headingText headingText--textLeft mb-0">
                                            <h1>Edit Categories</h1>
                                        </div>
                                    </div>
                                    <div className="col-auto ms-auto onlyButtons">
                                        <div className="pageHeader__elements">
                                            <Button type="button" className="commonButton commonButton--delete" onClick={onDeleteHandler}>
                                                Cancel
                                            </Button>
                                            <CommonButton
                                                buttonName="Update"
                                                loader={loader.save}
                                                type="half"
                                                onClick={updateCategoryHandler}
                                            />
                                        </div>
                                    </div>
                                </Row>
                            </Container>
                        </div>
                        <div className="selectCategoriesOpetions">
                            {isLoading ?
                                <Loader /> :
                                <CategoriesItem {...props} />}
                        </div>
                    </div>
                </div>
                <SidebarTwo activePage="" setSidebarType={setSidebarType} sidebarType={sidebarType} setPermissionData={setPermissionData} />
            </div>
        </React.Fragment>
    )
}
export default EditCategories;