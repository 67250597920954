import React, { useState, useEffect } from 'react';
import Header from './developerHeader';
import Sidebar from './developerSidebar';
import { Button, Container, Row, Col, Form, } from "react-bootstrap";
//SideBanner
import { allApiUrl } from "../../api/apiRoute";
import { apiGetMethod, apiPostMethod, apiPutMethod } from "../../api/rest";
import toastMessages from "../toastMessages";
import CommonButton from "../common-ui/Button";
import "react-phone-input-2/lib/bootstrap.css";
import queryString from "query-string";
import Loader from '../shared/spinner';
import PhoneInput from "react-phone-input-2";
import { countNumberInPhoneNumber, smallStyle } from "../../features/helper";
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import SidebarTwo from '../common/sidebarTwo';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

function EditOrganization() {
    let sidebarToggle = useSelector((state) => state.counter.sidebarToggle)
    let header = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    };
    let history = useHistory()
    const location = useLocation();
    let { id } = queryString.parse(location.search);
    const [active, setActive] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [form, setForm] = useState({
        organizationname: ''
    });
    const [loader, setLoader] = useState({
        organizationLoader: false,
        userLoader: false
    });
    // const [showPass, setShowPass] = useState(false);
    // const [isValid, setIsValid] = useState(false);
    // const [isEmailValid, setIsEmailValid] = useState(false);
    // const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(false);
    // const [phoneValidatError, setPhoneValidError] = useState(false);
    // const [error, setError] = useState(null);
    const [isPageRefreshed, setIsPageRefreshed] = useState(false)
    const [showLoadingPage, setShowLoadingPage] = useState(false)
    useEffect(() => {
        getOrganizationDetail()
    }, [])
    async function onSubmit(e) {
        e.preventDefault();
        setLoader({
            ...loader,
            organizationLoader: true
        });
        try {
            const response = await apiPutMethod(allApiUrl.updateOrganization, {
                organizationname: form.organizationname
            }, header, id);
            if (response.status === 200) {
                history.push('/organizations')
                setLoader({
                    ...loader,
                    organizationLoader: false
                });
                setIsPageRefreshed(!isPageRefreshed)
                toastMessages("success", response.data.message);
            }

        } catch (err) {
            setLoader({
                ...loader,
                organizationLoader: false
            });
            toastMessages("error", err.data.message);
            console.log("err", err);
        }
    }

    async function getOrganizationDetail() {
        const header = {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        };
        try {
            const response = await apiGetMethod(allApiUrl.getIndividualOrganization, `/${id}`, header);
            if (response.status === 200) {
                // console.log('response.data.data.org.Items[0]', response.data['org'].Items[0])
                setForm({
                    ...response.data.user.Items[0],
                    organizationname: response.data.org.Items[0].organizationname,
                    organizationKey: response.data.org.Items[0].organizationKey // || response.data.data.Items[0].PK
                })
                // setIsEmailValid(true)
                // setIsValid(true)
                setIsLoading(false)
            }
        } catch (err) {
            setIsLoading(false)
        }
    }
    // function handlePasswordChange(event) {
    //     setForm({ ...form, password: event.target.value });
    //     setIsValid(validatePassword(event.target.value));
    // }
    // function validatePassword(password) {
    //     const regex =
    //         /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*_#?&])([a-zA-Z0-9@$!%*_#?&]{8,})$/;
    //     return regex.test(password);
    // }
    // function isValidEmail(email) {
    //     return /\S+@\S+\.\S+/.test(email);
    // }
    // const emailValidation = (e) => {
    //     if (e.target.value.length < 81) {
    //         setForm({ ...form, email: e.target.value });
    //         setError(null);
    //         if (isValidEmail(e.target.value)) {
    //             let check = e.target.value.split("@")[0];
    //             let isnum = /^\d+$/.test(check);

    //             if (isnum === true || containsOnlySpecialChars(check)) {
    //                 setIsEmailValid(false);
    //                 setError("The email address field must be a valid email");
    //             } else {
    //                 setIsEmailValid(true);
    //                 setError("email is valid");
    //             }
    //         } else {
    //             setIsEmailValid(false);
    //             setError("The email address field must be a valid email");
    //         }
    //     } else {
    //         setIsEmailValid(false);
    //         setError("Email cann't be greater than 80 chars.");
    //     }
    // };
    // function containsOnlySpecialChars(str) {
    //     return /^[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/.test(str); //eslint-disable-line
    // }
    // const handleOnChange = (value, country) => {
    //     setForm({ ...form, phone: value });
    //     const result = countNumberInPhoneNumber(country.format, ".");
    //     if (value.length - country.dialCode.length == result - country.dialCode.length || (result - country.dialCode.length) > 10) {
    //         setIsPhoneNumberValid(true);
    //     } else {
    //         setIsPhoneNumberValid(false);
    //         setPhoneValidError(`The Phone Number should be of ${result - country.dialCode.length} Digit`);
    //     };
    // }
    // async function onSubmitUser(e) {
    //     e.preventDefault();
    //     setLoader({
    //         ...loader,
    //         userLoader: true
    //     });
    //     try {
    //         let data = {
    //             fullname: form.fullname,
    //             email: form.email,
    //             phone: form.phone,
    //             organizationKey: form.organizationKey
    //         }
    //         if (form.password) {
    //             data['password'] = form.password
    //         }
    //         const response = await apiPutMethod(allApiUrl.update_user, data, header, id);
    //         if (response.status === 200) {
    //             history.push('/organizations')
    //             setLoader({
    //                 ...loader,
    //                 userLoader: false
    //             });
    //             setIsPageRefreshed(!isPageRefreshed)
    //             toastMessages("success", response.data.message);
    //         }

    //     } catch (err) {
    //         setLoader({
    //             ...loader,
    //             userLoader: false
    //         });
    //         toastMessages("error", err.data.message);
    //         console.log("err", err); 
    //     }
    // }
    // console.log('form--------149-----', form)
    const [sidebarType, setSidebarType] = useState('singleSidebar')
    let sidebarTypeRedux = useSelector((state) => state.counter.sidebarType)
    return (
        <React.Fragment>
            <Header
                setActive={setActive}
                active={sidebarToggle}
                page="organizations"
                showLoadingPage={showLoadingPage}
                setShowLoadingPage={setShowLoadingPage}
                isPageRefreshed={isPageRefreshed}
            />
            <div className="interFaceWrap">
                <Sidebar active={sidebarToggle} activePage="organizations" />
                <div className={`interFaceWrap__inner ${sidebarTypeRedux == false ? "doubleSidebar" : "singleSidebar"}`}>
                    <div className="commonOuter">
                        <div className="pageHeader">
                            <Container fluid className="px-0">
                                <Row className="align-items-center">
                                    <div className="col">
                                        <div className="headingText headingText--textLeft mb-0">
                                            <h1>Edit Organization</h1>
                                        </div>
                                    </div>
                                    <div className="col-auto ms-auto onlyButtons">
                                        <div className="pageHeader__elements">
                                            <Button type="button" className="commonButton commonButton--delete" onClick={() => history.push('organizations')}>
                                                Back To Organization
                                            </Button>
                                        </div>
                                    </div>
                                </Row>
                            </Container>
                        </div>
                        {!isLoading ?
                            <>
                                <div className="selectModifierOpetions">
                                    <Row>
                                        <Col xl={6} lg={8}>
                                            <div className='whiteBg'>
                                                <Form onSubmit={onSubmit}>
                                                    <Row>
                                                        <Col lg={12}>
                                                            <Form.Group className="form-group">
                                                                <Form.Label>
                                                                    Organization Name<sup>*</sup>
                                                                </Form.Label>
                                                                <Form.Control
                                                                    required
                                                                    className="formField"
                                                                    type="text"
                                                                    value={form.organizationname}
                                                                    onChange={(e) => {
                                                                        const alphabetRegex = /^[a-zA-Z0-9 !@#$%^&*(),.?":{}|<>]*$/;
                                                                        if (e.target.value.match(alphabetRegex)) {
                                                                            setForm({
                                                                                ...form,
                                                                                organizationname: e.target.value,
                                                                            });
                                                                        }
                                                                    }}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <CommonButton
                                                        buttonName="Update"
                                                        type="fullWidth"
                                                        loader={loader.organizationLoader}
                                                    />
                                                </Form>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                {/* <div className="selectModifierOpetions">
                                    <Row>
                                        <Col xl={6} lg={8}>
                                            <div className='whiteBg'>
                                                <Form onSubmit={onSubmitUser}>
                                                    <Row>
                                                        <Col lg={12}>
                                                            <Form.Group className="form-group">
                                                                <Form.Label>
                                                                    Name<sup>*</sup><small>This user will be edited as a corporate admin.</small>
                                                                </Form.Label>
                                                                <Form.Control
                                                                    required
                                                                    className="formField "
                                                                    type="text"
                                                                    value={form.fullname}
                                                                    onChange={(e) => {
                                                                        const alphabetRegex = /^[a-zA-Z ]*$/;
                                                                        if (e.target.value.match(alphabetRegex)) {
                                                                            setForm({
                                                                                ...form,
                                                                                fullname: e.target.value,
                                                                            });
                                                                        }
                                                                    }}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={12}>
                                                            <Form.Group className="form-group">
                                                                <Form.Label>
                                                                    Email<sup>*</sup>
                                                                </Form.Label>
                                                                <Form.Control
                                                                    required
                                                                    className={`formField ${form.email ? !isEmailValid ? "error" : '' : ''} notCapital`}
                                                                    type="email"
                                                                    value={form.email}
                                                                    onChange={emailValidation}
                                                                />
                                                                {error ?
                                                                    form.email !== "" ?
                                                                        error !== "email is valid" ? (
                                                                            <small
                                                                                style={{
                                                                                    color: "red",
                                                                                }}
                                                                            >
                                                                                {error}
                                                                            </small>
                                                                        ) : '' : '' : ''}
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={12}>
                                                            <Form.Group className="form-group">
                                                                <Form.Label>
                                                                    Phone Number<sup>*</sup>
                                                                </Form.Label>
                                                                <PhoneInput
                                                                    country="us"
                                                                    value={form.phone}
                                                                    onChange={handleOnChange}
                                                                    enableSearch={true}
                                                                    inputStyle={{ width: "100%" }}
                                                                />
                                                                {!isPhoneNumberValid ? form.phone ? (
                                                                    <small style={smallStyle}>
                                                                        {phoneValidatError}
                                                                    </small>
                                                                ) : '' : ''}
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={12}>
                                                            <Form.Group className="form-group">
                                                                <Form.Label>
                                                                    Password
                                                                </Form.Label>
                                                                <div className="passwordWrap">
                                                                    <Form.Control
                                                                        autoComplete="new-password"
                                                                        className={`formField ${!isValid ? 'error' : ''} notCapital`}
                                                                        type={`${!showPass ? "password" : "text"}`}
                                                                        value={form.password}
                                                                        placeholder="****************"
                                                                        onChange={handlePasswordChange}
                                                                    />
                                                                    <Button className="showPassIcon" type="button">
                                                                        <i
                                                                            onClick={() => { setShowPass(!showPass) }}
                                                                            className={showPass ? "fas fa-eye" : "fas fa-eye-slash"}
                                                                        ></i>
                                                                    </Button>
                                                                </div>
                                                                {!isValid ? (<small
                                                                    style={{
                                                                        display: "block",
                                                                        fontSize: "13px",

                                                                    }}>
                                                                    The password has to be at least 8 characters
                                                                    long, with at least 1 Uppercase letter, 1 Lowercase
                                                                    letter, 1 Number and 1 Special Character.
                                                                </small>
                                                                ) : ''}
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <CommonButton
                                                        buttonName="Update"
                                                        type="fullWidth"
                                                        loader={loader.userLoader}
                                                    />
                                                </Form>

                                            </div>
                                        </Col>
                                    </Row>
                                </div> */}
                            </> : <Loader />
                        }
                    </div>
                </div>
                <SidebarTwo
                    activePage=""
                    setSidebarType={setSidebarType}
                    sidebarType={sidebarType}
                    showLoadingPage={showLoadingPage}
                    setShowLoadingPage={setShowLoadingPage}
                    isPageRefreshed={isPageRefreshed}
                />
            </div>
        </React.Fragment >
    )
}
export default EditOrganization;




