import React, { useEffect, useState } from 'react';
import { Button, Badge, Dropdown, Table, Container, Row, Col, Image, ListGroup, Form, FloatingLabel, InputGroup, Pagination } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { allApiUrl } from '../../api/apiRoute';
import { apiGetMethod, apiPostMethod } from '../../api/rest';
import AddOnTable from "../Tables/AddOnTable";
import Loader from '../shared/spinner';
//Header
import Header from '../common/header';
import Sidebar from '../common/sidebar';
import toastMessages from '../toastMessages';
import SidebarTwo from '../common/sidebarTwo';
function ChoicesAddons() {
    //Sidebar Toggle
    const history = useHistory()
    const [permissionData, setPermissionData] = useState({})
    const [active, setActive] = useState(false)
    const [pageRefresh, setPageRefresh] = useState(false)
    const [modifierList, setModifierList] = useState([])
    const branchArray = useSelector((state) => state?.counter?.branchArray)
    const currBranch = useSelector((state) => state?.counter?.currBranch)
    const [loaderForFetchMore, setLoaderForFetchMore] = useState(true)
    const [lastEvaluatedKey, setLastEvaluatedKey] = useState('')
    const [searchedValue, setSearchedValue] = useState('')
    const [isLoading, setIsLoading] = useState(true)
    let branches = branchArray.map((item) => item.value)
    const header = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        branchid: JSON.stringify(branches)
    };
    useEffect(() => {
        setIsLoading(true)
        getAllModifier()
        if ((branchArray.length > 1) || (branchArray && branchArray[0].value == "All")) {
            history.push('/dashboard')
        }
    }, [pageRefresh, branchArray])
    async function getAllModifier() {

        try {
            const response = await apiGetMethod(`${allApiUrl.view_all_modifier}`, "", header);
            if (response.status === 200) {
                if (response.data.categories.LastEvaluatedKey) {
                    setLastEvaluatedKey(response.data.categories.LastEvaluatedKey.SK)
                }
                setModifierList(response.data.categories.Items)
                setIsLoading(false)
                if (response.data.categories.Items.length < 5) {
                    setLoaderForFetchMore(false)
                }
                else {
                    setLoaderForFetchMore(true)
                }
                toastMessages("success", response.data.message);
            }
        } catch (err) {
            if (err.data.message !== "Select atleast one branch.") {
                toastMessages("error", err.data.message);
            }
            console.log("err", err);
        }
    }
    async function onSearchHandler(e) {
        const alphabetRegex = /^[0-9 a-z A-Z]*$/;
        if (e.target.value.match(alphabetRegex)) {
            setSearchedValue(e.target.value)
            try {
                let data = {
                    modifiername: e.target.value
                }
                const response = await apiPostMethod(allApiUrl.search_modifier, data, header);
                if (response.status === 200) {
                    if (response.data.message == "No records found.") {
                        setModifierList('');
                        setLoaderForFetchMore(false)
                        setLastEvaluatedKey('')
                    }
                    else {
                        if (response.data.data.LastEvaluatedKey) {
                            setLastEvaluatedKey(response.data.data.LastEvaluatedKey.SK)
                        }
                        setModifierList(response.data.data.Items);
                    }
                }
            } catch (err) {
                toastMessages("error", err.data.message);
                if (err.data.message == "Session Expired.") {
                    localStorage.clear();
                    history.push('/login')
                }
                console.log("err", err);
            }
        }

    }
    let sidebarToggle = useSelector((state) => state.counter.sidebarToggle)
    const [sidebarType, setSidebarType] = useState('singleSidebar')
    let sidebarTypeRedux = useSelector((state) => state.counter.sidebarType)

    return (
        <React.Fragment>
            <Header setActive={setActive} active={sidebarToggle} />
            <div className="interFaceWrap">
                <Sidebar active={sidebarToggle} activePage="modifiers" permissionData={permissionData} />
                <div className={`interFaceWrap__inner ${sidebarTypeRedux == false ? "doubleSidebar" : "singleSidebar"}`}>
                    <div className="commonOuter">
                        <div className="pageHeader">
                            <Container fluid className="px-0">
                                <Row className="align-items-center">
                                    <div className="col">
                                        <div className="headingText headingText--textLeft mb-0">
                                            <h1>Modifiers</h1>
                                        </div>
                                    </div>
                                    <div className="col-auto ms-auto">
                                        <div className="pageHeader__elements">
                                            <Form className="searchBarForm">
                                                <InputGroup className="searchBar">
                                                    <InputGroup.Text id="searchOrders"><i className="fa-solid fa-magnifying-glass"></i></InputGroup.Text>
                                                    <FloatingLabel controlId="floatingInput" label="Search">
                                                        <Form.Control type="search" placeholder="Search" value={searchedValue} onKeyDown={(e) => {
                                                            if (e.code === 'Enter') {
                                                                e.preventDefault()
                                                            }
                                                        }} onChange={onSearchHandler} />
                                                    </FloatingLabel>
                                                </InputGroup>
                                            </Form>
                                            <Link to="/add-modifiers" className="commonButton">Create Modifiers</Link>
                                        </div>
                                    </div>
                                </Row>
                            </Container>
                        </div>
                        <AddOnTable
                            isLoading={isLoading}
                            modifierList={modifierList}
                            setModifierList={setModifierList}
                            setPageRefresh={setPageRefresh}
                            pageRefresh={pageRefresh}
                            lastEvaluatedKey={lastEvaluatedKey}
                            setLastEvaluatedKey={setLastEvaluatedKey}
                            setLoaderForFetchMore={setLoaderForFetchMore}
                            loaderForFetchMore={loaderForFetchMore}
                        />

                    </div>
                </div>
                <SidebarTwo activePage="" setSidebarType={setSidebarType} sidebarType={sidebarType} setPermissionData={setPermissionData} />
            </div>
        </React.Fragment>
    )
}
export default ChoicesAddons;