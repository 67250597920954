import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Button, Table, Nav, Tab, Container, Row, Col, Breadcrumb, Accordion, Form, ListGroup, InputGroup, FloatingLabel, Spinner, OverlayTrigger, Tooltip, } from 'react-bootstrap';
import Chart from "react-apexcharts";
import DatePicker from "react-datepicker";
import { CSVLink } from "react-csv";
import Select from "react-select";
import queryString from "query-string";
//Datepicker CSS
import "react-datepicker/dist/react-datepicker.css";
//Header
import Header from '../common/header';
import Sidebar from '../common/sidebar';
//Images
import chooseProductImg from '../shared/assets/images/choose-product-img.svg';
//Files
import { apiGetMethod, apiPostMethod } from "../../api/rest";
import { allApiUrl } from "../../api/apiRoute";
import toastMessages from "../toastMessages";
import { getAllDatesBetween, titleCase, isCategoryEmpty, csvConvertJson, newDateOptions, formatDate_mm_dd_yyyy, monthDifference } from "../../features/helper";
import Loader from "../shared/spinner";
import { ExportCsv } from "../common-ui/Button";
import SidebarTwo from "../common/sidebarTwo";
import { changeTableManagement, changeWebsiteTemplate } from "../../features/todoSlice";

function Reports() {
    let dispatch = useDispatch()
    const location = useLocation();
    let branchid = useSelector((state) => state.counter.currBranch)
    let branchArray = useSelector((state) => state.counter.branchArray)
    let branches = branchArray.map((item) => item.value)
    const header = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        branchid: JSON.stringify(branches)
    };
    const [permissionData, setPermissionData] = useState({})
    let { page } = queryString.parse(location.search);
    const [currentTable, setCurrentTable] = useState('Order')
    // console.log('39', currentTable)
    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())
    const [csvData, setCsvData] = useState([]);
    const [filename, setFilename] = useState('')
    const [active, setActive] = useState(false);
    const [orderBy, setOrderBy] = useState('date')

    let DefaultGraphValue = {
        series: [{
            name: '',
            type: 'line',
            data: []
        }],
        options: {
            chart: {
                id: 'apexchart-example',
                height: 350,
                type: 'line',
                toolbar: {
                    show: false,
                    tools: {
                        download: false // <== line to add
                    }
                },
                selection: {
                    enabled: true
                },
                zoom: {
                    enabled: false,
                }
            },

            stroke: {
                curve: 'smooth'
            },
            fill: {
                type: 'solid',
                opacity: [1],
                colors: ['#0E9F6E'],
            },
            colors: ['#0E9F6E', '#FF0000'],
            markers: {
                strokeColors: '#000',
                strokeWidth: 2,
                strokeOpacity: 0.9,
                size: 3,
            },
            legend: {
                show: true,
                showForSingleSeries: false,
                showForNullSeries: true,
                showForZeroSeries: true,
                position: 'bottom',
                horizontalAlign: 'center',
                floating: false,
                fontSize: '16px',
                fontFamily: "Dosis,sans-serif",
                fontWeight: 500,
                formatter: undefined,
                inverseOrder: false,
                width: undefined,
                height: undefined,
                tooltipHoverFormatter: false,
                customLegendItems: [],
                offsetX: 0,
                offsetY: 0,
                labels: {
                    colors: undefined,
                    useSeriesColors: false
                },
                markers: {
                    width: 12,
                    height: 12,
                    strokeWidth: 0,
                    strokeColor: '#fff',
                    fillColors: undefined,
                    radius: 12,
                    customHTML: undefined,
                    onClick: undefined,
                    offsetX: 0,
                    offsetY: 0
                },
                itemMargin: {
                    horizontal: 5,
                    vertical: 0
                },
                onItemClick: {
                    toggleDataSeries: false
                },
                onItemHover: {
                    highlightDataSeries: false
                },
            },
            labels: [],
            xaxis: {
                show: true,
                labels: {
                    style: {
                        fontFamily: 'Dosis',
                        fontSize: '16px',
                        fontWeight: '600',
                        colors: 'var(--greyColorTint1)',
                    }
                },
                categories: [],
            },
            yaxis: {
                show: true,
                labels: {
                    offsetX: -15,
                    style: {
                        fontFamily: 'Dosis',
                        fontSize: '16px',
                        fontWeight: '600',
                        colors: 'var(--greyColorTint1)',
                    }
                },
                // categories: ["$0", "$40K", "$80K", "$120K", "$160K", "$200K"],
            },
            grid: {
                borderColor: "var(--greyColor)",
                strokeDashArray: 2,
                xaxis: {
                    lines: {
                        show: true
                    }
                },
                yaxis: {
                    lines: {
                        show: true
                    }
                },
                row: {
                    colors: ['transparent', 'transparent'],
                    opacity: 1,
                },
                column: {
                    colors: ['transparent', 'transparent'],
                    opacity: 1,
                },
                padding: {
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0
                },
            },
            tooltip: {
                shared: true,
                intersect: false,
                y: {
                    formatter: function (y) {
                        if (typeof y !== "undefined") {
                            return "$" + " " + y.toFixed(2);
                        }
                        return y;
                    }
                }
            },

        },
    }

    const [graphData, setGraphData] = useState(DefaultGraphValue)
    const [graphDataProducts, setGraphDataProducts] = useState(DefaultGraphValue)
    const [graphDataCategories, setGraphDataCategories] = useState(DefaultGraphValue)
    const [activeTab, setActiveTab] = useState('thisMonth')
    const [refresh, setRefresh] = useState(false)
    let currentCsvName = ''
    const date = new Date();
    let sdate = new Date(date.getFullYear(), date.getMonth() - 1, 1);
    let edate = new Date(date.getFullYear(), date.getMonth(), 0);
    const onCsvDateHandler = (currStart, currEnd) => {
        let lastMonthStart = `${(new Date(currStart).getMonth() + 1) < 10 ? '0' + (new Date(currStart).getMonth() + 1) : (new Date(currStart).getMonth() + 1)}_${new Date(currStart).getDate() < 10 ? '0' + new Date(currStart).getDate() : new Date(currStart).getDate()}_${new Date(currStart).getFullYear()}`
        let lastMonthEnd = `${(new Date(currEnd).getMonth() + 1) < 10 ? '0' + (new Date(currEnd).getMonth() + 1) : (new Date(currEnd).getMonth() + 1)}_${new Date(currEnd).getDate() < 10 ? '0' + new Date(currEnd).getDate() : new Date(currEnd).getDate()}_${new Date(currEnd).getFullYear()}`
        return `${lastMonthStart}-${lastMonthEnd}`
    }
    switch (activeTab) {
        case "lastMonth":
            sdate = new Date(date.getFullYear(), date.getMonth() - 1, 1).getTime();
            edate = new Date(date.getFullYear(), date.getMonth(), 0, 23, 59, 59, 999).getTime();
            // let lastMonthStart = `${new Date(sdate).getDate() < 10 ? '0' + new Date(sdate).getDate() : new Date(sdate).getDate()}_${(new Date(sdate).getMonth() + 1) < 10 ? '0' + (new Date(sdate).getMonth() + 1) : (new Date(sdate).getMonth() + 1)}_${new Date(sdate).getFullYear()}`
            // let lastMonthEnd = `${new Date(edate).getDate() < 10 ? '0' + new Date(edate).getDate() : new Date(edate).getDate()}_${(new Date(edate).getMonth() + 1) < 10 ? '0' + (new Date(edate).getMonth() + 1) : (new Date(edate).getMonth() + 1)}_${new Date(edate).getFullYear()}`
            currentCsvName = onCsvDateHandler(sdate, edate)
            break;
        case "thisMonth":
            sdate = new Date(date.getFullYear(), date.getMonth(), 1).getTime();
            edate = new Date(date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 59, 999).getTime();
            // let thisMonthStart = `${new Date(sdate).getDate() < 10 ? '0' + new Date(sdate).getDate() : new Date(sdate).getDate()}_${(new Date(sdate).getMonth() + 1) < 10 ? '0' + (new Date(sdate).getMonth() + 1) : (new Date(sdate).getMonth() + 1)}_${new Date(sdate).getFullYear()}`
            // let thisMonthEnd = `${new Date(edate).getDate() < 10 ? '0' + new Date(edate).getDate() : new Date(edate).getDate()}_${(new Date(edate).getMonth() + 1) < 10 ? '0' + (new Date(edate).getMonth() + 1) : (new Date(edate).getMonth() + 1)}_${new Date(edate).getFullYear()}`
            currentCsvName = onCsvDateHandler(sdate, edate)
            break;
        case "lastSevenDays":
            var sevenDayBefore = new Date(date.getTime());
            sevenDayBefore.setDate(date.getDate() - 7)
            edate = new Date().getTime();
            sdate = sevenDayBefore.getTime()
            // let lastSevenStart = `${new Date(sdate).getDate() < 10 ? '0' + new Date(sdate).getDate() : new Date(sdate).getDate()}_${(new Date(sdate).getMonth() + 1) < 10 ? '0' + (new Date(sdate).getMonth() + 1) : (new Date(sdate).getMonth() + 1)}_${new Date(sdate).getFullYear()}`
            // let lastSevenEnd = `${new Date(edate).getDate() < 10 ? '0' + new Date(edate).getDate() : new Date(edate).getDate()}_${(new Date(edate).getMonth() + 1) < 10 ? '0' + (new Date(edate).getMonth() + 1) : (new Date(edate).getMonth() + 1)}_${new Date(edate).getFullYear()}`
            currentCsvName = onCsvDateHandler(sdate, edate)
            break;
        case "year":
            edate = date.getTime()
            sdate = new Date(date.getFullYear(), 0, 1).getTime();
            let yearStartDate = `${new Date(sdate).getDate() < 10 ? '0' + new Date(sdate).getDate() : new Date(sdate).getDate()}_${(new Date(sdate).getMonth() + 1) < 10 ? '0' + (new Date(sdate).getMonth() + 1) : (new Date(sdate).getMonth() + 1)}_${new Date(sdate).getFullYear()}`
            currentCsvName = `${yearStartDate}-${formatDate_mm_dd_yyyy(Date.now())}`
            break;
        case 'custom':
            //forStart of the day 
            const currStartDate = startDate.getTime();
            const currStartSecond = Math.floor(currStartDate / 1000);
            const secondsSinceStartOfDay = currStartSecond % 86400;
            const startOfDayTimestamp = currStartSecond - secondsSinceStartOfDay;
            const startOfDayTimestampMillis = startOfDayTimestamp * 1000;
            sdate = startOfDayTimestampMillis
            //for End of the day 
            const currentTimeMillis = endDate.getTime();
            const currentTimeSeconds = Math.floor(currentTimeMillis / 1000);
            const secondsRemainingInDay = 86399 - (currentTimeSeconds % 86400);
            const endOfDayTimestamp = currentTimeSeconds + secondsRemainingInDay;
            const endOfDayTimestampMillis = endOfDayTimestamp * 1000;
            edate = endOfDayTimestampMillis
            // let customStart = `${new Date(sdate).getDate()}_${new Date(sdate).getMonth() + 1}_${new Date(sdate).getFullYear()}`
            // let customEnd = `${new Date(edate).getDate() < 10 ? '0' + new Date(edate).getDate() : new Date(edate).getDate()}_${(new Date(edate).getMonth() + 1) < 10 ? '0' + (new Date(edate).getMonth() + 1) : (new Date(edate).getMonth() + 1)}_${new Date(edate).getFullYear()}`
            currentCsvName = onCsvDateHandler(sdate, edate)
    }
    let props = {
        startDate,
        endDate,
        activeTab,
        refresh,
        orderBy,
        setOrderBy,
        setStartDate,
        setEndDate,
        setActiveTab,
        setRefresh,
        graphData,
        branchid,
        sdate,
        edate,
        setGraphData,
        setCsvData,
        csvData,
        currentTable,
        setGraphDataProducts,
        graphDataProducts,
        setGraphDataCategories,
        graphDataCategories,
        branchid,
        setFilename,
        page,
        currentCsvName
    }
    useEffect(() => {
        setGraphData(DefaultGraphValue)
        setGraphDataProducts(DefaultGraphValue)
        setGraphDataCategories(DefaultGraphValue)
        if (orderBy !== "date") {
            setCsvData([])
        }
    }, [orderBy])
    useEffect(() => {
        getDisableStatus()
    }, [permissionData])
    async function getDisableStatus() {
        const header = {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            branchid: JSON.stringify(branches),
        };
        try {
            const response = await apiGetMethod(allApiUrl.viewSetting, "", header);
            if (response.status == 200) {
                if (response.data.Items.length != 0) {
                    //for table managment sidebar toggle functionality.
                    if (permissionData.tablemanagement == 0) {
                        console.log('116')
                        dispatch(changeTableManagement(false));
                        console.log('116')
                    }
                    else {
                        console.log('116')
                        if (response.data.Items[0].GeneralSettings.tablemanagement == 1) {
                            console.log('116')
                            dispatch(changeTableManagement(true));
                        } else {
                            console.log('116')
                            dispatch(changeTableManagement(false));
                        }
                    }
                    // for website builder
                    if (permissionData.websitetemplate == 0) {
                        dispatch(changeWebsiteTemplate(false));
                    }
                    else {
                        if (response.data.Items[0].WebsiteTemplate.enableWebsiteTemplate == 1) {
                            //eslint-disable-line
                            dispatch(changeWebsiteTemplate(true));
                        } else {
                            dispatch(changeWebsiteTemplate(false));
                        }
                    }

                }
            }
        } catch (err) {
            console.log("err", err);
        }
    }
    let sidebarToggle = useSelector((state) => state.counter.sidebarToggle)
    let sidebarTypeRedux = useSelector((state) => state.counter.sidebarType)

    const [sidebarType, setSidebarType] = useState('singleSidebar')
    return (
        <React.Fragment>
            <Header setActive={setActive} active={sidebarToggle} />
            <div className="interFaceWrap">
                <Sidebar active={sidebarToggle} activePage="reports" permissionData={permissionData} />
                <div className={`interFaceWrap__inner ${sidebarTypeRedux == false ? "doubleSidebar" : "singleSidebar"}`}>
                    <div className="commonOuter">
                        <div className="pageHeader">
                            <Container fluid className="px-0">
                                <Row className="align-items-center">
                                    <div className="col">
                                        <div className="headingText headingText--textLeft mb-0">
                                            <h1>Reports</h1>
                                        </div>
                                    </div>
                                    <div className="col-auto ms-auto onlyButtons">
                                        <div className="pageHeader__elements">
                                            <ExportCsv
                                                filename={filename}
                                                csvData={csvData}
                                            />
                                        </div>
                                    </div>
                                </Row>
                            </Container>
                        </div>
                        <div className="graphChart">
                            <Tab.Container id="reportsDetailsTab" defaultActiveKey={`${page && page == "salesReport" ? "sales" : "orders"}`}>
                                <Nav className="commonTabs">
                                    <Nav.Item onClick={() => setCurrentTable("Order")}>
                                        <Nav.Link eventKey="orders" >Orders</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item onClick={() => setCurrentTable("Sale")}>
                                        <Nav.Link eventKey="sales"  >Sales</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                <Tab.Content className="">
                                    <OrderSection {...props} />
                                    <SalesSection {...props} />
                                </Tab.Content>
                            </Tab.Container>
                        </div>
                    </div>
                </div>
                <SidebarTwo activePage="" setSidebarType={setSidebarType} sidebarType={sidebarType} setPermissionData={setPermissionData} />
            </div>
        </React.Fragment>
    )
}
export default Reports;

const SalesSection = ({
    setEndDate,
    endDate,
    setStartDate,
    startDate,
    setActiveTab,
    activeTab,
    setRefresh,
    refresh,
    branchid,
    sdate,
    edate,
    orderBy,
    setCsvData,
    currentTable,
    setFilename,
    page,
    currentCsvName
}) => {
    let branchArray = useSelector((state) => state.counter.branchArray)

    const [isLoading, setIsLoading] = useState(false)
    const [salesReportList, setSalesReportList] = useState([])
    useEffect(() => {
        setIsLoading(true)
        if (currentTable == "Sale" || page && page == "salesReport") {
            getSalesReport(sdate, edate);
        }
    }, [activeTab, refresh, branchid.value, orderBy, currentTable, branchArray]);
    async function getSalesReport(sdate, edate) {
        let branches = branchArray.map((item) => item.value)
        const header = {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            branchid: JSON.stringify(branches)
        };
        let data = {
            sdate,
            edate
        }
        try {
            const response = await apiPostMethod(allApiUrl.viewRecords, data, header);
            if (response.status === 200) {
                let csvList = []
                setFilename(`Exported_Order_Reports(${currentCsvName})`)
                setSalesReportList(response.data)
                //for exporting csv data
                if (response.data?.orderDetails?.order == 0) {
                    csvList.push({ "Period": "No Order found in this period." })
                    setCsvData(csvList)
                }
                else {
                    // console.log('316')
                    response.data && response.data?.orderDetails?.order > 0 && Object.keys(response.data.orders).forEach((item, index) => {
                        let { subTotal, tax, orderCount } = response.data.orders[item]
                        csvList.push({
                            "Period": monthDifference(new Date(sdate), new Date(edate)) > 1 ? item : formatDate_mm_dd_yyyy(item),
                            "Number of Orders": orderCount,
                            "Total Sales": subTotal.toFixed(2),
                            "Total Tax": tax.toFixed(2)
                        })
                    })
                    // console.log('321')
                    csvList = csvList
                    csvList.push({
                        "Period": "Totals",
                        "Number of Orders": response.data?.orderDetails?.order,
                        "Total Sales": response.data?.orderDetails?.net,
                        "Total Tax": (response.data?.orderDetails?.totalTax).toFixed(2)
                    })
                    // console.log('329')
                    setCsvData(csvList)
                }
                // console.log('332')
                setIsLoading(false)
            }

        } catch (err) {
            // toastMessages("error", err.data.message);
            setIsLoading(false)
            // console.log("err", err);
        }
    }
    return (
        <Tab.Pane eventKey="sales">
            <div className="chart reportsChart">
                <div className="reportsChart__buttons">
                    <Button type="button" active={activeTab == "year" ? true : false} onClick={() => setActiveTab('year')}>Year</Button>
                    <Button type="button" active={activeTab == "lastMonth" ? true : false} onClick={() => setActiveTab('lastMonth')}>Last Month</Button>
                    {activeTab == "thisMonth" ?
                        <Button type="button" active={true} onClick={() => setActiveTab('thisMonth')}>This Month</Button> :
                        <Button type="button" onClick={() => setActiveTab('thisMonth')}>This Month</Button>}
                    <Button type="button" active={activeTab == "lastSevenDays" ? true : false} onClick={() => setActiveTab('lastSevenDays')}>Last 7 Days</Button>
                    <div className="customDatepicker">
                        <span className="subTitle">Custom:</span>
                        <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            selectsStart
                            maxDate={new Date()}
                            startDate={startDate}
                            endDate={endDate} />
                        <DatePicker
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            selectsEnd
                            maxDate={new Date()}
                            startDate={startDate}
                            endDate={endDate}
                            minDate={startDate} />
                        <Button type="button" className="commonButton commonButton--small" onClick={() => {
                            if (new Date(startDate).getTime() <= new Date(endDate).getTime()) {
                                setActiveTab('custom')
                                setRefresh(!refresh)
                            }
                            else {
                                toastMessages('error', "Start date should be less than end date.")
                            }
                        }}>Go</Button>
                    </div>
                </div>
                <div className="reportsGraphChart">
                    <div className="commonTable px-4 pb-4">
                        <div className="whiteBg">
                            {!salesReportList.length && isLoading ? <Loader /> : <Table responsive striped>
                                <thead>
                                    <tr>
                                        <th>Period</th>
                                        <th>Number of Orders</th>
                                        <th>Total Sales</th>
                                        <th>Total Tax</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {salesReportList && salesReportList?.orderDetails?.order > 0 ? Object.keys(salesReportList.orders).map((item, index) => {
                                        let { gross, tax, orderCount } = salesReportList.orders[item]

                                        return (
                                            <tr>
                                                <td>{monthDifference(new Date(sdate), new Date(edate)) > 1 ? item : formatDate_mm_dd_yyyy(item)}</td>
                                                <td>{orderCount}</td>
                                                <td>{gross.toFixed(2)}</td>
                                                <td>{tax.toFixed(2)}</td>
                                            </tr>
                                        )
                                    }) : <tr><td>No Order found in this period.</td></tr>
                                    }
                                </tbody>
                                {salesReportList?.orderDetails?.order > 0 && <tbody style={{ fontWeight: 'bold' }}>
                                    <tr>
                                        <td>Totals</td>
                                        <td>{salesReportList?.orderDetails?.order}</td>
                                        <td>{(salesReportList?.orderDetails?.gross).toFixed(2)}</td>
                                        <td>{(salesReportList?.orderDetails?.totalTax).toFixed(2)}</td>
                                    </tr>
                                </tbody>}
                            </Table>}

                        </div>
                    </div>
                </div>
            </div>
        </Tab.Pane >
    )
}
const OrderSection = ({
    setEndDate,
    endDate,
    setStartDate,
    startDate,
    setActiveTab,
    activeTab, // year,month,day
    setRefresh,
    refresh,
    orderBy, //order by date ,order by product
    setOrderBy,
    reportList,
    graphData,
    sdate,
    edate,
    setGraphData,
    csvData,
    setCsvData,
    hoverActive,
    setHoverActive,
    graphDataTesting,
    setCurrenHoverField,
    currenHoverField,
    graphDataOrderPlaced,
    graphDataItemPurchased,
    currentTable,
    setGraphDataProducts,
    graphDataProducts,
    setGraphDataCategories,
    graphDataCategories,
    branchid,
    setFilename,
    page,
    currentCsvName
}) => {

    let props = {
        setEndDate,
        endDate,
        page,
        setStartDate,
        startDate,
        setActiveTab,
        activeTab, // year,month,day
        setRefresh,
        refresh,
        orderBy, //order by date ,order by product
        setOrderBy,
        reportList,
        graphData,
        branchid,
        sdate,
        edate,
        setGraphData,
        csvData,
        setCsvData,
        hoverActive,
        setHoverActive,
        graphDataTesting,
        setCurrenHoverField,
        currenHoverField,
        graphDataOrderPlaced,
        graphDataItemPurchased,
        currentTable,
        setGraphDataProducts,
        graphDataProducts,
        setGraphDataCategories,
        graphDataCategories,
        setFilename,
        currentCsvName
    }
    const [isOrderByProductAndCategoriesVisible, setIsOrderByProductAndCategoriesVisible] = useState(false)
    let branchArray = useSelector((state) => state.counter.branchArray)
    useEffect(() => {
        if (branchArray.length == 1 && branchArray && branchArray[0].label !== "All") {
            setIsOrderByProductAndCategoriesVisible(true)
        }
        else {
            setOrderBy('date')
            setIsOrderByProductAndCategoriesVisible(false)
        }
        // console.log('175', branchArray)
    }, [branchArray])
    return (
        <Tab.Pane eventKey="orders">
            <div className="chart reportsChart">
                <Breadcrumb>
                    <Breadcrumb.Item
                        active={orderBy == "date" ? true : false}
                        onClick={() => setOrderBy('date')
                        }>Orders by Date</Breadcrumb.Item>
                    {isOrderByProductAndCategoriesVisible && <>
                        <Breadcrumb.Item
                            active={orderBy == "product" ? true : false}
                            onClick={() => setOrderBy('product')}
                        >Orders by Product</Breadcrumb.Item>
                        <Breadcrumb.Item
                            active={orderBy == "category" ? true : false}
                            onClick={() => setOrderBy('category')}
                        >Orders by Category</Breadcrumb.Item>
                    </>
                    }
                </Breadcrumb>
                <div className="reportsChart__buttons">
                    <Button
                        type="button"
                        active={activeTab == "year" ? true : false}
                        onClick={() => setActiveTab('year')}  >Year</Button>
                    <Button
                        type="button"
                        onClick={() => setActiveTab('lastMonth')}
                        active={activeTab == "lastMonth" ? true : false}
                    >Last Month</Button>
                    {activeTab == "thisMonth" ?
                        <Button type="button" active={true} onClick={() => setActiveTab('thisMonth')}>This Month</Button> :
                        <Button type="button" onClick={() => setActiveTab('thisMonth')}>This Month</Button>}
                    <Button
                        type="button"
                        onClick={() => setActiveTab('lastSevenDays')}
                        active={activeTab == "lastSevenDays" ? true : false}
                    >Last 7 days</Button>
                    <div className="customDatepicker">
                        <span className="subTitle">Custom:</span>
                        <DatePicker
                            selected={startDate}
                            onChange={(date) => {
                                // console.log('startDate--->', date)
                                setStartDate(date)
                            }}
                            maxDate={new Date()}
                            selectsStart
                            startDate={startDate}
                            endDate={endDate} />
                        <DatePicker
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            selectsEnd
                            maxDate={new Date()}
                            startDate={startDate}
                            endDate={endDate}
                            minDate={startDate} />
                        <Button type="button" className="commonButton commonButton--small" onClick={() => {
                            if (new Date(startDate).getTime() <= new Date(endDate).getTime()) {
                                setActiveTab('custom')
                                setRefresh(!refresh)
                            }
                            else {
                                toastMessages('error', "Start date should be less than end date.")
                            }
                        }}>Go</Button>
                    </div>
                </div>
                <div className="reportsGraphChart">
                    <Row>
                        {orderBy == "date" && <OrderByDate {...props} />}
                        {orderBy == "category" && < OrderByCategory {...props} />}
                        {orderBy == "product" && < OrderByProduct {...props} />}
                    </Row>
                </div>
            </div>
        </Tab.Pane >
    )
}
const OrderByDate = ({
    activeTab, // year,month,day
    orderBy, //order by date ,order by product
    graphData,
    refresh,
    branchid,
    sdate,
    edate,
    setGraphData,
    setCsvData,
    currentTable,
    setFilename,
    page,
    currentCsvName
}) => {
    let branchArray = useSelector((state) => state.counter.branchArray)
    const [orderDetails, setOrderDetails] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    useEffect(() => {
        if (currentTable == "Order" && orderBy == "date" && page !== "salesReport") {
            setIsLoading(true)
            getOrderByDateListing(sdate, edate);
        }
    }, [activeTab, refresh, branchid.value, orderBy, currentTable, branchArray]);

    async function getOrderByDateListing(sdate, edate) {
        let branches = branchArray.map((item) => item.value)
        const header = {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            branchid: JSON.stringify(branches)
        };
        let data = {
            sdate,
            edate
        }
        try {
            const response = await apiPostMethod(allApiUrl.viewRecords, data, header);
            if (response.status === 200) {
                setFilename(`Exported_Sales_Reports(${currentCsvName})`)
                let csvList = []
                let priceDataList = []
                let labelList = []
                let refundList = []
                //for exporting csv data
                if (response.data?.orderDetails?.order == 0) {
                    let date = getAllDatesBetween(sdate, edate)
                    date.forEach((item) => {
                        csvList.push({
                            "Date": item,
                            "Number of Item sold": 0,
                            "Number of orders": 0,
                            "Gross Sales Amount": 0,
                            "Net Sales amount": 0
                        })
                    })
                    setCsvData(csvList)
                    setOrderDetails(response.data.orderDetails)
                    setGraphData((prev) => {
                        prev.series[0].data = [0]
                        prev.options.labels = []
                        prev.options.xaxis.categories = []
                        return { ...prev }
                    })
                }
                else {
                    response.data && response.data?.orderDetails?.order > 0 && Object.keys(response.data.orders).forEach((item, index) => {
                        let { subTotal, tax, orderCount, gross, items, refundAmount } = response.data.orders[item]
                        labelList.push(item)
                        refundList.push(refundAmount.toFixed(2))
                        priceDataList.push((Number(gross) - Number(refundAmount)).toFixed(2))
                        csvList.push({
                            "Date": monthDifference(new Date(sdate), new Date(edate)) > 1 ? item : formatDate_mm_dd_yyyy(item),
                            "Number of Item sold": items,
                            // "Refund Amount": refundAmount.toFixed(2),
                            "Number of orders": orderCount,
                            "Gross Sales Amount": gross.toFixed(2),
                            "Net Sales amount": subTotal.toFixed(2)
                        })
                    })
                    function monthDiff(d1, d2) {
                        var months;
                        months = (d1.getFullYear() - d2.getFullYear()) * 12;
                        months -= d2.getMonth();
                        months += d1.getMonth();
                        return months
                    }
                    let difference = monthDiff(new Date(sdate), new Date(edate))
                    let averageDataType = null
                    if (difference < 1) {
                        averageDataType = 'Daily'
                        difference = Math.round((edate - sdate) / (24 * 60 * 60 * 1000))
                    }
                    else {
                        averageDataType = 'Monthly'
                        difference += 1
                    }
                    setOrderDetails({
                        ...response.data.orderDetails,
                        AverageGross: parseFloat(response.data.orderDetails.gross - response.data.orderDetails.refundAmount) / difference,
                        AverageNet: parseFloat(response.data.orderDetails.net - response.data.orderDetails.refundAmount) / difference,
                        averageDataType
                    })
                    let series = [{
                        name: 'Gross',
                        type: 'line',
                        data: priceDataList
                    },
                    {
                        name: 'Refund',
                        type: 'line',
                        data: refundList
                    }
                    ]
                    setGraphData((prev) => {
                        prev.series = series;
                        prev.options.labels = labelList;
                        prev.options.xaxis.categories = labelList; // update the categories array with new values
                        return { ...prev };
                    });
                    setCsvData(csvList)
                }
                setIsLoading(false)
            }

        } catch (err) {
            let data = {
                gross: 0,
                net: 0,
                order: 0,
                items: 0,
                refundAmount: 0,
                refundCount: 0,
                totalTax: 0,
                noStoreAvailable: true
            }
            setOrderDetails(data)
            setIsLoading(false)
            console.log("err", err);
        }
    }
    // console.log('---792----', orderDetails)
    const tooltip = (data) => (
        < Tooltip id="tooltip" >
            <small> This is the sum of orders totals after any refunds and {`${data == "gross" ? "including" : "excluding"}`} taxes.</small>
        </Tooltip >
    );
    // console.log('orderDetail', orderDetails)
    return (
        <>
            {isLoading ? <Loader /> :
                <>
                    <Col xxl={2} xl={3} lg={4} className="d-flex align-items-center">
                        <div className="chartGrossSale">
                            <OverlayTrigger placement="bottom" overlay={tooltip('gross')}>
                                <div className="reportData">
                                    {orderDetails?.gross ? <h3>${parseFloat(orderDetails?.gross - orderDetails?.refundAmount).toFixed(2)}</h3> : <h3>0</h3>}
                                    <span>Gross Sales In This Period</span>
                                </div>
                            </OverlayTrigger>
                            {orderDetails?.AverageGross ? <h3>${parseFloat(orderDetails?.AverageGross).toFixed(2)}</h3> : <h3>0</h3>}
                            <span>Average Gross {orderDetails?.averageDataType} Sales</span>
                            <OverlayTrigger placement="bottom" overlay={tooltip('net')}>
                                <div className="reportData2">
                                    {orderDetails?.net ? <h3>${parseFloat(orderDetails?.net - orderDetails?.refundAmount).toFixed(2)}</h3> : <h3>0</h3>}
                                    <span>Net Sales In This Period</span>
                                </div>
                            </OverlayTrigger>
                            {orderDetails?.AverageNet ? <h3>${parseFloat(orderDetails?.AverageNet).toFixed(2)}</h3> : <h3>0</h3>}
                            <span>Average Net {orderDetails?.averageDataType} Sales</span>
                            <h3> {orderDetails?.order || 0}</h3>
                            <span>Orders Placed</span>
                            <h3>{orderDetails?.items || 0}</h3>
                            <span>Items Purchased</span>
                            {orderDetails?.refundAmount ? <h3>${parseFloat(orderDetails?.refundAmount).toFixed(2)}</h3> : <h3>0</h3>}
                            <span>Refunded {orderDetails?.refundCount || 0} Orders</span>
                        </div>
                    </Col>
                    <Col xxl={10} xl={9} lg={8}>
                        <div className="reportsGraph">
                            <div className="chartHead">
                                {orderBy == "date" && <div className="chartHead__left">
                                    {orderDetails?.gross ?
                                        <h3>${parseFloat(orderDetails?.gross).toFixed(2)} </h3> : <h3>0</h3>}
                                    <span>
                                        Orders Placed{" "} {activeTab == "lastSevenDays" ? "This Week" : activeTab == "thisMonth" ? "This Month" : activeTab == "lastMonth" ? "Last Month" : activeTab == "year" ? "This Year" : "During This Period"}
                                    </span>
                                </div>}
                            </div>
                            {orderDetails?.gross == (0 || '0') ?
                                <div className="chooseProductViewStats">
                                    <img src={chooseProductImg} alt="chooseProductImg" />
                                    <h4>{orderDetails.noStoreAvailable ? "No Store Available" : "No Record To Display"}</h4>
                                </div> : <Chart options={{ ...graphData.options }} series={graphData.series} type="line" height={350} />
                            }

                        </div>

                    </Col>
                </>}
        </>
    )
}
const OrderByProduct = ({
    activeTab, // year,month,day
    orderBy, //order by date ,order by product
    refresh,
    branchid,
    sdate,
    edate,
    setCsvData,
    currentTable,
    setGraphDataProducts,
    graphDataProducts,
    setFilename,
    currentCsvName
}) => {
    let branchArray = useSelector((state) => state.counter.branchArray)
    let branches = branchArray.map((item) => item.value)
    const [productList, setProductList] = useState([])
    const [selectedProduct, setSelectedProduct] = useState({})
    const [isShow, setIsShow] = useState(false)
    const [productDetails, setProductDetails] = useState({
        orderItem: 0,
        priceItem: 0,
        name: ""
    })
    const [topSellersList, setTopSellersList] = useState([])
    const [topEarnersList, setTopEarnersList] = useState([])
    const [isSearched, setIsSearched] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    useEffect(() => {
        if (Object.keys(selectedProduct).length > 0) {
            onSearchProductHandler(selectedProduct)
        }
    }, [refresh])
    useEffect(() => {
        getAllProducts()
        if (currentTable == "Order" && orderBy == "product" && isShow) {
            onSearchProductHandler(selectedProduct);
        }
        getTopProducts()
    }, [activeTab, refresh, branchid.value, orderBy, currentTable, branchArray]);
    // useEffect(() => {
    //     getAllProducts()
    //     getTopProducts()
    // }, [branchArray])
    async function getAllProducts() {
        const header = {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            branchid: JSON.stringify(branches)
        };
        try {
            const response = await apiGetMethod(`${allApiUrl.view_all_product}`, "", header);
            if (response.status === 200) {
                let product = response.data.categories.Items.map((item) => {
                    return ({
                        value: item.SK,
                        label: titleCase(item.productname)
                    })
                })
                setProductList(product)
            }
        } catch (err) {
            console.log("err", err);
        }
    }
    async function getTopProducts() {
        const header = {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            branchid: JSON.stringify(branches)

        };
        let data = {
            sdate: sdate,
            edate: edate
        }
        try {
            const response = await apiPostMethod(allApiUrl.viewTopOrders, data, header);
            if (response.status === 200) {
                let topseller = response.data.Count.map((item) => {
                    return ({
                        value: item.productId,
                        label: `${titleCase(item.productName)} (    ${item.orderCount} ${item.orderCount > 1 ? "Units" : "Unit "})`

                    })
                })
                setTopSellersList(topseller)
                let topearner = response.data.price.map((item) => {
                    return ({
                        value: item.productId,
                        label: `${titleCase(item.productName)}  $(${item.price})`
                    })
                })
                setTopEarnersList(topearner)
            }
        } catch (err) {
            toastMessages("error", err.data.message);
            console.log("err", err);
        }
    }
    async function onSearchProductHandler(id) {
        setIsLoading(true)
        const header = {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            branchid: JSON.stringify(branches)
        };
        try {
            let data = {
                searchBy: {
                    type: "Product",
                    id: [id]
                },
                sdate,
                edate
            }
            const response = await apiPostMethod(`${allApiUrl.viewSearchedProductAndCategory}`, data, header);
            if (response.status === 200) {
                setFilename(`Exported_Sales_Reports(${currentCsvName})`)
                setIsShow(true)
                let orderItem = 0
                let priceItem = 0
                let name = ''
                let labelList = []
                let priceDataList = []
                let csvList = []
                // if(Object.keys(response.data.data[0]))
                // console.log('Object.keys(response.data.data[0])',)
                if (Object.keys(response.data.data[0]).length == 1) {
                    setGraphDataProducts((prev) => {
                        prev.series[0].data = [0];
                        prev.options.labels = [];
                        prev.options.xaxis.categories = []; // update the categories array with new values
                        return { ...prev };
                    });
                    let date = getAllDatesBetween(sdate, edate)
                    date.forEach((item) => {
                        csvList.push({
                            "Date": item,
                            "Number of items sold": 0,
                            "Sales amount": 0,
                        })
                    })
                    setCsvData(csvList)
                    setProductDetails({ orderItem: 0, priceItem: 0, name: response.data.data[0].entity })
                }
                else {
                    response.data && response.data.data && Object.keys(response.data.data[0]).forEach((item, index) => {
                        if (typeof response.data.data[0][item] == "object") {
                            let { orderCount, price } = response.data.data[0][item]
                            csvList.push({
                                "Date": monthDifference(new Date(sdate), new Date(edate)) > 1 ? item : formatDate_mm_dd_yyyy(item),
                                "Number of Items sold": orderCount,
                                "Sales amount": price
                            })
                            orderItem += orderCount
                            priceItem += price
                            labelList.push(item)
                            priceDataList.push(Number(price.toFixed(2)))
                        }
                        else {
                            name = response.data.data[0][item]
                        }
                    })
                    setProductDetails({ orderItem, priceItem, name })
                    labelList = labelList
                    csvList = csvList
                    priceDataList = priceDataList
                    setGraphDataProducts((prev) => {
                        prev.series[0].data = priceDataList;
                        prev.options.labels = labelList;
                        prev.options.xaxis.categories = labelList; // update the categories array with new values
                        return { ...prev };
                    });
                    setCsvData(csvList)
                }
                setIsLoading(false)
            }
        } catch (err) {
            console.log("err", err);
        }
    }
    function onResetHandler() {
        setGraphDataProducts((prev) => {
            prev.series[0].data = [];
            prev.options.labels = [];
            prev.options.xaxis.categories = []; // update the categories array with new values
            return { ...prev };
        });
        setIsShow(false)
        setSelectedProduct({})
    }
    // console.log('1011', topEarnersList, topSellersList)
    return (
        <>
            {
                isLoading ? <Loader /> : <div className="reportsWrapper">
                    <div className="pageHeader reportsHead">
                        <div className="pageHeader__elements">
                            <div className="reportsHead__field">
                                {/* <h5>Product Search</h5> */}
                                {/* {console.log('productList.length======>', productList.length)} */}
                                <Form.Group className="form-group mb-0">
                                    <Form.Label>Search For a product</Form.Label>
                                    <div className="d-flex align-items-center">
                                        <Select className="select2 multiSelect"
                                            // isLoading={!productList.length}
                                            options={productList}
                                            value={isSearched ? selectedProduct : ''}
                                            onChange={(e) => {
                                                setIsSearched(true)
                                                setSelectedProduct(e)
                                            }}
                                            labelledBy="Select"
                                            placeholder="Search For a product"
                                        />
                                        <div className="viewProductButton" onClick={() => {
                                            if (Object.keys(selectedProduct).length) {
                                                onSearchProductHandler(selectedProduct)
                                            } else {
                                                toastMessages("error", "Please Select A Product First.")
                                            }
                                        }}
                                        >
                                            <button className="commonButton"><span>Show
                                                {isLoading && (
                                                    <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />)}
                                            </span></button>
                                        </div>
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="reportsHead__field">
                                <Form.Group className="form-group mb-0">
                                    <Form.Label>Top Sellers</Form.Label>
                                    <Select
                                        className="select2 singleSelect"
                                        // isLoading={!topSellersList.length}
                                        // menuIsOpen={true}
                                        placeholder="Top Sellers"
                                        options={topSellersList}
                                        labelledBy="Select"
                                        onChange={(e) => {
                                            setSelectedProduct(e)
                                            setIsSearched(false)
                                            onSearchProductHandler(e)
                                        }}

                                    />
                                </Form.Group>
                            </div>
                            <div className="reportsHead__field">
                                <Form.Group className="form-group mb-0">
                                    <Form.Label>Top Earners</Form.Label>
                                    <Select
                                        // isLoading={!topEarnersList.length}
                                        className="select2 singleSelect"
                                        placeholder="Top Earners"
                                        options={topEarnersList}
                                        labelledBy="Select"
                                        onChange={(e) => {
                                            setSelectedProduct(e)
                                            setIsSearched(false)
                                            onSearchProductHandler(e)
                                        }}
                                    />
                                </Form.Group>

                            </div>
                        </div>
                    </div>
                    <div className="reportsBody">
                        <Row>
                            {isShow &&
                                <Col xxl={2} xl={3} lg={4}>
                                    <div className="chartGrossSale">
                                        <h3>${productDetails.priceItem}</h3>
                                        <span>Sales For The Selected Items</span>
                                        <h3>{productDetails.orderItem}</h3>
                                        <span>Purchases For The Selected Items</span>
                                    </div>
                                    <div className="whiteBg resetReportsData">
                                        <h4>Showing Result For:</h4>
                                        <div className="selectedProductName">{productDetails.name}</div>
                                        <div className="mt-2">
                                            <button onClick={onResetHandler} className="commonButton"><span>Reset</span></button>
                                        </div>
                                    </div>
                                </Col>
                            }
                            <Col xxl={10} xl={9} lg={8}>
                                <div className="reportsGraph pt-3 pb-4">
                                    {!isShow ?
                                        <div className="chooseProductViewStats">
                                            <img src={chooseProductImg} alt="chooseProductImg" />
                                            <h4>Choose a product to view stats</h4>
                                        </div> : productDetails.priceItem == ("0" || 0) ? <div className="chooseProductViewStats">
                                            <img src={chooseProductImg} alt="chooseProductImg" />
                                            <h4>No purchases in the given time.</h4>
                                        </div> :
                                            <Chart options={{ ...graphDataProducts.options }} series={graphDataProducts.series} type="line" height={350} />}
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            }
        </>
    )
}
const OrderByCategory = ({
    activeTab, // year,month,day
    orderBy, //order by date ,order by product
    refresh,
    branchid,
    sdate,
    edate,
    currentTable,
    setCsvData,
    setGraphDataCategories,
    graphDataCategories,
    setFilename,
    currentCsvName
}) => {
    let branchArray = useSelector((state) => state.counter.branchArray)
    const [categoriesList, setCategoriesList] = useState([])
    const [selectedCategories, setSelectedCategories] = useState([])
    const [categoryDetail, setCategoryDetail] = useState([])
    const [isShow, setIsShow] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    useEffect(() => {
        if (Object.keys(selectedCategories).length > 0) {
            onSearchCategoryHandler(selectedCategories)
        }
    }, [refresh])
    useEffect(() => {
        getAllCategories()
    }, [])
    useEffect(() => {
        if (currentTable == "Order" && orderBy == "category" && isShow) {
            onSearchCategoryHandler();
        }
    }, [activeTab, refresh, branchid.value, orderBy, currentTable, branchArray]);
    async function getAllCategories() {
        let branches = branchArray.map((item) => item.value)
        const header = {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            branchid: JSON.stringify(branches)
        };
        try {
            const response = await apiGetMethod(`${allApiUrl.view_all_categories}`, "", header);
            if (response.status === 200) {
                let category = response.data.categories.Items.map((item) => {
                    return ({
                        value: item.SK,
                        label: titleCase(item.categoryname)
                    })
                })
                setCategoriesList(category)
            }
        } catch (err) {
            console.log("err", err);
        }
    }
    async function onSearchCategoryHandler() {
        setIsLoading(true)
        let branches = branchArray.map((item) => item.value)
        const header = {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            branchid: JSON.stringify(branches)
        };
        try {
            let data = {
                searchBy: {
                    type: "Category",
                    id: selectedCategories
                },
                sdate: sdate,
                edate: edate
            }
            const response = await apiPostMethod(`${allApiUrl.viewSearchedProductAndCategory}`, data, header);
            if (response.status === 200) {
                setIsShow(true)
                // setFilename(`report-${activeTab}-${new Date().toLocaleString("en-US", newDateOptions)}`)
                setFilename(`Exported_Sales_Reports(${currentCsvName})`)
                if (isCategoryEmpty(response.data)) {
                    setGraphDataCategories({
                        series: [],
                        options: {
                            colors: ['#1C64F2', '#FDBA8C', '#D3EC1A', '#8A153C'],
                            xaxis: {
                                categories: [],
                            },
                        },
                    });
                    setCsvData([])
                }
                else {
                    let csvList = csvConvertJson(response.data.data)
                    setCsvData(csvList)
                    function monthDiff(d1, d2) {
                        var months;
                        months = (d2.getFullYear() - d1.getFullYear()) * 12;
                        months -= d1.getMonth();
                        months += d2.getMonth();
                        return months
                    }
                    // console.log("sdate", sdate, edate)
                    let diff = monthDiff(new Date(sdate), new Date(edate))
                    if (diff >= 4) {
                        let allMonths = [];
                        let monthOrder = [
                            'January', 'February', 'March', 'April',
                            'May', 'June', 'July', 'August', 'September',
                            'October', 'November', 'December'
                        ];
                        let newData = [];
                        for (let monthIndex = 0; monthIndex < response.data.data.length; monthIndex++) {
                            let obj = response.data.data[monthIndex];
                            for (let key in obj) {
                                if (key.match(/[A-Za-z]+\s\d{4}/)) {
                                    let monthName = key.split(' ')[0];
                                    let index = monthOrder.indexOf(monthName);
                                    if (index !== -1) {
                                        allMonths[index] = key;
                                    }
                                }
                            }
                        }
                        // console.log('1139', allMonths)
                        allMonths = allMonths.filter(function (month) {
                            return month !== undefined;
                        });
                        // console.log('1169')
                        Object.values(response.data.data).map((item) => {
                            let name = '';
                            Object.keys(item).forEach((keys) => {
                                if (typeof item[keys] !== "object") {
                                    name = item[keys]
                                }
                            })
                            let obj = { name, type: 'column', data: [] };
                            for (let i = 0; i < allMonths.length; i++) {
                                if (item.hasOwnProperty(allMonths[i])) {
                                    obj.data.push(item[allMonths[i]].price.toFixed(2));
                                } else {
                                    obj.data.push(0);
                                }
                            }
                            newData.push(obj);
                        });
                        // console.log('1169')
                        setGraphDataCategories({
                            series: newData,
                            options: {
                                colors: ['#1C64F2', '#FDBA8C', '#D3EC1A', '#8A153C'],
                                xaxis: {
                                    categories: allMonths,
                                },
                            },
                        });
                        //for showing in sides
                        let updatedData = []
                        for (let obj in response.data.data) {
                            // console.log('obj', obj)
                            let newPrice = 0
                            let name = ''
                            Object.values(response.data.data[obj]).forEach((item) => {
                                if (typeof item == "object") {
                                    newPrice += item.price
                                } else {
                                    name = item
                                }
                            })

                            updatedData.push({ name, newPrice })
                        }
                        setCategoryDetail(updatedData)
                    }
                    else {
                        let allDates = response.data.data.reduce((dates, item) => {
                            return dates.concat(Object.keys(item).filter(key => key !== 'entity'));
                        }, []);
                        allDates = [...new Set(allDates)].sort();
                        let startDate = new Date(Math.min(...allDates.map(date => new Date(date))));
                        let endDate = new Date(Math.max(...allDates.map(date => new Date(date))));
                        let allDatesArray = [];
                        let currentDate = new Date(startDate);
                        while (currentDate <= endDate) {
                            allDatesArray.push(currentDate.toLocaleDateString('en-US', { month: 'long', day: '2-digit', year: 'numeric' }));
                            currentDate.setDate(currentDate.getDate() + 1);
                        }
                        // allDatesArray = allDatesArray.map((item) => item.replaceAll(',', ''))
                        const newData = response.data.data.map(item => {
                            const dataItem = allDatesArray.map(date => {
                                // console.log('item[date]?.price', item[date]?.price, item[date], item, date)
                                const price = item[date]?.price || 0;
                                return price;
                            });
                            return {
                                name: item.entity,
                                type: 'column',
                                data: dataItem,
                            };
                        });
                        //for showing in sides
                        let updatedData = []
                        for (let obj in response.data.data) {
                            let newPrice = 0
                            let name = ''
                            Object.values(response.data.data[obj]).forEach((item) => {
                                if (typeof item == "object") {
                                    newPrice += item.price
                                } else {
                                    name = item
                                }
                            })

                            updatedData.push({ name, newPrice })
                        }
                        setCategoryDetail(updatedData)
                        setGraphDataCategories({
                            series: newData,
                            options: {
                                colors: ['#1C64F2', '#FDBA8C', '#D3EC1A', '#8A153C'],
                                xaxis: {
                                    categories: allDatesArray,
                                },
                            },
                        });
                    }
                }
                setIsLoading(false)
            }
        } catch (err) {
            setIsLoading(false)
            console.log("err", err);
        }
    }
    return (
        <>
            <Col xl={3} lg={4}>
                <div className="reportsCategoery">
                    <Form>
                        <Form.Group className="form-group">
                            <Form.Label>Categories</Form.Label>
                            <Select
                                // isLoading={!categoriesList.length}
                                className="select2 multiSelect"
                                isMulti
                                options={categoriesList}
                                value={selectedCategories}
                                onChange={(e) => {
                                    setSelectedCategories(e)
                                }}
                                labelledBy="Select" />
                        </Form.Group>
                        <Col className="buttonOuter d-flex align-items-center">
                            <Button className="commonButton" onClick={() => {
                                if (Object.keys(selectedCategories).length) {
                                    onSearchCategoryHandler()
                                }
                                else {
                                    toastMessages("error", "Please Select A Category First.")
                                }

                            }}>Show  {isLoading && (
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            )}</Button>
                            <ListGroup as="ul" className="filterCategories">
                                <ListGroup.Item as="li" onClick={() => setSelectedCategories(categoriesList)}>All</ListGroup.Item>
                                <ListGroup.Item as="li" onClick={() => {
                                    setIsShow(false)
                                    setSelectedCategories([])
                                    setCategoryDetail([])
                                }}>None</ListGroup.Item>
                            </ListGroup>
                        </Col>
                    </Form>
                    <div className="chartGrossSale mt-4 px-0">
                        {categoryDetail.map((item) => {
                            return (
                                <>
                                    {item.newPrice ? <h3>${parseFloat(item.newPrice).toFixed(2)}</h3> : <h3>$0.00</h3>}
                                    <span>Sales in {item.name}</span>
                                </>
                            )
                        })
                        }
                    </div>
                </div>
            </Col>
            <Col xl={9} lg={9}>
                <div className="reportsGraph pt-3 pb-4">
                    {!isShow ?
                        <div className="chooseProductViewStats">
                            <img src={chooseProductImg} alt="chooseProductImg" />
                            <h4>Choose a category to view stats</h4>
                        </div> : (Array.isArray(categoryDetail) && !categoryDetail.length) ?
                            <div className="chooseProductViewStats">
                                <img src={chooseProductImg} alt="chooseProductImg" />
                                <h4>No purchases in the given time.</h4>
                            </div>
                            : <Chart options={{ ...graphDataCategories.options }} series={graphDataCategories.series} type="line" height={350} />
                    }
                </div>
            </Col>
        </>

    )
}
