import { toast } from "react-toastify";
const customId = "custom-id-yes";

function toastMessages(messageType, message) {
  if (message) {
    if (messageType === "success") {
      toast.dismiss();
      toast.success(message, {});
    } else if (messageType === "error5000") {
      toast.error(message, {
        autoClose: 5000,
      });
    } else {
      toast.error(message, {});
    }
  }
}

export default toastMessages;
