import React, { useState } from 'react';
import { useEffect } from 'react';
import { Button, Badge, Dropdown, Table, Container, Row, Col, Image, ListGroup, Form, FloatingLabel, InputGroup, Pagination, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { allApiUrl } from '../../api/apiRoute';
import { apiGetMethod, apiPostMethod } from '../../api/rest';
import { CSVLink } from "react-csv";
//Header
import Header from '../common/header';
import Sidebar from '../common/sidebar';
import InfiniteScroll from 'react-infinite-scroll-component';
import { dateFormatter, formatDate_mm_dd_yyyy, titleCase } from '../../features/helper';
import { v4 as uuidv4 } from 'uuid';
import { ExportCsv } from '../common-ui/Button';
import SidebarTwo from '../common/sidebarTwo';
import { changeTableManagement, changeWebsiteTemplate } from '../../features/todoSlice';
function Transactions() {
    //Sidebar Toggle
    let dispatch = useDispatch()
    const [active, setActive] = useState(false)
    let branchArray = useSelector((state) => state.counter.branchArray)
    let branches = branchArray.map((item) => item.value)
    const [permissionData, setPermissionData] = useState({})
    const currBranch = useSelector((state) => state.counter.currBranch)
    const [isLoading, setIsLoading] = useState(true)
    const [transactionList, setTransactionList] = useState([])
    const [csvList, setCsvList] = useState([])
    useEffect(() => {
        setIsLoading(true)
        getAllTransaction()
    }, [branchArray])
    useEffect(() => {
        getDisableStatus()
    }, [permissionData])
    async function getDisableStatus() {
        const header = {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            branchid: JSON.stringify(branches),
        };
        try {
            const response = await apiGetMethod(allApiUrl.viewSetting, "", header);
            if (response.status == 200) {
                if (response.data.Items.length != 0) {
                    //for table managment sidebar toggle functionality.
                    if (permissionData.tablemanagement == 0) {
                        console.log('116')
                        dispatch(changeTableManagement(false));
                        console.log('116')
                    }
                    else {
                        console.log('116')
                        if (response.data.Items[0].GeneralSettings.tablemanagement == 1) {
                            console.log('116')
                            dispatch(changeTableManagement(true));
                        } else {
                            console.log('116')
                            dispatch(changeTableManagement(false));
                        }
                    }
                    // for website builder
                    if (permissionData.websitetemplate == 0) {
                        dispatch(changeWebsiteTemplate(false));
                    }
                    else {
                        if (response.data.Items[0].WebsiteTemplate.enableWebsiteTemplate == 1) {
                            //eslint-disable-line
                            dispatch(changeWebsiteTemplate(true));
                        } else {
                            dispatch(changeWebsiteTemplate(false));
                        }
                    }

                }
            }
        } catch (err) {
            console.log("err", err);
        }
    }
    async function getAllTransaction() {
        let branches = branchArray.map((item) => item.value)
        const header = {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            branchid: JSON.stringify(branches)
        };
        try {
            let data = {}
            const response = await apiPostMethod(allApiUrl.transaction, data, header)
            if (response.status === 200) {
                let csvList = []
                setTransactionList(response.data.data)
                response.data.data.map((item) => {
                    return (
                        csvList.push({
                            "Transaction Name": item.name,
                            "Transaction Date": dateFormatter(item.date),
                            "Transaction Amount": item.amount,
                            "Transaction Store": item.branch,
                            // "Transaction Type": item.transactionType,
                            "Payment Status": item.paymentStatus
                        })
                    )
                })
                setCsvList(csvList)
                setIsLoading(false)
            }
        } catch (err) {
            setIsLoading(false)
            console.log("err", err);
        }
    }
    // async function getAllOrders() {
    //     const header = {
    //         Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    //         branchid: currBranch.value
    //     };
    //     try {
    //         const response = await apiGetMethod(`${allApiUrl.viewOrder}`, "", header);
    //         if (response.status === 200) {
    //             setOrderListing(response.data.orders.Items)
    //             setIsLoading(false)

    //         }
    //     } catch (err) {
    //         console.log("err", err);
    //     }
    // }
    const [AllBranchList, setAllBranchList] = useState([])
    // console.log('allBranchList', AllBranchList)
    let sidebarToggle = useSelector((state) => state.counter.sidebarToggle)
    const [sidebarType, setSidebarType] = useState('singleSidebar')
    let sidebarTypeRedux = useSelector((state) => state.counter.sidebarType)
    console.log('permission from transaction', permissionData)
    return (
        <React.Fragment>
            <Header setActive={setActive} active={sidebarToggle} handleCb={(data) => {
                setAllBranchList(data)
            }} />
            <div className="interFaceWrap">
                <Sidebar active={sidebarToggle} activePage="transactions" permissionData={permissionData} />
                <div className={`interFaceWrap__inner ${sidebarTypeRedux == false ? "doubleSidebar" : "singleSidebar"}`}>
                    <div className="commonOuter">
                        <div className="pageHeader">
                            <Container fluid className="px-0">
                                <Row className="align-items-center">
                                    <div className="col">
                                        <div className="headingText headingText--textLeft mb-0">
                                            <h1>Transactions</h1>
                                        </div>
                                    </div>
                                    <div className="col-auto ms-auto onlyButtons">
                                        <div className="pageHeader__elements">
                                            <ExportCsv
                                                filename={`Exported_Transaction_Reports(${formatDate_mm_dd_yyyy(Date.now())})`}
                                                csvData={csvList}
                                            />
                                        </div>
                                    </div>
                                </Row>
                            </Container>
                        </div>
                        <div className="commonTable">
                            <div className="whiteBg">
                                <div className="headingText headingText--dflex headingText--textLeft headingText--border">
                                    <h4>All Transactions</h4>
                                </div>
                                {isLoading && <div style={{ height: '350px' }}><Spinner animation="border" role="status">  <span className="visually-hidden">Loading...</span> </Spinner></div>}
                                <InfiniteScroll
                                    dataLength={transactionList.length}
                                    // next={fetchMoreData}
                                    // hasMore={loaderForFetchMore}
                                    className="equalHeightTable">
                                    <div className='tableFixHead'>
                                        {!isLoading && <Table >
                                            <thead>
                                                <tr key={uuidv4()}>
                                                    <th>Transaction Name</th>
                                                    <th>Transaction Date</th>
                                                    <th>Transaction Amount</th>
                                                    <th>Transaction Store</th>
                                                    <th>Transaction Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {transactionList && transactionList.length > 0 ? transactionList.map((item) => {
                                                    let dateFormatted = dateFormatter(item.date)
                                                    return (
                                                        <tr key={uuidv4()}>
                                                            <td>{titleCase(item.name)}</td>
                                                            <td>{dateFormatted}</td>
                                                            <td>${parseFloat(item.amount).toFixed(2)}</td>
                                                            <td>{titleCase(item.branch)}</td>
                                                            <td><Badge bg="completed">{item.paymentStatus}</Badge></td>
                                                        </tr>
                                                    )
                                                }) : <tr key={uuidv4()}><td className="text-center" colSpan="5">No Transactions Found</td></tr>}
                                            </tbody>
                                        </Table>}
                                    </div>
                                </InfiniteScroll>
                            </div>
                        </div>
                    </div>
                </div>
                <SidebarTwo activePage="" setSidebarType={setSidebarType} sidebarType={sidebarType} setPermissionData={setPermissionData} />
            </div>
        </React.Fragment >
    )
}
export default Transactions;