import React, { useEffect } from "react";
import { useState } from "react";
import { Button, Table, Row, Col, Form, Spinner } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import Accordion from "react-bootstrap/Accordion";
import CryptoJS from "crypto-js";
import "react-phone-input-2/lib/bootstrap.css";
import PhoneInput from "react-phone-input-2";
import queryString from "query-string";

//Stripe Integration 
import toastMessages from "../toastMessages";
import Loader from "../shared/spinner";
import "./css/styles.scss";
import dummyProduct from "../../components/shared/assets/images/dummyProduct.jpg";
import { allApiUrl } from "../../api/apiRoute";
import { apiGetMethod, apiPostMethod } from "../../api/rest";
import { loadStripe } from "@stripe/stripe-js";
import { ElementsConsumer, CardElement } from "@stripe/react-stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CardSection from "../../features/CartSection";
import jwt_decode from "jwt-decode";
import { c, getUtcDate, titleCase } from "../../features/helper";

function MenuOrder() {
  const locationData = useLocation();
  let { token, tableNumber } = queryString.parse(locationData.search);
  let orderFromQrCode = false
  if (tableNumber) {
    orderFromQrCode = true
  }
  const decoded = jwt_decode(token);
  let { branchId, PK, branchname } = decoded;
  let restaurant = PK.split("#")[1];
  const alphabetRegex = /^[a-zA-Z ]*$/;
  const [selectDateOption, setSelectDateOption] = useState([]);
  const [selectedTimeOption, setSelectedTimeOption] = useState([]);
  const [productCategoryWise, setProductCategoryWise] = useState([]);
  const [modifierList, setModifierList] = useState([]);
  const [stripeObject, setStripeObject] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [currentTax, setCurrentTax] = useState(0);
  const [selectedDateObject, setSelectedDateObject] = useState({});
  const [timeUpdated, setTimeUpdated] = useState(false);
  const [restaurantStartTime, setRestaurantTime] = useState("");
  const [activeProduct, setActiveProduct] = useState(null);
  const [activePage, setActivePage] = useState("menuListing");
  const [disableContactButton, setDisableContactButton] = useState(true);
  const [showCommentBox, setShowCommentBox] = useState(false);
  const [cartItem, setCartItem] = useState([]);
  const [subTotal, setSubTotal] = useState(0);
  const [tax, setTax] = useState(0);
  // validation
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(false);
  const [phoneValidatError, setPhoneValidError] = useState(false);
  const [error, setError] = useState(null);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [contactDataSaved, setContactDataSaved] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isProductSelected, setIsProductSelected] = useState(false);
  const [isQuantityUpdated, setIsQuantityUpdated] = useState(false);
  const [isOrderPlaced, setIsOrderPlaced] = useState(false);
  const [addtoCartError, setAddToCartError] = useState([]);
  const [stripe, setStripe] = useState("");
  const [element, setElement] = useState("");
  const [preparationTime, setpreparationTime] = useState(0);
  const [minAndRequired, setMinAndRequired] = useState([]);
  // const [timeForAsap, setTimeForAsap] = useState('')
  const [quantityCountList, setQuantityCountList] = useState({})
  async function configurePayment(keyItem) {
    try {
      let token = keyItem
        .replace(/p1L2u3S/g, "+")
        .replace(/s1L2a3S4h/g, "/")
        .replace(/e1Q2u3A4l/g, "=");
      const bytes = CryptoJS.AES.decrypt(
        token,
        process.env.REACT_APP_SECRET
      );
      // console.log('bytes', bytes)
      let dataString1 = CryptoJS.enc.Utf8.stringify(bytes);
      let res = await loadStripe(dataString1);
      // console.log('82', dataString1)
      setStripeObject(res);
    } catch (errr) {
      console.log("errr", errr);
    }
  }
  const [form, setForm] = useState({
    origin: "webApplication",
    orderType: {
      type: "",
      name: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
      sendSms: 1,
      noOfGuest: "",
      date: '',
      time: '',
      timeObject: {},
      dateObject: {},
      note: "",
      email: "",
      sendEmail: 1,
    },
    paymentMethod: {
      fullName: "",
      cardNumber: "",
      expiryDate: "",
      cvc: "",
    },
    order: {
      productName: "",
      productSize: "",
      productPrice: "",
      preparationtime: "0",
      productId: "",
      modifier: [],
      note: "",
      quantity: 1,
      total: "",
    },
    tax: "",
    subTotal: "",
    discount: "",
    gratuity: "",
    taxPercentage: "",
    finalTotal: "",
    table: "",
    status: "",
    userRoleID: "Online Order",
  });
  function countNumberInPhoneNumber(str, letter) {
    let count = 0;
    for (let i = 0; i < str.length; i++) {
      if (str.charAt(i) == letter) {
        count += 1;
      }
    }
    return count;
  }
  useEffect(() => {
    getData();
    getTax();
    getStripeKeys();
    // let userInformation = JSON.parse(localStorage.getItem("userInformation"));
    // if (userInformation) {
    //   setForm(userInformation);
    // }
  }, []);
  useEffect(() => {
    getAllModifier();
  }, [selectedProduct]);
  async function getAllModifier() {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    try {
      const response = await apiGetMethod(
        `${allApiUrl.view_all_modifier_digital}`,
        "",
        header
      );
      if (response.status === 200) {
        setModifierList(response.data.categories.Items);
      }
    } catch (err) {
      console.log("err", err);
    }
  }
  async function getData() {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    try {
      const response = await apiGetMethod(
        `${allApiUrl.listingProduct}`,
        " ",
        header
      );
      if (response.status === 200) {
        setProductCategoryWise(response.data.product);
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
    }
  }
  async function getStripeKeys() {
    const header = {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    };
    try {
      let data = {
        branchId: branchId,
        restaurant: restaurant,
      };
      const response = await apiPostMethod(`${allApiUrl.keys}`, data, header);
      if (response.status === 200) {
        // console.log('200', response.data.string)
        configurePayment(response.data.string);
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
    }
  }
  function formatAMPM(date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    let strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }

  useEffect(() => {
    setForm({ ...form, orderType: { ...form.orderType, timeObject: {} } });
    let option = [];
    let liveTime = new Date();
    let timeDisplay = liveTime.setMinutes(liveTime.getMinutes());
    // console.log("timeDisplay--->247", new Date(timeDisplay), preparationTime);
    let today = new Date();
    // console.log("today", today);
    let tomorrow = new Date(today);
    today.setDate(today.getDate());
    option.push({ label: "ASAP", value: today });
    option.push({ label: "Today", value: today });
    tomorrow.setDate(tomorrow.getDate() + 1);
    option.push({ label: "Tomorrow", value: tomorrow });
    const dayAfterTomorrow = new Date(tomorrow);
    dayAfterTomorrow.setDate(dayAfterTomorrow.getDate() + 1);
    option.push({
      label: formatDate(dayAfterTomorrow),
      value: dayAfterTomorrow,
    });
    const twoDaysAfter = new Date(dayAfterTomorrow);
    twoDaysAfter.setDate(twoDaysAfter.getDate() + 1);
    option.push({ label: formatDate(twoDaysAfter), value: twoDaysAfter });
    setSelectDateOption(option);
    function generateTimeOptions() {
      // const startHour = 11;
      // const endHour = 22;
      const timeOptions = [];
      const anotherDateOption = [];
      let endHour = 23; //static time 
      let startHour = today.getHours();
      // console.log('startHour', startHour)

      if (startHour < 11) {
        // console.log('---249-')
        startHour = 11;
        today = new Date(liveTime.setHours(11, preparationTime, 0, 0));
      }
      // else if (startHour > 13) {
      //   const ampm = startHour < 12 ? "AM" : "PM";
      //   const hour12 = startHour > 12 ? startHour - 12 : startHour;
      //   let minutesnow = today.getMinutes();
      //   today.setMinutes(minutesnow + preparationTime);
      //   startHour = today.getHours();
      //   console.log("timeString 354", hour12);
      //   const timeString = `${hour12.toString().padStart(2, "0")}:${minutesnow
      //     .toString()
      //     .padStart(2, "0")} ${ampm}`;
      //   console.log("timeString 11", timeString);
      //   timeOptions.push({ label: timeString, value: timeString });
      // }
      else {
        // console.log('---267-', liveTime)
        today = new Date(
          liveTime.setMinutes(liveTime.getMinutes() + preparationTime)
        );
      }
      // console.log('today,livetime', today, liveTime)
      option[0].value = today;
      option[1].value = today;
      if (
        form.orderType.dateObject.label != "Today" &&
        form.orderType.dateObject.label != "ASAP"
      ) {
        startHour = 11;
        today = new Date(liveTime.setHours(11, preparationTime, 0, 0));
      }
      // console.log('todayBefore', today)
      // console.log('startHour', startHour)
      for (let hour = startHour; hour < endHour;) {
        // console.log("hour-----292", hour);
        let minutesnow = today.getMinutes();
        // console.log('minutesnow', minutesnow)  // 20
        today.setMinutes(minutesnow + 15);
        // console.log('291', today)
        hour = today.getHours();
        // console.log('hour---', hour)
        const ampm = hour < 12 ? "AM" : "PM";
        const hour12 = hour > 12 ? hour - 12 : hour; // 3.30 ke baad   3
        // const timeString = `${hour12.toString().padStart(2, "0")}:${minutesnow
        //   .toString()
        //   .padStart(2, "0")} ${ampm}`;
        const timeString = `${hour.toString().padStart(2, "0")}:${minutesnow
          .toString()
          .padStart(2, "0")}`;

        var currentTime = formatAMPM(today);
        var start_time = currentTime;
        var end_time = timeString;
        start_time = start_time.split(" ");
        var time = start_time[0].split(":");
        var stime = time[0];
        if (start_time[1] == "PM" && stime < 12) stime = parseInt(stime) + 12;
        start_time = stime + ":" + time[1] + ":00";
        end_time = end_time.split(" ");
        var time1 = end_time[0].split(":");
        var etime = time1[0];
        if (end_time[1] == "PM" && etime < 12) etime = parseInt(etime) + 12;
        end_time = etime + ":" + time1[1] + ":00";
        setRestaurantTime(start_time);
        // console.log('timeString--294', timeString)
        if (
          form.orderType.dateObject.label === "Today" ||
          form.orderType.dateObject.label === "ASAP" //&&end_time > start_time
        ) {
          // console.log("311", timeOptions, timeString);
          timeOptions.push({ label: timeString, value: timeString });
        } else {
          anotherDateOption.push({ label: timeString, value: timeString });
        }
        // console.log("time Display", today, hour);
      }
      // console.log("389", timeOptions);
      // console.log("390", anotherDateOption);
      if (
        form.orderType.dateObject.label === "Today" || form.orderType.dateObject.label === "ASAP") {
        return timeOptions;
      } else {
        return anotherDateOption;
      }
    }
    setSelectedTimeOption(generateTimeOptions());
  }, [timeUpdated, preparationTime]);
  function formatDate(date) {
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    const dateParts = date.toLocaleDateString("en-US", options).split(",");
    const day = date.getDate();
    const daySuffix = getDaySuffix(day);
    return `${dateParts[0]}, ${dateParts[1].trim()}${daySuffix}`;
  }
  function getDaySuffix(day) {
    if (day > 3 && day < 21) return "th";
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  }
  async function getTax() {
    const header = {
      Authorization: `Bearer ${token}`,
      branchid: branchId,
    };
    try {
      const response = await apiGetMethod(
        `${allApiUrl.viewSetting}`,
        "",
        header
      );
      if (response.status === 200) {
        setCurrentTax(
          parseFloat(
            response.data.Items[0].PaymentSettings.taxrate.replace("%", "")
          ) || 0
        );
      }
      toastMessages("success", response.data.message);
    } catch (err) {
      console.log("err", err);
    }
  }
  useEffect(() => {
    //disabling the contact save button if ant of the field is not filled in contact
    localStorage.setItem("userInformation", JSON.stringify(form));
    if (form.orderType) {
      if (
        isEmailValid == true &&
        isPhoneNumberValid == true &&
        form.orderType.firstName.length > 0 &&
        form.orderType.lastName.length > 0
      ) {
        setDisableContactButton(false);
      } else {
        setDisableContactButton(true);
      }
    }
  }, [form]);
  function containsOnlySpecialChars(str) {
    return /^[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/.test(str); //eslint-disable-line
  }
  const handleOnChange = (value, country) => {
    setForm({ ...form, orderType: { ...form.orderType, phoneNumber: value } });
    const result = countNumberInPhoneNumber(country.format, ".");
    if (
      value.length - country.dialCode.length ==
      result - country.dialCode.length ||
      result - country.dialCode.length > 10
    ) {
      setIsPhoneNumberValid(true);
    } else {
      setIsPhoneNumberValid(false);
      setPhoneValidError(
        `The Phone Number should be of ${result - country.dialCode.length
        } Digit`
      );
    }
  };
  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }
  const emailValidation = (e) => {
    if (e.target.value.length < 81) {
      setForm({
        ...form,
        orderType: { ...form.orderType, email: e.target.value },
      });
      setError(null);
      if (isValidEmail(e.target.value)) {
        let check = e.target.value.split("@")[0];
        let isnum = /^\d+$/.test(check);

        if (isnum === true || containsOnlySpecialChars(check)) {
          setIsEmailValid(false);
          setError("The email address field must be a valid email");
        } else {
          setIsEmailValid(true);
          setError("email is valid");
        }
      } else {
        setIsEmailValid(false);
        setError("The email address field must be a valid email");
      }
    } else {
      setIsEmailValid(false);
      setError("Email cann't be greater than 80 chars.");
    }
  };
  const [showIcon, setShowIcon] = useState({
    contact: { show: false },
    orderType: { show: false },
    paymentMethod: { show: false },
  });
  function saveContact(e) {
    e.preventDefault();
    setContactDataSaved(true);
    setShowIcon({ ...showIcon, contact: { show: !showIcon.contact.show } });
  }
  function titleCase(str) {
    var splitStr = str.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  }
  //when Quantity is Changed this will work
  useEffect(() => {
    if (selectedProduct && selectedProduct.price) {
      let data = 0;
      data = parseFloat(selectedProduct.price);
      currentModifierObject.forEach((item) => {
        if (item.quantity) {
          data = data + (parseFloat(item.price) * item.quantity)
        } else {
          data = data + parseFloat(item.price);
        }
      });
      if (form.order.quantity) {
        // console.log("form.order.quantity", form.order.quantity, data);
        setForm((prev) => {
          prev.order = { ...prev.order, total: data * form.order.quantity };
          return { ...prev };
        });
        setTotalProductPrice(data * form.order.quantity);
      }
      // console.log("form-->", form);
    }
  }, [isQuantityUpdated]);
  console.log('499', minAndRequired)
  function addToCartHandler() {
    let status = minAndRequired.map((item, index) => {
      let isExist = currentModifierObject.filter((row) => {
        return (row.added_by == item.name);
      });
      if (isExist.length > item.maximum && item.maximum != 0) {
        return {
          status: false,
          message: toastMessages(
            "error",
            `Maximum ${item.maximum} is allowed in ${item.name}`
          ),
        };
      } else if (isExist.length < item.minimum) {

        return {
          status: false,
          message: toastMessages(
            "error",
            `Minimum ${item.minimum} is required in ${item.name}.`
          ),
        };
      } else {
        return { status: true };
      }
    });
    let conditionStatus = status.filter((item) => {
      return item.status === false;
    });
    if (conditionStatus.length) {
      return conditionStatus[0].message;
    }
    let isValid = true;
    let errorMessage = [];

    if (isValid) {
      // console.log("status proceeding");
      setAddToCartError([]);
      setActiveProduct(null);
      setSelectedProduct(null);
      // console.log("form 700", form);
      setForm({ ...form, order: { ...form.order, quantity: 1, note: "" } });
      if (parseInt(form.order.preparationtime) > parseInt(preparationTime)) {
        setpreparationTime(parseInt(form.order.preparationtime));
      }
      let addToCartForm = {
        ...form,
        order: {
          ...form.order,
          modifier: currentModifierObject
        }
      }
      // console.log('cartItem 581', cartItem)
      // console.log('addtocartForm 581', addToCartForm)
      let existingItemIndex
      if (cartItem.length) {
        // console.log('cartItem-552', cartItem)
        existingItemIndex =
          cartItem &&
          cartItem.length > 0 &&
          cartItem.findIndex((item) => (
            item.form.order.productName == addToCartForm.order.productName &&
            item.form.order.productName === addToCartForm.order.productName &&
            item.form.order.productPrice === addToCartForm.order.productPrice &&
            item.form.order.modifier.length === addToCartForm.order.modifier.length &&
            item.form.order.modifier.every((modifier) =>
              addToCartForm.order.modifier.some((addToCartModifier) => addToCartModifier.name === modifier.name)
            )
          ))
      }
      if (cartItem.length >= 1 && existingItemIndex !== -1) {
        cartItem[existingItemIndex].form.order.quantity += addToCartForm.order.quantity;
        let updatedCartInformation = cartItem;
        setCartItem(updatedCartInformation);
      } else {
        setCartItem((prev) => {
          return [...prev, { form: addToCartForm }];
        });
      }
    } else {
      setAddToCartError(errorMessage);
    }
  }
  // console.log('586', form)
  // console.log('580', cartItem)
  async function onOrderHandler(e) {
    e.preventDefault();
    if (form.orderType.sendEmail === 0 && form.orderType.sendSms === 0) {
      toastMessages("error", "Please select atleast one communication mode");
    } else {
      setIsOrderPlaced(true);
      // let previousDataExist = localStorage.getItem("userInformation");
      // previousDataExist = JSON.parse(previousDataExist);
      // let checkContactDetail =
      //   previousDataExist.orderType.firstName &&
      //   previousDataExist.orderType.lastName &&
      //   previousDataExist.orderType.phoneNumber &&
      //   previousDataExist.orderType.email &&
      //   true;
      // let checkOrderType = previousDataExist.orderType.type && true;
      // let checkAvailableTimeChoice =
      //   !Object.keys(previousDataExist.orderType.date).length == 0 &&
      //   !Object.keys(previousDataExist.orderType.time).length == 0;
      // console.log('form.orderType', form.orderType, contactDataSaved, checkContactDetail, orderFromQrCode)
      if (
        (contactDataSaved || orderFromQrCode) && //|| checkContactDetail 
        (form.orderType.type || orderFromQrCode) && //|| checkOrderType 
        // (form.orderType.date || checkAvailableTimeChoice || orderFromQrCode) &&
        // (form.orderType.time || checkAvailableTimeChoice || orderFromQrCode)
        // console.log('orderFromQrCode', orderFromQrCode)
        (form.orderType.date || orderFromQrCode) &&
        (form.orderType.time || orderFromQrCode)
      ) {
        // console.log('955 Order Created')
        createOrder();
        // setIsOrderPlaced(false);
      } else {
        setTimeout(() => {
          setIsOrderPlaced(false);
        }, [1000]);
        let error = [
          {
            key: "contact",
            value:
              "Contact Information is not saved.Please save the contact information.",
          },
          {
            key: "payment",
            value:
              "Payment Information is not saved.Please save the payment information.",
          },
          {
            key: "orderType",
            value:
              "Ordering method is not saved.Please save the ordering method Information.",
          },
          {
            key: "availableTimeChoice",
            value:
              "Available Time Choice is not saved.Please save the Available Time Choice Information.",
          },
        ];
        if (!contactDataSaved
          // && !checkContactDetail
        ) {
          toastMessages("error", error[0].value);
        } else if (!form.orderType.type
          //  && !checkOrderType
        ) {
          toastMessages("error", error[2].value);
        } else if (
          (!form.orderType.date || !form.orderType.time)
          // &&
          // !checkAvailableTimeChoice
        ) {
          toastMessages("error", error[3].value);
        }
      }
      // const payLaterUsingQrCode = async () => {

      // }
      async function createOrder() {
        // let cartItemLocal = localStorage.getItem("cartItems");
        // if (cartItemLocal) {
        //   cartItemLocal = JSON.parse(cartItemLocal);
        // }
        const header = {};
        form.orderType.name = form.orderType.firstName.concat(" ", form.orderType.lastName
        );
        form.taxPercentage = currentTax + "%";
        form.product = cartItem.map((item) => item.form.order);
        form.product.forEach((orderDetail) => {
          let individualPrice = 0;
          if (typeof orderDetail.price == "string") {
            individualPrice = parseFloat(orderDetail.productPrice.replace("$", ""));
          } else {
            individualPrice = parseFloat(orderDetail.productPrice);
          }
          if (orderDetail.modifier.length > 0) {
            orderDetail.modifier.forEach((modifier) => {
              // console.log("modifier", modifier);
              if (typeof modifier.price == "string") {
                if (modifier.quantity) {
                  individualPrice += (parseFloat(modifier.price.replace("$", "")) * modifier.quantity);
                } else {
                  individualPrice += parseFloat(modifier.price.replace("$", ""))
                }
              } else {
                if (modifier.quantity) {
                  individualPrice += (parseFloat(modifier.price) * modifier.quantity);
                } else {
                  individualPrice += parseFloat(modifier.price)
                }
              }
              modifier.modifierId = modifier.id;
              delete modifier.added_by;
              delete modifier.parent_id;
              delete modifier.id;
              delete modifier.branchId;
              delete modifier.nestedData
              if (modifier.quantity == 0) delete modifier.quantity
            });
          }
          // console.log('individualPrice', individualPrice)
          orderDetail.total = individualPrice * orderDetail.quantity;
        });
        form.preparationTime = preparationTime
        Reflect.deleteProperty(form, "order");
        form.subTotal = subTotal;
        form.tax = tax;
        form.finalTotal = (parseFloat(subTotal) + parseFloat(tax)).toFixed(2);
        form.refund = "";
        form.registerID = "";
        form.employeeName = "Online Order";
        form.branchid = branchId;
        form.restaurant = restaurant;
        form.orderDate
          = form.orderType.dateObject.label !== "ASAP" ?
            form.orderType.date :
            getUtcDate(selectedTimeOption[0].value, new Date(form.orderType.dateObject.value).getTime())
        // delete form.orderType.dateObject;
        // delete form.orderType.timeObject;
        const card = element.getElement(CardElement);
        let paymenMethodParameter = {
          type: "card",
          card,
        };
        if (orderFromQrCode && paymentType == "later") {
          // payLaterUsingQrCode()
          const header = {};
          try {
            let updatedform = {
              ...form,
              orderType: {
                ...form.orderType,
                type: 'Dine-in',
              },
              transactionType: 'payLater',
              table: tableNumber
            }
            const response = await apiPostMethod(
              allApiUrl.createOrder_dine_in,
              updatedform,
              header
            );
            if (response.status === 200) {
              // console.log('748')
              toastMessages("success", response.data.message);
              setCartItem([]);
              // console.log('750')
              localStorage.removeItem("userInformation");
              localStorage.removeItem("cartItems");
              setActivePage("menuListing");
              // console.log('753')
              setSubTotal(0);
              setTax(0);
              setSelectedDateObject({});
              setForm({
                branchid: branchId,
                orderDate: Date.now(),
                restaurant: restaurant,
                origin: "webApplication",
                orderType: {
                  type: "",
                  name: "",
                  firstName: "",
                  lastName: "",
                  phoneNumber: "+1",
                  noOfGuest: "",
                  date: '',
                  time: '',
                  timeObject: {},
                  dateObject: {},
                  note: "",
                  email: "",
                  sendSms: 1,
                  sendEmail: 1,
                },
                paymentMethod: {
                  fullName: "",
                  cardNumber: "",
                  expiryDate: "",
                  cvc: "",
                },
                order: {
                  productName: "",
                  productSize: "",
                  productPrice: "",
                  productId: "",
                  modifier: [],
                  preparationtime: "0",
                  note: "",
                  quantity: 1,
                  total: "",
                },
                tax: "",
                subTotal: "",
                discount: "",
                gratuity: "",
                taxPercentage: "",
                finalTotal: "",
                userRoleID: "Online Order",
                table: "",
                status: "",
              });
            }
            setIsOrderPlaced(false);
            // console.log('809')
            // element.getElement(CardElement).clear();
            // console.log('811')
          } catch (err) {
            // element.getElement(CardElement).clear();
            toastMessages("error", err.data.message);
            setCartItem([]);
            localStorage.removeItem("userInformation");
            localStorage.removeItem("cartItems");
            setActivePage("menuListing");
            setSubTotal(0);
            setTax(0);
            setForm({
              branchid: branchId,
              orderDate: Date.now(),
              restaurant: restaurant,
              origin: "webApplication",
              orderType: {
                type: "",
                name: "",
                firstName: "",
                lastName: "",
                phoneNumber: "+1",
                noOfGuest: "",
                date: "",
                time: "",
                timeObject: {},
                dateObject: {},
                note: "",
                email: "",
              },
              paymentMethod: {
                fullName: "",
                cardNumber: "",
                expiryDate: "",
                cvc: "",
              },
              order: {
                productName: "",
                productSize: "",
                productPrice: "",
                preparationtime: "0",
                productId: "",
                modifier: [],
                note: "",
                quantity: 1,
                total: "",
              },
              tax: "",
              subTotal: "",
              discount: "",
              gratuity: "",
              taxPercentage: "",
              finalTotal: "",
              userRoleID: "Online Order",
              table: "",
              status: "",
            });
            setIsOrderPlaced(false);
          }
        }
        else {
          const paymentMethod = await stripe.createPaymentMethod(paymenMethodParameter);
          if (paymentMethod.error) {
            // console.log('800--1')
            toastMessages("error", paymentMethod.error.message);
            setIsOrderPlaced(false);
          } else {
            // console.log('800--2')
            if (orderFromQrCode) {
              form.orderType.type = "Dine-In"
              form.table = tableNumber
            }
            form.paymentMethod.cardNumber = paymentMethod["paymentMethod"].card.last4;
            form.paymentMethodId = paymentMethod["paymentMethod"].id;
            delete form.orderType.dateObject;
            delete form.orderType.timeObject;
            try {
              const response = await apiPostMethod(
                allApiUrl.createOrder,
                form,
                header
              );
              if (response.status === 200) {
                toastMessages("success", response.data.message);
                setCartItem([]);
                localStorage.removeItem("userInformation");
                localStorage.removeItem("cartItems");
                setActivePage("menuListing");
                setSubTotal(0);
                setTax(0);
                setSelectedDateObject({});
                setForm({
                  branchid: branchId,
                  orderDate: Date.now(),
                  restaurant: restaurant,
                  origin: "webApplication",
                  orderType: {
                    type: "",
                    name: "",
                    firstName: "",
                    lastName: "",
                    phoneNumber: "+1",
                    noOfGuest: "",
                    date: "",
                    time: "",
                    timeObject: {},
                    dateObject: {},
                    note: "",
                    email: "",
                    sendSms: 1,
                    sendEmail: 1,
                  },
                  paymentMethod: {
                    fullName: "",
                    cardNumber: "",
                    expiryDate: "",
                    cvc: "",
                  },
                  order: {
                    productName: "",
                    productSize: "",
                    productPrice: "",
                    productId: "",
                    modifier: [],
                    note: "",
                    quantity: 1,
                    total: "",
                  },
                  tax: "",
                  subTotal: "",
                  discount: "",
                  gratuity: "",
                  taxPercentage: "",
                  finalTotal: "",
                  userRoleID: "Online Order",
                  table: "",
                  status: "",
                });
              }
              setIsOrderPlaced(false);
              // element.getElement(CardElement).clear();
            } catch (err) {
              // element.getElement(CardElement).clear();
              toastMessages("error", err.data.message);
              setCartItem([]);
              localStorage.removeItem("userInformation");
              localStorage.removeItem("cartItems");
              setActivePage("menuListing");
              setSubTotal(0);
              setTax(0);
              setForm({
                branchid: branchId,
                orderDate: Date.now(),
                restaurant: restaurant,
                origin: "webApplication",
                orderType: {
                  type: "",
                  name: "",
                  firstName: "",
                  lastName: "",
                  phoneNumber: "+1",
                  noOfGuest: "",
                  date: "",
                  time: "",
                  timeObject: {},
                  dateObject: {},
                  note: "",
                  email: "",
                },
                paymentMethod: {
                  fullName: "",
                  cardNumber: "",
                  expiryDate: "",
                  cvc: "",
                },
                order: {
                  productName: "",
                  productSize: "",
                  productPrice: "",
                  productId: "",
                  modifier: [],
                  note: "",
                  quantity: 1,
                  total: "",
                },
                tax: "",
                subTotal: "",
                discount: "",
                gratuity: "",
                taxPercentage: "",
                finalTotal: "",
                userRoleID: "Online Order",
                table: "",
                status: "",
              });
              setIsOrderPlaced(false);
            }
          }
        }
      }
    }
  }
  // console.log('form---993', form)
  const [paymentType, setPaymentType] = useState('online')
  // console.log("cartItem", cartItem);
  useEffect(() => {
    if (activeProduct !== null)
      document.getElementById(activeProduct).scrollIntoView({
        block: "center",
      });
  }, [activeProduct]);
  // let cartItemLocal = localStorage.getItem("cartItems");
  // if (cartItemLocal) {
  //   cartItemLocal = JSON.parse(cartItemLocal);
  // }
  let props = {
    setCartItem,
    cartItem,
    form,
    setForm,
    setSubTotal,
    setTax,
    subTotal,
    tax,
    currentTax,
    restaurantStartTime,
    selectedDateObject,
    setpreparationTime,
    selectedTimeOption
  };
  const [currentModifierObject, setCurrentModifierObject] = useState([]);
  const [isProductDisplaying, setIsProductDisplaying] = useState(false);
  const [totalProductPrice, setTotalProductPrice] = useState(0);
  const [openCheckboxes, setOpenCheckboxes] = useState({});
  const [initialModifierObject, setInitialModifierObject] = useState([]);
  // console.log('Select Time', form.orderType)
  console.log('currentModifierObject', currentModifierObject)
  useEffect(() => {
    //to display the initial currentModifierObject and total price
    let initalPrice = 0;
    let maxMaxArray = [];
    if (isProductDisplaying && selectedProduct) {
      const selectedModifiers = []; // Initialize an empty object to store the selected modifiers
      let preselectionCheckbox = {};
      let quantityCounter = {}
      initalPrice = parseFloat(selectedProduct.price);
      selectedProduct.modifierset.map((modifierSetItem) => {
        if (modifierList && modifierList.length > 0) {
          let set = modifierList.filter(
            (item) => item.SK == modifierSetItem.value
          );
          set = set[0];
          // console.log("sub", set);
          //for root
          maxMaxArray.push({
            id: set.SK,
            minimum: set.required || 0,
            maximum: set.maximum || 0,
            name: set.modifiername,
          });
          let type = set.modifiertype.toLowerCase();
          set[type] &&
            // console.log('type', type)
            set[type].map((set_item, index) => {
              if (!set_item.price) set_item.price = 0;
              if (set_item.preselect === 1) {
                preselectionCheckbox[set_item.id] = true;
                quantityCounter[set_item.id] = 1
                selectedModifiers.push({
                  name: set_item.name,
                  id: set_item.id,
                  price: set_item.price,
                  parent_id: modifierSetItem.value,
                  added_by: modifierSetItem.label,
                  nestedData: false,
                  quantity: type == "addon" ? 1 : 0
                });
                initalPrice = parseFloat(set_item.price) + initalPrice;
                set_item.modifierSet &&
                  set_item.modifierSet.length > 0 &&
                  set_item.modifierSet.map((sub_modifier_item) => {
                    let sub_set =
                      modifierList &&
                      modifierList.length > 0 &&
                      modifierList.filter(
                        (item) => item.SK == sub_modifier_item.value
                      );
                    sub_set = sub_set[0];
                    // console.log('1096', set_item)
                    maxMaxArray.push({
                      id: sub_set.SK,
                      minimum: sub_set.required || 0,
                      maximum: sub_set.maximum || 0,
                      name: set_item.name,
                    });
                    let type_set = sub_set.modifiertype.toLowerCase();
                    sub_set[type_set] &&
                      sub_set[type_set].map((sub_set_item) => {
                        // console.log('type_set', type_set)
                        if (sub_set_item.preselect) {
                          if (!sub_set_item.price) sub_set_item.price = 0;
                          quantityCounter[sub_set_item.id] = 1
                          preselectionCheckbox[sub_set_item.id] = true;
                          selectedModifiers.push({
                            name: sub_set_item.name,
                            id: sub_set_item.id,
                            price: sub_set_item.price,
                            parent_id: sub_set.SK,
                            added_by: set_item.name,
                            nestedData: true,
                            quantity: type_set == "addon" ? 1 : 0
                          });
                          initalPrice = parseFloat(sub_set_item.price) + initalPrice;
                        }
                      });
                  });
              }
            });
        }
      });
      // console.log("initalPrice---1266", initalPrice);
      setTotalProductPrice(initalPrice);
      setForm((prev) => {
        prev.order = {
          modifier: selectedModifiers,
          note: "",
          categoryId:selectedProduct.categoryId,
          productId: selectedProduct.SK,
          productName: selectedProduct.productname,
          productPrice: selectedProduct.price,
          preparationtime: selectedProduct.preparationtime,
          quantity: 1,
          total: initalPrice,
        };
        return { ...prev };
      });

      // productPrice: ""
      // productSize: ""
      // quantity: 1
      // total: ""
      // console.log("selectedProduct", selectedProduct);
      // console.log("function calling");
      setMinAndRequired(maxMaxArray);
      setCurrentModifierObject(selectedModifiers);
      setOpenCheckboxes(preselectionCheckbox);
      setInitialModifierObject(selectedModifiers);
      setQuantityCountList(quantityCounter)
    }
  }, [isProductSelected, selectedProduct, modifierList]);

  // console.log("1263", initialCheckboxData);
  function clearFieldsFn() {
    setIsProductDisplaying(false);
    setMinAndRequired([]);
    setIsProductSelected(!isProductSelected);
    setActiveProduct(null);
    setAddToCartError([]);
    setModifierList([]);
    setSelectedProduct(null);
    setCurrentModifierObject([]);
    setQuantityCountList({})
  }
  useEffect(() => {
    console.log('currentModifierObject', currentModifierObject)
    if (selectedProduct && selectedProduct.price) {
      //for total
      let data = 0;
      data = parseFloat(selectedProduct.price);
      currentModifierObject.forEach((item) => {
        let quantity
        if (item.quantity) {
          quantity = parseFloat(item.quantity * item.price)
        } else {
          quantity = parseFloat(item.price)
        }
        data = data + quantity;
      });
      // console.log("data", data);
      setTotalProductPrice(data * form.order.quantity);
    }
  }, [currentModifierObject]);
  // console.log("currentModifierObject", currentModifierObject);
  // console.log('selectedTimeOption', selectedTimeOption)
  useEffect(() => {
    clearFieldsFn()
  }, [activePage])
  function autofillHandler() {
    // console.log('hello')
    setContactDataSaved(true)
    setForm((prev) => {
      prev.orderType = {
        ...prev.orderType,
        email: "hello@yopmail.com",
        firstName: "tesitng",
        lastName: "tesing",
        name: "",
        noOfGuest: "",
        note: "",
        phoneNumber: "12121212121",
        sendEmail: 1,
        sendSms: 1,
        type: "Pick Up"
      }
      return { ...prev }
    })
  }
  // console.log('1199', selectedTimeOption)
  console.log('openCheckboxes', openCheckboxes)
  return (
    <React.Fragment>
      <div className="menuOrderWrap">
        <div className="menuOrderInner">
          <div className="menuHeader">
            <div
              className="menuHeader__title"
              style={{ textTransform: "capitalize" }}
            >
              {branchname}
            </div>
            <div className="menuHeader__buttons">
              <Button
                className={`menuButton ${activePage == "menuListing" ? "active" : ""
                  }`}
                type="button"
                onClick={() => {
                  setActivePage("menuListing");
                }}
              >
                {" "}
                <i className="fa-solid fa-utensils"></i>
              </Button>
              <Button
                className={`cartButton ${activePage == "checkout" ? "active" : ""
                  }`}
                type="button"
                onClick={() => {
                  setActivePage("checkout");
                }}
              >
                {" "}
                <span className="notification">
                  {cartItem !== null ? cartItem?.length : 0}
                </span>{" "}
                <i className="fa-solid fa-cart-shopping"></i>
              </Button>
            </div>
          </div>
          {/* Header End */}

          <div
            className={`menuListing ${activePage == "menuListing" ? "show" : "hide"
              }`}
          >
            {isLoading && (
              <div>
                <p>We are syncing the product</p>
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            )}
            {!isLoading && !productCategoryWise && <p>No Product Found.</p>}
            {!isLoading &&
              productCategoryWise &&
              productCategoryWise.length > 0 &&
              productCategoryWise.map((item) => {
                if (item.products && item.products.length > 0) {
                  return (
                    <div className="menuListing__inner">
                      <div className="menuListing__title">
                        <h3>{item.category.name}</h3>
                        <p>{item.category.description}</p>
                      </div>
                      <div
                        className="menuListing__body"
                        onClick={(e) => {
                          if (activeProduct !== null) {
                          }
                        }}
                      >
                        {item.products.map((productItem, index) => {
                          let price = productItem.price;
                          // console.log('productItem--->', productItem)
                          return (
                            <div
                              className={`menuItem ${`${productItem.productname}-${item.category.name}` ==
                                activeProduct
                                ? "activeTab"
                                : activeProduct !== null
                                  ? "inactiveTab"
                                  : ""
                                }`}
                              onClick={() => {
                                if (activeProduct == null) {
                                  setIsProductDisplaying(true);
                                  setIsProductSelected(!isProductSelected);
                                  productItem.categoryId = item.category.id;
                                  setForm((prev) => {
                                    prev.order = [];
                                    return { ...prev };
                                  });
                                  setSelectedProduct(productItem);
                                  setActiveProduct(
                                    `${productItem.productname}-${item.category.name}`
                                  );
                                }
                              }}
                            >
                              <div className="menuItem__inner">
                                <div className="menuItem__img">
                                  <img
                                    src={productItem?.image?.banner ? productItem?.image?.banner : dummyProduct
                                    }
                                    alt=""
                                  />
                                </div>
                                <div className="menuItem__description">
                                  <h4>{titleCase(productItem.productname)}</h4>
                                  <p>
                                    {productItem.description !== "undefined" &&
                                      productItem.description}
                                  </p>
                                  <div className="price">
                                    {price}
                                  </div>
                                </div>
                              </div>
                              {`${productItem.productname}-${item.category.name}` ==
                                activeProduct && (
                                  <div
                                    className={`itemCartInfo ${`${productItem.productname}-${item.category.name}` ==
                                      activeProduct
                                      ? "newAdded"
                                      : ""
                                      }`}
                                    id={`${productItem.productname}-${item.category.name}`}
                                  >
                                    <Button
                                      type="button"
                                      className="closeButton"
                                      onClick={() => {
                                        setIsProductDisplaying(false);
                                        setMinAndRequired([]);
                                        setIsProductSelected(!isProductSelected);
                                        setActiveProduct(null);
                                        setAddToCartError([]);
                                        setModifierList([]);
                                        setSelectedProduct(null);
                                        setCurrentModifierObject([]);
                                        setQuantityCountList({})
                                      }}
                                    >
                                      <i
                                        className="fa-solid fa-xmark"
                                        onClick={() => { }}
                                      ></i>
                                    </Button>
                                    <div className="itemCartInfo__body">
                                      <div className="itemCartInfoImg">
                                        <img
                                          src={
                                            productItem?.image?.thumbnail
                                              ? productItem.image.thumbnail
                                              : dummyProduct
                                          }
                                          alt="dummyProduct"
                                        />
                                      </div>
                                      <div className="itemCartInfoDescription">
                                        <p>
                                          <small>
                                            <b>Price:</b>$ {productItem.price}
                                          </small>
                                        </p>
                                        <p>
                                          {productItem.description !==
                                            "undefined" &&
                                            productItem.description}
                                        </p>
                                      </div>
                                      <div className="itemSelectInfo">
                                        <Form>
                                          <div className="itemSelectInfo__inner">
                                            <h3 className="itemTitle">
                                            </h3>
                                            <div className="commonCheckOptions">
                                              <ChoiceBoxHandle
                                                selectedProduct={selectedProduct}
                                                modifierList={modifierList}
                                                titleCase={titleCase}
                                                setCurrentModifierObject={setCurrentModifierObject}
                                                currentModifierObject={currentModifierObject}
                                                openCheckboxes={openCheckboxes}
                                                setOpenCheckboxes={setOpenCheckboxes}
                                                initialModifierObject={initialModifierObject}
                                                setQuantityCountList={setQuantityCountList}
                                                quantityCountList={quantityCountList}
                                                isProductSelected={isProductSelected}
                                                // setIsProductSelected={setIsProductSelected}
                                                setMinAndRequired={setMinAndRequired}
                                                minAndRequired={minAndRequired}
                                              />
                                            </div>
                                          </div>
                                          <div className="itemSelectInfo__inner">
                                            <h3 className="itemTitle">
                                              Special Instructions
                                            </h3>
                                            <Form.Group className="form-group">
                                              <Form.Control
                                                className="small"
                                                as="textarea"
                                                placeholder="Example: No pepper / sugar / salt please."
                                                value={form.order.note}
                                                onChange={(e) => {
                                                  setForm({
                                                    ...form,
                                                    order: {
                                                      ...form.order,
                                                      note: e.target.value,
                                                    },
                                                  });
                                                }}
                                              />
                                            </Form.Group>
                                          </div>
                                          <div className="itemSelectInfo__inner">
                                            <h3 className="itemTitle">
                                              Quantity
                                            </h3>
                                            <Form.Group className="form-group">
                                              <div className="quantitySelect">
                                                <Form.Control
                                                  type="number"
                                                  value={form.order.quantity}
                                                  onChange={(e) => {
                                                    const alphabetRegex =
                                                      /^[0-9 ]*$/;
                                                    if (
                                                      e.target.value.match(
                                                        alphabetRegex
                                                      )
                                                    ) {
                                                      setIsQuantityUpdated(
                                                        !isQuantityUpdated
                                                      );
                                                      setForm({
                                                        ...form,
                                                        order: {
                                                          ...form.order,
                                                          quantity: parseFloat(
                                                            e.target.value
                                                          ),
                                                        },
                                                      });
                                                    } else {
                                                      setIsQuantityUpdated(
                                                        !isQuantityUpdated
                                                      );
                                                      setForm({
                                                        ...form,
                                                        order: {
                                                          ...form.order,
                                                          quantity: 0,
                                                        },
                                                      });
                                                    }
                                                  }}
                                                />
                                                <Button
                                                  className="quantityButton plus"
                                                  type="button"
                                                  onClick={() => {
                                                    if (form.order.quantity > 1) {
                                                      setIsQuantityUpdated(
                                                        !isQuantityUpdated
                                                      );
                                                      setForm({
                                                        ...form,
                                                        order: {
                                                          ...form.order,
                                                          quantity: parseFloat(
                                                            form.order.quantity -
                                                            1
                                                          ),
                                                        },
                                                      });
                                                    }
                                                  }}
                                                >
                                                  <i className="fa-solid fa-minus"></i>
                                                </Button>
                                                <Button
                                                  className="quantityButton minus"
                                                  type="button"
                                                  onClick={() => {
                                                    setIsQuantityUpdated(
                                                      !isQuantityUpdated
                                                    );
                                                    setForm({
                                                      ...form,
                                                      order: {
                                                        ...form.order,
                                                        quantity: parseFloat(
                                                          form.order.quantity + 1
                                                        ),
                                                      },
                                                    });
                                                  }}
                                                >
                                                  <i className="fa-solid fa-plus"></i>
                                                </Button>
                                              </div>
                                            </Form.Group>
                                          </div>
                                        </Form>
                                      </div>
                                    </div>
                                    <div className="itemCartInfo__footer">
                                      <Button
                                        className="commonButton commonButton--fullWidth"
                                        type="button"
                                        onClick={addToCartHandler}
                                      >
                                        <span className="price">
                                          ${`${totalProductPrice.toFixed(2)}`}
                                        </span>
                                        <span className="text">Add to cart</span>
                                      </Button>
                                      {addtoCartError &&
                                        addtoCartError.map((item) => (
                                          <small
                                            style={{
                                              fontSize: "0.225em;",
                                              color: "red",
                                            }}
                                          >
                                            {item}
                                          </small>
                                        ))}
                                    </div>
                                  </div>
                                )}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                }
              })}
          </div>
          {/* Order Menu Listing End */}

          <div
            className={`checkout ${activePage == "checkout" ? "show" : "hide"}`}
          >
            <div className="checkout__inner">
              <Row>
                <Col md={6}>
                  <div className="reviewList">
                    <Accordion>
                      <Accordion.Item
                        eventKey={`${showIcon.contact.show == true ? "0" : "1"
                          }`}
                      >
                        <Accordion.Header>
                          <i className="fa-solid fa-user"></i>{" "}
                          <span>Contact</span>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="cartDetails">
                            <div className="formButtons"></div>
                            <Form>
                              <Row className="g-2">
                                <Col md={6}>
                                  <Form.Group className="form-group">
                                    <Form.Label>
                                      First Name{orderFromQrCode ? '' : <sup>*</sup>}
                                    </Form.Label>
                                    <Form.Control
                                      className="formField"
                                      type="text"
                                      value={form?.orderType?.firstName}
                                      onChange={(e) => {
                                        if (
                                          e.target.value.match(alphabetRegex)
                                        ) {
                                          setForm((prev) => {
                                            prev.orderType.firstName =
                                              e.target.value;
                                            return { ...prev };
                                          });
                                        }
                                      }}
                                    />
                                  </Form.Group>
                                </Col>
                                <Col md={6}>
                                  <Form.Group className="form-group">
                                    <Form.Label>
                                      Last Name{orderFromQrCode ? '' : <sup>*</sup>}
                                    </Form.Label>
                                    <Form.Control
                                      className="formField"
                                      type="text"
                                      value={form.orderType.lastName}
                                      onChange={(e) => {
                                        if (
                                          e.target.value.match(alphabetRegex)
                                        ) {
                                          setForm((prev) => {
                                            prev.orderType.lastName =
                                              e.target.value;
                                            return { ...prev };
                                          });
                                        }
                                      }}
                                    />
                                  </Form.Group>
                                </Col>
                                <Col lg={12}>
                                  <Form.Group className="form-group">
                                    <Form.Label>
                                      Phone Number{orderFromQrCode ? '' : <sup>*</sup>}
                                    </Form.Label>
                                    <PhoneInput
                                      country="us"
                                      value={form.orderType.phoneNumber}
                                      onChange={handleOnChange}
                                      enableSearch={true}
                                      inputStyle={{ width: "100%" }}
                                    />
                                    <div style={{ display: "flex" }}>
                                      <div className="">
                                        <Form.Group className="form-group formCheckbox">
                                          <Form.Check
                                            checked={
                                              form.orderType.sendSms == 1
                                                ? true
                                                : false
                                            }
                                            className=" label"
                                            key={"index"}
                                            type="checkbox"
                                            value={form.orderType.sendSms}
                                            id={"index_sendEmail"}
                                            label={`Prefer to communicate`}
                                            onClick={(e) => {
                                              setForm((prev) => {
                                                prev.orderType.sendSms =
                                                  e.target.checked == true
                                                    ? 1
                                                    : 0;
                                                return { ...prev };
                                              });
                                            }}
                                          />
                                        </Form.Group>
                                      </div>
                                    </div>
                                    {isPhoneNumberValid === false &&
                                      form.orderType.phoneNumber && (
                                        <small
                                          style={{
                                            color: "red",
                                            marginTop: "4px",
                                          }}
                                        >
                                          {phoneValidatError}
                                        </small>
                                      )}
                                  </Form.Group>
                                </Col>
                                <Col md={12}>
                                  <Form.Group className="form-group">
                                    <Form.Label>
                                      Email{orderFromQrCode ? '' : <sup>*</sup>}
                                    </Form.Label>
                                    <Form.Control
                                      className="formField notCapital"
                                      type="email"
                                      value={form.orderType.email}
                                      onChange={emailValidation}
                                    />
                                    <div style={{ display: "flex" }}>
                                      <div className="">
                                        <Form.Group className="form-group formCheckbox">
                                          <Form.Check
                                            checked={
                                              form.orderType.sendEmail == 1
                                                ? true
                                                : false
                                            }
                                            className=" label"
                                            key={"index"}
                                            type="checkbox"
                                            // defaultChecked={true}
                                            value={form.orderType.sendEmail}
                                            id={"index_sendEmail"}
                                            label={`Prefer to communicate`}
                                            onClick={(e) => {
                                              setForm((prev) => {
                                                prev.orderType.sendEmail =
                                                  e.target.checked == true
                                                    ? 1
                                                    : 0;
                                                return { ...prev };
                                              });
                                            }}
                                          />
                                        </Form.Group>
                                      </div>
                                    </div>
                                    {error &&
                                      form.orderType.email !== "" &&
                                      error !== "email is valid" && (
                                        <small
                                          style={{
                                            color: "red",
                                          }}
                                        >
                                          {error}
                                        </small>
                                      )}
                                  </Form.Group>
                                </Col>
                                <Col md={12}>
                                  <Button
                                    type="button"
                                    className="commonButton commonButton--fullWidth"
                                    disabled={disableContactButton}
                                    onClick={saveContact}
                                  >
                                    Save
                                  </Button>
                                </Col>
                              </Row>
                            </Form>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item
                        eventKey="10"
                        onClick={() => {
                          setShowIcon({
                            ...showIcon,
                            contact: { show: false },
                            paymentMethod: { show: false },
                          });
                        }}
                      >
                        <Accordion.Header>
                          <i className="fa-solid fa-hand-pointer"></i>{" "}
                          <span>Ordering Method</span>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="cartDetails">
                            <div className="formButtons"></div>
                            <div className="commonCheckOptions">
                              <Form>
                                {orderFromQrCode ?
                                  ["Dine In"].map(
                                    (radioItem, index) => {
                                      return (
                                        <Form.Group className="form-group formCheckbox">
                                          {["radio"].map((type) => (
                                            <Form.Check
                                              checked={true}
                                              onClick={() => { }}
                                              type={type}
                                              id={`${type}-${radioItem}`}
                                              label={radioItem}
                                              name={radioItem}
                                            />
                                          ))}
                                        </Form.Group>
                                      );
                                    }
                                  )
                                  : ["Pick Up", "Delivery"].map(
                                    (radioItem, index) => {
                                      return (
                                        <Form.Group className="form-group formCheckbox">
                                          {["radio"].map((type) => (
                                            <Form.Check
                                              checked={
                                                form.orderType.type == radioItem
                                                  ? true
                                                  : false
                                              } //eslint-disable-line
                                              onClick={() => {
                                                setForm((prev) => {
                                                  prev.orderType.type = radioItem;
                                                  return { ...prev };
                                                });
                                              }}
                                              type={type}
                                              id={`${type}-${radioItem}`}
                                              label={radioItem}
                                              name={radioItem}
                                            />
                                          ))}
                                          {form.orderType.type == "Delivery" &&
                                            index == 1 && (
                                              <div className="deliveryAddress">
                                                <Form.Group className="form-group">
                                                  <Form.Label>
                                                    Add an address
                                                  </Form.Label>
                                                  <Form.Control
                                                    className="formField"
                                                    type="text"
                                                    placeholder="Street name & Number*"
                                                    value={
                                                      form.orderType.streetName
                                                    }
                                                    onChange={(e) => {
                                                      setForm((prev) => {
                                                        prev.orderType.streetName =
                                                          e.target.value;
                                                        return { ...prev };
                                                      });
                                                    }}
                                                  />
                                                  <Form.Control
                                                    className="formField"
                                                    type="text"
                                                    placeholder="Town or City Area*"
                                                    value={form.orderType.town}
                                                    onChange={(e) => {
                                                      setForm((prev) => {
                                                        prev.orderType.town =
                                                          e.target.value;
                                                        return { ...prev };
                                                      });
                                                    }}
                                                  />
                                                  <Form.Control
                                                    className="formField"
                                                    type="text"
                                                    placeholder="Postcode, Block, Intercom, Where to Park*"
                                                    value={
                                                      form.orderType.postCode
                                                    }
                                                    onChange={(e) => {
                                                      setForm((prev) => {
                                                        prev.orderType.postCode =
                                                          e.target.value;
                                                        return { ...prev };
                                                      });
                                                    }}
                                                  />
                                                </Form.Group>
                                              </div>
                                            )}
                                        </Form.Group>
                                      );
                                    }
                                  )}
                              </Form>
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                      {orderFromQrCode ? '' :
                        <Accordion.Item eventKey="2">
                          <Accordion.Header>
                            <i className="fa-solid fa-clock"></i>{" "}
                            <span>Available Time Choice</span>
                          </Accordion.Header>
                          <Accordion.Body>
                            <div className="cartDetails">
                              <Form>
                                <Row className="g-2">
                                  <Col md={12}>
                                    <Form.Group className="form-group">
                                      <Form.Label>
                                        Select Date<sup>*</sup>
                                      </Form.Label>
                                      {/* {console.log('selectedTimeOption-1781', selectedTimeOption)} */}
                                      <Select
                                        className="select2 singleSelect"
                                        placeholder="Today"
                                        value={form.orderType.dateObject}
                                        onChange={(e) => {
                                          setTimeUpdated(!timeUpdated);
                                          setForm((prev) => {
                                            // console.log(' e.value', e.value.getTime())
                                            prev.orderType.date = e.value.getTime();
                                            prev.orderType.dateObject = e;
                                            if (e.label == "ASAP") {
                                              prev.orderType.time = selectedTimeOption[0].value
                                              prev.orderType.timeObject = selectedTimeOption[0]
                                            }
                                            return { ...prev };
                                          });
                                          setSelectedDateObject(e);
                                        }}
                                        // menuIsOpen={true}
                                        options={selectDateOption}
                                        labelledBy="Select"
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col md={12}>
                                    {selectedTimeOption.length == 0 ? "" :
                                      <Form.Group className="form-group">
                                        <Form.Label>
                                          Select Time<sup>*</sup>
                                        </Form.Label>
                                        <Select
                                          isDisabled={selectedDateObject.label == "ASAP" ? true : false}
                                          className="select2 singleSelect"
                                          placeholder={"Select Date"}
                                          value={selectedDateObject.label == "ASAP" ? selectedTimeOption[0] : form.orderType.timeObject}
                                          onChange={(e) => {
                                            setForm((prev) => {
                                              prev.orderType.time = e.value;
                                              prev.orderType.timeObject = e;
                                              // console.log('e--2390', e)
                                              prev.orderType.date = getUtcDate(e.value, prev.orderType.date)
                                              // console.log(' prev.orderType.date ', getUtcDate(e.value, prev.orderType.date))
                                              return { ...prev };
                                            });
                                          }}
                                          options={selectedTimeOption}
                                          labelledBy="Select"
                                        />
                                      </Form.Group>}
                                  </Col>
                                </Row>
                              </Form>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      }
                      <Accordion.Item
                        eventKey={`${showIcon.paymentMethod.show == true ? "0" : "3"
                          }`}
                      >
                        <Accordion.Header>
                          <i className="fa-solid fa-credit-card"></i>{" "}
                          <span>Payment Method</span>
                        </Accordion.Header>
                        <Accordion.Body>
                          {
                            orderFromQrCode ?
                              <>
                                {
                                  <>
                                    <div style={{ display: "flex", marginTop: '10px' }}>
                                      <div className="">
                                        <Form.Group className="form-group formCheckbox">
                                          <Form.Check
                                            checked={paymentType == "online" ? true : false}
                                            className=" label"
                                            key={"index"}
                                            type="checkbox"
                                            // value={form.orderType.sendSms}
                                            id={"index_payNow"}
                                            label={`Pay Now`}
                                            onClick={(e) => {
                                              if (e.target.checked) {
                                                setPaymentType('online')
                                              }
                                            }}
                                          />
                                        </Form.Group>
                                      </div>
                                    </div>
                                    {
                                      paymentType == "online" &&
                                      <div className="cartDetails">
                                        <Form>
                                          <Row className="g-2">
                                            <Col md={12}>
                                              <Form.Group className="form-group">
                                                <Form.Label>
                                                  Full Name{orderFromQrCode ? "" : <sup>*</sup>}
                                                </Form.Label>
                                                <Form.Control
                                                  className="formField"
                                                  type="text"
                                                  value={form.paymentMethod.fullName}
                                                  onChange={(e) => {
                                                    if (
                                                      e.target.value.match(alphabetRegex)
                                                    ) {
                                                      setForm((prev) => {
                                                        prev.paymentMethod.fullName =
                                                          e.target.value;
                                                        return { ...prev };
                                                      });
                                                    }
                                                  }}
                                                />
                                              </Form.Group>
                                            </Col>
                                            <Col md={12}>
                                              <Form.Group className="form-group">
                                                <Form.Label>
                                                  Card Number{orderFromQrCode ? "" : <sup>*</sup>}
                                                </Form.Label>
                                                <Elements stripe={stripeObject}>
                                                  <ElementsConsumer>
                                                    {({ stripe, elements }) => (

                                                      <>
                                                        {
                                                          (setStripe(stripe),
                                                            setElement(elements))
                                                        }
                                                        <CardSection />
                                                      </>
                                                    )}
                                                  </ElementsConsumer>
                                                </Elements>
                                              </Form.Group>
                                            </Col>
                                          </Row>
                                        </Form>
                                      </div>
                                    }
                                    <div style={{ display: "flex", marginTop: '10px' }}>
                                      <div className="">
                                        <Form.Group className="form-group formCheckbox">
                                          <Form.Check
                                            checked={paymentType == "later" ? true : false}
                                            className=" label"
                                            key={"index"}
                                            type="checkbox"
                                            // value={form.orderType.sendSms}
                                            id={"index_payLater"}
                                            label={`Pay Later`}
                                            onClick={(e) => {
                                              if (e.target.checked) {
                                                setPaymentType('later')
                                              }
                                            }}
                                          />
                                        </Form.Group>
                                      </div>
                                    </div>
                                  </>
                                }
                              </>
                              :
                              <div className="cartDetails">
                                <Form>
                                  <Row className="g-2">
                                    <Col md={12}>
                                      <Form.Group className="form-group">
                                        <Form.Label>
                                          Full Name{orderFromQrCode ? "" : <sup>*</sup>}
                                        </Form.Label>
                                        <Form.Control
                                          className="formField"
                                          type="text"
                                          value={form.paymentMethod.fullName}
                                          onChange={(e) => {
                                            if (
                                              e.target.value.match(alphabetRegex)
                                            ) {
                                              setForm((prev) => {
                                                prev.paymentMethod.fullName =
                                                  e.target.value;
                                                return { ...prev };
                                              });
                                            }
                                          }}
                                        />
                                      </Form.Group>
                                    </Col>
                                    <Col md={12}>
                                      <Form.Group className="form-group">
                                        <Form.Label>
                                          Card Number{orderFromQrCode ? "" : <sup>*</sup>}
                                        </Form.Label>
                                        <Elements stripe={stripeObject}>
                                          <ElementsConsumer>
                                            {({ stripe, elements }) => (
                                              <>
                                                {
                                                  (setStripe(stripe),
                                                    setElement(elements))
                                                }
                                                <CardSection />
                                              </>
                                            )}
                                          </ElementsConsumer>
                                        </Elements>
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </Form>
                              </div>
                          }


                        </Accordion.Body>
                      </Accordion.Item>

                      {showCommentBox && (
                        <Accordion.Item eventKey="5">
                          <Accordion.Header>
                            <i className="fa-solid fa-comment-dots"></i>{" "}
                            <span>
                              Comments <small>(Optional)</small>
                            </span>
                          </Accordion.Header>
                          <Accordion.Body>
                            <div className="cartDetails">
                              <div className="formButtons">
                                <Button
                                  className="cancelDeatilsBtn"
                                  onClick={() => setShowCommentBox(false)}
                                >
                                  <i className="fa-solid fa-xmark"></i>
                                </Button>
                              </div>
                              <Form.Group className="form-group">
                                <Form.Label>Example: Cutlery</Form.Label>
                                <Form.Control
                                  className="formField small"
                                  as="textarea"
                                  value={form.orderType.note}
                                  onChange={(e) => {
                                    setForm({
                                      ...form,
                                      orderType: {
                                        ...form.orderType,
                                        note: e.target.value,
                                      },
                                    });
                                  }}
                                />
                              </Form.Group>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      )}
                    </Accordion>
                    {!showCommentBox && (
                      <div className="addCommentsText">
                        <Button
                          className="commentButton"
                          type="button"
                          onClick={() => setShowCommentBox(true)}
                        >
                          Comments <span>(Optional)</span>
                        </Button>
                      </div>
                    )}
                    {
                      // process.env.
                      process.env.REACT_APP_ENVIRONMENT !== "production" &&
                      <button onClick={autofillHandler}>Autofill contact</button>
                    }
                  </div>
                </Col>
                <Col md={6}>
                  <CartTable {...props} />
                </Col>
              </Row>
            </div>
            <div className="checkout__footer">
              <div className="footerInner">
                <Row>
                  <Col md={6} className="footerInner__grid">
                    <div className="checkoutLicenseAgreement">
                      <h6>By placing this order you accept the:</h6>
                      <ul>
                        <li>
                          <a target="_blank" href="javascript:void(0);">
                            Data Processing Policy
                          </a>
                        </li>
                        <li>
                          <a target="_blank" href="javascript:void(0);">
                            End User License Agreement
                          </a>
                        </li>
                        <li>
                          <a target="_blank" href="javascript:void(0);">
                            Restaurant Terms
                          </a>
                        </li>
                        <li>
                          <a target="_blank" href="javascript:void(0);">
                            Privacy Policy
                          </a>
                        </li>
                      </ul>
                    </div>
                  </Col>
                  <Col
                    md={6}
                    className="footerInner__grid footerInner__grid--orderButton"
                  >
                    <Button
                      className="commonButton commonButton--fullWidth"
                      type="button"
                      onClick={onOrderHandler}
                    >
                      <span className="price">
                        <span>Total</span> ${(subTotal + tax).toFixed(2)}
                      </span>
                      <span className="text">Place Your Order Now</span>
                      {isOrderPlaced && (
                        <Spinner
                          as="span"
                          className="delete"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
          {/* Checkout End */}
        </div>
      </div>
    </React.Fragment>
  );
}
export default MenuOrder;
const CartTable = (props) => {
  const {
    cartItem,
    setCartItem,
    form,
    setForm,
    setSubTotal,
    setTax,
    subTotal,
    tax,
    currentTax,
    selectedDateObject,
    restaurantStartTime,
    setpreparationTime,
    selectedTimeOption
  } = props;
  function titleCase(str) {
    var splitStr = str.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  }
  function calculateTotal(cartItems) {
    let total = 0;
    cartItems.forEach((cart) => {
      let itemTotal = 0;
      if (typeof cart.form.order.productPrice == "string") {
        itemTotal = parseFloat(cart.form.order.productPrice.replace("$", ""));
      } else {
        itemTotal = parseFloat(cart.form.order.productPrice);
      }

      cart.form.order.modifier.forEach((modifierItem) => {
        if (typeof modifierItem.price == "string") {
          if (modifierItem.quantity) {
            itemTotal += parseFloat(modifierItem.price.replace("$", "") * modifierItem.quantity);
          } else {
            itemTotal += parseFloat(modifierItem.price.replace("$", ""));
          }

        } else {
          if (modifierItem.quantity) {
            itemTotal += parseFloat(modifierItem.price * modifierItem.quantity);
          }
          else {
            itemTotal += parseFloat(modifierItem.price);
          }
        }
      });
      total += itemTotal * parseFloat(cart.form.order.quantity);
    });

    return total;
  }
  function taxCalcuation(totalAmount) {
    let total = (currentTax / 100) * totalAmount;
    return total;
  }
  useEffect(() => {
    if (cartItem) {
      const total = calculateTotal(cartItem);
      // console.log("total", total);
      setSubTotal(total);
      setTax(taxCalcuation(total));
    }
  }, [cartItem, currentTax, form]);
  return (
    <div className="cartTable">
      <div className="commonTable">
        <div className="fixTableHead">
          <Table>
            <thead>
              <tr>
                <th>Qty</th>
                <th>Item</th>
                <th>Price</th>
                <th></th>
              </tr>
            </thead>

            <tbody>
              {cartItem &&
                cartItem.length > 0 &&
                cartItem.map((item, idx) => {
                  let {
                    form: { order: orderDetails },
                  } = item;
                  let total = 0;
                  if (typeof orderDetails.product == "string") {
                    total = parseFloat(
                      orderDetails.productPrice.replace("$", "")
                    );
                  } else {
                    total = parseFloat(orderDetails.productPrice);
                  }
                  if (orderDetails.modifier) {
                    orderDetails.modifier.forEach((cart) => {
                      if (typeof cart.price == "string") {
                        if (cart.quantity) {
                          total += parseFloat(cart.price.replace("$", "") * cart.quantity);
                        }
                        else {
                          total += parseFloat(cart.price.replace("$", ""));
                        }
                      } else {
                        if (cart.quantity) {
                          total += parseFloat(cart.price * cart.quantity);
                        }
                        else {
                          total += parseFloat(cart.price);
                        }
                      }
                    });
                    total = total * parseFloat(orderDetails.quantity);
                  }
                  return (
                    <tr>
                      <td>{orderDetails.quantity} x</td>
                      <td>
                        <div className="itemCartDetails">
                          <h5>
                            {titleCase(orderDetails.productName)}
                            {"  "}
                            <small>(${orderDetails?.productPrice})</small>
                          </h5>
                          {orderDetails.modifier.map((item, index) => {
                            // console.log("item", item);
                            return (
                              <div className="cartItemOption">
                                <span className="cartItemOptionText">
                                  <div>
                                    <span>{titleCase(item.name)}</span>
                                  </div>
                                  {/* <small
                                  style={{
                                    color: "grey",
                                    marginRight: "5px",
                                  }}
                                >
                                  {titleCase(choiceItem.name)}
                                </small> */}
                                  {/* <small
                                  style={{ color: "grey" }}
                                  className="cartItemOptionPrice"
                                >
                                  +$
                                  {parseFloat(
                                    choiceItem.price.replace("$", "") *
                                    orderDetails.quantity
                                  ).toFixed(2)}
                                </small> */}
                                </span>
                              </div>
                            );
                          })}
                          <small>Note: {orderDetails.note}</small>
                        </div>
                      </td>
                      <td>${total.toFixed(2)}</td>
                      <td>
                        <Button className="commonTableBtn deleteOrder">
                          <i
                            className="fa-solid fa-circle-xmark"
                            onClick={() => {
                              let updatedCart;
                              if (cartItem) {
                                // setpreparationTime(0)
                                updatedCart = cartItem.filter(
                                  (item, index) => index !== idx
                                );
                                if (updatedCart.length) {

                                }
                                let highestPrepartion = Math.max(...updatedCart.map(o => parseFloat(o.form.order.preparationtime)))
                                // console.log('highestPreparation', highestPrepartion, updatedCart)
                                setpreparationTime(highestPrepartion)
                              }
                              localStorage.setItem("cartItems", JSON.stringify(updatedCart))
                              if (!updatedCart.length) {
                                setpreparationTime(0)
                              }
                              setCartItem(updatedCart)
                            }}
                          ></i>
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              {(cartItem == null || cartItem.length == 0) && (
                <tr>
                  <td colSpan={4}>
                    <div>Cart is Empty</div>
                  </td>
                </tr>
              )}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={3}>Subtotal:</td>
                <td>
                  <span className="subtotal">${subTotal.toFixed(2)}</span>
                </td>
              </tr>
              <tr>
                <td colSpan={3}>Sales Tax ({currentTax}%):</td>
                <td>
                  <span className="salesTax">${tax ? tax.toFixed(2) : 0}</span>
                </td>
              </tr>
              <tr>
                <td colSpan={3}>Order Total:</td>
                <td>
                  <span className="orderTotal">
                    ${(subTotal + tax).toFixed(2)}
                  </span>
                </td>
              </tr>

              {/* {restaurantStartTime > "12:45:00" && */}
              {(selectedDateObject.label == "Today" || selectedDateObject.label == "ASAP") && selectedTimeOption.length == 0 &&
                (<tr>
                  <td className="noteSection" colSpan={4}>
                    <p>
                      <b>Please Note:</b>Your order will be ready on next
                      business day
                    </p>
                  </td>
                </tr>
                )}
            </tfoot>
          </Table>
        </div>
      </div>
    </div>
  );
};

const ChoiceBoxHandle = ({
  modifierList,
  selectedProduct,
  titleCase,
  setCurrentModifierObject,
  currentModifierObject,
  openCheckboxes,
  setOpenCheckboxes,
  initialModifierObject,
  setQuantityCountList,
  quantityCountList,
  minAndRequired,
  setMinAndRequired
  // setIsProductSelected,
  // isProductSelected
}) => {
  // console.log("modifierList", modifierList)
  // let defaultCurrentModiferObject =JSON.parse(JSON.stringify())
  // console.log("initialModifierObject", initialModifierObject);
  const onCheckBoxHandler = (data_set, modifierType) => {
    // setIsProductSelected(!isProductSelected);
    // console.log("data_set", data_set);
    // console.log("modifiertype", modifierType)
    let isModifierCount = currentModifierObject.filter(
      (item) => item.added_by == data_set.added_by
    );
    //for remove the data
    if (!data_set.preselect) {
      setMinAndRequired((prev) => prev.filter((item) => item.name !== data_set.name))
      //single or nestesd single
      let isExist = currentModifierObject.filter((item) => {
        return data_set.id != item.id && data_set.id != item.parent_id;
      });
      // console.log("isExist--2674", isExist, data_set);
      //check nested parent id
      if (data_set.modifierSet && data_set.modifierSet.length > 0) {
        data_set.modifierSet.map((row_item) => {
          let test = isExist.filter((row_filter) => {
            return (
              row_item.id !== row_filter.parent_id &&
              row_filter.added_by != data_set.name
            );
          });
          setCurrentModifierObject(test);
        });
      } else {
        setCurrentModifierObject(isExist);
      }
      const updatedQuantityCountList = {
        ...quantityCountList,
        [data_set.id]: 1,
      };
      setQuantityCountList(updatedQuantityCountList);
      return { status: false, id: data_set.id };
    }
    //for Adding the data 
    else {
      //////////////_____/////////
      // let name = {
      //   id: data_set.id,
      //   minimum: data_set.modifierstatus.forceminimum,
      //   maximum: data_set.modifierstatus.forcemaximum,
      //   name: data_set.name
      // }
      // setMinAndRequired((prev) => [...prev, name])
      /////////////______////////
      let requiredCount = parseInt(data_set.modifierstatus.forcemaximum) || parseInt(data_set.required)
      if (isModifierCount.length == requiredCount && requiredCount != 0) {
        // console.log("in else case false", data_set.id);
        data_set.preselect = 0;
        return {
          status: false,
          message: "Maximum Already Selected",
          id: data_set.id,
        };
      }
      // console.log('2634')
      let new_arr = [];
      new_arr.push({
        price: data_set.price ? data_set.price : 0,
        name: data_set.name,
        parent_id: data_set.parent_id,
        added_by: data_set.added_by,
        id: data_set.id,
        quantity: modifierType == "addon" ? 1 : 0
      });
      if (data_set.depth == 1) {
        if (
          data_set &&
          data_set.modifierSet &&
          data_set.modifierSet.length > 0
        ) {
          data_set.modifierSet.forEach((dataItem) => {
            let isExist = modifierList.filter(
              (item) => item.SK == dataItem.value
            );
            isExist = isExist[0];
            let dataSetType = isExist.modifiertype.toLowerCase();
            isExist[dataSetType].forEach((subDataSetItem) => {
              if (subDataSetItem.preselect) {
                new_arr.push({
                  price: subDataSetItem.price ? subDataSetItem.price : 0,
                  name: subDataSetItem.name,
                  parent_id: isExist.SK,
                  added_by: data_set.name,
                  id: subDataSetItem.id,
                  quantity: modifierType == "addon" ? 1 : 0
                });
              }
            });
          });
        }
      }
      // console.log("new_arr", new_arr);
      setCurrentModifierObject((prev) => {
        return [...prev, ...new_arr];
      });
      const updatedQuantityCountList = {
        ...quantityCountList,
        [data_set.id]: 1,
      };
      setQuantityCountList(updatedQuantityCountList);
      return { status: true };
    }
  };
  const toggleCheckbox = (id, boolean_values) => {
    setOpenCheckboxes({
      ...openCheckboxes,
      [id]: boolean_values,
    });
  };
  function handleAddonCheckBox(e, data, option) {
    e.preventDefault()
    let tempCurrent = currentModifierObject.map((item) => {
      if (item.id == data.id) {
        if (option == "plus") {
          if (!item.quantity) item.quantity = 0
          item.quantity = item.quantity + 1
          const updatedQuantityCountList = {
            ...quantityCountList,
            [data.id]: (quantityCountList[data.id] || 0) + 1,
          };
          setQuantityCountList(updatedQuantityCountList);
        }
        else {
          if (!item.quantity) item.quantity = 0
          item.quantity = item.quantity - 1
          const updatedQuantityCountList = {
            ...quantityCountList,
            [data.id]: (quantityCountList[data.id] || 0) - 1,
          };
          setQuantityCountList(updatedQuantityCountList);
        }
        return item
      }
      return item
    })
    // console.log('tempCurrent', tempCurrent)
    setCurrentModifierObject(tempCurrent)
  }

  return selectedProduct.modifierset.map((item) => {
    if (modifierList && modifierList.length > 0) {
      const matchedModifier = modifierList.find(
        (modifier) => modifier.SK == item.value
      );
      let modifiertype = matchedModifier && matchedModifier.modifiertype.toLowerCase();
      if (matchedModifier) {
        let forceminimum;
        let forcemaximum;
        let show;
        if (matchedModifier.required !== "0") {
          forceminimum = matchedModifier.required;
        }
        if (matchedModifier.maximum !== "0") {
          forcemaximum = matchedModifier.maximum;
        }
        if (forceminimum) {
          show = `(${forceminimum} required)`;
        }
        if (forcemaximum) {
          show = `(${forcemaximum} allowed)`;
        }
        if (forceminimum && forcemaximum) {
          show = `(${forceminimum} required & ${forcemaximum} allowed)`;
        }
        return (
          <div key={item.value}>
            <Form.Group className="form-group formCheckbox">
              <Form.Label>{titleCase(item.label)}</Form.Label>
              <small>{show}</small>
              {matchedModifier[modifiertype].map((matchModifierItem) => {
                let current_count = 0;
                let matchModifier_type =
                  matchedModifier.modifiertype.toLowerCase();
                matchedModifier[matchModifier_type].forEach((item) => {
                  if (item.preselect == true && item.preselect == 1) {
                    current_count = current_count + 1;
                  }
                });
                if (!matchModifierItem.price) {
                  matchModifierItem.price = 0;
                }
                return (
                  <div>
                    <Form.Check
                      type="checkbox"
                      defaultChecked={matchModifierItem.preselect === 1}
                      id={matchModifierItem.id}
                      label={
                        <div style={{
                          display: 'flex',
                          justifyContent: 'space-between'
                        }}>
                          <div>
                            {matchModifierItem.name}{" "}
                            <span style={{ fontSize: "13px" }}>
                              ( $
                              {typeof matchModifierItem.price == "string"
                                ? parseFloat(matchModifierItem.price).toFixed(2)
                                : matchModifierItem.price.toFixed(2)}
                              )
                            </span>
                          </div>
                          <div>
                            {/* {c('matchModifierItem', matchModifierItem)} */}
                            {(modifiertype == "addon" && matchModifierItem.allowmultiple) ?
                              <div className="quantity-input">
                                <button
                                  disabled={quantityCountList[matchModifierItem.id] == 1 ? true : false}
                                  type="button"
                                  className="quantity-input__modifier quantity-input__modifier--left"
                                  onClick={(e) => handleAddonCheckBox(e, matchModifierItem, 'minus')}
                                >
                                  <i class="fa-solid fa-minus"></i>
                                </button>
                                <input
                                  disabled={true}
                                  className={`quantity-input__screen`}
                                  type="text"
                                  value={quantityCountList[matchModifierItem.id]}
                                />
                                <button
                                  // disabled={!item.active || (item.defaultQuantity == item.itemQuantity)}
                                  type="button"
                                  className="quantity-input__modifier quantity-input__modifier--right"
                                  onClick={(e) => handleAddonCheckBox(e, matchModifierItem, 'plus')}
                                >
                                  <i class="fa-solid fa-plus"></i>
                                </button>
                              </div> : null
                            }
                          </div>
                        </div>
                      }
                      checked={openCheckboxes[matchModifierItem.id] || false}
                      onClick={(e) => {
                        matchModifierItem.parent_id = item.value;
                        matchModifierItem.added_by = item.label;
                        matchModifierItem.depth = 1;
                        matchModifierItem.modifierstatus = {
                          forceminimum, forcemaximum,
                        };
                        matchModifierItem.preselect = !matchModifierItem.preselect;
                        matchModifierItem.nestedData = true
                        let isStatus = onCheckBoxHandler(matchModifierItem, modifiertype);
                        if (!isStatus.status) {
                          if (isStatus.message) {
                            toastMessages("error", isStatus.message);
                          }
                          // console.log('2746')
                          toggleCheckbox(e.target.id, false);
                        } else {
                          // console.log('matchModifierItem', matchModifierItem, modifiertype)
                          if (modifiertype !== "addon") {
                            if (modifiertype == "choices" && matchModifierItem.modifierSet.length > 0) {
                              let data
                              matchModifierItem.modifierSet.map((item) => {
                                data = modifierList.find((sub) => sub.SK == item.value);
                              })
                              // console.log('2757', data)
                              let name = {
                                id: data.SK,
                                minimum: data.required,
                                maximum: data.maximum,
                                name: matchModifierItem.name
                              }
                              setMinAndRequired((prev) => [...prev, name])
                            }
                          }
                          toggleCheckbox(e.target.id, true);
                        }
                      }}
                    />
                    {openCheckboxes[matchModifierItem.id] && (
                      <NestedCheckbox
                        nested_data={matchModifierItem}
                        modifierList={modifierList}
                        onCheckBoxHandler={onCheckBoxHandler}
                        toggleCheckbox={toggleCheckbox}
                        openCheckboxes={openCheckboxes}
                        currentModifierObject={currentModifierObject}
                        setCurrentModifierObject={setCurrentModifierObject}
                        setQuantityCountList={setQuantityCountList}
                        quantityCountList={quantityCountList}
                      />
                    )}
                  </div>
                );
              })}
            </Form.Group>
          </div>
        );
      }
    }
  });
};
const NestedCheckbox = ({
  setCurrentModifierObject,
  nested_data,
  modifierList,
  onCheckBoxHandler,
  toggleCheckbox,
  openCheckboxes,
  currentModifierObject,
  setQuantityCountList,
  quantityCountList
}) => {
  function handleAddonCheckBox(e, data, option) {
    e.preventDefault()
    let tempCurrent = currentModifierObject.map((item) => {
      if (item.id == data.id) {
        if (option == "plus") {
          if (!item.quantity) item.quantity = 0
          item.quantity = item.quantity + 1
          const updatedQuantityCountList = {
            ...quantityCountList,
            [data.id]: (quantityCountList[data.id] || 0) + 1,
          };
          setQuantityCountList(updatedQuantityCountList);
        }
        else {
          if (!item.quantity) item.quantity = 0
          item.quantity = item.quantity - 1
          const updatedQuantityCountList = {
            ...quantityCountList,
            [data.id]: (quantityCountList[data.id] || 0) - 1,
          };
          setQuantityCountList(updatedQuantityCountList);
        }
        return item
      }
      return item
    })
    // console.log('tempCurrent', tempCurrent)
    setCurrentModifierObject(tempCurrent)
  }
  // console.log('quantityCountList', quantityCountList)
  // console.log("nested_data_2612", nested_data)
  return (
    <div style={{
      marginLeft: "30px",
    }}>
      {nested_data &&
        nested_data.modifierSet &&
        nested_data.modifierSet.length > 0 &&
        nested_data.modifierSet.map((item) => {
          const sub_modifier = modifierList.find((sub) => sub.SK == item.value);
          let sub_modifier_type = sub_modifier.modifiertype.toLowerCase();
          if (sub_modifier) {
            let forceminimum;
            let forcemaximum;
            let show;
            // console.log('nested_data', nested_data)
            if (sub_modifier.required !== "0") {
              forceminimum = sub_modifier.required;
            }
            if (sub_modifier.maximum !== "0") {
              forcemaximum = sub_modifier.maximum;
            }
            if (forceminimum) {
              show = `(${forceminimum} required)`;
            }
            if (forcemaximum) {
              show = `(${forcemaximum} allowed)`;
            }
            if (forceminimum && forcemaximum) {
              show = `(${forceminimum} required & ${forcemaximum} allowed)`;
            }
            return (
              <Form.Group className="form-group formCheckbox">
                <Form.Label>{titleCase(sub_modifier.modifiername)}</Form.Label>
                <small>{show}</small>
                {sub_modifier[sub_modifier_type].map((display_sub_item) => {
                  if (!display_sub_item.price) {
                    display_sub_item.price = 0;
                  }
                  // display_sub_item.quantity = 1
                  return (
                    <Form.Check
                      type="checkbox"
                      // defaultChecked={display_sub_item.preselect === 1}
                      checked={openCheckboxes[display_sub_item.id] || false}
                      id={display_sub_item.id}
                      // label={display_sub_item.name}
                      label={
                        <div style={{
                          display: 'flex',
                          justifyContent: 'space-between'
                        }}>
                          <div>
                            {display_sub_item.name}{" "}
                            <span style={{ fontSize: "13px" }}>
                              ( $
                              {typeof display_sub_item.price == "string"
                                ? parseFloat(display_sub_item.price).toFixed(2)
                                : display_sub_item.price}
                              )
                            </span>
                          </div>
                          <div>
                            {/* {console.log('display_sub_item', display_sub_item)} */}
                            {(sub_modifier_type == "addon" && display_sub_item.allowmultiple) ?
                              <div className="quantity-input">
                                <button
                                  disabled={quantityCountList[display_sub_item.id] == 1 ? true : false}
                                  type="button"
                                  className="quantity-input__modifier quantity-input__modifier--left"
                                  onClick={(e) => handleAddonCheckBox(e, display_sub_item, 'minus')}
                                >
                                  <i class="fa-solid fa-minus"></i>
                                </button>
                                <input
                                  disabled={true}
                                  className={`quantity-input__screen`}
                                  type="text"
                                  value={quantityCountList[display_sub_item.id]}
                                />
                                <button
                                  // disabled={!item.active || (item.defaultQuantity == item.itemQuantity)}
                                  type="button"
                                  className="quantity-input__modifier quantity-input__modifier--right"
                                  onClick={(e) => handleAddonCheckBox(e, display_sub_item, 'plus')}
                                >
                                  <i class="fa-solid fa-plus"></i>
                                </button>
                              </div> : null
                            }
                          </div>
                        </div>
                      }
                      onClick={(e) => {
                        display_sub_item.preselect =
                          !display_sub_item.preselect;
                        display_sub_item.parent_id = sub_modifier.SK;
                        display_sub_item.added_by = nested_data.name;
                        display_sub_item.depth = 2;
                        display_sub_item.modifierstatus = {
                          forceminimum, forcemaximum,
                        };
                        display_sub_item.nestedData = true
                        let isStatus = onCheckBoxHandler(display_sub_item, `${sub_modifier_type}`);
                        if (!isStatus.status) {
                          if (isStatus.message) {
                            toastMessages("error", isStatus.message);
                          }
                          toggleCheckbox(e.target.id, false);
                        } else {
                          toggleCheckbox(e.target.id, true);
                        }

                      }}
                    />
                  );
                })}
              </Form.Group>
            );
          }
        })}
    </div>
  );
};
