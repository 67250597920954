import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import "../common/header/header.scss";
import AvatarImg from "../../components/shared/assets/images/people.png";

import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  logoutHandlerFunctionality,
  toggleSidebarFunctionality
} from "../../features/todoSlice";
import { redirection } from "../../features/helper";
import toastMessages from "../toastMessages";
import { allApiUrl } from "../../api/apiRoute";
import { apiGetMethod } from "../../api/rest";

function DeveloperHeader(props) {
  let history = useHistory()
  let dispatch = useDispatch()
  const body = document.body;
  const toggle = document.querySelector(".toggle-inner");

  let typeOfUser = useSelector((state) => state.counter.typeOfUser)
  let isDeveloperMode = useSelector((state) => state.counter.isDeveloperMode)
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  useEffect(() => {
    if (userInfo && userInfo.image && userInfo.image.includes("X-Amz-Date")) {
      onExpireDateHandler()
    }
    const isLoggedIn = JSON.parse(localStorage.getItem("isLoggedIn"));
    let userInfoType = typeof userInfo;
    if (isLoggedIn == true && userInfoType !== null) { //eslint-disable-line
    } else {
      history.push("/login");
    }
    redirection(typeOfUser, history, toastMessages, isDeveloperMode)
  }, [])

  function onExpireDateHandler() {
    if (userInfo && userInfo.image) {
      // console.log('-------52-----', userInfo)
      let data = userInfo.image.split("&")
      let XamzDate
      let XamzExpire
      if (data && data.length > 0) {
        data.map((item) => {
          if (item.split('=')[0] == "X-Amz-Date") XamzDate = item.split('=')[1]
          if (item.split('=')[0] == "X-Amz-Expires") XamzExpire = item.split('=')[1]
        })
      }
      const year = XamzDate.substring(0, 4);
      const month = XamzDate.substring(4, 6);
      const day = XamzDate.substring(6, 8);
      const hours = XamzDate.substring(9, 11);
      const minutes = XamzDate.substring(11, 13);
      const seconds = XamzDate.substring(13, 15);
      const date = new Date(`${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`);
      const formattedTime = date.toISOString().replace(/\.\d{3}Z$/, 'Z')
      let expireTimeStamp = new Date(formattedTime).getTime() + (parseInt(XamzExpire) * 1000)
      // if (userInfo?.image?.split('&')[1]) {
      // let profileExpireTime = userInfo?.image?.split('&')[1].substring(8)
      // console.log('profileExpireTime', profileExpireTime)
      if (expireTimeStamp <= new Date().getTime()) {
        getProfile()
      }
      // }
    }
    else {
      // console.log('-------60-----')
      getProfile()
    }
  }

  async function getProfile() {
    try {
      let header = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      };
      const response = await apiGetMethod(allApiUrl.profile, "", header);
      if (response.status === 200) {
        localStorage.setItem(
          "userInfo",
          JSON.stringify({
            ...userInfo,
            image: response.data.user.image,
          })
        );
      }
    } catch (err) {
      console.log("err", err);
    }
  }
  function logoutHandler() {
    history.push('/login')
    body.classList.remove("dark-mode");
    toggle.classList.remove("toggle-active");
    localStorage.removeItem("userInfo");
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("accessToken");
    let isDarkMode = JSON.parse(localStorage.getItem("darkMode"))
    if (isDarkMode) {
      localStorage.setItem("darkMode", true);
    }
    else {
      localStorage.setItem("darkMode", false);
    }
    dispatch(logoutHandlerFunctionality())
  }
  return (
    <header className="header">
      <Container fluid>
        <div className="header__inner">
          <div className={`header__left ${props.active ? "resize" : ""}`}>
            <div className="menuIcon">
              <div
                className={`menuIcon__button ${props.active ? "active" : ""}`}
                onClick={() => dispatch(toggleSidebarFunctionality(!props.active))}>
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </div>
          <div className={`header__right ${props.active ? "resize" : ""}`}>
            <div className="headerAvatar" >
              <Dropdown className="headerAvatar__button" autoClose="outside">
                <Dropdown.Toggle id="dropdown-autoclose-outside">
                  <img src={userInfo && userInfo.image ? userInfo.image : AvatarImg} alt="AvatarImg" />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {props.page !== "profile" && <Dropdown.Item href="/profile?developerMode=true">Profile</Dropdown.Item>}
                  <Dropdown.Item
                    onClick={logoutHandler}>
                    Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      </Container>
    </header>
  );
}
export default DeveloperHeader;
