import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import Flickity from "flickity";
import "flickity/dist/flickity.min.css";

function Slider(props) {
  const [flickityReady, setFlickityReady] = useState(false);
  const flickityNodeRef = useRef(null);
  const flickityRef = useRef(null);

  useEffect(() => {
    flickityRef.current = new Flickity(
      flickityNodeRef.current,
      props.options || {}
    );

    setFlickityReady(true);

    return () => {
      flickityRef.current.destroy();
    };
  }, [props.options]);

  useEffect(() => {
    if (flickityReady) {
      flickityRef.current.reloadCells();
      flickityRef.current.resize();
      flickityRef.current.updateDraggable();
    }
  }, [flickityReady, props.children]);

  const renderPortal = () => {
    if (!flickityNodeRef.current) {
      return null;
    }

    const mountNode = flickityNodeRef.current.querySelector(".flickity-slider");

    if (mountNode) {
      return ReactDOM.createPortal(props.children, mountNode);
    }
  };

  return (
    <div className={"test"} key="flickityBase" ref={flickityNodeRef}>
      {renderPortal()}
    </div>
  );
}

export default Slider;
