import React, { useState, useEffect } from "react";
import Select from 'react-select';
import { Button, Badge, Dropdown, Table, Container, Row, Col, Image, ListGroup, Form, FloatingLabel, InputGroup, Pagination } from 'react-bootstrap';
import { Link, useHistory, useLocation } from 'react-router-dom';
import queryString from "query-string";

//Header
import Header from '../common/header';
import Sidebar from '../common/sidebar';
import { AddChoiceItem } from "../add-choices-addons";
import { apiGetMethod, apiPutMethod } from "../../api/rest";
import toastMessages from "../toastMessages";
import { allApiUrl } from "../../api/apiRoute";
import { useSelector } from "react-redux";
import CommonButton from "../common-ui/Button";
import SidebarTwo from "../common/sidebarTwo";

function EditChoicesAddons() {
    const currBranch = useSelector((state) => state.counter.currBranch)
    const branchArray = useSelector((state) => state?.counter?.branchArray)
    const [permissionData, setPermissionData] = useState({})
    let branches = branchArray.map((item) => item.value)
    const header = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        branchid: JSON.stringify(branches)
    };
    useEffect(() => {
        getAllModifier()
        getAllProducts()
    }, [currBranch.value, branchArray])
    const location = useLocation()
    const history = useHistory()
    let { id } = queryString.parse(location.search);
    const [active, setActive] = React.useState(false)
    const [modifierList, setModifierList] = useState([])
    const [addChoiceCount, setAddChoiceCount] = useState(0)
    const [addVariationCount, setAddVariationCount] = useState(0)
    const [addAddonCount, setAddAddonCount] = useState(0)
    const [loader, setLoader] = useState({ save: false })
    const [sidebarType, setSidebarType] = useState('singleSidebar')
    const [productList, setProductList] = useState([])
    const [initialProductList, setInitialProductList] = useState([])
    const [displayProduct, setDisplayProduct] = useState([])
    const [isShowError, setisShowError] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [currForm, setCurrForm] = useState({
        modifiername: '',
        setName: '',
        modifiertype: 'variation',
        modifierstatus: {
            modifiretype: "optional",
            forceminimum: "0",
            forcemaximum: "0"
        },
        required: 0,
        maximum: 0,
        variation: [],
        addon: [],
        choices: []
    })
    // console.log()
    const [form, setForm] = useState({
        modifiername: '',
        setName: '',
        modifiertype: 'variation',
        modifierstatus: {
            modifiretype: "optional",
            forceminimum: "0",
            forcemaximum: "0"
        },
        required: 0,
        maximum: 0,
        variation: [],
        addon: [],
        choices: []
    })
    async function getAllProducts() {
        try {
            const response = await apiGetMethod(`${allApiUrl.view_all_product}`, "", header);
            if (response.status === 200) {
                let updatedProductList = response.data.categories.Items.map((item) => {
                    return {
                        value: item.SK,
                        label: item.productname,
                        modifierset: item.modifierset
                    }
                })
                setProductList(updatedProductList)
                setInitialProductList(updatedProductList)
                let productset = []
                updatedProductList.map((item) => {
                    let isExist = item.modifierset.filter((filterItem) => filterItem.value == id)
                    if (isExist.length) productset.push(item)
                })
                productset = productset.map((item) => {
                    return {
                        productName: item.label,
                        productId: item.value,
                        modifierset: item.modifierset
                    }
                })
                setDisplayProduct(productset)
                getAddonDetails(productset)
            }
        } catch (err) {
            console.log("err", err);
        }
    }
    async function getAddonDetails(productset) {
        try {
            const response = await apiGetMethod(allApiUrl.view_modifier, `/${id}`, header);
            if (response.status === 200) {
                const initialCurrForm = JSON.parse(JSON.stringify(response.data.data.Items[0]));
                setForm({ ...initialCurrForm, id: parseInt(id), productset: productset, modifierId: response.data.data.Items[0].SK });
                setCurrForm({ ...initialCurrForm, productset: productset });
                setAddAddonCount(initialCurrForm.addon.length)
                setAddVariationCount(initialCurrForm.variation.length)
                setAddChoiceCount(initialCurrForm.choices.length);
                setIsLoading(false)
                if (response.data.data.Items[0].branchId !== (branchArray && branchArray[0].value)
                    || (branchArray.length > 1)
                    || (branchArray && branchArray[0].value == "All")) {
                    history.push('/modifiers')
                }
            }
        } catch (err) {
            toastMessages("error", err.data.message);
            if (err.data.message == "Session Expired.") {
                localStorage.clear();
                history.push('/login')
            }
        }
    }
    console.log('formdata', form)
    async function editModifier(e) {
        e.preventDefault()
        setLoader({ ...loader, save: true })
        setisShowError(true)
        const allNameAndPriceFilled = form.choices.every(item => item.name && item.price);
        let defaultSelect = form.choices.filter((item) => item.preselect == 1).length
        async function editProductFunction() {
            // const formData = {}
            // if (form.modifiername !== currForm.modifiername) {
            //     formData['modifiername'] = form.modifiername
            // }
            // if (form.modifierstatus.modifiretype !== currForm.modifierstatus.modifiretype ||
            //     form.modifierstatus.forcemaximum !== currForm.modifierstatus.forcemaximum ||
            //     form.modifierstatus.forceminimum !== currForm.modifierstatus.forceminimum) {
            //     // console.log('form----->', form)
            //     if (form.modifierstatus.modifiretype == "optional") {
            //         // console.log('changed the data')
            //         formData['modifierstatus'] = {
            //             forcemaximum: "0",
            //             forceminimum: "0",
            //             modifiretype: "optional"
            //         }
            //     }
            //     else {
            //         if (form.modifierstatus.forcemaximum == "") {
            //             form.modifierstatus.forcemaximum = "0"
            //         }
            //         if (form.modifierstatus.forceminimum == "") {
            //             form.modifierstatus.forceminimum = "0"
            //         }
            //         formData['modifierstatus'] = form.modifierstatus
            //     }
            // }
            // formData['productset']=form.productset

            // formData['choices'] = form.choices
            let type = form.modifiertype.toLowerCase()
            let count = 0
            form[type].forEach((item) => {
                if (item.preselect) {
                    count = count + 1
                }
            })
            if (count > parseFloat(form.maximum) && parseFloat(form.maximum) !== 0) {
                toastMessages('error', `preselect ${type} cannot be greater than maximum`)
                setLoader({ ...loader, save: false });
            }
            else {
                try {
                    const response = await apiPutMethod(
                        allApiUrl.update_modifier,
                        form,
                        header,
                        id
                    );
                    if (response.status === 200) {
                        toastMessages("success", response.data.message);
                        history.push('/modifiers')
                        setLoader({ ...loader, save: false })
                    }
                } catch (err) {
                    setLoader({ ...loader, save: false })
                    toastMessages("error", err.data.message);
                }
            }
        }

        // if (form.modifiername && form.modifierstatus && allNameAndPriceFilled == true) {   //eslint-disable-line
        //check if variation ,addon ,choices are empty if empty then show error
        let isModifierEmpty = false
        console.log('form.addon.length)', form.type)
        if (form.modifiertype == "choices") {
            if (!form.choices.length) {
                console.log('208')
                isModifierEmpty = true
            }
        }
        else if (form.modifiertype == "addOn") {

            if (!form.addon.length) {
                console.log('215')
                isModifierEmpty = true
            }
        } else {
            if (!form.variation.length) {
                console.log('220')
                isModifierEmpty = true
            }
        }
        console.log('isModifierEmpty', isModifierEmpty)
        //check if yours modifier names are empty or not 
        let isNameAndPriceFilledforModifiers = false
        if (form.modifiertype == "choices") {
            let choiceCheck = form.choices.every((item) => item.name)
            if (choiceCheck) isNameAndPriceFilledforModifiers = true
        } else if (form.modifiertype == "addOn") {
            let addonCheck = form.addon.every((item) => item.name)
            let checkprice = form.addon.every((item) => item.price)
            console.log('--221--', addonCheck, checkprice)
            if (addonCheck && checkprice) isNameAndPriceFilledforModifiers = true
        } else {
            let variationCheck = form.variation.every((item) => item.name)
            if (variationCheck) isNameAndPriceFilledforModifiers = true
        }
        console.log('---227---', !isModifierEmpty && isNameAndPriceFilledforModifiers, isModifierEmpty, isNameAndPriceFilledforModifiers)
        if (form.modifiername && form.setName &&
            !isModifierEmpty &&
            isNameAndPriceFilledforModifiers) {   //eslint-disable-line
            if (form.modifiertype == "variation") {
                editProductFunction()
            }
            else {
                //To check if Force minimum cannot be greater than Force maximum
                if ((parseInt(form.required) > parseInt(form.maximum)) &&
                    (parseInt(form.maximum) != 0)) { //eslint-disable-line
                    toastMessages("error", 'required cannot be greater than maximum')
                    setLoader({ ...loader, save: false });
                }
                //case to handle the both the zero in maximum and minimum
                else if (parseInt(form.maximum) == 0 && parseInt(form.required) == 0) {
                    editProductFunction()
                }
                //To check the number of pre-selected choices should be less than the force maximum
                // else if ((form.modifierstatus.modifiretype == "mandatory")
                //     && (defaultSelect > parseInt(form.modifierstatus.forcemaximum))
                //     && parseInt(form.modifierstatus.forceminimum) == 0) {
                //     toastMessages("error", 'The number of Pre Selected choices should be less than the Force Maximum.')
                //     setLoader({ ...loader, save: false });
                // }
                else {
                    //force minimum check
                    if (form.modifiertype == "addOn") {
                        if (parseInt(form.required) > form.addon.length) {
                            toastMessages("error", `Select at least ${form.required} addOn to continue.`)
                            setLoader({ ...loader, save: false });
                        }
                        else {
                            editProductFunction()
                        }
                    }
                    else {
                        if (parseInt(form.required) > form.choices.length) {
                            toastMessages("error", `Select at least ${form.required} choices to continue.`)
                            setLoader({ ...loader, save: false });
                        }
                        else {
                            editProductFunction()
                        }
                    }
                }
            }
        }
        else {
            toastMessages("error", "Please fill the required fields")
            setLoader({ ...loader, save: false });
        }


    }
    async function getAllModifier() {
        try {
            const response = await apiGetMethod(`${allApiUrl.view_all_modifier}`, "", header);
            if (response.status === 200) {
                setModifierList(response.data.categories.Items)
            }
        } catch (err) {
            console.log("err", err);
        }
    }
    function onProductSelect(e, dataSet) {
        e.preventDefault()
        let { value, label, modifierset } = dataSet
        console.log('-----655----', dataSet)
        if (displayProduct) {
            let newDisplayProduct = {
                productName: label,
                productId: value,
                modifierset
            }
            setDisplayProduct((prev) => [...prev, newDisplayProduct])
        }
        setForm((prevData) => {
            let isAlreadyExist = modifierset.find((item) => item.value == form.modifierId)
            //check is modifier is already exist 
            if (!isAlreadyExist) {
                modifierset.push({
                    value: form.modifierId,
                    label: form.modifiername
                })
            }
            let newEntry = {
                productName: label,
                productId: value,
                modifierset
            }
            //checking id product is already existin in product set array if exist them add the modifier there only dont create new listing
            let isProductExist = prevData.productset.find((item) => item.productId == value)
            console.log('isProductExist', isProductExist)
            if (isProductExist) {
                isProductExist.modifierset = modifierset
            } else {
                prevData.productset = [...prevData.productset, newEntry]
            }
            console.log('prevData ---297--', prevData.productset)
            return { ...prevData }
        })
    }
    function onDeleteSelectedProductHandler(e, dataSet) {
        e.preventDefault()
        //for handling display product deletion 
        if (form.productset.length) {
            let updatedProductset = displayProduct.filter((item) => item.productId !== dataSet.productId)
            setDisplayProduct(updatedProductset)
        }
        //for handling the modifier 
        setForm((prevData) => {
            prevData.productset.map((prodItem) => {
                prodItem.modifierset = prodItem.modifierset.filter((item) => {
                    if (prodItem.productId == dataSet.productId) {
                        return item.value != form.modifierId
                    } else {
                        return item
                    }
                })
            })
            return { ...prevData }
        })
    }
    let sidebarToggle = useSelector((state) => state.counter.sidebarToggle)
    let sidebarTypeRedux = useSelector((state) => state.counter.sidebarType)
    function onSearchHandler(e) {
        if (e.target.value !== "") {
            setProductList(productList.filter((item) => {
                if (item.label.toLowerCase().includes(e.target.value.toLowerCase())) {
                    return item
                }
            }))
        } else {
            setProductList(initialProductList)
        }
    }
    let props = {
        form,
        setForm,
        addChoiceCount,
        setAddChoiceCount,
        addAddonCount,
        setAddAddonCount,
        setAddVariationCount,
        addVariationCount,
        setisShowError,
        isShowError,
        isLoading,
        modifierList,
        setModifierList,
        productList,
        onProductSelect,
        onDeleteSelectedProductHandler,
        displayProduct,
        onSearchHandler,
        id
    }
    return (
        <React.Fragment>
            <Header setActive={setActive} active={sidebarToggle} />
            <div className="interFaceWrap">
                <Sidebar active={sidebarToggle} activePage="modifiers" permissionData={permissionData} />
                <div className={`interFaceWrap__inner ${sidebarTypeRedux == false ? "doubleSidebar" : "singleSidebar"}`}>
                    <div className="commonOuter">
                        <div className="pageHeader">
                            <Container fluid className="px-0">
                                <Row className="align-items-center">
                                    <div className="col">
                                        <div className="headingText headingText--textLeft mb-0">
                                            <h1>Edit Modifier's</h1>
                                        </div>
                                    </div>
                                    <div className="col-auto ms-auto onlyButtons">
                                        <div className="pageHeader__elements">
                                            <Button type="button" className="commonButton commonButton--delete" onClick={() => history.push('/modifiers')}>Cancel</Button>
                                            <CommonButton
                                                buttonName="Update"
                                                type="half"
                                                onClick={editModifier}
                                                loader={loader.save}
                                            />
                                        </div>
                                    </div>
                                </Row>
                            </Container>
                        </div>
                        <AddChoiceItem {...props} />
                    </div>
                </div>
                <SidebarTwo
                    activePage=""
                    setSidebarType={setSidebarType}
                    setPermissionData={setPermissionData}
                    sidebarType={sidebarType} />
            </div>
        </React.Fragment>
    )
}
export default EditChoicesAddons;