import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
//CSS
import "./sidebar.scss";
//Icons
import chartPieIcon from "../../shared/assets/sidebar/chart-pie.svg";
import profileIcon from "../../shared/assets/sidebar/profile.svg";
import settingsIcon from "../../shared/assets/sidebar/settings.svg";
import branchesIcon from "../../shared/assets/sidebar/branches.svg";
import usersIcon from "../../shared/assets/sidebar/users.svg";
import categoriesIcon from "../../shared/assets/sidebar/categories.svg";
import productsIcon from "../../shared/assets/sidebar/products.svg";
import choicesAddonsIcon from "../../shared/assets/sidebar/choices-addons.svg";
import tableManagementIcon from "../../shared/assets/sidebar/table-management.svg";
import ordersIcon from "../../shared/assets/sidebar/orders.svg";
import digitalMenuIcon from '../../shared/assets/sidebar/digital-menu.svg'
import reportsIcon from "../../shared/assets/sidebar/reports.svg";
import websiteBuilder from "../../shared/assets/sidebar/website-builder.svg";
import transactionsIcon from "../../shared/assets/sidebar/transactions.svg";
import CustomTooltip from "../tooltip";
import { titleCase } from "../../../features/helper";
import { toggleDigitalMenu } from "../../../features/todoSlice";

function Sidebar(props) {
  const tableManagement = useSelector((state) => state.counter.disableTableManagment)
  const websiteTemplate = useSelector((state) => state.counter.disableWebsiteTemplate)
  // const websiteTemplate = useSelector((state) => state.counter.disableWebsiteTemplate)
  const showDisplayMenu = useSelector((state) => state.counter.showDisplayMenu)
  const dispatch = useDispatch()
  // console.log('showDisplayMenu', showDisplayMenu)
  // console.log('websiteTemplate', websiteTemplate)
  // console.log('permissionsData--33', props.permissionData)
  // useEffect(() => {
  //   console.log('first useEffect')
  //   if (props.permissionData) {
  //     if (props.permissionData && props.permissionData.digitalmenu == 0) {
  //       dispatch(toggleDigitalMenu(false));
  //     } else {
  //       dispatch(toggleDigitalMenu(true));
  //     }
  //   }
  // }, [props.permissionData])
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [sidebarList, setSidebarList] = useState()
  let branch = useSelector((state) => state.counter.currBranch)
  let branchArray = useSelector((state) => state.counter.branchArray)
  const history = useHistory();
  //Dark Mode Toggle
  const [currTab, setCurrTab] = useState("");
  const [darkMode, setDarkMode] = React.useState(
    JSON.parse(localStorage.getItem("darkMode")) ? JSON.parse(localStorage.getItem("darkMode")) : false
  );
  useEffect(() => {
    if (localStorage.getItem("darkMode") === null) {
      setDarkMode(false)
    }
  }, []);
  useEffect(() => {
    if (localStorage.getItem("darkMode") !== undefined) {
      localStorage.setItem("darkMode", false);
    }
  }, [darkMode]);
  useEffect(() => {
    localStorage.setItem("darkMode", darkMode);
  }, [darkMode]);
  useEffect(() => {
    const body = document.body;
    const toggle = document.querySelector(".toggle-inner");
    // If dark mode is enabled - adds classes to update dark-mode styling.
    // Else, removes and styling is as normal.
    if (darkMode === true) {
      // console.log('---44-----', darkMode)
      body.classList.add("dark-mode");
      toggle.classList.add("toggle-active");
    } else {
      // console.log('---56-----', darkMode)
      body.classList.remove("dark-mode");
      toggle.classList.remove("toggle-active");
    }
  }, [darkMode]);
  useEffect(() => {
    setCurrTab(props.activePage);
  }, []);
  function activTabHandler(value) {
    setCurrTab(value);
    history.push(`/${value}`);
  }

  let customSideBar = [
    {
      label: 'Dashboard', url: 'dashboard', imgSrc: chartPieIcon
    },
    {
      label: 'Stores', url: 'stores', imgSrc: branchesIcon
    },
    {
      label: 'Users', url: 'users', imgSrc: usersIcon
    },
    {
      label: 'Categories', url: 'categories', imgSrc: categoriesIcon
    },
    {
      label: 'Products', url: 'products', imgSrc: productsIcon
    },
    {
      label: 'Modifiers', url: 'modifiers', imgSrc: choicesAddonsIcon
    },
    {
      label: 'Table Management', url: 'table-management', imgSrc: tableManagementIcon
    },
    {
      label: 'Orders', url: 'orders', imgSrc: ordersIcon
    },
    {
      label: 'Digital Menu', url: 'digital-menu', imgSrc: digitalMenuIcon
    },
    {
      label: 'Reports', url: 'reports', imgSrc: reportsIcon
    },
    {
      label: 'Website Builder', url: 'website-builder', imgSrc: websiteBuilder
    },
    {
      label: 'Transactions', url: 'transactions', imgSrc: transactionsIcon
    },
    {
      label: 'Profile', url: 'profile', imgSrc: profileIcon
    },
    {
      label: 'Settings', url: 'settings', imgSrc: settingsIcon
    },
  ]
  let AllSidebar = [
    {
      label: 'Dashboard', url: 'dashboard', imgSrc: chartPieIcon
    },
    {
      label: 'Stores', url: 'stores', imgSrc: branchesIcon
    },
    {
      label: 'Users', url: 'users', imgSrc: usersIcon
    },
    {
      label: 'Reports', url: 'reports', imgSrc: reportsIcon
    },
    {
      label: 'Transactions', url: 'transactions', imgSrc: transactionsIcon
    },
    {
      label: 'Profile', url: 'profile', imgSrc: profileIcon
    }
  ]
  useEffect(() => {
    console.log('second useEffect')
    //Handled the table management tab in case of it is disabled from setting module.
    if (tableManagement == false) {
      customSideBar = customSideBar.filter(item => item.label !== 'Table Management');
    }
    //Handled the website template tab in case of it is disabled from setting module
    if (websiteTemplate == false) {
      customSideBar = customSideBar.filter(item => item.label !== 'Website Builder');
    }
    //Handle if digital menu permission is given or not if not then remove the option from sidebar
    if (showDisplayMenu == false) {
      customSideBar = customSideBar.filter(item => item.label !== 'Digital Menu');
    }
    //if roleId is Manager or Owner then remove the Store From the side bar from both AllSidebar and custom sidebar
    if (userInfo && userInfo.roleId) {
      if (userInfo.roleId == "Manager" || userInfo.roleId == "Owner") {
        AllSidebar = AllSidebar.filter(item => item.label !== 'Stores');
        customSideBar = customSideBar.filter(item => item.label !== 'Stores');
      }
    }
    //if all branches are selected from header than show All Sidebar else show the custom sidebar 
    let isAllBranchPresent = branchArray.find((findItem) => findItem.label == "All")
    if (isAllBranchPresent || branchArray.length > 1) {
      setSidebarList(AllSidebar);
    } else {
      setSidebarList(customSideBar);
    }
  }, [branch.label, branchArray, showDisplayMenu, tableManagement, websiteTemplate])
  function darkModeHandler(mode) {
    // Cookies.set('darkMode', mode.toString(), { expires: oneMinutes })
    if (mode) {
      setDarkMode(true)
    } else {
      setDarkMode(false)
    }
  }
  console.log('customSideBar', customSideBar)
  // console.log('-----269-----sidebar', localStorage.getItem('darkMode'))
  return (
    <div className={`sidebar ${props.active ? "slideToggle" : ""}`}>
      <div className="sidebar__list">
        <ul>
          {
            sidebarList && sidebarList.map((item, index) => {
              return (
                <li onClick={() => activTabHandler(item.url)} key={`${index}`}>
                  <Link className={currTab === item.url ? "active" : ''} to="/">
                    {props.active ? <CustomTooltip tooltipText={props.active ? titleCase(item.url) : ''} placement="right">
                      <img src={item.imgSrc} alt="chartPieIcon" />
                    </CustomTooltip> : <img src={item.imgSrc} alt="chartPieIcon" />

                    }
                    <span>{item.label}</span>
                  </Link>
                </li>
              )
            })
          }
        </ul>
      </div>
      <div className="darkModeToggle">
        <h4>Dark Mode</h4>
        <div id="toggle" onClick={() => (darkMode === false) ?
          darkModeHandler(true) :
          darkModeHandler(false)
        }
        >
          <div className="toggle-inner" />
        </div>
      </div>
    </div>
  );
}
export default Sidebar;
