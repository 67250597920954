import React from "react";
import { CardElement } from "@stripe/react-stripe-js";

const CARD_ELEMENT_OPTIONS = {
  hidePostalCode: true,
  style: {
    base: {
      color: "#303238",
      fontSize: "16px",
      fontFamily: "sans-serif",
      fontSmoothing: "antialiased",
      "::placeholder": {
        color: "#CFD7DF",
      },
    },
    invalid: {
      color: "#e5424d",
      ":focus": {
        color: "#303238",
      },
    },
  },
};

function CardSection() {
  return (
    <div>
      {/* <label>Card details</label> */}
      {/* <p
        style={{
          fontSize: "13px",
          paddingRight: "275px",
          fontWeight: "500",
        }}
      >
        <small>Card Number</small>
      </p> */}
      <div className="cardNumber-section">
        <CardElement options={CARD_ELEMENT_OPTIONS} />
      </div>
    </div>
  );
}

export default CardSection;
